<form [formGroup]="Customreport">
    <p-toast position="top-right" key="popup"></p-toast>
    <div class="p-grid table-demo">
      <div class="p-col-12">
        <div class="card" style="min-height:600px;">
          <div class="card-header"  style="border-radius: 5px;height:40px; width:99.8% " >
            <h5 style="color:#495057; padding:15px 0 0 15px;"></h5>
            <span class="p-input-icon-left" style="float:right;">
              <button pButton type="button" label="Create Project Description" (click)="projDescName = '';editProjDescId = ''; projDescdisplay = true;createProjectDescform.reset();responseError='';"
                class="p-button-raised p-button-primary p-mr-2 p-mb-2" icon="pi pi-plus" iconPos="left"
                style="margin-right:5px;"></button> 
            </span>
          </div>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
            <h5 style="color:#495057; padding:15px 0 0 15px;">Project Description</h5>
            <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
  
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float:right; width:25px; height:25px;"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right;height:25px; width:25px; "></button>
            </span>
          </div>
  
          <div class="invoice invoice-header" id="invoice-content">
            <p-table #dt [value]="ProjectDescList" id="excel-table" dataKey="id" styleClass="p-datatable-striped" [rowHover]="true"
              [rows]="3" [paginator]="false" [filterDelay]="0">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="reportName">
                    Project Description Name
                    <p-sortIcon field="projectName"></p-sortIcon>
                  </th>
                  <!-- <th pSortableColumn="created By">
                    Created By
                    <p-sortIcon field="craetedby"></p-sortIcon>
                  </th> -->
                  <th style="width:100px;">
                    Actions
                  </th>
                </tr>
                <tr>
                  <th>
                    <input pInputText type="text" (input)="
                      dt.filter($event.target.value, 'projectDescriptionName', 'contains')
                    " placeholder="Project Description Name" class="p-column-filter"
                      style="width: 95%; height:25px; padding-left:15px;" />
                  </th>
                  <!-- <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'createdByName', 'contains')"
                      placeholder="Created By" class="p-column-filter"
                      style="width: 95%; height:25px; padding-left:15px;" />
                  </th> -->
                  <th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-ProjectDescList>
                <tr class="p-selectable-row">
                  <td style="padding-left:15px;">
                    <span class="data-style">{{ ProjectDescList.projectDescriptionName }}</span>
                  </td>
                  <!-- <td style="padding-left:15px;">
                    <span class="data-style">{{ ProjectDescList.createdByName }}</span>
                  </td> -->
                  <td style="text-align:center;">
                    <span>
                      <button pButton pRipple type="button" (click)="onProjectDescEdit(ProjectDescList.projectDescriptionId, ProjectDescList.projectDescriptionName)"
                        icon="pi pi-pencil" pTooltip="Edit" tooltipPosition="right"
                        class="p-button-raised p-button-primary" style="margin-left:15px;height:25px; width:25px;">
                      </button>
                      <button pButton pRipple type="button" (click)="onProjectDescDelete(ProjectDescList.projectDescriptionId, ProjectDescList.projectDescriptionName)"
                      icon="pi pi-trash" pTooltip="Delete" tooltipPosition="right"
                      class="p-button-raised p-button-danger" style="margin-left:15px;height:25px; width:25px;">
                    </button>
                    </span>
                  </td>
                </tr>
              </ng-template>
  
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="2">No Records found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  
  </form>
  
  <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
  </div>
  
  <p-dialog header="Delete Project Description" [(visible)]="projDescDeleteConfirm" modal="modal" showEffect="fade" 
  [style]="{ width: '40%' }">
  <hr>
  
  Are you sure, you want to delete {{deleteProjDescName}} ?
  <br /><br />
  
  <span>
    <div class="p-col-12 p-md-12" style="vertical-align: center">
      <small class="p-invalid" style="float:right;">{{responseError}}</small>
    </div>
    <div class="p-col-12 p-md-12" style="float: left;">
      <button pButton type="button" label="Delete" class="p-button-danger" icon="pi pi-trash"
        iconPos="left" style="margin-left: 10px; width: 100px; float: right"
        (click)="onProjectDescDeleteConfirmed()"></button>
    </div>
  </span>
  </p-dialog>

  <div [formGroup]="createProjectDescform">
    <p-dialog header="Project Description" [(visible)]="projDescdisplay" modal="modal" showEffect="fade" 
      [style]="{ width: '40%' }">
      <hr>
      <label for="projDescName">Project Description: <span style="color: red">* </span>
      </label>
      <!-- <input #newProjectDesctxt type="text" pInputText placeholder="Project Description" required="true"  formControlName="newProjectDesc"
      [(ngModel)]="projDescName" requiredMessage="The input must not be empty" /> -->
      <textarea
        id="projDescNameid"
        type="text"
        rows="4"
        cols="60"
        [(ngModel)]="projDescName"
        formControlName="newProjectDesc"
        pInputTextarea
      ></textarea>
      <br /><br />

      <span>
        <div class="p-col-12 p-md-12" style="vertical-align: center">
          <small class="p-invalid" style="float:right;">{{responseError}}</small>
        </div>
        <div class="p-col-12 p-md-12" style="float: left;">
          <button pButton type="button" label="Save" class="p-button-success" icon="pi pi-check"
            iconPos="left" style="margin-left: 10px; width: 100px; float: right"
            (click)="onProjectDescSubmit()"></button>
        </div>
      </span>
    </p-dialog>
  </div>