<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-grid p-fluid p-formgrid">
                <div class="p-field p-col-12 p-lg-12 p-md-12 p-sm-12">
                    <div class="card-header" id="cardheader"
                        style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto;">
                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">Project Management Help Guide</h5> 
                       <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                            <button *ngIf="showplus" pButton pRipple type="button" icon="pi pi-plus" pTooltip="Expand" (click)="showexpand1()"
                                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right;height:25px; width:25px;"></button>

                                <button *ngIf="showminus" pButton pRipple type="button" icon="pi pi-minus" pTooltip="Collapse" (click)="showexpand1()"
                                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right;height:25px; width:25px;"></button>                           
                        </span>
                    </div><br><br>
                    <!-- <div class="card-header">
                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">My Documents</h5>                        
                    </div> -->
                    <p-accordion>
                        <p-accordionTab header="Projects" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Projects">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>                                          
                                            <span class="overview-title">Projects</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ProjectDetails">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title"> Project Details</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ProjectDetailsActions">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-3">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title"> Project Details: Actions</span>
                                        </div>
                                    </a>
                                </div>                             
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ProjectDescription">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-4">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Project Description</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="p-grid p-fluid p-formgrid" style="margin-top:20px;">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ProjectAttributes">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title"> Project Attributes</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#OverlapCycleTime">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Overlap Cycle Time</span>
                                        </div>
                                    </a>
                                </div>                               
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Milestones" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid" style="margin-top:20px;"> 
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Milestones">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Milestones</span>
                                    </div>
                                    </a>
                                </div>                            
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#MilestoneList">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Milestone List</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#MilestoneTaskBoard">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-3">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Milestone / Task Board</span>
                                    </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#MilestoneSchedule">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-4">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Milestone Schedule</span>
                                    </div>
                                    </a>
                                </div> 
                            </div>
                            <div class="p-grid p-fluid p-formgrid"  style="margin-top:20px;">
                            <div class="p-col-12 p-md-6 p-xl-3">
                                <a href="#EditMilestone">
                                <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                    <span class="overview-icon"><i class="pi pi-file"></i></span>
                                    <span class="overview-title">Edit Milestone</span>
                                </div>
                                </a>
                            </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Admin" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Reviews">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Reviews</span>
                                    </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ManageCustomAttributes">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Manage Custom Attributes</span>
                                    </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ProjectActualStartDate">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-3">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Project Actual Start Date</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="PLM Admin" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#OverlapDays">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Overlap Days</span>
                                    </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#MilestoneCycleTime">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Milestone Cycle Time</span>
                                    </div>
                                    </a>
                                </div>
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ProportionateCycleTime">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-3">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Proportionate Cycle Time</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>
        </div>

        <div class="card" style="min-height:600px;">
            <div class="p-grid p-formgrid p-fluid">
                <!-- <h3 style="margin-left: 20px;">My Documents</h3><br> --> 
                <div id="Projects">
                    <p style="font-size: large;margin-left: 20px;"><strong>Projects:</strong> Here we can find the project summary of the logged-in user. </p>
                    <img src="assets/gallery/projects.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">Once you click on the view icon, you can find the project details. Project details will have all the information about the specific project clicked, there you can see 2 sections left and right sides.</p>
                    <p style="font-size: large;margin-left: 20px;">The left section is showing the Project and its phases. P0, P1, P2…. EOL are the phases. Every phase will have tasks in it.</p>
                    <p style="font-size: large;margin-left: 20px;">The right section is showing the project details as a collapsible element; here complete details of the project are provided. Above the Project Details, you can see Actions Button.</p>
                    <br><br>
                </div>
                <div id="ProjectDetails">
                    <p style="font-size: large;margin-left: 20px;"><strong>Projects > Project Details: </strong> Here in the Project Details, we can see Phases and Milestones. </p>
                    <img src="assets/gallery/projectdetails.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the Project Details, we can also see the Project Description, Project Attributes and Overlap Cycle Time. Below the project details, we can also find the allocated milestones of the selected phase.</p>
                    <br><br>
                </div> 
                 <div id="ProjectDetailsActions">
                    <p style="font-size: large;margin-left: 20px;"><strong>Projects > Project Details > Actions:</strong> Above the Project Details, the user can see the Actions Button; if the user clicks on that button, the user can see action items.</p>
                    <img src="assets/gallery/projectdetailsactions.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br> 
                    <p style="font-size: large;margin-left: 20px;"><strong>Actions > View: (Edit Project Start Date & End Date):</strong> By clicking over here we can see the Project Details, and also user has an option to Edit Target Start Date and End Date of the Project.</p>
                    <img src="assets/gallery/projectdetailsactions1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;"><strong>Actions > Create Silicon:</strong> When the user clicks on the Create Silicon, the popup model is displayed with the following options. </p>
                    <img src="assets/gallery/projectdetailsactions2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">While creating a Product, the option to add Die code information will be provided. if not, Die code will be created later with the Change request and approval process.</p>
                    <p style="font-size: large;margin-left: 20px;">Every Project will have a Die Code. In the Silicon Layer user can revise the die code. Once the user changes the die code of the project then it will become another project.</p>
                    <p style="font-size: large;margin-left: 20px;">In the above screen, the user can see the Project Name is ESIA-DCC-TEST; if we create silicon, a new project will be created with the name ESIA-DCC-TEST-S2.</p>
                    <p style="font-size: large;margin-left: 20px;">In the above examples, the die codes shown are different from each other (i.e., ESIA-DCC-TEST vs ESIA-DCC-TEST-S2), so these are for two different projects respectively.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Actions > Create Package:</strong> If we click on the Create Package, a new package will be created for the existing project.</p>
                    <img src="assets/gallery/projectdetailsactions3.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">While creating the Project, the Package will be allocated, if the user decides to change or revise the package details, the user can create a package from this screen; the Project will be the same but it can be in a different package.</p>
                    <p style="font-size: large;margin-left: 20px;">For example, for the above project ESIA-DCC-TEST, the allocated package is WLCSP.</p>
                    <p style="font-size: large;margin-left: 20px;">That can be changed from WLCSP to the other options given in the dropdown list like Fanout WLP or something else from the list.</p>
                    <img src="assets/gallery/projectdetailsactions4.jpg" style="width: 30%;height:auto;display: block;margin-left: 35%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">After selecting the package, the Project name will be changed to identify the package. By default, the project will have a package; if the user creates a package, then the project will show as P2.</p>
                    <p style="font-size: large;margin-left: 20px;">For example, the Project name will be changed from ESIA-DCC-TEST to ESIA-DCC-TEST-P2. </p>
                    <p style="font-size: large;margin-left: 20px;">Here project is the same but the packages are two. Similar to this, user can create multiple packages.</p>
                    <br><br>
                </div>                    
                <div id="ProjectDescription">
                    <p style="font-size: large;margin-left: 20px;">Project Description: </p>
                    <img src="assets/gallery/projectdescription.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div> 
                <div id="ProjectAttributes">
                    <p style="font-size: large;margin-left: 20px;">Project Attributes:</p>
                    <img src="assets/gallery/projectattributes.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">These custom fields show which attributes are allocated to this project. Also, you can see that there is a field called Change Control Board “CCB Approval”/; Options, “No” in this field means change request is not necessary to go through the formal “CCB Approval”, whereas “Yes” means that the Change requests have to be approved by the approvers. Also, you can find the history of each of the changes that took place.</p>
                    <br><br>
                </div> 
                <div id="OverlapCycleTime">
                    <p style="font-size: large;margin-left: 20px;"><strong>Overlap Cycle Time:</strong> </p>
                    <img src="assets/gallery/overlapcycletime.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">This section gives you an idea about the overlap duration for the project. These changes are according to the allocation of the manpower for the particular task/ milestone to complete.</p>
                    <p style="font-size: large;margin-left: 20px;">In the project details, you can find the milestone details of the selected phase.</p>
                    <p style="font-size: large;margin-left: 20px;">Milestones are represented in 3 ways, Milestone list, Milestone/Task Board and Milestone schedule. </p>
                <br><br>
                </div> 
                <div id="Milestones">
                    <p style="font-size: large;margin-left: 20px;"><strong>Milestones:</strong> On this page, we can see milestones allocated for the projects.</p> 
                    <img src="assets/gallery/milestones.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>   
                    <p style="font-size: large;margin-left: 20px;">If we click on the view icon, we can see the view milestone page. </p>
                    <img src="assets/gallery/milestones1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br> 
                    <p style="font-size: large;margin-left: 20px;">On the above page all the fields are readable only.</p>
                    <br><br>
                </div>               
                <div id="MilestoneList">
                    <p style="font-size: large;margin-left: 20px;"><strong>Milestone List:</strong> This is the list of all milestones of the Selected Project.</p>
                    <img src="assets/gallery/milestonelist.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">If we click on the particular Phase such as P0 or P1, then we can see only Milestones allocated to the selected Phase. </p>
                    <img src="assets/gallery/milestonelist1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the above image, we can see P0 is highlighted, which means P0 is selected and the list of milestones displayed belongs to P0.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Milestone List:</strong> Here we can see the milestone details of the selected phase of the project, and that milestone also can be edited.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Milestone Id-</strong> it is always unique and every milestone is identified by its Id.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Milestone Name-</strong> it is a description of the task to be completed.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Assignee-</strong> Specified user/ users will drive the milestone.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Duration-</strong> Duration of the milestone. 
                    <p style="font-size: large;margin-left: 20px;"><strong>Target End Date-</strong> Date planned to end.
                    <p style="font-size: large;margin-left: 20px;"><strong>Start Date-</strong> When the milestone is planned to start, then “Current date” shows the Start Date with status as “New”. If a milestone is in progress, then “Start date” will show the actual start date. Click the help icon for more clarity.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>End Date-</strong> It shows the Current end date to finish the milestone. If a milestone is completed then End date will be the actual end date. Click the help icon for more clarity.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Status: “New”</strong> indicates that the milestone has not started yet, </p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Status: “In-Progress”</strong> indicates that the milestone Started,</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Status: “Completed”</strong> indicates that the milestone has been completed.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Status: “Not Required”</strong> indicates that the milestone is not required.</p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Actions-</strong> has an edit icon; once if we click on the icon, we will be redirected to the edit milestone page, where we can edit the milestone.</p>
                    <p style="font-size: large;margin-left: 20px;">In the below image if the user clicks on the Product Details link, then he/she will be redirected to Product Management Tasks directly.</p>
                    <img src="assets/gallery/milestonelist2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">If the user clicks on the help icon, then he/she can see the information about Start Date and End Date. </p>
                    <img src="assets/gallery/milestonelist3.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">The above image explains the Start Date and End Dates.</p>
                    <p style="font-size: large;margin-left: 20px;">If the user clicks on the Download button, the Milestone list will be downloaded.</p>
                    <p style="font-size: large;margin-left: 20px;">If the user clicks on Print Button, the Milestone list will be printed.</p>
                    <br><br>
                </div>
                <div id="MilestoneTaskBoard">
                    <p style="font-size: large;margin-left: 20px;"><strong>TaskBoard:</strong> This is the other view of representation about the milestone where the milestone/task is segregated status-wise. </p>
                    <img src="assets/gallery/milestonetaskboard.jpg" style="width: 55%;height:auto;display: block;margin-left: 25%;">
                    <br><br>
                </div>
                <div id="MilestoneSchedule">
                    <p style="font-size: large;margin-left: 20px;"><strong>Milestone Schedule:</strong> Milestone Schedule: This is another representation of the milestone where the milestone created time with the start date and milestone names are displayed.</p>
                    <img src="assets/gallery/milestoneschedule.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>
                <div id="EditMilestone">
                    <p style="font-size: large;margin-left: 20px;"><strong>EditMilestone:</strong> In the below image, we can see the list of the milestones for Phase P0 with the edit button. </p>
                    <img src="assets/gallery/editmilestone.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">if we click on the edit button, then we can edit or update the milestone details. </p> 
                    <img src="assets/gallery/editmilestone1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the above image, existing milestone details are listed in the fields that can be edited according to the requirement. </p>
                    <p style="font-size: large;margin-left: 20px;">On the left side of the page, you can see the project and phase details, where the particular task belongs to. </p>
                    <p style="font-size: large;margin-left: 20px;">Milestones such as Milestone Id, Milestone Name, Predecessor…cannot be edited. </p>
                    <p style="font-size: large;margin-left: 20px;">Also, milestones such as Actual start date, Actual end date, Man hours spent, assign to, how much has been progressed and milestone status can be edited. </p> 
                    <p style="font-size: large;margin-left: 20px;"><strong>In the edit milestones, some of the important features are given as follows:</strong>
                    <p style="font-size: large;margin-left: 20px;"><strong>Predecessor-</strong> when there is a predecessor allocated, then the present milestone cannot be completed until the predecessor milestone has been finished; so, this current milestone is dependent on the predecessor milestone. </p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Duration-</strong> Let’s assume that, if the duration is 5, means 5 days and the estimated effort is 40 hours that means 8 hours a day for 5 days per week. So, 1 user/assignee can complete the milestone in 5 days or 40 hours. If the same milestone is assigned to 2 users, then automatically the duration changes to 2.5 or 20 hours. </p> 
                    <p style="font-size: large;margin-left: 20px;"><strong>Man hours spent today-</strong> This indicates how many hours were spent on the milestone on a particular day.  </p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Man hours spent cumulative-</strong> It is the total number of hours spent on the milestone.
                    <p style="font-size: large;margin-left: 20px;"><strong>Estimated Effort-</strong> This indicates how much effort was spent on the milestone: It is calculated as duration multiplied by 8 hours, for example, duration is 5 so the Estimated effort is 5X8=40hours. </p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Progress Completed-</strong> This shows the ratio of the milestone completed as 30%, 40%... depends upon the completed ratio. </p>
                    <p style="font-size: large;margin-left: 20px;"><strong>View Effected Milestone - </strong> This is about the milestones. When a new expected date of completion or changes to resources is expected to take place, the impacted milestones along with the news dates are shows beforehand prior to accepting the changes. For example, if a milestone has to be finished by 10th March, but its predecessor milestone has not been completed and delayed for 2 days so the current milestone End date will move automatically from March 10th to March 12th, as it has been affected by its predecessor. </p>
                    <p style="font-size: large;margin-left: 20px;"><strong>View History- </strong>This shows the history of the milestone status, which has been converted gradually from New to In-Progress till Completed. </p>
                    <p style="font-size: large;margin-left: 20px;"><strong>Layout Milestone - </strong> Overlap: Overlap defines that the tasks that can be performed in parallel.  </p>
                    <img src="assets/gallery/editmilestone2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">For example, if we observe in the above image, Layout Milestone id is PLM-21-0001.5 and its previous task end date is December 8th. If we go according to the above calculation, the 10th day from the previous milestone end date will be 27th November excluding weekends </p>
                    <br><br>
                </div>                
                <div id="Reviews">
                    <p style="font-size: large;margin-left: 20px;"><strong>Admin > Reviews: </strong> On this page, we can see the list of review reports with the download button.</p>
                    <img src="assets/gallery/reviews.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">If we click on the download icon, we can download any particular report.</p>                        
                    <br><br>
                </div>
                <div id="ManageCustomAttributes">
                    <p style="font-size: large;margin-left: 20px;"><strong>Admin > Manage Custom Attributes: </strong> By selecting the Project, respective groups are displayed under the Group dropdown. When we select the Group, then related Custom Attributes and their values will be displayed.</p>  
                    <img src="assets/gallery/managecustomattributes.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">Also displayed the owner, dependency and history of the custom attributes and values.</p> 
                    <img src="assets/gallery/managecustomattributes1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In the above screen, we can see that we have selected the Unicorn project, so automatically its respective groups are displayed under the group dropdown field. If we select any of the groups, then the related custom attributes, its values, owner and CCB approval details and history will be displayed.</p>
                    <img src="assets/gallery/managecustomattributes2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>                     
                    <p style="font-size: large;margin-left: 20px;">The values under “CCB Approval” show where Change Control Board Approval Is required.</p> 
                    <p style="font-size: large;margin-left: 20px;"> “CCB Approval”: “No” indicates that the ‘change request’ is not necessary to go through the formal CCB approval process.</p>
                    <p style="font-size: large;margin-left: 20px;">“CCB Approval” - “Yes” indicates that the ‘change request’ is necessary to go through the formal CCB approval process.</p>
                    <br><br>
                </div>
                <div id="ProjectActualStartDate">
                    <p style="font-size: large;margin-left: 20px;"><strong>Admin > Project Actual Start Date: </strong> To edit or update the milestone, first, the project should have been in started. Follow the below steps to start the project.</p>
                    <p style="font-size: large;margin-left: 20px;">After clicking on the menu, we will be landed at the Actual start date page, here we have to select the Project from the dropdown.</p> 
                    <img src="assets/gallery/projectactualstartdate.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br> 
                    <p style="font-size: large;margin-left: 20px;">After selecting the Project from the dropdown, we have to update the Actual Start Date of the project, then click on the Update button.</p>
                    <p style="font-size: large;margin-left: 20px;">After clicking the Update button, Project will be started. 
                    <img src="assets/gallery/projectactualstartdate1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br> 
                    <p style="font-size: large;margin-left: 20px;">Without starting the Project, Milestones cannot be updated or edited. After updating the actual start date of the project, its status will be converted from new to in-process.</p>
                    <p style="font-size: large;margin-left: 20px;">Project status-before update.</p> 
                    <img src="assets/gallery/projectactualstartdate2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br> 
                    <p style="font-size: large;margin-left: 20px;"> Project status-after update.</p>                     
                    <br><br>
                </div>
                <div id="OverlapDays">
                    <p style="font-size: large;margin-left: 20px;"><strong>PLM Admin:</strong> PLM Admin is a special role given to limited users</p> 
                    <p style="font-size: large;margin-left: 20px;">There are some features pre-defined by PLM Admin. For example, for attributes Overlap Days, Cycle Time, and Proportionate Cycle Time some fixed days are allocated by the PLM Admin.</p> 
                    <p style="font-size: large;margin-left: 20px;"><strong>PLM Admin > Overlap Days:</strong> PLM Admin has the privilege to Add, View, Edit and Delete Overlap days of a milestone.</p> 
                    <p style="font-size: large;margin-left: 20px;">If the user has created a project, the silicon becomes 1. If there is a milestone called Layout, then the overlap days will be 10 days which counts from the previous milestones end date excluding weekends. </p> 
                    <p style="font-size: large;margin-left: 20px;">For Example, Layout Milestone id is PLM-21-0001.5 and its previous Milestone end date is December 8th. As per the above logic, the 10th day from the previous milestone end date, i.e., 27th November, will be the beginning date.</p> 
                    <p style="font-size: large;margin-left: 20px;">If the user has created silicon for the above project, then the overlap days will be 3.</p> 
                    <p style="font-size: large;margin-left: 20px;">If the user has created silicon for the above silicon, then the overlap days will be 3 again. </p> 
                    <img src="assets/gallery/overlapdays.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In most cases, the above calculation of Overlap days is fixed, but if PLM Admin wants to edit or declare some other value then he/she has an option to edit the milestone and fix the value.</p> 
                    <p style="font-size: large;margin-left: 20px;">By clicking on the Edit button PLM Admin, the privileged user can get into the below screen. In this, overlap days are defined based on milestone and silicon level. Overlap days are days in which milestone start date overlap the predecessor milestone end date. This will affect all unfinished milestone dates and new projects milestone dates. </p> 
                    <img src="assets/gallery/overlapdays1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">By clicking on the “Save” button user can revise the overlap days. Similarly, If PLM Admin clicks on the +Add button he/she can add the Overlap Days to the Milestone if it is not declared. </p> 
                    <img src="assets/gallery/overlapdays2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>
                <div id="MilestoneCycleTime">
                <p style="font-size: large;margin-left: 20px;"><strong>PLM Admin > Milestone Cycle Time:</strong> PLM Admin has the privilege to Add, View, Edit and Delete the Cycle Time of a milestone.</p> 
                <p style="font-size: large;margin-left: 20px;">There are some packages and Qual types, which are selected at the time of Product Creation.</p> 
                <p style="font-size: large;margin-left: 20px;">Every project will be defined with a selected package and for that package, a particular Cycle time was pre-defined by the PLM Admin.</p> 
                <p style="font-size: large;margin-left: 20px;">From the below image, we can observe that Cycle Time is pre-defined from14 days to 25 days according to the Package Type and Qual Type.</p> 
                <img src="assets/gallery/milestonecycletime.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                <br>
                <p style="font-size: large;margin-left: 20px;">In most cases, the above calculation of Cycle Times is fixed, but PLM Admin has an option to edit and change the value.</p> 
                <p style="font-size: large;margin-left: 20px;">By clicking on the Edit button PLM Admin can get into the below screen. </p> 
                <img src="assets/gallery/milestonecycletime1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                <br>
                <p style="font-size: large;margin-left: 20px;">By clicking on the Save button user can revise the Cycle Time. Similarly, If PLM Admin clicks on the +Add button he/she can add the Cycle Time to the Milestone Attribute.</p> 
                 <br><br>
                </div>
                <div id="ProportionateCycleTime">
                    <p style="font-size: large;margin-left: 20px;"><strong>PLM Admin > Proportionate Cycle Time:</strong> PLM Admin has the privilege to Add, View, Edit and Delete the Proportionate Cycle Time of a milestone.</p> 
                    <p style="font-size: large;margin-left: 20px;">For a project with the Milestone attribute like Circuit Design, the Proportionate Cycle time is pre-defined as 100 days. </p> 
                    <p style="font-size: large;margin-left: 20px;">When silicon was created for the above project, the Proportionate cycle time will be 50% that means 50 days, </p> 
                    <p style="font-size: large;margin-left: 20px;">If silicon is created for the above silicon, then the Proportionate cycle time will be 30% from the 1st Project, which is 30 Days.</p> 
                    <p style="font-size: large;margin-left: 20px;">Similarly, for the Milestone attribute like Layout, the Proportionate cycle time is pre-defined as 100 days.</p>  
                    <p style="font-size: large;margin-left: 20px;">If silicon is created for the above project, then the Proportionate cycle time will be 50% that means 50 days,</p>  
                    <p style="font-size: large;margin-left: 20px;">If silicon is created for the above silicon, then the Proportionate cycle time will be 20% from the 1st Project that is 20 Days. </p> 
                    <img src="assets/gallery/proportionatecycletime.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">In most cases, the above calculation of Cycle Times is fixed, but PLM Admin has an option to edit and change the value.</p> 
                    <p style="font-size: large;margin-left: 20px;">By clicking on the Edit button, PLM Admin can get into the below screen. </p> 
                    <img src="assets/gallery/proportionatecycletime1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">By clicking on the Save button user can revise the Cycle Time. Similarly, If PLM Admin clicks on the +Add button, he can add the Proportionate Cycle Time to the Milestone.</p>  
                    <img src="assets/gallery/proportionatecycletime2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>
                          
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-10"></div>
            <div class="p-field p-col-12 p-md-2">
            <a href="#cardheader" >
                <button pButton pRipple type="button" icon="pi pi-arrow-up" pTooltip="Go to Top"
                tooltipPosition="top" class="p-button-rounded p-button-info p-mr-2 p-mb-2"
                style="float:right;height:25px; width:25px; "></button>
                </a>        
        </div>  
    </div>
    </div>
</div>


