<div class="p-grid table-demo">
<div class="p-col-12">
  <div class="card" style="min-height:600px;">
    <div class="card-header" style="border-radius: 5px; height:40px; ">
      <h5 style="color:#495057; padding:15px 0 0 15px;"></h5>
    </div> 
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:40px; width:99.8%; ">
      <h5 style="color:#495057; padding:15px 0 0 15px;">Projects Summary - {{Count}}</h5> 
      <span class="p-field-radiobutton" style="float:left;">
        <label style="margin-right: 10px;">Filter by:</label>
        <p-radioButton value="startsWith" [(ngModel)]="selectedfilter"></p-radioButton>
        <label style="margin-right: 10px;">startsWith</label>
        <p-radioButton value="Contains" [(ngModel)]="selectedfilter"></p-radioButton>
        <label>Contains</label>
      </span>
      <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">        
        <button pButton pRipple type="button" icon="pi pi-print"  (click)="print()" pTooltip="Print"tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right; height:25px;width:25px;"></button>
        <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" pTooltip="Download"tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; width:25px; height:25px; "></button>
      </span>
    </div> 
    <div  class="invoice invoice-header" id="invoice-content">
      <p-table #dt [value]="projects" id="excel-table" dataKey="id" styleClass="p-datatable-striped" class="table-align"
      [rowHover]="true" [rows]="10" [paginator]="false" [scrollable]="true" scrollHeight="380px"[filterDelay]="0" (onFilter)="onFilter($event, dt)">
      <ng-template pTemplate="header" class="invoice-items">
        <tr>
          <th style="width:80px;">Actions</th>
          <th style="width: 40%;">Project Name</th>
          <th style="width:200px;">Active Phase</th>
          <th style="width:150px;">Start Date</th>
          <th style="width:150px;"> End Date</th>
          <th style="width: 15%;">Product Engineer</th>
          <th style="width: 15%;">Project Status</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr> 
        <tr id="trSearch">
          <th></th>
          <th><input pInputText type="text" (input)="test($event.target.value,'projectName',dt)" placeholder="Project Name" class="p-column-filter" style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
          <th><input pInputText type="text" (input)="test($event.target.value,'phaseName',dt)" placeholder="Active Phase" class="p-column-filter"  style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
          <th><input pInputText type="text" (input)="test($event.target.value,'projectStartDate',dt)" placeholder="Start date" class="p-column-filter"  style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
          <th><input pInputText type="text" (input)="test($event.target.value,'targetDate',dt)" placeholder="End date" class="p-column-filter"  style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
          <th><input pInputText type="text" (input)="test($event.target.value,'projectManagerName',dt)" placeholder="Product Engineer" class="p-column-filter"  style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
          <th><input pInputText type="text" (input)="test($event.target.value,'projectStatusName',dt)" placeholder="Project Status" class="p-column-filter"  style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
        </tr> 
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr> 
      </ng-template>
      <ng-template pTemplate="body" let-project>
        <tr *ngIf="project.phaseName==''" [pSelectableRow]="rowData" style="background-color:#008EBD;color:white;border: 2px;vertical-align: middle;">
          <td  style="text-align:center;width:80px;"></td>
          <th style="padding-left:15px;width:40%;font-size: medium;"><span class="data-style">{{project.projectName}}</span></th>
          <td style="text-align:center;width:200px;font-weight: bold;"></td>
          <td style="text-align:center;width:150px;"><span ></span></td>
          <td style="text-align:center;width:150px;"><span></span></td>
          <td style="padding-left:15px;width:15%;"><span ></span></td>
          <td style="padding-left:15px;width:15%;"><span ></span></td>
        </tr>
        <tr *ngIf="project.phaseName!=''" [pSelectableRow]="rowData" style="vertical-align: middle;">
          <td  style="text-align:center;width:80px;">
            <span><button (click)=openproject(project.projectId) pButton pRipple type="button"  icon="pi pi-eye"  
              pTooltip="View"tooltipPosition="right"  class="p-button-raised p-button-primary p-mr-2 p-mb-2" 
              style="margin-top:5px;height:25px; width:25px;"></button>
            </span>
          </td>
          <td style="padding-left:15px;width:40%;"><span class="data-style">{{project.projectName}}</span></td>
          <!-- <td  style="text-align:center;width:200px;" ><span >{{project.phaseName}}</span></td> -->
          <td style="text-align:center;width:200px;font-weight: bold;"  [ngStyle]="{'color': project.phaseName === 'P0' ? '#c14a09': project.phaseName === 'Review' ? '#FFA500' : project.phaseName === 'P1' ? '#008000' : project.phaseName === 'P2' ?  '#0000FF': project.phaseName === 'P3 RTS' ? '#3b2820': project.phaseName === 'P3 Production' ? '#800080': project.phaseName === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ project.phaseName}}</td>
          <td style="text-align:center;width:150px;"><span >{{project.projectStartDate}}</span></td>
          <td style="text-align:center;width:150px;"><span>{{project.targetDate}}</span></td>
          <td style="padding-left:15px;width:15%;"><span >{{project.projectManagerName}}</span></td>
          <td style="padding-left:15px;width:15%;"><span >{{project.projectStatusName}}</span></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td colspan="7"> {{ProductCountFilter}} </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No projects found...</td>
        </tr>
      </ng-template>
    </p-table> 
  </div>
  </div>
</div>
</div>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;" *ngIf="showLoading">
	<i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>