import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/injector';
import { DomSanitizer } from '@angular/platform-browser';
import { API_ENDPOINTS } from 'src/app/app.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
showLoading: boolean = false;
Lists: any[];

constructor(
    private facadeService: FacadeService, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
}

ngOnInit() {
    this.GetReports();
}

GetReports() {
    this.showLoading = true;
    this.facadeService.GetReportNames().subscribe(data => {
        this.showLoading = false;
        this.Lists = data.returnObject;
    });
}

GetFile(filename) {
      this.showLoading=true;
      this.facadeService.GenerateReport(filename).subscribe(response => {
        response.filename = filename + " " + this.date_TO_String(new Date());
        let url = window.URL.createObjectURL(response);
        var anchor = document.createElement("a");
        anchor.download = filename + " " + this.date_TO_String(new Date());
        anchor.href = url;
        anchor.click();
        anchor.remove();
        this.showLoading = false;
      }),
       error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
}
private date_TO_String(date_Object) {
    var date_String = date_Object.getFullYear() +
       "/" +
       (date_Object.getMonth() + 1) +
       "/" +
       +date_Object.getDate() +
       " " +
       +date_Object.getHours() +
       ":" +
       +date_Object.getMinutes();
    return date_String;
 }

}
