export class ReviewDto {
    rID: number;
    reviewGuid: string;
    projectGuid: string;
    reviewDate: Date;
    reviewNotes: string;
    createdBy: string;
    createdDate: Date;
    updatedBy: string;
    updatedDate: Date;
}