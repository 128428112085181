
<div class="p-grid p-fluid">
    <div class="p-col-12">
       <div class="card" style="min-height: 600px">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 p-md-12" style="float: left">
                    <h4 style="margin-top: 5px">Project Reviews</h4>
                </div>
            </div>
            <hr />
            <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;">
                <div class="p-col-12 p-md-6">
                  <!-- <label> Project<span style="color: red">*</span></label>
                  <p-dropdown [options]="projects" [(ngModel)]="selectedproject" required="true"
                    placeholder="Select Project" optionLabel="projectName" requiredMessage="The input must not be empty"
                    (onChange)="productListChange($event.value.projectId)"></p-dropdown>
                  <small class="p-invalid">{{ productNameError }}</small>  -->

                  <label for="Phase">Project<span style="color: red">*</span></label>
                  <p-autoComplete [(ngModel)]="selectedproject" [suggestions]="filteredProdType"  placeholder="Select Project"
                  (completeMethod)="filterproductListChange($event)"   field="projectName" [dropdown]="true" (onSelect)="productListChange(selectedproject.projectId)">
                  <ng-template>
                    <div>
                      <div>{{ filteredProdType.projectName }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                </div>
                <div class="p-col-12 p-md-6"><br><b>Current Week: </b> WW{{currentweekNo}}</div>
            </div>
            <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;" *ngIf="Show">
              <div class="p-col-12 p-md-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-4 p-xl-4">
                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px">
                            <ul class="widget-image-list">
                                <li>
                                <span style="font-weight: bold;" >Project Name:</span>
                                <span>{{projectInformation?.projectName}}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Product Engineer:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.owner : "" }}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Project Status:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.projectStatusName: "" }}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Die Code:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.dieCode: "" }}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Mfg Part #:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.mfgPart: "" }}</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-xl-4">
                            <div
                            class="card no-gutter widget-overview-box widget-overview-box-1"
                            style="min-height: 137px"
                            >
                            <ul class="widget-image-list">
                                <li>
                                <span style="font-weight: bold;" >Package Name:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.packageOption: "" }}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Need CP Trim:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.cpTrim: "" }}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Package Type:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.packageType: "" }}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Qual Type:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.qualType: "" }}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Active Phase:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.currentPhase : "" }}</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4 p-xl-4">
                            <div
                            class="card no-gutter widget-overview-box widget-overview-box-1"
                            style="min-height: 137px"
                            >
                            <ul class="widget-image-list">
                                <li>
                                <span style="font-weight: bold;" >Start Date:</span>
                                <span>{{projectInformation?.projectStartDate|date:"MM/dd/yyyy"}}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >End Date:</span>
                                <span>{{projectInformation?.targetDate|date:"MM/dd/yyyy"}}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Project Description:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.projectdesc : "" }}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Product Function:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.productfunction : "" }}</span>
                                </li>
                                <li>
                                <span style="font-weight: bold;" >Product Line:</span>
                                <span>{{ projectInformation != undefined ? projectInformation.productline : "" }}</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
              </div>
              <div class="p-field p-col-12 p-md-12 p-xl-12">
                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 100px">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-10">
                                <label><b>Current Week Remarks</b></label>
                                <textarea type="text" rows="4" [maxLength]="300" pInputTextarea  placeholder="Current Week Remarks"  [readonly]="viewmode" 
                                [(ngModel)]="currentWeekRemarks">
                                </textarea>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save" [disabled]="viewmode"
                                    class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="SaveRemarks()"
                                    style="width: auto;margin-top: 45px;float: right;"></button>
                            </div>
                            <div class="p-col-12 p-md-12">
                                <small class="p-invalid">{{ Error }}</small> 
                            </div>
                        </div>
                    </div>
              </div>

            <p-accordion>
                <p-accordionTab header="Previous Remarks" [selected]="true">
                     <div class="p-fluid p-formgrid p-grid">
                         <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
                            <p-table #dt [value]="PrevReviews" id="excel-table" [columns]="cols1" sortMode="multiple"
                                class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                                selectionMode="multiple" [globalFilterFields]="['workWeek','reviewNotes','reviewDate','reviewByName']">
                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                    <tr>
                                        <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                            [ngClass]="{'width100':i == 0,'width150': i == 2 || i == 3}">
                                            {{col.header}}
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                            <input pInputText type="text" [placeholder]="col.header"
                                                (input)="dt.filter($event.target.value, col.field, col.contains)"
                                                style="margin: -10px 0 -10px 0;width: 95%; height:20px;">
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr [pSelectableRow]="rowData">
                                        <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                            <td *ngIf="i == 0" style="text-align:center;">{{ rowData[col.field] }}</td>
                                            <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">{{rowData[col.field]}}</td>
                                            <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">{{rowData[col.field]}}</td>
                                            <td *ngIf="i == 3" style="text-align:center;">{{ rowData[col.field]|date:"MM/dd/yyyy" }}</td>
                                        </ng-container>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="4" style="text-align: left;">No data found.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                     </div>
                 </p-accordionTab>
             </p-accordion>
            </div>
        </div>
    </div>
</div>


<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500 !Important;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>
