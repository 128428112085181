
  <p-toast position="top-right" key="popup"></p-toast>
  <div class="p-grid table-demo">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="card-header" style="border-radius: 5px;height:40px; width:99.8% ">
          <h5 style="color:#495057; padding:15px 0 0 15px;"></h5>
          <span class="p-input-icon-left" style="float:right;">
            <button pButton type="button" label="Create Report" *ngIf="isProductEngineer"  (click)="onReportPopupDisplay('create')"
              class="p-button-raised p-button-primary p-mr-2 p-mb-2" icon="pi pi-plus" iconPos="left"
              style="margin-right:5px;"></button>
           
          </span>
        </div>
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
          <h5 style="color:#495057; padding:15px 0 0 15px;">Reports</h5>
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
           
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
              (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
              style="float:right; width:25px; height:25px;"></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
              (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
              style="float:right;height:25px; width:25px; "></button>
          </span>
        </div>
        <p-dialog header="{{rptActionType}} Report" [(visible)]="reportPopupDisplay" modal="modal" showEffect="fade"
          [style]="{ width: '50%' }">
          <hr />
          <form [formGroup]="Customreport" (ngSubmit)="onCreateReport(Customreport.value)">
          <div class="p-grid">
           
            <div class="p-col-12 p-md-12">
              <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-6">
                  <label for="FCReportName">Report Name<span style="color: red">*</span>
                  </label>
                  <input type="text" pInputText placeholder="Report Name" [(ngModel)]="editReportName" formControlName="FCReportName" required="true"
                    requiredMessage="The input must not be empty" />
                  <small class="p-invalid"
                    *ngIf="submitted && Customreport.controls.FCReportName.hasError('required')">Report Name is not
                    mentioned.</small>
                  <br />
                </div>
                <div class="p-col-12 p-md-6">
                  <label for="ProjectName">Custom Attributes
                  </label>
                  <p-multiSelect [options]="optionsCustomAttributes" [(ngModel)]="editoptionsCustomAttributes"  optionLabel="name"
                    formControlName="FCCustomAttributes" >
                  </p-multiSelect>
                  <small class="p-invalid"
                    *ngIf="submitted && Customreport.controls.FCCustomAttributes.hasError('required')">The input field is empty</small>
                </div>
                <div class="p-col-12 p-md-6">
                  <label for="state">Task Name<span style="color: red">*</span>
                  </label>

                  <!-- <p-dropdown [options]="optionsTaskNames" [(ngModel)] ="editoptionsTaskNames" placeholder="Select Task" optionLabel="name"
                    required="true" formControlName="FCTaskName" 
                    requiredMessage="The input must not be empty"></p-dropdown> -->

                  <p-multiSelect [options]="optionsTaskNames" [(ngModel)] = "editoptionsTaskNames"  optionLabel="name"
                    formControlName="FCTaskName" >
                  </p-multiSelect>

                  <small class="p-invalid"
                    *ngIf="submitted && Customreport.controls.FCTaskName.hasError('required')">The input field is empty</small>
                </div>
                <div class="p-col-12 p-md-6">
                  <label for="ProjectName">Task Attributes<span style="color: red">*</span>
                  </label>
                  <p-multiSelect [options]="optionsTaskAttributes" [(ngModel)] = "editoptionsTaskAttributes"  optionLabel="name"
                    formControlName="FCTaskAttributes" >
                  </p-multiSelect>
                  <small class="p-invalid"
                    *ngIf="submitted && Customreport.controls.FCTaskAttributes.hasError('required')">The input field is empty</small>
                </div>
              </div>
            </div>

            <div class="p-col-12 p-md-12" style="vertical-align: center">
              <small class="p-invalid" style="float:right;">{{responseError}}</small>
            </div>
            
            <div class="p-col-12 p-md-12">
              <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
                <button pButton type="submit" label="Save" icon="pi pi-check" iconPos="left"
                  class="p-button-raised p-button-success" style="margin-top:-5px;margin-bottom:-5px;"></button>
                <!-- <button pButton type="button" label="Close" icon="pi pi-times" iconPos="left"
                  class="p-button-raised p-button-secondary" (click)="onClose();"
                  style="margin-top:-5px;margin-bottom:-5px; margin-left:10px;">
                </button> -->
                <!-- <button pButton type="button" label="Preview" icon="pi pi-eye" iconPos="left"
                  class="p-button-raised p-button-primary"
                  style="margin-top:-5px;margin-bottom:-5px; margin-left:10px;">
                </button> -->
              </span>
            </div>
          </div>
        </form>
        </p-dialog>

        <div class="invoice invoice-header" id="invoice-content">
          <p-table #dt [value]="CustomReportsLists" id="excel-table" dataKey="id" styleClass="p-datatable-striped" [rowHover]="true" [rows]="4"
            [paginator]="false" [filterDelay]="0">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="reportName">
                  Report Name
                  <p-sortIcon field="projectName"></p-sortIcon>
                </th>
                <th pSortableColumn="created By">
                  Created By
                  <p-sortIcon field="craetedby"></p-sortIcon>
                </th>
                <th pSortableColumn="createdat">
                  Created At
                  <p-sortIcon field="createdat"></p-sortIcon>
                </th>
               
                <th style="width:100px;">
                  Actions
                </th>
              </tr>
              <tr>
                <th>
                  <input pInputText type="text" (input)="
                  dt.filter($event.target.value, 'projectName', 'contains')
                " placeholder="Project Name" class="p-column-filter" style="width: 95%; height:25px; padding-left:15px;" />
                </th>
                <th>
                  <input pInputText type="text" (input)="dt.filter($event.target.value, 'stageName', 'contains')"
                    placeholder="Created By" class="p-column-filter" style="width: 95%; height:25px; padding-left:15px;" />
                </th>
                <th>
                  <input pInputText type="text" (input)="
                  dt.filter($event.target.value, 'milestoneName', 'contains')
                " placeholder="Created At" class="p-column-filter" style="width: 95%; height:25px; padding-left:15px;" />
                </th>
               
                <th></th>


              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-CustomReportsList>
              <tr class="p-selectable-row">
                <td style="padding-left:15px;">
                  <!-- <span class="p-column-title">Project Name</span> -->
                  <span class="data-style">{{ CustomReportsList.reportName }}</span>
                </td>
                <td style="padding-left:15px;">
                  <!-- <span class="p-column-title">Manager11</span> -->
                  <span class="data-style">{{ CustomReportsList.createdBy }}</span>
                </td>
                <td style="text-align:center;">
                  <!-- <span class="p-column-title">Start Date</span> -->
                  <span >{{ CustomReportsList.createdDate }}</span>
                </td>
             


                <td style="text-align:center;">
                  <!-- <span class="p-column-title">Actions</span> -->
                  <span>
                      <a routerLink="/viewreport/{{CustomReportsList.reportId}}">
                      <button pButton pRipple type="button" icon="pi pi-eye" pTooltip="View" tooltipPosition="left"
                        class="p-button-raised p-button-primary" style="height:25px; width:25px;">
                      </button></a>
                    <button pButton pRipple type="button" *ngIf="isProductEngineer" (click)="onReportSchemaEdit(CustomReportsList.reportId)" icon="pi pi-pencil" pTooltip="Edit"
                      tooltipPosition="right" class="p-button-raised p-button-primary"
                      style="margin-left:15px;height:25px; width:25px;">
                    </button>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="4">No Reports found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>



<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>