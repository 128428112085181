import { Component, OnInit, ViewChild } from '@angular/core';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import { DatePipe } from '@angular/common';
import { Table } from 'primeng/table';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FacadeService } from '../../facade/facade.service';
import { Tasks, TaskBoard, TaskCalender } from '../domain/Tasks';
import { formatDate } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { List } from '../domain/lists';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ListEnum } from 'src/app/demo/domain/lists';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from "src/injector";
import { CustomFields } from '../domain/project';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-phasetree',
    templateUrl: './phasetree.component.html',
    styleUrls: ['./phasetree.component.scss'],
    providers: [MessageService, ConfirmationService],
    styles: [`
		:host ::ng-deep .misc-demo .p-button.p-widget {
		    min-width: 6rem;
	    }

		:host ::ng-deep .misc-demo .badges .p-badge {
		    margin-right: .5rem;
		}

		:host ::ng-deep .misc-demo .badges .p-tag {
			margin-right: .5rem;
		}
    `]
})

export class PhasetreeComponent implements OnInit {
    projectdisplay: boolean;
    events: TaskCalender[];
    valSwitch: boolean;
    options: any;
    header: any;
    items: any;
    filterItems: any;
    helpdisplay : boolean;
    overlayMenuActive: boolean;
    eventDialog: boolean;
    changedEvent: any;
    panelMenuItems: MenuItem[];
    menuItems: MenuItem[];
    clickedEvent = null;
    tasksListsByProject: any[];
    customFieldList: any;
    selectedCustomField: CustomFields[];
    selectedtasksListsByProject: any[];
    docsTypesData: any;
    tasksBoard: TaskBoard[];
    taskBoard: TaskBoard;
    phasedisplay: boolean;
    stagedisplay: boolean;
    milestonedisplay: boolean;
    selectedDocType: any;
    taskBoardNew: Tasks[];
    taskBoardNewCount: number;
    taskBoardInprogress: Tasks[];
    taskBoardInprogressCount: number;
    taskBoardHold: Tasks[];
    taskBoardHoldCount: number;
    taskBoardComplete: Tasks[];
    taskBoardCompleteCount: number;
    projectInformation: any;
    projectId: string;
    phaseName: string;
    createStageMdl: any;
    createstagestatus: any;
    createPhaseStatusMdl: any;
    createStageOptions: [];
    createMilestonePhaseOptions: [];
    createMilestoneStageOptions: [];
    createMilestonePhaseOptionsMdl: any;
    createMilestoneStageOptionsMdl: any;
    createmilestonestatus: any;
    listStatusOptions: [];
    listStatusOptionsMdl: any;
    phaseNameControl: FormControl;
    phaseStatusDdlControl: FormControl;
    createPhaseform: FormGroup;
    createStageform: FormGroup;
    createMilestoneform: FormGroup;
    isCreatePhaseSubmitted: boolean;
    isCreateStageSubmitted: boolean;
    isCreateMilestoneSubmitted: boolean;
    editDeleteListDTO: List;
    showCreateTask: boolean = false;
    //editDeleteItem: {};
    confirmDropDatabaseDialogVisible = false;
    //Edit variables

    DieCodes = [];
    AllMFGParts = [];
    editTaskId: number = 0;
    editPhaseName: string;
    editStageName: string;
    editMilestone: string;
    phagebtnText: string = "Create Phase";
    stagebtnText: string = "Create Stage";
    milestoneText: string = "Create Milestone"
    showLoading = false;
    responseError: string;
    deleteConfirmModal: boolean = false;
    projectdescriptiondisplay: boolean = false;
    Overlapinfo:boolean = false;
    //start silicon
    Action : any;
    childprojectdisplay: boolean = false;
    addchildproject : boolean = false;
    CPErrorDisplay:boolean = false;
    ChildProjectError:string = "";

    projectdesciptionoption:any[];
    selectedprojectdescription:any;
    packagetypeoption:any[];
    selectedpackagetype:any;
    packingoption : any[];
    selectedpackingoption : any;
    tempgradeoption : any[];
    selectedtempgrade:any;
    foundryoptions: any[];
    selectedfoundry:any;
    foundryprocessoptions: any[];
    selectedfoundryprocess:any;

    CPSubconO: any[];
    selectedCPSubcon:any;
    FTSubconO: any[];
    selectedFTSubcon:any;
    AssySubcon1O: any[];
    selectedAssySubcon1:any;
    AssySubcon2O: any[];
    selectedAssySubcon2:any;
    DPSSubconO: any[];
    selectedDPSSubcon:any;
    AssySubconProcess1O: any[];
    selectedAssySubconProcess1:any;
    AssySubconProcess2O: any[];
    selectedAssySubconProcess2:any;
    DPSSubconProcessO: any[];
    selectedDPSSubconProcess:any;

    diecoderoot:string = ""; 
    diecoded:string = "";
    diecodee:string = "";
    packagename:string = "";
    mfgpartSilicon:string = "";
    fgpart:string="";
    description:string = "";
    trqty:string = "";
    tubeqty:string = "";
    trayqty:string = "";

    MaxStartDate: Date;
    minMaxStartDate: Date;
    AddModel :any;
    //end silicon

    PDMURL :string;
    PDMbtnDisplay:boolean = false;
    FromReport = sessionStorage.getItem("FromReport");
    From = sessionStorage.getItem("From");

    @ViewChild('dt') table: Table;
    LoggedInUser:boolean=true;
    userGuid = sessionStorage.getItem('userGuid');
    userID = sessionStorage.getItem('userId');
    DisableTaskStatus:boolean=false;
    ShowActualDate: boolean;
    actualStartDate:any;
    revisedStartDate:any;
    Error:string="";
    constructor(
        private pipe : DatePipe,
        private breadcrumbService: BreadcrumbService, private facadeService: FacadeService, private route: ActivatedRoute,
        private messageService: MessageService, private formBuilder: FormBuilder, 
        private printService: PrintcommonService,
        private router: Router, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) {
        this.breadcrumbService.setItems([
            { label: 'Table' }
        ]);
    }
    report()
    {
        if(this.From === "ReportMilestone")
            this.router.navigate(['projectsbymilestone']);
        if(this.From === "ReportSummary")
            this.router.navigate(['projectsummary']);
        if(this.From === "projectlist")
            this.router.navigate(['projectlist']);
        if(this.From === "dashboard")
            this.router.navigate(['dashboard']);
        if(this.From === "overdue")
            this.router.navigate(['overduemilestone']);
        if(this.From === "p2summary")
            this.router.navigate(['p2summary']);
    }
    open(val,val1)
    {
      this.router.navigate(['/customfields',{ projectId: val, pid: val1 }]);     
    }

    ngOnInit() {
        
        this.createPhaseform = this.formBuilder.group({
            phaseNameControl: new FormControl('', [Validators.required]),
            phaseStatusDdlControl: new FormControl('', [Validators.required])
        })

        //this.phaseNameControl = new FormControl('', [Validators.required]);
        
        this.editDeleteListDTO = new List();
        this.createStageform = this.formBuilder.group({
            phaseNameDdlControl: new FormControl('', [Validators.required]),
            stageNameInputControl: new FormControl('', [Validators.required]),
            stageStatusDdlControl: new FormControl('', [Validators.required])
        })
        this.createMilestoneform = this.formBuilder.group({
            milestonePhaseDdlControl: new FormControl('', [Validators.required]),
            milestoneStageDdlControl: new FormControl('', [Validators.required]),
            milestoneNameInputControl: new FormControl('', [Validators.required]),
            milestoneStatusDdlControl: new FormControl('', [Validators.required]),
        })

        this.route.paramMap.subscribe(params => {
            if (params.get('id') != null) {
                this.projectId = params.get('id');
                this.GetProjectMenu(this.projectId);
                this.getProjectDetails(params.get('id'));
                this.getTaskListByproject(params.get('id'), params.get('id'), 'Project');
            }
        });

        this.items = [
            // { id: "addCreatePhaseBtn", label: 'Create Phase', icon: 'pi pi-plus', command: () => { this.showCreatePhaseModel(false) } },
            // { id: "addCreatePhaseSeperator", separator: true },
            // { id: "addCreateStageBtn", label: 'Create Stage', icon: 'pi pi-plus', command: () => { this.showCreateStageModel(false) } },
            // { id: "addCreateStageSeperator", separator: true },
            // { id: "addCreateMilestoneBtn", label: 'Create Milestone', icon: 'pi pi-plus', command: () => { this.showCreateMilestoneModel(false) } },
            // { id: "addCreateMilestoneSeperator", separator: true },
            // { id: "addCreateTaskBtn", label: 'Create Task', icon: 'pi pi-plus', command: () => { this.buildCreateTaskUrl() } },
            // { id: "addCreateTaskSeperator", separator: true },
            // { id: "addProjectEditBtn", label: 'Edit Project', icon: 'pi pi-pencil', command: (eve: Event) => { this.buildCreateProjectUrl() } },
            // { id: "addProjectEditSeperator", separator: true },
             { id: "addCreateEditBtn", label: 'View', icon: 'pi pi-pencil', command: (eve: Event) => { this.editItem(eve) } },
            //  { id: "addCreateEditSeperator", separator: true },
            // { id: "addCreateDeleteBtn", label: 'Delete', icon: 'pi pi-trash', command: (eve: Event) => { this.showDeleteItem(eve) } },
            // { id: "addSiliconSeperator", separator: true },
            // { id: "addSilicon", label: 'Create Silicon', icon: 'pi pi-plus', command: (eve: Event) => { this.createChildProject('Silicon') } },
            // { id: "addPackageSeperator", separator: true },
            // { id: "addPackage", label: 'Create Alternate Package', icon: 'pi pi-check', command: (eve: Event) => { this.createChildProject('Package') } },
            // { id: "addPackingSeperator", separator: true },
            // { id: "addPacking", label: 'Create Alternate Packing', icon: 'pi pi-sliders-v', command: (eve: Event) => { this.createChildProject('Packing') } },
            // { id: "addDerivativeSeperator", separator: true },
            // { id: "addDerivative", label: 'Create Derivative', icon: 'pi pi-clone', command: (eve: Event) => { this.createChildProject('Derivative') } },
            // { id: "addFoundrySeperator", separator: true },
            // { id: "addFoundry", label: 'Create Alternate Foundry', icon: 'pi pi-sliders-h', command: (eve: Event) => { this.createChildProject('Foundry') } },
            // { id: "addSubconSeperator", separator: true },
            // { id: "addSubcon", label: 'Create Alternate Subcon', icon: 'pi pi-copy', command: (eve: Event) => { this.createChildProject('Subcon') } }
        ];

        this.filterItems = this.items;

        this.changedEvent = { title: '', start: null, end: '', allDay: null, taskGuid: null };
        this.options = {
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            defaultDate: formatDate(new Date(), 'MM-dd-yyyy', 'en-US'),
            header: {
                left: 'prev,next',
                center: 'title',
                // right: 'dayGridMonth,timeGridWeek,timeGridDay'
                right: 'dayGridMonth'
            },
            editable: true,
            eventClick: (e) => {
                this.eventDialog = true;
                this.clickedEvent = e.event;
                this.buildCalenderTaskUrl(this.clickedEvent.extendedProps.taskGuid);
                // this.changedEvent.title = this.clickedEvent.title;
                // this.changedEvent.start = this.clickedEvent.start;
                // this.changedEvent.end = this.clickedEvent.end;
            }
        };
    }

    getProjectDetails(projectId) {
        this.showLoading = true;
        this.facadeService.getProjectDetailsById(projectId).subscribe(
            (success) => {
                if (success.returnCode == 0) {
                    //success.returnObject.forEach(e => {
                    if(success.returnObject.projectStartDate != '' || success.returnObject.projectStartDate != null){
                        success.returnObject.projectStartDate = new Date(success.returnObject.projectStartDate).toLocaleDateString()
                    }
                    if(success.returnObject.targetDate != '' || success.returnObject.targetDate != null){
                        success.returnObject.targetDate = new Date(success.returnObject.targetDate).toLocaleDateString()
                    }
                    this.projectInformation = success.returnObject;
                        
                    if(this.projectInformation.pdmProductID != undefined && this.projectInformation.pdmProductID != null && this.projectInformation.pdmProductID != "")
                    {
                      this.PDMbtnDisplay = true;
                    }  

                    this.editDeleteListDTO.ListName = this.projectInformation.projectName;
                    this.editDeleteListDTO.ListId = this.projectInformation.projectId
                    this.editDeleteListDTO.ListTypeName = "Project";
                    this.editDeleteListDTO.ListType = 4;
                    this.customFieldList = this.projectInformation.customFields;
                    this.onGetTasks(this.projectId,"Project",this.projectInformation.projectName,this.projectInformation.currentPhase);
                    this.Error = "";
                    this.revisedStartDate = new Date(this.projectInformation.revisedStartDate);
                    this.actualStartDate = this.projectInformation.actualStartDate == null ? null:new Date(this.projectInformation.actualStartDate);
                    if(this.projectInformation.actualStartDate==null|| this.projectInformation.actualStartDate==""||this.projectInformation.actualStartDate==undefined )
                    {
                        var ok = false;
                        if(this.projectInformation.firstMilestoneAssignee != null && this.projectInformation.firstMilestoneAssignee != undefined)
                        {
                            if(this.projectInformation.firstMilestoneAssignee.length > 0)
                            {
                                this.projectInformation.firstMilestoneAssignee.forEach(element => {
                                    if(!ok && element.toLowerCase() == this.userGuid.toLowerCase())
                                     ok = true;
                                });
                            }
                        }
                        if(this.projectInformation.projectManagerId.toLowerCase()==this.userGuid.toLowerCase() || ok)
                            this.ShowActualDate=true;
                    }
                }
                else{
                    //this.showToast("error", "Error", "Unable to retrieve Project Information. We are working to get it.")
                    this.responseError = "Unable to retrieve Project Information. We are working to get it.";
                }
            });
    }
    UpdateProjectActualStartDate(){
        this.Error="";
        if(this.actualStartDate==null||this.actualStartDate==""||this.actualStartDate==undefined){
          this.Error="Please select Actual Start Date";
        }
        else if(this.actualStartDate > new Date){
          this.Error="Actual Start Date should be less then or equal to today's date";
        }
        else{
          this.showLoading = true;
          var changedDate = this.pipe.transform(this.actualStartDate, "MM/dd/yyyy");
          this.facadeService.UpdateProjectActualStartDate(this.projectInformation.projectId,changedDate).subscribe(
              (res) => {
                  this.showLoading = false;
                   if(res.returnCode==0){
                      location.reload();
                   }
                   else{
                        this.Error=res.returnMessage;
                   }
              },
              (error) => {
                this.showLoading = false;
                console.log(error);
              });
        }
      }

    save() {
        this.eventDialog = false;
        this.clickedEvent.setProp('title', this.changedEvent.title);
        this.clickedEvent.setStart(this.changedEvent.start);
        this.clickedEvent.setEnd(this.changedEvent.end);
        this.clickedEvent.setAllDay(this.changedEvent.allDay);
        this.changedEvent = { title: '', start: null, end: '', allDay: null };
    }

    reset() {
        this.changedEvent.title = this.clickedEvent.title;
        this.changedEvent.start = this.clickedEvent.start;
        this.changedEvent.end = this.clickedEvent.end;
    }

    getTaskListByproject(projectId, listId, type) {
        this.showLoading = true;
        this.facadeService.getTaskListByProject(projectId, listId, type).subscribe(
            (sucess) => {
                this.showLoading = false;
                if (sucess.returnObject) {
                    this.DisableTaskStatus = sucess.returnObject.disableTaskStatus;
                    if(this.DisableTaskStatus){
                        let filter=[];
                        this.filterItems.forEach(element => {
                            if(element.label!="Create Task"){
                                filter.push(element);
                            }
                        });
                        this.filterItems=filter;
                    }
                    this.tasksListsByProject = sucess.returnObject.taskList;
                    this.tasksListsByProject.forEach(element => {
                        element.bold = false;
                        if(element.plannedEndDate != null && element.plannedEndDate != '01/01/1970' && element.dueDate != null && element.dueDate != '01/01/1970' && (element.status == 'New' || element.status == 'InProgress'))
                        {
                            if(Date.parse(element.plannedEndDate)){  if (new Date(element.plannedEndDate) < new Date())  element.bold = true;  }
                            else{  if (element.plannedEndDate < new Date().toLocaleDateString())  element.bold = true;  }
                            if(Date.parse(element.dueDate)) {  if (new Date(element.dueDate) < new Date())  element.bold = true;  }
                            else {  if (element.dueDate < new Date().toLocaleDateString())  element.bold = true;  }
                        }   
                    });
                    this.tasksBoard = sucess.returnObject.taskBoard;
                    this.taskBoardNew = this.tasksBoard.filter(x => x.taskStatusName == 'New');
                    this.taskBoardNewCount = (this.taskBoardNew).length;
                    this.taskBoardInprogress = this.tasksBoard.filter(x => x.taskStatusName == 'InProgress');
                    this.taskBoardInprogressCount = (this.taskBoardInprogress).length;
                    this.taskBoardHold = this.tasksBoard.filter(x => x.taskStatusName == 'NotRequired');
                    this.taskBoardHoldCount = (this.taskBoardHold).length;
                    this.taskBoardComplete = this.tasksBoard.filter(x => x.taskStatusName == 'Completed');
                    this.taskBoardCompleteCount = (this.taskBoardComplete).length;
                    this.events = sucess.returnObject.taskCalender;
                }
            }
        )
    }

    showToast(severity, status, message) {
        this.messageService.add({ key: 'tl', severity: severity, summary: status, detail: message });
    }

    phaseDialogCancel() {
        this.phaseNameControl = new FormControl('', [Validators.required]);
        this.isCreatePhaseSubmitted = false;
    }

    createPhase(phaseName) {
        this.isCreatePhaseSubmitted = true;
        this.responseError = "";
        //let user = this.facadeService.getloggedInUser();
        if (this.createPhaseform.valid) {
            let phaseObj = this.getListObj();
            phaseObj.ListName = phaseName;
            phaseObj.ListParent = this.projectId;
            phaseObj.ListStatus = parseInt(this.listStatusOptionsMdl.id);
            phaseObj.ListType = 1;//Phase
            this.showLoading = true;
            this.facadeService.createList(phaseObj).subscribe(
                (sucess) => {
                    this.showLoading = false;
                    if (sucess.returnCode == 0) {
                                    //ErrorMessage
                        if (sucess.returnObject.isValid == false) {
                            this.responseError = sucess.returnObject.errorMessage//"Active phase exists with same name.";
                            //this.showToast("error", "Error", sucess.returnObject.errorMessage);
                        }
                        else {
                            this.editTaskId = 0;
                            this.phasedisplay = false;
                            this.GetProjectMenu(this.projectId);
                            this.getProjectDetails(this.projectId);
                            //this.showToast("success", "Success", "Phase created successfully.")
                        }
                    }
                    else {
                        this.responseError = "Unable to create Phase.";
                        //this.showToast("error", "Error", "Unable to created Phase.")
                    }
                });
            this.isCreatePhaseSubmitted = false;
        }
    }

    createStage(stageName) {
        this.responseError = "";
        this.isCreateStageSubmitted = true;
        if (this.createStageform.valid) {
            let phaseObj = this.getListObj();
            phaseObj.ListName = stageName;
            phaseObj.ListParent = this.createStageMdl.id;
            phaseObj.ListType = 2;//Stage
            phaseObj.ListStatus = parseInt(this.createstagestatus.id);
            this.showLoading = true;
            this.facadeService.createList(phaseObj).subscribe(
                (sucess) => {
                    this.showLoading = false;
                    if (sucess.returnCode == 0) {
                        if (sucess.returnObject.isValid == false) {
                            this.responseError = sucess.returnObject.errorMessage//"Active stage exists with same name.";
                            //this.showToast("error", "Error", "Already stage exists with active.");
                        }
                        else {
                            this.editTaskId = 0;
                            this.stagedisplay = false;
                            this.GetProjectMenu(this.projectId);
                            this.getProjectDetails(this.projectId);
                            //this.showToast("success", "Success", "Stage created successfully.")
                        }
                    }
                    else {
                        //this.showToast("error", "Error", "Unable to created Stage.")
                        this.responseError = "Unable to create Stage.";
                    }
                });
            this.isCreateStageSubmitted = false;
        }
    }

    createMilestone(milestoneName) {
        this.responseError = "";
        this.isCreateMilestoneSubmitted = true;
        if (this.createMilestoneform.valid) {
            const lstParent = this.createMilestoneStageOptionsMdl === undefined ? this.createMilestonePhaseOptionsMdl.id : this.createMilestoneStageOptionsMdl.id;
            let phaseObj = this.getListObj();
            phaseObj.ListName = milestoneName;
            phaseObj.ListParent = lstParent;
            phaseObj.ListType = 3;//Stage
            phaseObj.ListStatus = parseInt(this.createmilestonestatus.id);
            this.showLoading = true;
            this.facadeService.createList(phaseObj).subscribe(  
                (sucess) => {
                    this.showLoading = false;
                    if (sucess.returnCode == 0) {
                        if (sucess.returnObject.isValid == false) {
                            this.responseError = sucess.returnObject.errorMessage//"Active milestone exists with same name.";
                            //this.showToast("error", "Error", "Already milestone exists with active.");
                        }
                        else {
                            this.milestonedisplay = false;
                            this.GetProjectMenu(this.projectId);
                            this.getProjectDetails(this.projectId);
                            //this.showToast("success", "Success", "Milestone created successfully.")
                        }
                    }
                    else {
                        //this.showToast("error", "Error", "Unable to created Milestome.")
                        this.responseError = "Unable to create Milestone.";
                    }
                });
            this.isCreateMilestoneSubmitted = false;
        }
    }

    showCreatePhaseModel(isPhaseEdit: boolean) {
        this.responseError = "";
        this.createPhaseform.reset();
        this.isCreatePhaseSubmitted = false;
        this.showLoading = true;
        this.facadeService.GetTasksDropdownOptionsByProject(this.projectId).subscribe(
            (sucess) => {
                this.showLoading = false;
                if (sucess.returnObject.taskStatus) {
                    this.listStatusOptions = sucess.returnObject.taskStatus;
                    this.editTaskId = 0;
                    this.phagebtnText = "Create Phase";
                    //Edit Code
                    if (isPhaseEdit == true) {
                        if (this.editDeleteListDTO.ListTypeName.toLowerCase() == "phase") {
                            this.facadeService.GetListDetailsByListId(this.editDeleteListDTO.ListId).subscribe((data) => {
                               this.editPhaseName = data.returnObject.listName;
                                this.listStatusOptionsMdl = data.returnObject.listStatus;
                                // sucess.returnObject.taskStatus.filter(ele => {
                                //     return ele.id == data.returnObject.listStatus;
                                // })[0];

                                this.editTaskId = data.returnObject.lid;
                                this.phagebtnText = "Edit Phase";
                                if(this.userGuid!=this.projectInformation.projectManagerId){
                                    this.LoggedInUser=false;
                                }
                            });
                        }
                    }
                    else {
                        this.editTaskId = 0;
                        this.listStatusOptionsMdl = "New";
                    }
                    // Ends Edit Code
                }

                this.phasedisplay = true;
            });
    }

    showCreateStageModel(isStageEdit: boolean) {
        this.responseError = "";
        this.createStageform.reset();
        this.isCreateStageSubmitted = false;
        this.showLoading = true;
        this.facadeService.GetTasksDropdownOptionsByProject(this.projectId).subscribe(
            (sucess) => {
                this.showLoading = false;
                if (sucess.returnObject.taskPhaseOptions)
                    this.createStageOptions = sucess.returnObject.taskPhaseOptions;
                if (sucess.returnObject.taskStatus)
                    this.listStatusOptions = sucess.returnObject.taskStatus;
                this.editTaskId = 0;
                this.stagebtnText = "Create Stage"
                //Edit code
                if (isStageEdit == true) {
                    this.showLoading = true;
                    this.facadeService.GetListDetailsByListId(this.editDeleteListDTO.ListId).subscribe((data) => {
                        this.showLoading = false;
                        this.editStageName = data.returnObject.listName;
                        this.createStageMdl = sucess.returnObject.taskPhaseOptions.filter(ele => {
                            return ele.id == data.returnObject.listParent;
                        })[0];
                        this.createstagestatus = sucess.returnObject.taskStatus.filter(ele => {
                            return ele.id == data.returnObject.listStatus;
                        })[0];

                        this.editTaskId = data.returnObject.lid;
                        this.stagebtnText = "Edit Stage" 
                    });
                }
                else {
                    this.editTaskId = 0;
                    this.createstagestatus = {id: "1", name: "New"};
                    if(this.editDeleteListDTO.ListType == 1){
                        this.createStageMdl = sucess.returnObject.taskPhaseOptions.filter(ele => {
                            return ele.id == this.editDeleteListDTO.ListId;
                        })[0];
                    }                    
                }
                this.stagedisplay = true;
            });
    }

    showCreateMilestoneModel(isMilestoneEdit: boolean) {
        this.responseError = "";
        this.createMilestoneform.reset();
        this.isCreateMilestoneSubmitted = false;
        this.showLoading = true;
        this.facadeService.GetTasksDropdownOptionsByProject(this.projectId).subscribe(
            (sucess) => {
                this.showLoading = false;
                if (sucess.returnObject.taskPhaseOptions) {
                    this.createMilestonePhaseOptions = sucess.returnObject.taskPhaseOptions;
                }
                // if (sucess.returnObject.taskStageOptions) {
                //     this.createMilestoneStageOptions = sucess.returnObject.taskStageOptions;
                // }
                if (sucess.returnObject.taskStatus) {
                    this.listStatusOptions = sucess.returnObject.taskStatus;
                }
                this.editTaskId = 0;
                this.milestoneText = "Create Milestone"
                //Edit
                if (isMilestoneEdit == true) {
                    this.showLoading = true;
                    this.facadeService.GetListDetailsByListId(this.editDeleteListDTO.ListId).subscribe((data) => {
                        this.showLoading = false;
                        this.editMilestone = data.returnObject.listName;
                        this.createMilestonePhaseOptionsMdl = sucess.returnObject.taskPhaseOptions.filter(ele => {
                            return ele.id == data.returnObject.mileStonePhase;
                        })[0];

                        // this.createMilestoneStageOptionsMdl = sucess.returnObject.taskStageOptions.filter(ele => {
                        //     return ele.id == data.returnObject.listParent;
                        // })[0];

                        this.GetStageByPhase(data.returnObject.mileStonePhase, true, data.returnObject.mileStoneStage); 

                        this.createmilestonestatus = sucess.returnObject.taskStatus.filter(ele => {
                            return ele.id == data.returnObject.listStatus;
                        })[0];

                        this.milestoneText = "Edit Milestone";
                        this.editTaskId = data.returnObject.lid;
                    });
                }
                else {
                    this.editTaskId = 0;
                    this.createmilestonestatus = {id: "1", name: "New"};
                    
                    Object.entries(this.panelMenuItems).forEach(([projKey, projValue]) => {
                        Object.entries(projValue.items).forEach(([phaseKey, phaseValue]) => {
                            Object.entries(phaseValue.items).forEach(([stageKey, stageValue]) => {
                                if(stageValue.id == this.editDeleteListDTO.ListId){                                    
                                    this.createMilestonePhaseOptionsMdl = sucess.returnObject.taskPhaseOptions.filter(ele => {
                                        return ele.id == phaseValue.id; // v2 is Phase
                                    })[0];
                                    this.GetStageByPhase(phaseValue.id, true, stageValue.id); 
                                }
                            });
                        });
                    });
                }

                this.milestonedisplay = true;
            });
    }

    onClickGetStageByPhase(event:any)
    {
        let phaseId = event.value ? event.value.id : event;
        this.GetStageByPhase(phaseId, false, ""); 
    }

    GetStageByPhase(phaseId:string, isMilestoneEdit : boolean, selectedId:string){
        this.showLoading = true;
        this.facadeService.GetStageByPhase(phaseId).subscribe(sucess => {
            this.showLoading = false;
            this.createMilestoneStageOptions = sucess.returnObject;
            if(isMilestoneEdit == true) {
                this.createMilestoneStageOptionsMdl = sucess.returnObject.filter(ele => {
                    return ele.id == selectedId;
                })[0];
            }
        });      
    }

    GetProjectMenu(projectId) {
        let _classProjects: MenuItem[] = [];
        this.showLoading = true;
        this.facadeService.GetProjectMenuItems(this.projectId).subscribe((data) => {
            if (data.returnObject) {
                let _classProject: MenuItem = {
                    label: data.returnObject.projectName, //projectId
                    icon: "pi pi-fw pi-briefcase",
                    expanded: true,
                    id: this.projectId,
                    command: (event) => { this.onGetTasks(this.projectId, "Project", data.returnObject.projectName,data.returnObject.currentPhase); }
                };

                let _phaseItems: MenuItem[] = [];
                // Getting phages in project.
                if (data.returnObject.phaseitems != undefined && data.returnObject.phaseitems != null && data.returnObject.phaseitems.length > 0) {
                    for (let i = 0; i < data.returnObject.phaseitems.length; i++) {
                        let _stageMilestoneItems: MenuItem[] = [];
                        // Getting stage(s)/milestone(s) in phage.
                        if (data.returnObject.phaseitems[i].stageMilestoneitems != undefined && data.returnObject.phaseitems[i].stageMilestoneitems != null && data.returnObject.phaseitems[i].stageMilestoneitems.length > 0) {
                            for (let j = 0; j < data.returnObject.phaseitems[i].stageMilestoneitems.length; j++) {
                                let _milestones: MenuItem[] = [];
                                //Getting milestone in stage.
                                if (data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage != undefined && data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage != null && data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage.length > 0) {
                                    for (let k = 0; k < data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage.length; k++) {
                                        console.log(data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage.mileStoneName);
                                        let _milestone: MenuItem = {
                                            label: data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage[k].mileStoneName,//milestoneId//type
                                            icon: "pi pi-fw pi-compass",
                                            badge: data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage[k].type,
                                            id: data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage[k].milestoneId,
                                            command: (event) => { this.onGetTasks(data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage[k].milestoneId, data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage[k].type, data.returnObject.phaseitems[i].stageMilestoneitems[j].mileStoneinStage[k].mileStoneName,data.returnObject.currentPhase); }
                                        }
                                        if (_milestone != undefined) {
                                            _milestones.push(_milestone);
                                        }
                                    }
                                }
                                let _stageMilestoneItem: MenuItem = {
                                    label: data.returnObject.phaseitems[i].stageMilestoneitems[j].stageorMilestoneName,//stageorMilestoneId//isStage//type
                                    icon: (data.returnObject.phaseitems[i].stageMilestoneitems[j].isStage) ? "pi pi-fw pi pi-globe" : "pi pi-fw pi-compass",
                                    id: data.returnObject.phaseitems[i].stageMilestoneitems[j].stageorMilestoneId,
                                    badge: data.returnObject.phaseitems[i].stageMilestoneitems[j].type,
                                    command: (event) => { this.onGetTasks(data.returnObject.phaseitems[i].stageMilestoneitems[j].stageorMilestoneId, data.returnObject.phaseitems[i].stageMilestoneitems[j].type, data.returnObject.phaseitems[i].stageMilestoneitems[j].stageorMilestoneName,data.returnObject.currentPhase); },
                                    items: _milestones
                                };
                                if (_stageMilestoneItem != undefined) {
                                    _stageMilestoneItems.push(_stageMilestoneItem); // Assigning stage/milestoe to phase.
                                }
                            }
                        }

                        let _phaseItem: MenuItem = {
                            label: data.returnObject.phaseitems[i].phaseName, //phaseListIdId//type
                            icon: "pi pi-fw pi-chevron-circle-right",
                            id: data.returnObject.phaseitems[i].phaseListIdId,
                            styleClass:data.returnObject.phaseitems[i].color,
                            badge: data.returnObject.phaseitems[i].type,
                            command: (event) => { this.onGetTasks(data.returnObject.phaseitems[i].phaseListIdId, data.returnObject.phaseitems[i].type, data.returnObject.phaseitems[i].phaseName,data.returnObject.currentPhase); },
                            items: _stageMilestoneItems,
                        };

                        if (_phaseItem != undefined) {
                            _phaseItems.push(_phaseItem);
                        }

                    }

                    _classProject.items = _phaseItems; // Assigning phase
                }

                _classProjects.push(_classProject);
                this.panelMenuItems = _classProjects;
            }
        });
    }

    getListObj() {
        let phaseObj = new List();
        phaseObj.ProjectId = this.projectId;
        phaseObj.ModuleId = "m1";
        phaseObj.ListOwnerId = "";
        phaseObj.ListStatus = 1;
        phaseObj.Active = "Y";
        phaseObj.ListSeq = 1;
        phaseObj.CreatedBy = this.facadeService.getloggedInUser().userguid;
        phaseObj.CreatedDate = new Date();
        phaseObj.UpdatedBy = null;
        phaseObj.UpdatedDate = new Date();
        phaseObj.ProductPhaseID = '';
        if (this.editTaskId > 0) {
            phaseObj.Lid = this.editTaskId;
        }

        return phaseObj;
    }

    onGetTasks(listId: string, type: string, currentEdit: string,currentPhase: string) {
        if(type.toLowerCase() == "project" || this.panelMenuItems[0]?.items?.length == 0){
            this.filterItems = this.items.filter(x => x.id != "addCreateTaskBtn");
            this.filterItems = this.filterItems.filter(x => x.id != "addCreateTaskSeperator");
            this.showCreateTask = false;
        }
        else {
            if(this.userGuid==this.projectInformation.projectManagerId){
                this.showCreateTask = true;
            }
        }
        if(type.toLowerCase() == "phase"){
            this.filterItems = this.items.filter(x => x.id != "addCreatePhaseBtn");
            this.filterItems = this.filterItems.filter(x => x.id != "addCreatePhaseSeperator");
        }
        else if(type.toLowerCase() == "stage"){
            this.filterItems = this.items.filter(x => x.id != "addCreatePhaseBtn");
            this.filterItems = this.filterItems.filter(x => x.id != "addCreatePhaseSeperator");
            this.filterItems = this.filterItems.filter(x => x.id != "addCreateStageBtn");
            this.filterItems = this.filterItems.filter(x => x.id != "addCreateStageSeperator");
        }
        else if(type.toLowerCase() == "milestone"){
            this.filterItems = this.items.filter(x => x.id != "addCreatePhaseBtn");
            this.filterItems = this.filterItems.filter(x => x.id != "addCreatePhaseSeperator");
            this.filterItems = this.filterItems.filter(x => x.id != "addCreateStageBtn");
            this.filterItems = this.filterItems.filter(x => x.id != "addCreateStageSeperator");
            this.filterItems = this.filterItems.filter(x => x.id != "addCreateMilestoneBtn");
            this.filterItems = this.filterItems.filter(x => x.id != "addCreateMilestoneSeperator");
        }
        if(currentPhase != "P0" && currentPhase != "P1" && currentPhase != "P2")
        {
            this.filterItems = this.items.filter(x => x.id != "addCreateSilicon");
            this.filterItems = this.items.filter(x => x.id != "addCreatePackage");
        }
        

        this.editDeleteListDTO.ListName = currentEdit;
        this.editDeleteListDTO.ListId = listId
        this.editDeleteListDTO.ListTypeName = type;
        this.editDeleteListDTO.ListType = ListEnum[type];
        this.showPhaseStatus();
        this.getTaskListByproject(this.projectId, listId, type);
    }

    exportExcel(){
        /* table id is passed over here */   
        let element = document.getElementById('excel-table'); 
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        this.delete_row(wb.Sheets.Sheet1, 1);
        ws['!cols'] = [{ wpx : 100 },{ wpx : 200 },{ wpx : 120 },{ wpx : 80 },{ wpx : 100 },{ wpx : 100 },{ wpx : 100 },{ wpx : 100 }];
        /* save to file */
        var name = this.projectInformation?.projectName + " (" + (this.projectInformation != undefined ? this.projectInformation.mfgPart: "") + ") Milestones";
        XLSX.writeFile(wb, name + ".xlsx");
     }

    delete_row (ws, row_index){
        let range = XLSX.utils.decode_range(ws["!ref"])
        for(var R = row_index; R < range.e.r; ++R){
            for(var C = range.s.c; C <= range.e.c; ++C){
              ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
              if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
                ws[this.ec(R+1, C)].v  = "";
            }
        }
        range.e.r--
        delete (ws['I1'])
        ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
      }
      ec(r, c) {
        return XLSX.utils.encode_cell({r:r,c:c})
      }

      print() {
        var Content = document.getElementById('invoice-content').innerHTML;
        Content = '<br>' + document.getElementById("productdetails").innerHTML + '<br>' + Content;
        this.printService.Print(Content,this.projectInformation?.projectName + " (" + (this.projectInformation != undefined ? this.projectInformation.mfgPart: "") + ") Milestones",'trSearch',true,0);
    }
    showPhaseStatus() {
        if (this.editDeleteListDTO.ListTypeName.toLowerCase() == "phase") {
            this.showLoading = true;
        this.facadeService.GetTasksDropdownOptionsByProject(this.projectId).subscribe(
            (sucess) => {
                this.showLoading = false;
                if (sucess.returnObject.taskStatus) {
                        if (this.editDeleteListDTO.ListTypeName.toLowerCase() == "phase") {
                            this.facadeService.GetListDetailsByListId(this.editDeleteListDTO.ListId).subscribe((data) => {
                              this.listStatusOptionsMdl = data.returnObject.listStatus;
                            if (this.projectInformation.projectStatusName.toLowerCase() == "hold")
                                this.editDeleteListDTO.ListName=this.editDeleteListDTO.ListName+" - Hold";
                            else if (this.projectInformation.projectStatusName.toLowerCase() == "discontinued" || this.projectInformation.projectStatusName.toLowerCase() == "eol"
                            || this.projectInformation.projectStatusName.toLowerCase() == "obs")
                                this.editDeleteListDTO.ListName=this.editDeleteListDTO.ListName+" - NA";
                            else if (this.projectInformation.projectStatusName.toLowerCase() == "completed" && this.listStatusOptionsMdl == "In-Process")
                                this.editDeleteListDTO.ListName=this.editDeleteListDTO.ListName+" - In-Production";
                            else if (this.projectInformation.projectStatusName.toLowerCase() == "completed" && this.listStatusOptionsMdl.toLowerCase() == "ready to start")
                                this.editDeleteListDTO.ListName=this.editDeleteListDTO.ListName+" - In-Process";
                            else
                              this.editDeleteListDTO.ListName=this.editDeleteListDTO.ListName+" - "+this.listStatusOptionsMdl;
                            });
                        }
                }
            });
            this.items = [
                // { id: "addSilicon", label: 'Create Silicon', icon: 'pi pi-plus', command: (eve: Event) => { this.createChildProject('Silicon') } },
                // { id: "addPackageSeperator", separator: true },
                // { id: "addPackage", label: 'Create Alternate Package', icon: 'pi pi-check', command: (eve: Event) => { this.createChildProject('Package') } },
                // { id: "addPackingSeperator", separator: true },
                // { id: "addPacking", label: 'Create Alternate Packing', icon: 'pi pi-sliders-v', command: (eve: Event) => { this.createChildProject('Packing') } },
                // { id: "addDerivativeSeperator", separator: true },
                // { id: "addDerivative", label: 'Create Derivative', icon: 'pi pi-clone', command: (eve: Event) => { this.createChildProject('Derivative') } },
                // { id: "addFoundrySeperator", separator: true },
                // { id: "addFoundry", label: 'Create Alternate Foundry', icon: 'pi pi-sliders-h', command: (eve: Event) => { this.createChildProject('Foundry') } },
                // { id: "addSubconSeperator", separator: true },
                // { id: "addSubcon", label: 'Create Alternate Subcon', icon: 'pi pi-copy', command: (eve: Event) => { this.createChildProject('Subcon') } }
            ];
            this.filterItems = this.items;
        }
        else{
            this.items = [                 
                { id: "addCreateEditBtn", label: 'View', icon: 'pi pi-pencil', command: (eve: Event) => { this.editItem(eve) } },
                // { id: "addSiliconSeperator", separator: true },
                // { id: "addSilicon", label: 'Create Silicon', icon: 'pi pi-plus', command: (eve: Event) => { this.createChildProject('Silicon') } },
                // { id: "addPackageSeperator", separator: true },
                // { id: "addPackage", label: 'Create Alternate Package', icon: 'pi pi-check', command: (eve: Event) => { this.createChildProject('Package') } },
                // { id: "addPackingSeperator", separator: true },
                // { id: "addPacking", label: 'Create Alternate Packing', icon: 'pi pi-sliders-v', command: (eve: Event) => { this.createChildProject('Packing') } },
                // { id: "addDerivativeSeperator", separator: true },
                // { id: "addDerivative", label: 'Create Derivative', icon: 'pi pi-clone', command: (eve: Event) => { this.createChildProject('Derivative') } },
                // { id: "addFoundrySeperator", separator: true },
                // { id: "addFoundry", label: 'Create Alternate Foundry', icon: 'pi pi-sliders-h', command: (eve: Event) => { this.createChildProject('Foundry') } },
                // { id: "addSubconSeperator", separator: true },
                // { id: "addSubcon", label: 'Create Alternate Subcon', icon: 'pi pi-copy', command: (eve: Event) => { this.createChildProject('Subcon') } }
            ];
    
            this.filterItems = this.items;
        }
    }
    editItem(event) {
        if (this.editDeleteListDTO.ListTypeName.toLowerCase() == "project")
            this.router.navigate(['/editproject/' + this.projectId]);
        else if (this.editDeleteListDTO.ListTypeName.toLowerCase() == "phase")
            this.showCreatePhaseModel(true);
        else if (this.editDeleteListDTO.ListTypeName.toLowerCase() == "stage")
            this.showCreateStageModel(true);
        else if (this.editDeleteListDTO.ListTypeName.toLowerCase() == "milestone")
            this.showCreateMilestoneModel(true);
    }

    showDeleteItem(event) {
        this.responseError = "";
        this.deleteConfirmModal = true;
    }

    deleteItemConfirm() {
        this.editDeleteListDTO.ProjectId = this.projectId;
        this.editDeleteListDTO.CreatedBy = this.facadeService.getloggedInUser().userguid;
        this.editDeleteListDTO.CreatedDate = new Date();
        if (this.editDeleteListDTO.ListTypeName.toLowerCase() == "phase")
            this.editDeleteListDTO.ListType = 1;
        else if (this.editDeleteListDTO.ListTypeName.toLowerCase() == "stage")
            this.editDeleteListDTO.ListType = 2;
        else if (this.editDeleteListDTO.ListTypeName.toLowerCase() == "milestone")
            this.editDeleteListDTO.ListType = 3;
        else
            this.editDeleteListDTO.ListType = 4;
            this.showLoading = true;
        this.facadeService.DeleteList(this.editDeleteListDTO).subscribe(
            (sucess) => {
                this.showLoading = false;
                
                if (sucess.returnCode == 0) {
                    if(sucess.returnObject.isValid == false){
                        //this.showToast("error", "Error", sucess.returnObject.errorMessage)
                        this.responseError = sucess.returnObject.errorMessage;
                    }
                    else{
                        this.deleteConfirmModal = false;
                        this.GetProjectMenu(this.projectId);
                        //this.showToast("success", "Success", "Phase deleted successfully.")
                    }
                }
                else {
                    //this.showToast("error", "Error", "Unable to delete Phase.")
                    this.responseError = "Unable to delete "+this.editDeleteListDTO.ListTypeName.toLowerCase();
                }
            });
    }

    buildCreateTaskUrl(){
        this.router.navigate(['/createtask/'+this.editDeleteListDTO.ListId+"/"+this.editDeleteListDTO.ListType]);
    }

    buildCreateProjectUrl(){
        this.router.navigate(['/editproject/'+this.projectId]);
    }
    buildCalenderTaskUrl(taskGuid){
        this.router.navigate(['/edittask/'+taskGuid]);
    }
    createChildProject(Action){
        this.showLoading = true;
        this.Action = Action;
        this.childprojectdisplay = true;
        this.CPErrorDisplay= false;
        this.ChildProjectError = "";

        this.facadeService
        .DataSourceAsQuery("select * from tblProjectDescription")
        .subscribe((data) => {
            this.projectdesciptionoption =  data.returnObject;
            if(Action== "Silicon")
                this.selectedprojectdescription = this.projectdesciptionoption.find(m=>m.name=="Bug Fixes");
            if(Action== "Package")
                this.selectedprojectdescription = this.projectdesciptionoption.find(m=>m.name=="Alternate Package Option");
            if(Action == 'Packing')
                this.selectedprojectdescription = this.projectdesciptionoption.find(m=>m.name=="Alternate Packing Option");
            if(Action == 'Derivative')
                this.selectedprojectdescription = this.projectdesciptionoption.find(m=>m.name=="Derivative");
            if(Action == 'Foundry')
                this.selectedprojectdescription = this.projectdesciptionoption.find(m=>m.name=="Alternate Foundry");
            if(Action == 'Subcon')
                this.selectedprojectdescription = this.projectdesciptionoption.find(m=>m.name=="Alternate Subcon");
            //this.selectedprojectdescription = this.projectdesciptionoption.find(m=>m.id==this.projectInformation.projectDesciptionID);
        });
        
        if(Action== "Package")
        {   
            this.facadeService
            .DataSourceAsQuery("select * from tblpackagetype")
            .subscribe((data) => {
                this.packagetypeoption =  data.returnObject;
                this.selectedpackagetype = this.packagetypeoption.find(m=>m.id==this.projectInformation.packageTypeID);
            });
        }
        if(Action == 'Packing')
        {   
            this.facadeService
            .DataSourceAsQuery("select * from tblPackingOption")
            .subscribe((data) => {
                this.packingoption =  data.returnObject;
                this.selectedpackingoption = this.packingoption.find(m=>m.id==this.projectInformation.packingOptionID);
            });
        }
        if(Action == 'Derivative')
        {
            this.facadeService
            .DataSourceAsQuery("select * from tblTemperatureGrade")
            .subscribe((data) => {
                this.tempgradeoption =  data.returnObject;
                this.selectedtempgrade = this.tempgradeoption.find(m=>m.id==this.projectInformation.temparatureGradeID);
            });
        }
        if(Action == 'Foundry')
        {
            this.facadeService
            .DataSourceAsQuery("select * from tblFoundry")
            .subscribe((data) => {
                this.foundryoptions =  data.returnObject;
                this.selectedfoundry = this.foundryoptions.find(m=>m.id==this.projectInformation.foundryID);
            });
            this.facadeService
            .DataSourceAsQuery("select * from tblFoundryProcess")
            .subscribe((data) => {
                this.foundryprocessoptions =  data.returnObject;
                this.selectedfoundryprocess = this.foundryprocessoptions.find(m=>m.id==this.projectInformation.foundryProcessID);
            });
        }
        if(Action == 'Subcon')
        {
            this.facadeService
            .DataSourceAsQuery("select * from tblSubcon")
            .subscribe((data) => {
                this.CPSubconO =  data.returnObject;
                this.selectedCPSubcon = this.CPSubconO.find(m=>m.id==this.projectInformation.cpSubconID);
                this.FTSubconO =  data.returnObject;
                this.selectedFTSubcon = this.FTSubconO.find(m=>m.id==this.projectInformation.ftSubconID);
                this.AssySubcon1O =  data.returnObject;
                this.selectedAssySubcon1 = this.AssySubcon1O.find(m=>m.id==this.projectInformation.assySubcon1ID);
                this.AssySubcon2O =  data.returnObject;
                this.selectedAssySubcon2 = this.AssySubcon2O.find(m=>m.id==this.projectInformation.assySubcon2ID);
                this.DPSSubconO =  data.returnObject;
                this.selectedDPSSubcon = this.DPSSubconO.find(m=>m.id==this.projectInformation.dpsSubconID);
            });
            this.facadeService
            .DataSourceAsQuery("select * from tblSubconProcess")
            .subscribe((data) => {
                this.AssySubconProcess1O =  data.returnObject;
                this.selectedAssySubconProcess1 = this.AssySubconProcess1O.find(m=>m.id==this.projectInformation.assySubconProcess1ID);
                this.AssySubconProcess2O =  data.returnObject;
                this.selectedAssySubconProcess2 = this.AssySubconProcess2O.find(m=>m.id==this.projectInformation.assySubconProcess2ID);
                this.DPSSubconProcessO =  data.returnObject;
                this.selectedDPSSubconProcess = this.DPSSubconProcessO.find(m=>m.id==this.projectInformation.dpsSubconProcessID);
            });
        }
        this.diecoderoot = this.projectInformation.dieCodeRoot;
        this.diecoded = this.projectInformation.dieCodeD;
        this.diecodee = this.projectInformation.dieCodeE;
        this.packagename = this.projectInformation.packageOption;
        this.mfgpartSilicon = this.projectInformation.mfgPartSilicon;
        this.fgpart=this.projectInformation.fgPart;
        this.MaxStartDate = new Date(this.projectInformation.maxDate);
        this.minMaxStartDate = new Date(this.projectInformation.minMaxStartDate);
        this.description=this.projectInformation.description;
        this.trqty = this.projectInformation.trQty;
        this.tubeqty = this.projectInformation.tubeQty;
        this.trayqty = this.projectInformation.trayQty;

        if(Action== "Silicon")
        {
            if(this.projectInformation.dieCode != undefined && this.projectInformation.dieCode != null && this.projectInformation.dieCode != '')
                this.addchildproject = false;
            else
            {
                this.CPErrorDisplay= true;
                this.ChildProjectError = "Die Code is not defined";
                this.addchildproject = true;
            }
        }
        if(Action== "Package")
        {
            if(this.projectInformation.packageTypeID != undefined && this.projectInformation.packageTypeID != null && this.projectInformation.packageTypeID != ''
            && this.projectInformation.packageOption != undefined && this.projectInformation.packageOption != null && this.projectInformation.packageOption != '')
                this.addchildproject = false;
            else
            {
                this.CPErrorDisplay= true;
                this.ChildProjectError = "Package Type or Package Name is not defined";
                this.addchildproject = true;
            }
        }
        if(Action== "Packing")
        {
            if(this.projectInformation.packingOptionID != undefined && this.projectInformation.packingOptionID != null && this.projectInformation.packingOptionID != '')
                this.addchildproject = false;
            else
            {
                this.CPErrorDisplay= true;
                this.ChildProjectError = "Packing Option is not defined";
                this.addchildproject = true;
            }
        }
        if(Action == 'Derivative')
        {
            this.addchildproject = true;
        }
        if(Action== "Foundry")
        {
            if(this.projectInformation.foundryID != undefined && this.projectInformation.foundryID != null && this.projectInformation.foundryID != ''
            && this.projectInformation.foundryProcessID != undefined && this.projectInformation.foundryProcessID != null && this.projectInformation.foundryProcessID != '')
            {   
                if(this.projectInformation.dieCode != undefined && this.projectInformation.dieCode != null && this.projectInformation.dieCode != '')
                    this.addchildproject = false;
                else
                {
                    this.CPErrorDisplay= true;
                    this.ChildProjectError = "Die Code is not defined";
                    this.addchildproject = true;
                }
            }
            else
            {   
                this.CPErrorDisplay= true;
                this.ChildProjectError = "Foundry and Foundry Process is not defined.";
                this.addchildproject = true;
            }
        }
        if(Action== "Subcon")
        {
            if(this.projectInformation.cpSubconID != undefined && this.projectInformation.cpSubconID != null && this.projectInformation.cpSubconID != ''
            && this.projectInformation.ftSubconID != undefined && this.projectInformation.ftSubconID != null && this.projectInformation.ftSubconID != ''
            && this.projectInformation.assySubcon1ID != undefined && this.projectInformation.assySubcon1ID != null && this.projectInformation.assySubcon1ID != ''
            && this.projectInformation.assySubcon2ID != undefined && this.projectInformation.assySubcon2ID != null && this.projectInformation.assySubcon2ID != ''
            && this.projectInformation.dpsSubconID != undefined && this.projectInformation.dpsSubconID != null && this.projectInformation.dpsSubconID != ''
            && this.projectInformation.assySubconProcess1ID != undefined && this.projectInformation.assySubconProcess1ID != null && this.projectInformation.assySubconProcess1ID != ''
            && this.projectInformation.assySubconProcess2ID != undefined && this.projectInformation.assySubconProcess2ID != null && this.projectInformation.assySubconProcess2ID != ''
            && this.projectInformation.dpsSubconProcessID != undefined && this.projectInformation.dpsSubconProcessID != null && this.projectInformation.dpsSubconProcessID != '')
            {   
                this.addchildproject = false;
            }
            else
            {
                this.CPErrorDisplay= true;
                this.ChildProjectError = "CP Subcon (Primary), FT Subcon (Primary), Assy Subcon 1 (Primary), Assy Subcon 2 (Primary), DPS Subcon (Primary), Assy Subcon Process 1, Assy Subcon Process 2, DPS Subcon Process is not defined.";
                this.addchildproject = true;
            }
        }
        if(!this.CPErrorDisplay)
        {
            this.facadeService.GetAllDieCodes().subscribe(
            (response) => {
                this.showLoading = false;
                if (response.returnCode == 0) {
                    this.DieCodes = response.returnObject;
                }
            });
            this.facadeService.GetAllMFGParts().subscribe(
                (response) => {
                    this.showLoading = false;
                    if (response.returnCode == 0) {
                        this.AllMFGParts = response.returnObject;
                    }
                });
        }
        else
            this.showLoading = false;
    }

    AddSilicon(){
        this.showLoading =true;
        this.childprojectdisplay = true;
        this.CPErrorDisplay= false;
        this.ChildProjectError = "";
        
        if (this.MaxStartDate == undefined || this.MaxStartDate.toString() == "") {
            this.CPErrorDisplay = true;
            this.ChildProjectError = "Please enter project start date";
            this.showLoading = false;
        }
        if(this.selectedprojectdescription != undefined && this.selectedprojectdescription != null && !this.CPErrorDisplay)
        {
            if((this.selectedprojectdescription.id == undefined || this.selectedprojectdescription.id == null || this.selectedprojectdescription.id == "") && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Project Description is required";
                this.showLoading = false;
            }
        }
        else
        {
            this.CPErrorDisplay = true;
            this.ChildProjectError = "Project Description is required";
            this.showLoading = false;
        }
        if(this.projectInformation.projectDesciptionID == this.selectedprojectdescription.id && !this.CPErrorDisplay)
        {
            this.CPErrorDisplay = true;
            this.ChildProjectError = "Project Description should be different from original one.";
            this.showLoading = false;
        }
        if ((this.mfgpartSilicon == undefined || this.mfgpartSilicon == "" || this.mfgpartSilicon == null) && !this.CPErrorDisplay)
        {
            this.CPErrorDisplay = true;
            this.ChildProjectError = "MFG Part # is required";
            this.showLoading = false;
        }
        if(this.projectInformation.mfgPart == this.mfgpartSilicon && !this.CPErrorDisplay)
        {
            this.CPErrorDisplay = true;
            this.ChildProjectError = "MFG Part # Option should be diffrent from original one";
            this.showLoading = false;
        }
        if (this.minMaxStartDate != undefined && this.minMaxStartDate.toString() != "" && this.minMaxStartDate != null && !this.CPErrorDisplay)
        {
            if(this.MaxStartDate < this.minMaxStartDate)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "New Project start date should be greater then Predecessors Actual end date.";
                this.showLoading = false;
            }
        }
        if(this.Action == "Silicon")
        {
            if ((this.diecoded == undefined || this.diecoded == "" || this.diecoded == null) && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Die Code Rev (D) is required";
                this.showLoading = false;
            }
            if ((this.diecodee == undefined || this.diecodee == "" || this.diecodee == null) && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Die Code Rev (E) is required";
                this.showLoading = false;
            }
            if(this.projectInformation.dieCodeD == this.diecoded && this.projectInformation.dieCodeE == this.diecodee && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Die Code Revision should be diffrent from original one";
                this.showLoading = false;
            }
            if(this.DieCodes != undefined && this.DieCodes != null && this.DieCodes.length > 0 && !this.CPErrorDisplay)
            { 
                var newarr = this.projectInformation.dieCode.split("-");
                var newvalue = newarr[0] + '-' + this.diecoded + this.diecodee;
                var exists = false;
                this.DieCodes.forEach(element => {
                    if(element.toLowerCase() == newvalue.toLowerCase() && !exists){
                        exists = true;
                    }
                });
                if(exists)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Entered Die Code already exists in the system.";
                    this.showLoading = false;
                }
            }
        }
        if(this.Action == "Package")
        {
            if(this.selectedpackagetype != undefined && this.selectedpackagetype != null && !this.CPErrorDisplay)
            {
                if((this.selectedpackagetype.id == undefined || this.selectedpackagetype.id == null || this.selectedpackagetype.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Package Type is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Package Type is required";
                this.showLoading = false;
            }
            if ((this.packagename == undefined || this.packagename == "" || this.packagename == null) && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Package Name is required";
                this.showLoading = false;
            }
            if(this.projectInformation.packageTypeID == this.selectedpackagetype.id && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Package Type should be diffrent from original one";
                this.showLoading = false;
            }
            if(this.projectInformation.packageOption == this.packagename && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Package Name should be diffrent from original one";
                this.showLoading = false;
            }
        }
        if(this.Action == "Packing")
        {
            if(this.selectedpackingoption != undefined && this.selectedpackingoption != null && !this.CPErrorDisplay)
            {
                if((this.selectedpackingoption.id == undefined || this.selectedpackingoption.id == null || this.selectedpackingoption.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Packing Option is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Packing Option is required";
                this.showLoading = false;
            }
            if(this.projectInformation.packingOptionID == this.selectedpackingoption.id && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Packing Option should be diffrent from original one";
                this.showLoading = false;
            }
        }
        if(this.Action == "Foundry")
        {
            if ((this.diecoderoot == undefined || this.diecoderoot == "" || this.diecoderoot == null) && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Die Code (Root) is required";
                this.showLoading = false;
            }
            if ((this.diecoded == undefined || this.diecoded == "" || this.diecoded == null) && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Die Code Rev (D) is required";
                this.showLoading = false;
            }
            if ((this.diecodee == undefined || this.diecodee == "" || this.diecodee == null) && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Die Code Rev (E) is required";
                this.showLoading = false;
            }
            if(this.projectInformation.dieCodeRoot == this.diecoderoot && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Die Code Root should be diffrent from original one";
                this.showLoading = false;
            }
            if(this.DieCodes != undefined && this.DieCodes != null && this.DieCodes.length > 0 && !this.CPErrorDisplay)
            { 
                var newvalue = this.diecoderoot + '-' + this.diecoded + this.diecodee;
                var exists = false;
                this.DieCodes.forEach(element => {
                    if(element.toLowerCase() == newvalue.toLowerCase() && !exists){
                        exists = true;
                    }
                });
                if(exists)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Entered Die Code already exists in the system.";
                    this.showLoading = false;
                }
            }
            if(this.selectedfoundry != undefined && this.selectedfoundry != null && !this.CPErrorDisplay)
            {
                if((this.selectedfoundry.id == undefined || this.selectedfoundry.id == null || this.selectedfoundry.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Foundry is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Foundry is required";
                this.showLoading = false;
            }
            if(this.selectedfoundryprocess != undefined && this.selectedfoundryprocess != null && !this.CPErrorDisplay)
            {
                if((this.selectedfoundryprocess.id == undefined || this.selectedfoundryprocess.id == null || this.selectedfoundryprocess.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Foundry Process is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Foundry Process is required";
                this.showLoading = false;
            }
            if(this.projectInformation.foundryID == this.selectedfoundry.id && this.projectInformation.foundryProcessID == this.selectedfoundryprocess.id && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Atleast one value should be different from original for Foundry and Foundry Process";
                this.showLoading = false;
            }
        }
        if(this.Action == "Subcon")
        {
            if(this.selectedCPSubcon != undefined && this.selectedCPSubcon != null && !this.CPErrorDisplay)
            {
                if((this.selectedCPSubcon.id == undefined || this.selectedCPSubcon.id == null || this.selectedCPSubcon.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "CP Subcon (Primary) is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "CP Subcon (Primary) is required";
                this.showLoading = false;
            }
            if(this.selectedFTSubcon != undefined && this.selectedFTSubcon != null && !this.CPErrorDisplay)
            {
                if((this.selectedFTSubcon.id == undefined || this.selectedFTSubcon.id == null || this.selectedFTSubcon.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "FT Subcon (Primary) is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "FT Subcon (Primary) is required";
                this.showLoading = false;
            }
            if(this.selectedAssySubcon1 != undefined && this.selectedAssySubcon1 != null && !this.CPErrorDisplay)
            {
                if((this.selectedAssySubcon1.id == undefined || this.selectedAssySubcon1.id == null || this.selectedAssySubcon1.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Assy Subcon 1 (Primary) is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Assy Subcon 1 (Primary) is required";
                this.showLoading = false;
            }
            if(this.selectedAssySubcon2 != undefined && this.selectedAssySubcon2 != null && !this.CPErrorDisplay)
            {
                if((this.selectedAssySubcon2.id == undefined || this.selectedAssySubcon2.id == null || this.selectedAssySubcon2.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Assy Subcon 2 (Primary) is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Assy Subcon 2 (Primary) is required";
                this.showLoading = false;
            }
            if(this.selectedDPSSubcon != undefined && this.selectedDPSSubcon != null && !this.CPErrorDisplay)
            {
                if((this.selectedDPSSubcon.id == undefined || this.selectedDPSSubcon.id == null || this.selectedDPSSubcon.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "DPS Subcon (Primary) is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "DPS Subcon (Primary) is required";
                this.showLoading = false;
            }
            if(this.selectedAssySubconProcess1 != undefined && this.selectedAssySubconProcess1 != null && !this.CPErrorDisplay)
            {
                if((this.selectedAssySubconProcess1.id == undefined || this.selectedAssySubconProcess1.id == null || this.selectedAssySubconProcess1.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Assy Subcon Process 1 is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Assy Subcon Process 1 is required";
                this.showLoading = false;
            }
            if(this.selectedAssySubconProcess2 != undefined && this.selectedAssySubconProcess2 != null && !this.CPErrorDisplay)
            {
                if((this.selectedAssySubconProcess2.id == undefined || this.selectedAssySubconProcess2.id == null || this.selectedAssySubconProcess2.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Assy Subcon Process 2 is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Assy Subcon Process 2 is required";
                this.showLoading = false;
            }
            if(this.selectedDPSSubconProcess != undefined && this.selectedDPSSubconProcess != null && !this.CPErrorDisplay)
            {
                if((this.selectedDPSSubconProcess.id == undefined || this.selectedDPSSubconProcess.id == null || this.selectedDPSSubconProcess.id == "") && !this.CPErrorDisplay)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "DPS Subcon Process is required";
                    this.showLoading = false;
                }
            }
            else
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "DPS Subcon Process is required";
                this.showLoading = false;
            }
            if(this.projectInformation.cPSubconID == this.selectedCPSubcon.id 
            && this.projectInformation.fTSubconID == this.selectedFTSubcon.id 
            && this.projectInformation.assySubcon1ID == this.selectedAssySubcon1.id 
            && this.projectInformation.assySubcon2ID == this.selectedAssySubcon2.id 
            && this.projectInformation.dPSSubconID == this.selectedDPSSubcon.id 
            && this.projectInformation.assySubconProcess1ID == this.selectedAssySubconProcess1.id 
            && this.projectInformation.assySubconProcess2ID == this.selectedAssySubconProcess2.id 
            && this.projectInformation.dPSSubconProcessID == this.selectedDPSSubconProcess.id 
            && !this.CPErrorDisplay)
            {
                this.CPErrorDisplay = true;
                this.ChildProjectError = "Atleast one value should be different from original for CP Subcon (Primary), FT Subcon (Primary), Assy Subcon 1 (Primary), Assy Subcon 2 (Primary), DPS Subcon (Primary), Assy Subcon Process 1, Assy Subcon Process 2, DPS Subcon Process";
                this.showLoading = false;
            }
        }

        //MFG Duplicate validation
        if(this.Action != "Foundry" && this.Action != "Subcon")
        {
            if(this.AllMFGParts != undefined && this.AllMFGParts != null && this.AllMFGParts.length > 0 && !this.CPErrorDisplay)
            { 
                var exists = false;
                this.AllMFGParts.forEach(element => {
                    if(element.toLowerCase() == this.mfgpartSilicon.toLowerCase() && !exists){
                        exists = true;
                    }
                });
                if(exists)
                {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = "Entered MFG Part # already exists in the system.";
                    this.showLoading = false;
                }
            }
        }
        
        if(!this.CPErrorDisplay)
        {

            this.AddModel = {
                ProjectID: this.projectId,
                ProjectDescription: this.selectedprojectdescription == null || this.selectedprojectdescription == undefined ? "" 
                                    : this.selectedprojectdescription.length == 0 ? "" : this.selectedprojectdescription.id,
                PackageType: this.selectedpackagetype == null || this.selectedpackagetype == undefined ? "" 
                                    : this.selectedpackagetype.length == 0 ? "" : this.selectedpackagetype.id,
                PackageName:this.packagename == null || this.packagename == undefined ? "" : this.packagename,
                DieCodeRoot:this.diecoderoot == null || this.diecoderoot == undefined ? "" : this.diecoderoot,
                DieCodeD:this.diecoded == null || this.diecoded == undefined ? "" : this.diecoded,
                DieCodeE:this.diecodee == null || this.diecodee == undefined ? "" : this.diecodee,
                PackingOption: this.selectedpackingoption == null || this.selectedpackingoption == undefined ? "" 
                            : this.selectedpackingoption.length == 0 ? "" : this.selectedpackingoption.id,
                TRQty:this.trqty == null || this.trqty == undefined ? "" : this.trqty,
                TubeQty:this.tubeqty == null || this.tubeqty == undefined ? "" : this.tubeqty,
                TrayQty:this.trayqty == null || this.trayqty == undefined ? "" : this.trayqty,
                MFGPart:this.mfgpartSilicon == null || this.mfgpartSilicon == undefined ? "" : this.mfgpartSilicon ,
                FGPart:this.fgpart == null || this.fgpart == undefined ? "" : this.fgpart ,
                TemperatureGrade: this.selectedtempgrade == null || this.selectedtempgrade == undefined ? "" : this.selectedtempgrade.length == 0 ? "" : this.selectedtempgrade.id,
                Foundry: this.selectedfoundry == null || this.selectedfoundry == undefined ? "" : this.selectedfoundry.length == 0 ? "" : this.selectedfoundry.id, 
                FoundryProcess: this.selectedfoundryprocess == null || this.selectedfoundryprocess == undefined ? "" : this.selectedfoundryprocess.length == 0 ? "" : this.selectedfoundryprocess.id, 
                CPSubcon: this.selectedCPSubcon == null || this.selectedCPSubcon == undefined ? "" : this.selectedCPSubcon.length == 0 ? "" : this.selectedCPSubcon.id,
                FTSubcon: this.selectedFTSubcon == null || this.selectedFTSubcon == undefined ? "" : this.selectedFTSubcon.length == 0 ? "" : this.selectedFTSubcon.id,
                AssySubcon1: this.selectedAssySubcon1 == null || this.selectedAssySubcon1 == undefined ? "" : this.selectedAssySubcon1.length == 0 ? "" : this.selectedAssySubcon1.id,
                AssySubcon2: this.selectedAssySubcon2 == null || this.selectedAssySubcon2 == undefined ? "" : this.selectedAssySubcon2.length == 0 ? "" : this.selectedAssySubcon2.id,
                DPSSubcon: this.selectedDPSSubcon == null || this.selectedDPSSubcon == undefined ? "" : this.selectedDPSSubcon.length == 0 ? "" : this.selectedDPSSubcon.id,
                AssyProcess1: this.selectedAssySubconProcess1 == null || this.selectedAssySubconProcess1 == undefined ? "" : this.selectedAssySubconProcess1.length == 0 ? "" : this.selectedAssySubconProcess1.id,
                AssyProcess2: this.selectedAssySubconProcess2 == null || this.selectedAssySubconProcess2 == undefined ? "" : this.selectedAssySubconProcess2.length == 0 ? "" : this.selectedAssySubconProcess2.id,
                DPSProcess: this.selectedDPSSubconProcess == null || this.selectedDPSSubconProcess == undefined ? "" : this.selectedDPSSubconProcess.length == 0 ? "" : this.selectedDPSSubconProcess.id,
                ProductDescription:this.description == null || this.description == undefined ? "" : this.description,

                ProjectType: this.Action,
                StartDate: new Date(this.pipe.transform(this.MaxStartDate,"MM/dd/yyyy hh:mm")),
                CreatedBy: this.facadeService.getloggedInUser().userguid
              };

            this.facadeService.CreateSiliconLayer(this.AddModel).subscribe(
            (response) => {
                this.showLoading = false;
                if (response.returnCode == 0) {
                    this.router.navigate(['/projectlist']);
                }
                else {
                    this.CPErrorDisplay = true;
                    this.ChildProjectError = response.returnMessage
                }
            });
        }
    }

    PMToken(){
        var clientId = sessionStorage.getItem('clientId')!=''&& sessionStorage.getItem('clientId')!=null&&sessionStorage.getItem('clientId')!=undefined?parseInt(sessionStorage.getItem('clientId')):0;
        var applications = JSON.parse(sessionStorage.getItem('applications'));
        var appli = applications.find(m=>m.projectIdentifier=="ProductUI");
        var IsDCC = sessionStorage.getItem('IsDCC')!=''&& sessionStorage.getItem('IsDCC')!=null&&sessionStorage.getItem('IsDCC')!=undefined?sessionStorage.getItem("IsDCC").toLowerCase() == "true"?true:false:false;
        this.showLoading = true;
              this.facadeService.CreateSecurityToken().subscribe(
                (data) => {
                  this.showLoading = false;
                  if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
                    var access_token = data.returnObject;
                    window.open(this.baseUrl["API_ENDPOINTS"]["ProductUI"] 
                    + "entrypoint?ProductID="+this.projectInformation.pdmProductID+
                    "&userguid=" +this.userGuid +
                     "&userId=" + parseInt(sessionStorage.getItem('userId'))
                     +'&destination=phasedetails'+'&access_token='+
                     access_token+'&IsDCC='+IsDCC+
                     '&bt='+sessionStorage.getItem('bt')+"&appliId="+appli.applicationId+"&clientId="+clientId);
                  }
                  else{
                    location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
                  }
                },
                (error) => {
                  this.showLoading = false;
                  console.log(error);
                } 
              );
      }
}