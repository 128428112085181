import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Product } from '../domain/product';
import { CountryService } from '../service/countryservice';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { FacadeService } from 'src/app/facade/facade.service';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { OptionItems, OptionsData, CustomReportsList } from '../domain/CustomReports';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from "src/injector";
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-createreport',
    templateUrl: './createreport.component.html',
    styles: [`:host ::ng-deep .p-multiselect {
		min-width: 15rem;
	}

	:host ::ng-deep .multiselect-custom-virtual-scroll .p-multiselect {
		min-width: 20rem;
	}

	:host ::ng-deep .multiselect-custom .p-multiselect-label {
		padding-top: .25rem;
		padding-bottom: .25rem;

	}


	:host ::ng-deep .multiselect-custom .country-item.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}

	:host ::ng-deep .multiselect-custom .country-item.country-item-value img.flag {
		width: 17px;
	}

	:host ::ng-deep .multiselect-custom .country-item {
		display: flex;
		align-items: center;
	}

	:host ::ng-deep .multiselect-custom .country-item img.flag {
		width: 18px;
		margin-right: .5rem;
	}

	:host ::ng-deep .multiselect-custom .country-placeholder {
		padding: 0.25rem;
	}

	:host ::ng-deep .p-colorpicker {
		width: 2.5em
	}
    `],
    providers: [MessageService]

})
export class CreatereportComponent implements OnInit {
    products: Product[];
    display: boolean;
    reportPopupDisplay: boolean = false;
    editDisplay: boolean;
    selectedMulti: any;
    countries: any[];
    projecName: any;
    projectManagerList: [];
    projectId: string;
    submitted: boolean = false;
    responseError: string;
    Customreport: FormGroup;
    showLoading: boolean = false;

    optionsTaskNames: OptionItems[];
    optionsCustomAttributes: OptionItems[];
    optionsTaskAttributes: OptionItems[];
    CustomReportsLists: CustomReportsList[];
    //Edit popupdata
    editReportName: string;
    editoptionsCustomAttributes: OptionItems[];
    editoptionsTaskAttributes: OptionItems[];
    editoptionsTaskNames: OptionItems[];
    editTaskName: string;
    editCustomReportId: string;
    rptActionType: string;
    // isAdminUser:boolean = false;
    isProductEngineer:boolean=false;
    projectget:boolean = false;
    constructor(
        private countryService: CountryService,
        private breadcrumbService: BreadcrumbService,
        private facadeService: FacadeService, private formBuilder: FormBuilder,
        private messageService: MessageService,
        @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard', routerLink: ['/'] }
        ]);
    }

    ngOnInit() {
        const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PM_GP");
        if(featureExist!=null && featureExist != undefined)
          this.projectget = true;
      }
    }
        this.countryService.getCountries().then(countries => {
            this.countries = countries;
        });

        this.GetReports();
        this.getProjects();
        // this.isAdmin();

        this.Customreport = this.formBuilder.group({

            FCReportName: new FormControl('', Validators.required),
            FCCustomAttributes: new FormControl(''),
            FCTaskName: new FormControl('', Validators.required),
            FCTaskAttributes: new FormControl('', Validators.required)
        });
    }

    GetReports() {
        this.showLoading = true;
        this.facadeService.GetReports().subscribe(data => {
            this.showLoading = false;
            data.returnObject.forEach(e => {
                if(e.createdDate != '' || e.createdDate != null){
                    let createdDate = new Date(e.createdDate) 
                    e.createdDate = createdDate.toLocaleDateString()
                }
            });
            this.CustomReportsLists = data.returnObject;
        });
    }

    onReportPopupDisplay(action) {
        this.resetForm();
        this.reportPopupDisplay = true;
        this.showLoading = true;
        this.responseError = "";
        this.facadeService.GetCustomReportPopupData().subscribe((responce) => {
            this.showLoading = false;
            this.rptActionType = action == "create" ? "Create" : "Edit";
            if (responce.returnObject != undefined) {
                if (responce.returnObject.customAttributes != undefined) {
                    this.optionsCustomAttributes = responce.returnObject.customAttributes;
                }
                if (responce.returnObject.taskNames != undefined) {
                    this.optionsTaskNames = responce.returnObject.taskNames;
                    // if (this.editTaskName != null && this.editTaskName != "") {
                    //     this.editoptionsTaskNames = responce.returnObject.taskNames.filter(ele => {
                    //         return ele.id === this.editTaskName;
                    //     })[0];
                    // }
                }
                if (responce.returnObject.taskAttributes != undefined) {
                    this.optionsTaskAttributes = responce.returnObject.taskAttributes;
                }
            }
        });
    }

    resetForm() {
        this.Customreport.reset();
        this.submitted = false;
    }

    getProjects() {
        this.showLoading = true;
        var userguid = this.facadeService.getloggedInUser().userguid;
        this.facadeService.getProjects(this.projectget,userguid,"").subscribe(
            (response) => {
                this.showLoading = false;
                if(response.returnObject!=null && response.returnObject.length>0){
                    var isPEExist= response.returnObject.find(m=>m.projectManagerId==userguid);
                    if(isPEExist!=null){
                        this.isProductEngineer=true;
                    }
                }
            }
        )
    }

    onCreateReport(reportFormdata: any) {
        if(!this.Customreport.valid){
            this.submitted = true;
            return false;            
        }
        this.submitted = false;
        this.responseError = "";
        let popupOptions = new OptionsData();
        popupOptions.ReportName = reportFormdata.FCReportName;
        //popupOptions.TaskName = reportFormdata.FCTaskName.id;
        popupOptions.CreatedBy = this.facadeService.getloggedInUser().userguid;

        let _optionTaskNames: OptionItems[] = [];
        if (reportFormdata.FCTaskName != undefined && reportFormdata.FCTaskName != null && reportFormdata.FCTaskName.length > 0) {
            for (let i = 0; i <= (reportFormdata.FCTaskName.length - 1); i++) { 
                let _option = new OptionItems();
                _option.Id = reportFormdata.FCTaskName[i].id;
                _option.Name = reportFormdata.FCTaskName[i].name;
                _optionTaskNames.push(_option);
            }

            popupOptions.TaskNames = _optionTaskNames;
        }

        let _optionCustomAttributesItems: OptionItems[] = [];
        if (reportFormdata.FCCustomAttributes != undefined && reportFormdata.FCCustomAttributes != null && reportFormdata.FCCustomAttributes.length > 0) {
            for (let i = 0; i <= (reportFormdata.FCCustomAttributes.length - 1); i++) {
                let _option = new OptionItems();
                _option.Id = reportFormdata.FCCustomAttributes[i].id;
                _option.Name = reportFormdata.FCCustomAttributes[i].name;
                _optionCustomAttributesItems.push(_option);
            }

            popupOptions.CustomAttributes = _optionCustomAttributesItems;
        }


        let _optionTaskAttributesItems: OptionItems[] = [];
        if (reportFormdata.FCTaskAttributes != undefined && reportFormdata.FCTaskAttributes != null && reportFormdata.FCTaskAttributes.length > 0) {
            for (let i = 0; i <= (reportFormdata.FCTaskAttributes.length - 1); i++) {
                let _option = new OptionItems();
                _option.Id = reportFormdata.FCTaskAttributes[i].id;
                _option.Name = reportFormdata.FCTaskAttributes[i].name;
                _optionTaskAttributesItems.push(_option);
            }

            popupOptions.TaskAttributes = _optionTaskAttributesItems;
        }


        if (this.editCustomReportId != undefined && this.editCustomReportId != null && this.editCustomReportId != "") {
            popupOptions.ReportId = this.editCustomReportId;
        }
        else {
            popupOptions.ReportId = "";
        }
        this.showLoading = true;
        this.facadeService.CreateReportSchema(popupOptions).subscribe((response) => {
            this.showLoading = false;
            if (response.returnObject == true) {
                this.reportPopupDisplay = false;
                this.GetReports();
                //this.showToast('success', 'Success', "Report Saved Successfully");
                //this.router.navigate(['/createreport']);
            }
            else {
                //this.showToast("error", "Error", "Error");
                this.responseError = response.returnMessage ? response.returnMessage : "Error Saving Report";
            }
        });
    }

    onReportSchemaEdit(reportId: string) {
        this.showLoading = true;
        this.facadeService.GetReportSchemaById(reportId).subscribe(data => {
            this.showLoading = false;
            if (data.returnObject != undefined) {
                this.editCustomReportId = data.returnObject.reportId;
                this.editTaskName = data.returnObject.taskName;
                this.onReportPopupDisplay('Edit');
                this.editReportName = data.returnObject.reportName;
                this.editoptionsTaskNames = data.returnObject.taskNames;
                this.editoptionsCustomAttributes = data.returnObject.customAttributes;
                this.editoptionsTaskAttributes = data.returnObject.taskAttributes;
            }
        });
    }

    showToast(severity, status, message) {
        this.messageService.add({ key: 'popup', severity: severity, summary: status, detail: message });
    }

    exportExcel() {
        let exportList = [];
        this.CustomReportsLists.forEach(x => {
            exportList.push({
                reportId:x.reportId,
                reportName:x.reportName,
                createdBy:x.createdBy,
                createdDate:x.createdDate,
                updateddBy:x.updateddBy,
                updatedDate:x.updatedDate
            });
        });
console.log(exportList)
          /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "Reports.xlsx");
    }
    delete_row (ws, row_index){
        let range = XLSX.utils.decode_range(ws["!ref"])
        for(var R = row_index; R < range.e.r; ++R){
            for(var C = range.s.c; C <= range.e.c; ++C){
              ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
              if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
                ws[this.ec(R+1, C)].v  = "";
            }
        }
        range.e.r--
        delete (ws['D1'])
        ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
      }
      ec(r, c) {
        return XLSX.utils.encode_cell({r:r,c:c})
      }
    print() {
        window.print();
    }
    onClose() {
        this.reportPopupDisplay = false;
    }

    // isAdmin(){
    //     let roles = JSON.parse(sessionStorage.getItem("userRoles"));

    //     let adminRole = roles.filter(ele => {
    //         return ele.name == "Administrator";
    //     })[0];
    //     this.isAdminUser = adminRole != undefined;
    // }
}
