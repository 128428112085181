
  <p-toast position="top-right" key="popup"></p-toast>
  <div class="p-grid table-demo">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
          <h5 style="color:#495057; padding:15px 0 0 15px;">Monitoring Reviews
          </h5>
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
           
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
              (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
              style="float:right; width:25px; height:25px;"></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
              (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
              style="float:right;height:25px; width:25px; "></button>
          </span>
        </div>
        <div class="invoice invoice-header" id="invoice-content">
          <p-table #dt [value]="CustomReportsLists" id="excel-table" dataKey="id" styleClass="p-datatable-striped" [rowHover]="true" [rows]="4"
            [paginator]="false" [filterDelay]="0">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="reportName">
                  Monitoring Review
                  <p-sortIcon field="projectName"></p-sortIcon>
                </th>
                <th pSortableColumn="created By">
                  Created By
                  <p-sortIcon field="craetedby"></p-sortIcon>
                </th>
                <th pSortableColumn="createdat">
                  Created At
                  <p-sortIcon field="createdat"></p-sortIcon>
                </th>
               
                <th style="width:100px;">
                  Actions
                </th>
              </tr>
              <tr>
                <th>
                  <input pInputText type="text" (input)="
                  dt.filter($event.target.value, 'projectName', 'contains')
                " placeholder="Monitoring Review" class="p-column-filter" style="width: 95%; height:25px; padding-left:15px;" />
                </th>
                <th>
                  <input pInputText type="text" (input)="dt.filter($event.target.value, 'stageName', 'contains')"
                    placeholder="Created By" class="p-column-filter" style="width: 95%; height:25px; padding-left:15px;" />
                </th>
                <th>
                  <input pInputText type="text" (input)="
                  dt.filter($event.target.value, 'milestoneName', 'contains')
                " placeholder="Created At" class="p-column-filter" style="width: 95%; height:25px; padding-left:15px;" />
                </th>
               
                <th></th>


              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-CustomReportsList>
              <tr class="p-selectable-row">
                <td style="padding-left:15px;">
                  <!-- <span class="p-column-title">Project Name</span> -->
                  <span class="data-style">{{ CustomReportsList.reportName }}</span>
                </td>
                <td style="padding-left:15px;">
                  <!-- <span class="p-column-title">Manager11</span> -->
                  <span class="data-style">{{ CustomReportsList.createdBy }}</span>
                </td>
                <td style="text-align:center;">
                  <!-- <span class="p-column-title">Start Date</span> -->
                  <span >{{ CustomReportsList.createdDate }}</span>
                </td>
             


                <td style="text-align:center;">
                  <!-- <span class="p-column-title">Actions</span> -->
                  <span>
                      <button routerLink="/viewreport/{{CustomReportsList.reportId}}/R" pButton pRipple type="button" icon="pi pi-eye" pTooltip="View" tooltipPosition="left"
                        class="p-button-raised p-button-primary" [disabled]="userFeature" style="height:25px; width:25px;">
                      </button>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="4">No Reports found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>



<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>