<div class="p-grid p-fluid">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="p-fluid p-formgrid p-grid"> 
          <div class="p-field p-col-12 p-xl-2">
            <label>Project Engineer:</label>
            <p-multiSelect [options]="owners" defaultLabel="Select Project Engineer" [(ngModel)]="selectedowners" optionLabel="name" [virtualScroll]="true" 
            [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
          </div>       
          <div class="p-field p-col-12 p-xl-2">        
            <label style="padding-right:10px; margin-left:10px;">Milestone:<span style="color: red"></span></label>
            <p-multiSelect [options]="milestones" defaultLabel="Select Milestone" [(ngModel)]="selectedmilestones" optionLabel="taskName" [virtualScroll]="true" 
            [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
          </div>
          <div class="p-field p-col-12 p-xl-2">
            <label style="padding-right:10px; margin-left:10px;">Product Line:</label>
            <p-multiSelect [options]="productlines" defaultLabel="Select Product Line" [(ngModel)]="selectedproductlines" optionLabel="name" [virtualScroll]="true" 
            (onChange) = "GetProjects()" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
          </div>
          <div class="p-field p-col-12 p-xl-2">
            <label style="padding-right:10px; margin-left:10px;">Projects:</label>
            <p-multiSelect [options]="projects" defaultLabel="Select Project" [(ngModel)]="selectedprojects" optionLabel="projectName" [virtualScroll]="true" 
            [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
          </div>
          <div class="p-field p-col-12 p-xl-2">
              <a (click)="getprojects()">
                <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search" 
                class="p-button-raised p-button-primary" style="width:100%;float:left;margin-top: 25px;"></button>
            </a>
          </div>  
          <div class="p-field p-col-12 p-xl-2">
            <label style="color: red;width:auto;">{{error}}</label>       
          </div>          
        </div>
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
          <h5 style="color:#495057; padding:15px 0 0 15px;width: 100%;">Projects by Milestone - {{Count}}</h5> 
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
    
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
              (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
              style="float:right; width:25px; height:25px;"></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
              (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
              style="float:right;height:25px; width:25px; "></button>
          </span>
      </div>
      <div class="invoice invoice-header" id="invoice-content">           
   <p-table (sortFunction)="customSort($event)" [customSort]="true"  #dt [value]="overduetasks" [columns]="cols"  id="excel-table" [loading]="loading" [autoLayout]="true"
  styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedoverduetasks"
  [scrollable]="true" scrollHeight="590px"
  dataKey="projectId"> 
  <ng-template pTemplate="header" let-columns class="invoice-items">
  <tr>              
      <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" style="white-space:wrap"
      [ngClass]="{'width100': i == 1 ||  i == 3,'width150': i == 7 || i == 8, 'width170' : i == 4,'w':i==0 || i==2 || i==5 || i==6}" >
      {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
    <th style="text-align: center;width: 80px;">Actions</th>
  </tr>
  <tr id="trSearch">
    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
      <input pInputText type="text" (input)="test($event.target.value,col.field,dt)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:20px"/>
  </th>
    <th style="text-align: center"></th>
  </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
  <tr [pSelectableRow]="rowData">
    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
      <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 20%;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 1" style="text-align: left; padding-left:15px;width: 110px;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 20%;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width: 110px;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width: 170px;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width: 20%;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 6" style="text-align: left; padding-left:15px;width: 20%;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 7" style="text-align: center;width: 150px;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 8" style="text-align: center;color: red; font-weight: bold;width: 150px;">{{ rowData[col.field] }}</td>
    </ng-container>
    <td style="text-align: center;width: 80px;">
      <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="phasetree(rowData.projectId)"
      icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
    </td>
  </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
  <tr>
      <td colspan="9" style="text-align: left;">No records found...</td>
  </tr>
  </ng-template>
  
   </p-table>
      </div>
      </div>
    </div>
  </div>



<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>