import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Product } from '../../domain/product';
import { BreadcrumbService } from '../../../app.breadcrumb.service';
import { FacadeService } from 'src/app/facade/facade.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { OptionItems, CustomReportsList } from '../../domain/CustomReports';
import { ProjectDesc } from '../../domain/projectdescription';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from "src/injector";
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-manageprojectdescription',
  templateUrl: './manageprojectdescription.component.html',
  styleUrls: ['./manageprojectdescription.component.scss'],
  providers: [MessageService]
})
export class ManageprojectdescriptionComponent implements OnInit {
  products: Product[];
  display: boolean;
  reportPopupDisplay: boolean = false;
  editDisplay: boolean;
  selectedMulti: any;
  countries: any[];
  projecName: any;
  projectManagerList: [];
  projectId: string;
  submitted: boolean = false;
  createProjectDescform: FormGroup;
  responseError: string;
  projDescdisplay: boolean;
  Customreport: FormGroup;
  showLoading: boolean = false;
  projDescSubmitted: boolean;
  optionsTaskNames: OptionItems[];
  optionsCustomAttributes: OptionItems[];
  optionsTaskAttributes: OptionItems[];
  CustomReportsLists: CustomReportsList[];
  ProjectDescList: ProjectDesc[];
  //Edit popupdata
  editReportName: string;
  editoptionsCustomAttributes: OptionItems[];
  editoptionsTaskAttributes: OptionItems[];
  editoptionsTaskNames: OptionItems[];
  editTaskName: string;
  editCustomReportId: string;
  rptActionType: string;
  projDescName: string = "";
  editProjDescId: string = "";
  projDescDeleteConfirm: boolean = false;
  deleteProjDescName: string = '';
  deleteProjDescId: string = '';
  constructor(
    private breadcrumbService: BreadcrumbService,
    private facadeService: FacadeService, private formBuilder: FormBuilder,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
    this.breadcrumbService.setItems([
      { label: 'Dashboard', routerLink: ['/'] }
    ]);
  }

  ngOnInit(): void {
    this.GetAllProjectDesc();

    this.createProjectDescform = this.formBuilder.group({
      newProjectDesc: new FormControl('', Validators.required)
    });
  }

  onProjectDescSubmit() {
    this.projDescSubmitted = true;
    this.createProjectDescform.controls.newProjectDesc.setValue(this.projDescName);
    if (this.createProjectDescform.valid) {
      const projectDescData = this.createProjectDescform.controls.newProjectDesc.value;
      this.saveProjectDesc(projectDescData);
      this.projDescSubmitted = false;
    }
  }

  saveProjectDesc(projDesc) {
    this.responseError = "";
    this.projDescSubmitted = true;
    let projDescDTO = new ProjectDesc();
    projDescDTO.projectDescriptionName = projDesc;
    projDescDTO.createdBy = this.facadeService.getloggedInUser().userguid;
    if (this.editProjDescId != null && this.editProjDescId != "") {
      projDescDTO.projectDescriptionId = this.editProjDescId;
    }
    this.showLoading = true;
    this.facadeService.saveProjectDesc(projDescDTO).subscribe(
      (response) => {
        this.showLoading = false;
        if (response.returnCode == 0) {
          if(response.returnObject.isError == true){
            this.responseError = response.returnObject.errorMessage;
          }
          else{
            this.GetAllProjectDesc();
            this.createProjectDescform.reset();
            this.projDescdisplay = false;
            this.projDescName = '';
          }
        }
        else {
          this.responseError = response.returnMessage;
        }
      }
    );
    this.projDescSubmitted = false;
  }

  onProjectDescEdit(projectDescId: string, projDescName: string) {
    this.responseError = "";
    this.projDescName = projDescName;
    this.projDescdisplay = true;
    this.editProjDescId = projectDescId;
  }

  onProjectDescDelete(projectDescId: string, projDescName: string) {
    this.responseError = "";
    this.deleteProjDescName = projDescName;
    this.projDescDeleteConfirm = true;
    this.deleteProjDescId = projectDescId;
  }

  onProjectDescDeleteConfirmed() {
    this.responseError = "";
    this.showLoading = true;
    let projectDescDTO = new ProjectDesc();
    projectDescDTO.projectDescriptionName = this.deleteProjDescName;
    projectDescDTO.projectDescriptionId = this.deleteProjDescId;
    projectDescDTO.createdBy = this.facadeService.getloggedInUser().userguid;
    this.showLoading = true;
    this.facadeService.deleteProjectDesc(projectDescDTO).subscribe(
      (response) => {
        this.projDescDeleteConfirm = false;
        if (response.returnCode == 0) {
          this.GetAllProjectDesc();
        }
        else {
          this.responseError = response.returnMessage;
        }
      }
    );
  }

  GetAllProjectDesc() {
    this.showLoading = true;
    this.facadeService.getProjectDescription()
      .subscribe((res) => {
        this.showLoading = false;
        if (res.returnObject != undefined && res.returnObject != null) {
          this.ProjectDescList = res.returnObject;
        }
      });
  }

  exportExcel() {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "Project Description List.xlsx");
  }

  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['B1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }

  print() {
    window.print();
  }

  onClose() {
    this.reportPopupDisplay = false;
  }

}
