import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { FacadeService } from 'src/app/facade/facade.service';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { CreateTaskFormViewModel, TaskStatusEnum } from '../domain/Tasks';
import { ActivatedRoute, Router } from '@angular/router';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from "src/injector";
import { DatePipe } from '@angular/common'
import { FileUpload } from 'primeng/fileupload';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-createtask',
    templateUrl: './createtask.component.html',
    styleUrls: ['./createtask.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class CreatetaskComponent implements OnInit {
    showLoading = false;
    notesinfo: boolean = false;
    userGuid = sessionStorage.getItem('userGuid');
    UserIntID = sessionStorage.getItem('userId');
    IsLoggedIn: boolean = true;
    editTaskData: any = [];
    formType: string = 'View';
    editProgressCompleted?: string;
    taskStatus: any = [];
    editSelectedTaskStatus?: any;
    PredecessortaskStatus: boolean;
    editTaskStatus?: string;
    DisableTaskStatus: boolean = false;
    editDuration?: number;
    editPlannedStartDateCanEdit?: boolean;
    plannedStartMinDate = new Date();
    // changeManagementMessage: string = 'Use Change Management to edit this value';
    editPlannedStartDate?: Date;
    isDateValidationRequired: boolean = true;
    submitted: boolean;
    editPlannedEndDateCanEdit: boolean;
    editPlannedEndDate?: Date;
    editRevisedPlannedStartDateCanEdit: boolean;
    editRevisedPlannedStartDate?: Date;
    editRevisedPlannedEndDateCanEdit: boolean;
    editRevisedPlannedEndDate?: Date;
    isIncludeweekends: boolean = true;
    editActualStartDate?: Date;
    editActualStartDateCanEdit: boolean;
    isActualStartDataRequired: boolean = false;
    isActualEndDataRequired: boolean = false;
    editActualEndDate?: Date;
    editActualEndDateCanEdit: boolean;
    file: any;
    editBurntHoursCumulative?: number;
    editBurntHoursToday?: number;
    editEstimatedEffort?: any = 0;
    projectUsers: any[];
    editAssignTo?: any;
    AssignCount: number = 1;
    @ViewChild('fileInput') fileInput: FileUpload;
    allnotes: any[];
    editNotes?: string = '';
    responseError: string;
    isresetShown: boolean
    effectedTasksdisplay: boolean = false;
    effectedTasksData: any = [];
    expandedRows: {} = {};
    historydisplay: boolean;
    editTaskName?: string;
    taskHistory: any;
    taskId: string = "";
    createTaskform: FormGroup;
    createTask: CreateTaskFormViewModel;
    editProjectName?: string;
    editTid?: number;
    TaskSeq: number = 0;
    OverlapDays: number = 0;
    ProportionateTime: number = 0;
    AllowMultipleAssignees: string = "N";
    departmentId: any;
    editTaskId?: string = '';
    editSelectedPredecessorOf?: any[] = [];
    nonWorkingDaysCount: number = 0;
    headCountAssigneCount: number = 0;
    headCountDuration: number = 0;
    isDurationModified: boolean = false;
    changedDuration: number = 0;
    FCBurntHoursTodayD: boolean = false;
    FCProgressD: boolean = false;
    FCTaskStatusD: boolean = false;
    FCNotesD: boolean = false;
    FCActualStartDateD: boolean = false;
    FCActualEndDateD: boolean = false;
    FCPlannedStartDateD: boolean = false;
    FCPlannedEndDateD: boolean = false;
    FCRevisedPlannedStartDateD: boolean = false;
    FCRevisedPlannedEndDateD: boolean = false;
    targetOverride: boolean = false;
    currentOverride: boolean = false;
    actualStartOverride: boolean = false;
    actualEndOverride: boolean = false;
    userFeature: boolean = false;
    constructor(
        private breadcrumbService: BreadcrumbService, private facadeService: FacadeService,
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private printService: PrintcommonService,
        private datePipe: DatePipe,
        @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard', routerLink: ['/'] }
        ]);
    }

    ngOnInit() {
        const userFeatures = sessionStorage.getItem('userFeatures');
        if (userFeatures) {
            var feature = JSON.parse(userFeatures);
            if (feature.length > 0) {
                var featureExist = feature.find(m => m.code == "PM_PD");
                if (featureExist != null && featureExist != undefined)
                    this.userFeature = true;
            }
        }
        this.formType = 'Create';
        this.route.paramMap.subscribe(params => {
            if (params.get('taskId') != null) {
                this.formType = 'Edit';
                this.taskId = params.get('taskId');
                this.GetTaskById(this.taskId);
            }
        });

        this.createTask = new CreateTaskFormViewModel();
        this.createTaskform = this.formBuilder.group({
            FCPlannedStartDate: new FormControl(''),
            FCPlannedEndDate: new FormControl(''),
            FCRevisedPlannedStartDate: new FormControl(''),
            FCRevisedPlannedEndDate: new FormControl(''),
            FCActualStartDate: new FormControl(''),
            FCActualEndDate: new FormControl(''),
            FCBurntHoursToday: new FormControl(''),
            FCAssign: new FormControl(''),
            FCProgress: new FormControl(''),
            FCTaskStatus: new FormControl('', Validators.required),
            FCNotes: new FormControl('')
        });
        if (this.formType == "Create") {
            this.FCTaskStatusD = true;
            this.FCActualStartDateD = true;
            this.FCActualEndDateD = true;
            this.FCRevisedPlannedStartDateD = true;
            this.FCRevisedPlannedEndDateD = true;
            this.FCBurntHoursTodayD = true;
            this.FCProgressD = true;
        }
    }
    GetTaskById(taskId) {
        this.showLoading = true;
        this.facadeService.GetTaskById(taskId, this.UserIntID).subscribe(data => {
            this.showLoading = false;
            this.editTaskData = data.returnObject;
            this.allnotes = data.returnObject.allNotes;
            if (this.allnotes != null && this.allnotes != undefined) {
                if (this.allnotes.length > 0) {
                    this.allnotes.forEach(e => {
                        if (e.addedDate != '' || e.addedDate != null) {
                            let addedDate = new Date(e.addedDate + 'Z');
                            e.addedDate = this.datePipe.transform(addedDate, 'MM/dd/yyyy');
                        }
                    });
                }
            }
            this.plannedStartMinDate = (data.returnObject.projectStartDate == null || data.returnObject.projectStartDate == "" || data.returnObject.projectStartDate == undefined) ? new Date() : new Date(data.returnObject.projectStartDate);
            this.editTid = data.returnObject.tid;
            this.editProjectName = data.returnObject.projectId;
            this.editTaskName = data.returnObject.taskName;
            this.TaskSeq = data.returnObject.taskSeq;
            this.OverlapDays = data.returnObject.overlapDays;
            this.departmentId = data.returnObject.departmentId;
            this.ProportionateTime = data.returnObject.proportionateTime;
            this.DisableTaskStatus = data.returnObject.disableTaskStatus;
            this.editPlannedStartDate = (data.returnObject.planedStartDate == null || data.returnObject.planedStartDate == "") ? null : new Date(data.returnObject.planedStartDate);
            this.editPlannedEndDate = (data.returnObject.plannedEndDate == null || data.returnObject.plannedEndDate == "") ? null : new Date(data.returnObject.plannedEndDate);
            this.editRevisedPlannedStartDate = (data.returnObject.revisedPlannedStartDate == null || data.returnObject.revisedPlannedStartDate == "") ? null : new Date(data.returnObject.revisedPlannedStartDate);
            this.editRevisedPlannedEndDate = (data.returnObject.revisedPlannedEndDate == null || data.returnObject.revisedPlannedEndDate == "") ? null : new Date(data.returnObject.revisedPlannedEndDate);
            this.editActualStartDate = (data.returnObject.actualStartDate == null || data.returnObject.actualStartDate == "") ? null : new Date(data.returnObject.actualStartDate);
            this.editActualEndDate = (data.returnObject.actualEndDate == null || data.returnObject.actualEndDate == "") ? null : new Date(data.returnObject.actualEndDate);
            this.AllowMultipleAssignees = data.returnObject.allowMultipleAssignees;
            this.editDuration = parseInt(data.returnObject.duration.toString());
            this.editBurntHoursCumulative = data.returnObject.burntHoursToday;
            this.editBurntHoursToday = 0;
            this.editEstimatedEffort = data.returnObject.estimatedEfforts;
            this.projectUsers = data.returnObject.allAssigneeTo;
            this.editAssignTo = data.returnObject.assigneeTo;
            this.editSelectedPredecessorOf = data.returnObject.predecessor;
            this.headCountDuration = data.returnObject.duration;
            this.headCountAssigneCount = data.returnObject.assigneeTo != null ? data.returnObject.assigneeTo.length : 0;
            this.editProgressCompleted = data.returnObject.progressCompleted;
            this.editNotes = data.returnObject.notes;
            this.editTaskId = data.returnObject.workTaskId
            this.file = data.returnObject.taskFiles;
            this.isIncludeweekends = (data.returnObject.isIncludeweekends == undefined || data.returnObject.isIncludeweekends == null) ? false : data.returnObject.isIncludeweekends;
            this.isDateValidationRequired = (data.returnObject.isDateValidationRequired == undefined || data.returnObject.isDateValidationRequired == null) ? false : data.returnObject.isDateValidationRequired;
            this.AssignCount = 1;
            this.isDurationModified = true;
            this.editTaskStatus = data.returnObject.taskStatus;
            this.PredecessortaskStatus = data.returnObject.predecessortaskStatus;
            this.changedDuration = parseInt(data.returnObject.duration);
            this.targetOverride = data.returnObject.targetOverride;
            this.currentOverride = data.returnObject.currentOverride;
            this.actualStartOverride = data.returnObject.actualStartOverride;
            this.actualEndOverride = data.returnObject.actualEndOverride;
            if (data.returnObject.predecessor != undefined && data.returnObject.predecessor != null) {
                if (data.returnObject.predecessor.length > 0) {
                    var pred = "";
                    data.returnObject.predecessor.forEach(element => {
                        pred = pred + element.name + ", ";
                    });
                    pred = pred.slice(0, -2);
                    this.editTaskData.pred = pred;
                }
            }
            if (data.returnObject.assigneeTo != undefined && data.returnObject.assigneeTo != null) {
                if (data.returnObject.assigneeTo.length > 0) {
                    var assignee = "";
                    data.returnObject.assigneeTo.forEach(element => {
                        assignee = assignee + element.name + ", ";
                    });
                    assignee = assignee.slice(0, -2);
                    this.editTaskData.assignee = assignee;
                }
            }

            if (data.returnObject.allowMultipleAssignees != undefined && data.returnObject.allowMultipleAssignees != null) {
                if (data.returnObject.allowMultipleAssignees == "Y")
                    this.AssignCount = 10;
            }
            if (data.returnObject.projectActualStartDate == undefined || data.returnObject.projectActualStartDate == null) {
                this.DisabledControllers();
                this.responseError = "Project Actual start date is not entered, please enter it before starting the project.";
            }
            else {
                if (data.returnObject.projectActualStartDate > new Date()) {
                    this.DisabledControllers();
                    this.responseError = "Project Actual start date is less then todays date, you can start the project on actual start date.";
                }
            }
            this.FCActualStartDateD = false;
            this.FCActualEndDateD = false;
            if (data.returnObject.predecessorMinDate != undefined && data.returnObject.predecessorMinDate != null) {
                let predecessorMinDate: Date = new Date(data.returnObject.predecessorMinDate);
                const copy = new Date(Number(predecessorMinDate));
                copy.setDate(parseInt(predecessorMinDate.getDate().toString()) + 1);
                this.plannedStartMinDate = copy;
            }

            this.functionEnableNormalActualDate(data.returnObject.taskStatus);
            this.editPlannedStartDateCanEdit = data.returnObject.planedStartDateCanEdit;
            this.editPlannedEndDateCanEdit = data.returnObject.plannedEndDateCanEdit;
            this.editRevisedPlannedStartDateCanEdit = data.returnObject.revisedPlannedStartDateCanedit;
            this.editRevisedPlannedEndDateCanEdit = data.returnObject.revisedPlannedEndDateCanEdit;
            this.editActualStartDateCanEdit = data.returnObject.actualStartDateCanEdit;
            this.editActualEndDateCanEdit = data.returnObject.actualEndDateCanEdit;


            var assignes = null;
            if (this.editAssignTo != null && this.editAssignTo != "" && this.editAssignTo != undefined && this.editAssignTo.length > 0) {
                assignes = this.editAssignTo.find(m => m.id.toLowerCase() == this.userGuid.toLowerCase());
            }
            if (this.userGuid.toLowerCase() == data.returnObject.projectManager.toLowerCase() || (assignes != null && assignes != "" && assignes != undefined)) {
                //No disabling
            }
            else {
                if (this.userFeature) {
                    this.editPlannedStartDateCanEdit = false;
                    this.editPlannedEndDateCanEdit = false;
                    this.editRevisedPlannedStartDateCanEdit = false;
                    this.editRevisedPlannedEndDateCanEdit = false;
                    this.editActualStartDateCanEdit = false;
                    this.editActualEndDateCanEdit = false;
                }
                this.DisabledControllers();
            }
            this.FCPlannedStartDateD = true;
            this.FCPlannedEndDateD = true;
            this.FCRevisedPlannedStartDateD = true;
            this.FCRevisedPlannedEndDateD = true;
            if (this.DisableTaskStatus)
                this.DisabledControllers();
            this.GetTaskcreateProjectoptions();
        });
    }

    CMSRedirection() {
        var applications = JSON.parse(sessionStorage.getItem('applications'));
        var appli = applications.find(m => m.projectIdentifier == "CMSUI");
        this.showLoading = true;
        var IsDCC = sessionStorage.getItem('IsDCC') != '' && sessionStorage.getItem('IsDCC') != null && sessionStorage.getItem('IsDCC') != undefined ? sessionStorage.getItem("IsDCC").toLowerCase() == "true" ? true : false : false;
        var clientId = sessionStorage.getItem('clientId') != '' && sessionStorage.getItem('clientId') != null && sessionStorage.getItem('clientId') != undefined ? parseInt(sessionStorage.getItem('clientId')) : 0;
        this.facadeService.CreateSecurityToken().subscribe(
            (data) => {
                this.showLoading = false;
                if (data.returnObject != null && data.returnObject != "" && data.returnObject != undefined) {
                    var access_token = data.returnObject;
                    window.open(this.baseUrl["API_ENDPOINTS"]["CMSUI"] + "entrypoint?ProjectID=" + this.editProjectName + "&source=New&userguid=" + this.userGuid +
                        "&userId=" + parseInt(sessionStorage.getItem('userId'))
                        + '&destination=projectchangerequest' + '&IsDCC=' + IsDCC
                        + '&access_token=' + access_token + '&bt=' + sessionStorage.getItem('bt') + "&appliId=" + appli.applicationId + "&clientId=" + clientId);
                }
                else
                    location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
            },
            (error) => {
                this.showLoading = false;
                console.log(error);
            }
        );
    }

    GetTaskcreateProjectoptions() {
        this.showLoading = true;
        this.facadeService.GetTaskcreateProjectoptions().subscribe((data) => {
            this.showLoading = false;
            if (data.returnObject.taskStatus) {
                this.taskStatus = data.returnObject.taskStatus;
                if (this.editTaskStatus != undefined) {
                    this.editSelectedTaskStatus = data.returnObject.taskStatus.filter(ele => {
                        return ele.id == this.editTaskStatus;
                    })[0];
                }
                else {
                    this.editSelectedTaskStatus = { id: "1", name: "New" };
                }

                if (!this.PredecessortaskStatus) {
                    let taskSta = [];
                    data.returnObject.taskStatus.forEach(element => {
                        if (element.name != "Completed") {
                            taskSta.push(element);
                        }
                    });
                    if (taskSta.length >> 0)
                        this.taskStatus = taskSta;
                }
            }
        });
    }
    DisabledControllers() {
        this.FCBurntHoursTodayD = true;
        this.FCProgressD = true;
        this.FCTaskStatusD = true;
        this.FCNotesD = true;
        this.FCActualStartDateD = true;
        this.FCActualEndDateD = true;
        // this.createTaskform.controls['FCBurntHoursToday'].disable();
        // this.createTaskform.controls['FCProgress'].disable();
        // this.createTaskform.controls['FCTaskStatus'].disable();
        // this.createTaskform.controls['FCNotes'].disable();
        // this.createTaskform.controls['FCActualStartDate'].disable();
        // this.createTaskform.controls['FCActualEndDate'].disable();
        this.IsLoggedIn = false;
        this.formType = 'View';
    }
    StatusUpdate() {
        this.editSelectedTaskStatus = this.taskStatus.filter(ele => { return ele.name == "New"; })[0];
        if (this.editActualStartDate != null) {
            if (this.editActualEndDate != null)
                this.editSelectedTaskStatus = this.taskStatus.filter(ele => { return ele.name == "Completed"; })[0];
            else
                this.editSelectedTaskStatus = this.taskStatus.filter(ele => { return ele.name == "InProgress"; })[0];
        }

    }
    onSubmit(obj) {
        this.submitted = true;
        this.responseError = "";

        if (this.createTaskform.valid) {
            let start = false, end = false, assign = false;
            if (this.editActualStartDate != undefined || this.editActualStartDate != null) {
                if (new Date(this.editActualStartDate) > new Date()) {
                    start = true;
                    this.responseError = "Actual start date should be less then or equal to today's date";
                }
            }
            if (this.editActualEndDate != undefined || this.editActualEndDate != null) {
                if (new Date(this.editActualEndDate) > new Date() && !start) {
                    this.responseError = "Actual end date should be less then or equal to today's date";
                    end = true;
                }
            }
            if (this.editAssignTo != null && this.editAssignTo != undefined) {
                if (this.editAssignTo.length > this.AssignCount) {
                    this.responseError = "Max limit of assign selection is " + this.AssignCount.toString();
                    this.editAssignTo = this.projectUsers.filter(m => m.id.toLowerCase() == this.editAssignTo[0].id.toLowerCase());
                    assign = true;
                }
            }

            if (!start && !end && !assign) {
                const projectFormData = this.createTaskform.value;
                this.CreateTask(projectFormData);
                this.submitted = false;
            }
        }
    }

    CreateTask(projectFormData: any) {
        this.createTask.ProjectId = this.editProjectName;
        if (this.editTid != undefined && this.editTid > 0) {
            this.createTask.TaskId = this.taskId;
            this.createTask.Tid = this.editTid;
            this.createTask.TaskSeq = (this.TaskSeq === undefined || this.TaskSeq == null) ? 0 : this.TaskSeq;
            this.createTask.OverlapDays = (this.OverlapDays === undefined || this.OverlapDays == null) ? 0 : this.OverlapDays;
            this.createTask.ProportionateTime = (this.ProportionateTime === undefined || this.ProportionateTime == null) ? 100 : this.ProportionateTime;
            this.createTask.AllowMultipleAssignees = this.AllowMultipleAssignees;
        }
        else {
            this.createTask.TaskSeq = 0;
            this.createTask.OverlapDays = 0;
            this.createTask.ProportionateTime = 100;
        }
        this.createTask.DeptId = this.departmentId;
        this.createTask.ProjectName = this.editTaskData?.projectName;
        this.createTask.PhaseId = this.editTaskData?.phaseId;
        this.createTask.StageId = this.editTaskData?.phaseId;
        this.createTask.MilestoneId = this.editTaskData?.phaseId;
        this.createTask.StartDate = new Date(this.editTaskData?.projectStartDate).toDateString();
        this.createTask.DueDate = new Date(this.editTaskData?.projectEndDate).toDateString();
        this.createTask.ProjectManager = this.editTaskData?.projectManager;
        this.createTask.TaskName = this.editTaskName;
        this.createTask.TaskParent = "";
        let predecessorData: string[] = [];
        if (this.editSelectedPredecessorOf != null && this.editSelectedPredecessorOf != undefined && this.editSelectedPredecessorOf.length > 0) {
            for (let i = 0; i <= (this.editSelectedPredecessorOf.length - 1); i++) {
                if (this.editSelectedPredecessorOf[i] != undefined && this.editSelectedPredecessorOf[i] != null) {
                    let _uGuid = this.editSelectedPredecessorOf[i].id;
                    predecessorData.push(_uGuid);
                }
            }
        }
        this.createTask.Predecessor = predecessorData;
        if (this.editPlannedStartDate != undefined || this.editPlannedStartDate != null)
            this.createTask.PlanedStartDate = new Date(this.editPlannedStartDate).toDateString();
        this.createTask.Duration = parseInt(this.editTaskData?.duration.toString());
        if (this.editRevisedPlannedStartDate != undefined || this.editRevisedPlannedStartDate != null)
            this.createTask.RevisedPlannedStartDate = new Date(this.editRevisedPlannedStartDate).toDateString();
        if (this.editActualStartDate != undefined || this.editActualStartDate != null)
            this.createTask.ActualStartDate = new Date(this.editActualStartDate).toDateString();
        if (this.editPlannedEndDate != undefined || this.editPlannedEndDate != null)
            this.createTask.PlannedEndDate = new Date(this.editPlannedEndDate).toDateString();
        if (this.editRevisedPlannedEndDate != undefined || this.editRevisedPlannedEndDate != null)
            this.createTask.RevisedPlannedEndDate = new Date(this.editRevisedPlannedEndDate).toDateString();
        if (this.editActualEndDate != undefined || this.editActualEndDate != null)
            this.createTask.ActualEndDate = new Date(this.editActualEndDate).toDateString();
        this.createTask.CriticalPathSwitch = true;
        this.createTask.CompletedEfforts = this.editBurntHoursCumulative;// (projectFormData.FCBurntHoursCumulative == null || parseFloat(projectFormData.FCBurntHoursCumulative) == NaN) ? 0 : parseFloat(projectFormData.FCBurntHoursCumulative); 
        this.createTask.BurntHoursToday = projectFormData.FCBurntHoursToday == undefined ? 0 : parseFloat(projectFormData.FCBurntHoursToday);
        this.createTask.EstimatedEfforts = this.editEstimatedEffort;
        this.createTask.ProgressCompleted = this.editProgressCompleted ? this.editProgressCompleted : "";
        this.createTask.TaskStatus = this.editSelectedTaskStatus === undefined ? "" : this.editSelectedTaskStatus.id;
        this.createTask.AttachedDoc = projectFormData.FCAttachedDoc ? projectFormData.FCAttachedDoc : "";
        this.createTask.createdBy = this.facadeService.getloggedInUser().userguid;
        this.createTask.createdDate = new Date();
        let assigneData: string[] = [];
        if (this.editAssignTo != null && this.editAssignTo != undefined && this.editAssignTo.length > 0) {
            for (let i = 0; i <= (this.editAssignTo.length - 1); i++) {
                if (this.editAssignTo[i] != undefined && this.editAssignTo[i] != null) {
                    let _uGuid = this.editAssignTo[i].id;
                    assigneData.push(_uGuid);
                }
            }
        }
        this.createTask.Assignee = assigneData;
        this.createTask.Notes = this.editNotes;
        this.createTask.WorkTaskId = this.editTaskId;
        if (this.editBurntHoursCumulative != undefined && this.editBurntHoursCumulative != null)
            this.createTask.burntHoursCumulative = this.editBurntHoursCumulative;
        else
            this.createTask.burntHoursCumulative = 0;
        this.createTask.IsIncludeweekends = this.isIncludeweekends;
        this.createTask.IsDateValidationRequired = true;
        this.createTask.targetOverride = this.targetOverride;
        this.createTask.currentOverride = this.currentOverride;
        this.createTask.actualStartOverride = this.actualStartOverride;
        this.createTask.actualEndOverride = this.actualEndOverride;
        const formData = new FormData();
        Object.keys(this.createTask).forEach(i => {
            if (i == 'Assignee') {
                if (this.createTask[i].length > 0) {
                    this.createTask[i].forEach(element => {
                        formData.append(i, element);
                    });
                }

            }
            else {
                formData.append(i, this.createTask[i]);
            }
        });

        if (this.fileInput.files.length > 0) {
            this.fileInput.files.forEach(file => {
                formData.append('TaskFiles', file);
            });
        }
        formData.append("DepartmentId", sessionStorage.getItem("userDepartmentID"));
        formData.append("ApplicationID", sessionStorage.getItem("applicationID"));
        console.log(formData);
        this.showLoading = true;
        this.facadeService.CreateTask(formData).subscribe(
            (response) => {
                this.showLoading = false;
                if (response.returnCode == 0) {
                    if (response.returnObject.issuccess == false)
                        this.responseError = response.returnObject.message;
                    else {
                        sessionStorage.FromReport = false;
                        this.router.navigate(['/phasetree/' + this.editProjectName]);
                    }
                }
                else
                    this.responseError = response.returnMessage ? response.returnMessage : "Error Saving Task";
            }, (error) => {
                this.showLoading = false;
                console.log(error);
            }
        );
    }
    phasetree(PID) {
        sessionStorage.FromReport = false;
        this.router.navigate(["/phasetree/" + PID]);
    }

    AllBaseHeadCountcalculation(_duration: number, _assignToCount: number, isEventCall: boolean) {

        if (isEventCall) {
            _duration = (_duration / (_assignToCount > 0 ? _assignToCount : 1)) - 1;
        } else {
            _duration = _duration - 1;
        }

        if (this.editPlannedStartDate == undefined || this.editPlannedStartDate == null) {
            return null;
        }

        let plannedStartDate: Date = this.editPlannedStartDate;
        if (plannedStartDate != undefined && plannedStartDate != null) {
            const copy = new Date(Number(plannedStartDate));
            this.functionCalculateTotalWorkingDays(new Date((plannedStartDate)), _duration);
            let _currentduration: number = parseInt(_duration.toString()) + parseInt(this.nonWorkingDaysCount.toString());
            this.nonWorkingDaysCount = 0;
            copy.setDate(parseInt(plannedStartDate.getDate().toString()) + parseInt(_currentduration.toString()));
            this.editPlannedEndDate = copy;
        }

        if (this.editRevisedPlannedStartDate != undefined && this.editRevisedPlannedStartDate != null) {
            this.functionCalculateTotalWorkingDays(new Date((this.editRevisedPlannedStartDate)), _duration);
            let _currentduration: number = parseInt(_duration.toString()) + parseInt(this.nonWorkingDaysCount.toString());
            this.nonWorkingDaysCount = 0;
            this.RevisedPlanneddatesCalculations(this.editRevisedPlannedStartDate, _currentduration, this.headCountAssigneCount);
        }
        else {
            this.functionCalculateTotalWorkingDays(new Date((plannedStartDate)), _duration);
            let _currentduration: number = parseInt(_duration.toString()) + parseInt(this.nonWorkingDaysCount.toString());
            this.nonWorkingDaysCount = 0;
            this.editRevisedPlannedStartDate = plannedStartDate;
            this.RevisedPlanneddatesCalculations(this.editRevisedPlannedStartDate, _currentduration, this.headCountAssigneCount);
        }
    }

    //Revised Planned Start Date event handler.
    onSelectRevisedPlannedStartDate(event: any) {
        let revisedPlannedStartDate: Date = this.editRevisedPlannedStartDate;
        let _currentDuration: number = parseInt(this.editDuration.toString()) - 1;
        if (_currentDuration == 0)
            _currentDuration = 1;
        this.functionCalculateTotalWorkingDays(new Date((revisedPlannedStartDate)), _currentDuration);
        _currentDuration = parseInt(_currentDuration.toString()) + parseInt(this.nonWorkingDaysCount.toString());
        this.nonWorkingDaysCount = 0;
        this.RevisedPlanneddatesCalculations(revisedPlannedStartDate, (parseInt(_currentDuration.toString())), this.headCountAssigneCount);
    }

    RevisedPlanneddatesCalculations(revisedPlannedStartDate: Date, _duration: number, _assignToCount: number) {
        const copy = new Date(Number(revisedPlannedStartDate));
        copy.setDate(parseInt(revisedPlannedStartDate.getDate().toString()) + parseInt(_duration.toString()));
        this.editRevisedPlannedEndDate = copy;
    }

    // onFocusOutDuration(event: any) {
    //     if (event.target.value != undefined && event.target.value != null && event.target.value != "" && event.target.value != 0) {
    //         if (parseInt(this.headCountDuration.toString()) > 0 && parseInt(this.currentHeadCountDuration.toString()) != parseInt(event.target.value) && (this.editAssignTo != undefined) && (parseInt(this.editAssignTo.length) > 0)) {
    //             this.isDurationModified = true;
    //             this.preSelectedheadCount = parseInt(this.editAssignTo.length);
    //             this.changedDuration = parseInt(event.target.value);
    //         }

    //         this.headCountDuration = parseInt(event.target.value);
    //         this.AllBaseHeadCountcalculation(this.headCountDuration, this.headCountAssigneCount, false);
    //         let _assigneCount: number = (this.headCountAssigneCount == undefined || this.headCountAssigneCount == null || this.headCountAssigneCount == 0) ? 1 : parseInt(this.headCountAssigneCount.toString());
    //         this.editEstimatedEffort = 8 * parseInt(this.headCountDuration.toString()) * _assigneCount;
    //     }
    // }
    //Resource
    onAssignToItemSelect(items: any) {
        let ok = true;
        this.responseError = "";
        if (this.editAssignTo != null && this.editAssignTo != undefined) {
            if (this.editAssignTo.length > this.AssignCount) {
                this.responseError = "Max limit of assign selection is " + this.AssignCount.toString();
                this.editAssignTo = this.projectUsers.filter(m => m.id.toLowerCase() == this.editAssignTo[0].id.toLowerCase());
                ok = false;
            }
        }
        if (ok) {
            const assignToSelectedItems: any = items.value;
            this.headCountAssigneCount = assignToSelectedItems.length;
            if (this.headCountAssigneCount == 0) {
                this.isDurationModified = false;
            }
            if (this.isDurationModified == true && this.headCountAssigneCount > 0 && this.changedDuration > 0) {
                let totalWorkDays: number = 0;
                // let preSelectedheadCountNumber = parseInt(this.preSelectedheadCount.toString()) == 0 ? 1 : parseInt(this.preSelectedheadCount.toString());
                // totalWorkDays = preSelectedheadCountNumber * parseInt(this.changedDuration.toString());
                let currentDuration: number = 0;
                currentDuration = parseInt(totalWorkDays.toString()) / parseInt(this.headCountAssigneCount.toString());
                if (currentDuration == 0)
                    currentDuration = 1;
                this.editDuration = parseInt(currentDuration.toString());
                this.headCountDuration = this.editDuration;
                this.AllBaseHeadCountcalculation(this.editDuration, this.headCountAssigneCount, false);
            }
            else {
                if (this.headCountDuration == 0 && this.editDuration > 0) {
                    this.headCountDuration = this.editDuration;
                }

                if (this.headCountDuration > 0 && this.headCountAssigneCount > 0) {
                    let _duration = parseInt(this.headCountDuration.toString()) / parseInt(this.headCountAssigneCount.toString());
                    if (_duration == 0)
                        _duration = 1;
                    _duration = parseFloat(_duration.toString());
                    this.editDuration = _duration;
                    this.AllBaseHeadCountcalculation(_duration, this.headCountAssigneCount, false);
                }
            }

            // this.currentHeadCountDuration = this.editDuration;
            let _assigneCount: number = (this.headCountAssigneCount == undefined || this.headCountAssigneCount == null || this.headCountAssigneCount == 0) ? 1 : parseInt(this.headCountAssigneCount.toString());
            this.editEstimatedEffort = 8 * parseInt(this.editDuration.toString()) * _assigneCount;
        }
    }

    functionCalculateTotalWorkingDays(startDate: Date, duration: number) {
        let _extraDays: number = 0, _currentDuration: number = 0, _incweekDuration: number = 0, _weekName: string = "";
        if (this.isIncludeweekends == false) {
            while (duration >= _currentDuration) {
                const copy = new Date(Number(startDate));
                copy.setDate(parseInt(startDate.getDate().toString()) + parseInt(_incweekDuration.toString()));
                var weekday = copy.toLocaleString("default", { weekday: "long" });
                if (weekday.toLowerCase() == "saturday" || weekday.toLowerCase() == "sunday") {
                    _extraDays = _extraDays + 1;
                }
                else {
                    _currentDuration++;
                }
                _incweekDuration++;
            }
        }

        this.nonWorkingDaysCount = _extraDays;
    }

    onBurntHourstoday(event: any) {
        if (this.editBurntHoursToday > 24)
            this.editBurntHoursToday = 0;
    }
    onTaskStatusChanging(event: any) {
        if (event != undefined) {
            this.functionEnableNormalActualDate(event.value.id);
        }
    }

    functionEnableNormalActualDate(taskStatusId) {
        if (TaskStatusEnum.Hold == taskStatusId || TaskStatusEnum.Rejected == taskStatusId) {
            this.FCActualStartDateD = true;
            this.FCActualEndDateD = true;
            this.isActualStartDataRequired = false;
            this.isActualEndDataRequired = false;
        }
        else if (TaskStatusEnum.New == taskStatusId) {
            this.FCActualStartDateD = false;
            this.FCActualEndDateD = false;
            this.isActualStartDataRequired = false;
            this.isActualEndDataRequired = false;
        }
        else if (TaskStatusEnum.InProgress == taskStatusId) {
            this.FCActualStartDateD = false;
            this.FCActualEndDateD = false;
            this.isActualStartDataRequired = true;
            this.isActualEndDataRequired = false;
        }
        else if (TaskStatusEnum.Completed == taskStatusId) {
            this.FCActualStartDateD = false;
            this.FCActualEndDateD = false;
            this.isActualStartDataRequired = true;
            this.isActualEndDataRequired = true;
        }
        else if (TaskStatusEnum.NotRequired == taskStatusId) {
            this.FCActualStartDateD = false;
            this.FCActualEndDateD = false;
            this.isActualStartDataRequired = false;
            this.isActualEndDataRequired = false;
        }
        this.FCPlannedStartDateD = true;
        this.FCRevisedPlannedStartDateD = true;
        this.FCPlannedEndDateD = true;
        this.FCRevisedPlannedEndDateD = true;
    }
    resetForm() {
        this.createTaskform.reset();
        this.submitted = false;
    }

    exportExcel(from, ID, Title) {
        var tasksListsByProject = from == 'history' ? this.taskHistory : null;
        if (tasksListsByProject != null) {
            /* table id is passed over here */
            let element = document.getElementById(ID);
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
            /* generate workbook and add the worksheet */
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            this.delete_row(wb.Sheets.Sheet1, 1);
            Title = this.editTaskData?.projectName + "_" + Title;
            ws['!cols'] = [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 130 }, { wpx: 70 }
                , { wpx: 110 }, { wpx: 120 }, { wpx: 100 }, { wpx: 250 }, { wpx: 250 }];
            /* save to file */
            XLSX.writeFile(wb, Title + ".xlsx");
        }
    }

    delete_row(ws, row_index) {
        let range = XLSX.utils.decode_range(ws["!ref"])
        for (var R = row_index; R < range.e.r; ++R) {
            for (var C = range.s.c; C <= range.e.c; ++C) {
                ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
                if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
                    ws[this.ec(R + 1, C)].v = "";
            }
        }
        ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
    }
    ec(r, c) {
        return XLSX.utils.encode_cell({ r: r, c: c })
    }
    print(ID, Title, hidecol) {
        var Content = document.getElementById(ID).innerHTML;
        this.printService.Print(Content, Title, '', hidecol, 0);
    }

    getTaskHistory() {
        if (this.taskId != null && this.taskId != "" && this.taskHistory == undefined) {
            this.showLoading = true;
            this.facadeService.getTaskHistory(this.taskId).subscribe((response) => {
                this.showLoading = false;
                if (response.returnCode == 0) {
                    this.taskHistory = response.returnObject;
                    this.historydisplay = true;
                }
                else {
                    this.responseError = response.returnMessage ? response.returnMessage : "Error in getting history";
                }
            });
        }
        else {
            this.historydisplay = true;
        }
    }
    removeFile(file: File, uploader: FileUpload) {
        const index = uploader.files.indexOf(file);
        uploader.remove(null, index);
    }

    removeFileFromDB(file: any) {
        this.showLoading = true;
        this.facadeService.deleteTaskDocument(file.documentGuid, this.facadeService.getloggedInUser().userguid).subscribe((response) => {
            this.facadeService.GetTaskById(file.taskGuid, this.UserIntID).subscribe(data => {
                this.showLoading = false;
                this.file = data.returnObject.taskFiles;
            })
        });
    }

    GetEffectedTasks() {
        this.effectedTasksdisplay = true;
        this.showLoading = true;
        this.effectedTasksData = [];
        var aed;
        if (this.editSelectedTaskStatus.id == 4)
            aed = this.datePipe.transform(this.editActualEndDate, "MM/dd/yyyy");
        else
            aed = this.datePipe.transform(this.editRevisedPlannedEndDate, "MM/dd/yyyy");
        this.facadeService.GetEffectedTasks(this.taskId, aed, "actual", false)
            .subscribe((response) => {
                this.showLoading = false;
                if (response.returnObject != null && response.returnObject.length > 0) {
                    const thisRef = this;
                    response.returnObject.forEach((value1, key) => {
                        if (value1.dates != null && value1.dates.length > 0) {
                            value1.dates.forEach((value, key) => {
                                let convertactiondate = new Date(value.current);
                                value.current = convertactiondate.toLocaleDateString();
                                let convertactiondateProp = new Date(value.proposed);
                                value.proposed = convertactiondateProp.toLocaleDateString();
                            });
                            this.effectedTasksData.push(value1);
                        }
                        thisRef.expandedRows[value1.taskId] = true;
                    });

                    this.expandedRows = Object.assign({}, this.expandedRows);
                }
            },
                (error) => {
                    this.showLoading = false;
                    console.log(error);
                }
            );
    }
    DMSToken(rowData) {
        this.showLoading = true;
        if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
            this.showLoading = true;
            var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
            this.Download(rowData.fileName, newname, rowData.userFileName);
        }
    }
    Download(filename, newname, userfilename) {
        this.facadeService.downloadDMSFile(filename, newname).subscribe(response => {
            response.filename = userfilename;
            let url = window.URL.createObjectURL(response);
            var anchor = document.createElement("a");
            anchor.download = userfilename;
            anchor.href = url;
            anchor.click();
            anchor.remove();
            // window.open(url, '_blank').focus();
            this.showLoading = false;
        }),
            error => console.log('Error downloading the file'),
            () => console.info('File downloaded successfully');
    }
}

