import { Injectable } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { ProjectDetailsService } from '../services/project-details.service';
import { TimelineService } from '../services/timeline.service'
import { TasksService } from '../services/tasks.service'
import { ListService } from '../services/list.service';
import { UserauthService } from '../services/userauth.service';
import { CustomReportService } from '../services/customReport.service'
import { ReviewactionService } from '../services/reviewaction.service'
import { CustomfieldsService } from '../services/customfields.service'

@Injectable({
  providedIn: 'root'
})
export class FacadeService {

  constructor(
    private _dashboardService: DashboardService,
    private _projectDetailsService: ProjectDetailsService,
    private _timelineService: TimelineService,
    private _tasksService: TasksService,
    private _listService: ListService,
    private _userAuthService: UserauthService,
    private _customReportService: CustomReportService,
    private _reviewactionService: ReviewactionService,
    private _customfieldsService: CustomfieldsService
  ) { }

  getDepartments()
  {
    return this._dashboardService.getDepartments();
  }
  redirectToLogin(url) {
    //location.href = url;
  }
  login(model) {
    return this._dashboardService.login(model);
  }
  getTilesData(Admin,UserGUID) {
    return this._dashboardService.getTilesData(Admin,UserGUID);
  };
  Chart(UserGUID) {
    return this._dashboardService.Chart(UserGUID);
  };


  // getProjects(Admin,UserGUID) {
  //   return this._dashboardService.getProjects(Admin,UserGUID);
  // };

  getProjects(Admin,UserGUID,download) {
    return this._dashboardService.getProjects(Admin,UserGUID,download);
  };

  getProjectDetailsById(id) {
    return this._projectDetailsService.getProjectDetailsById(id);
  }
  getListsByProjectId(id) {
    return this._projectDetailsService.getListsByProjectId(id);
  }
  getTasksByListId(id) {
    return this._projectDetailsService.getTasksByListId(id);
  }

  getProjectDashboardTimeLines(userGuid, days) {
    return this._timelineService.getTimeLines(userGuid, days);
  }
  GetTasksManageAssignee(projectId) {
    return this._tasksService.GetTasksManageAssignee(projectId);
  }

  getTaskListByProject(projectId, listId, type) {
    return this._tasksService.getTasksByProject(projectId, listId, type);
  }
  getSpaceList() {
    return this._projectDetailsService.getSpaceList();
  }

  getCustomFieldList(projectId) {
    return this._projectDetailsService.getCustomFieldList(projectId);
  }
  getUserList() {
    return this._projectDetailsService.getUserList();
  }
  CreateSiliconLayer(data) {
    return this._projectDetailsService.CreateSiliconLayer(data);
  }

  createProject(data) {
    return this._projectDetailsService.createProject(data);
  }

  getProjectManagerUsers() {
    return this._projectDetailsService.getProjectManagerUsers();
  }

  getTasksByUserID(userId) {
    return this._tasksService.getTasksByUserID(userId);
  }

  getTasksByUserID1(userId,download)
  {
    return this._tasksService.getTasksByUserID1(userId,download);
  }

  saveSpace(space) {
    return this._projectDetailsService.saveSpace(space);
  }

  deleteSpace(space) {
    return this._projectDetailsService.deleteSpace(space);
  }

  GetTaskListPreInformation(listid: string, listType: number) {
    return this._tasksService.GetTaskListPreInformation(listid, listType);
  }

  GetTaskcreateProjectoptions() {
    return this._tasksService.GetTaskcreateProjectoptions();
  }

  // ProjectChangeManagementValidation(model){
  //   return this._tasksService.ProjectChangeManagementValidation(model);
  // }
  TaskDeActive(taskid) {
    return this._tasksService.TaskDeActive(taskid);
  }

  GetTasksDropdownOptionsByProject(projectId) {
    return this._tasksService.GetTasksDropdownOptionsByProject(projectId);
  }

  CreateTask(data) {
    return this._tasksService.CreateTask(data);
  }

  getUserKeyValue() {
    return this._tasksService.GetUserKeyValue();
  }

  GetAllDieCodes() {
    return this._tasksService.GetAllDieCodes();
  }
  GetAllMFGParts() {
    return this._tasksService.GetAllMFGParts();
  }
  createList(data) {
    return this._listService.createList(data);
  }

  GetListDetailsByListId(listId) {
    return this._listService.GetListDetailsByListId(listId);
  }

  GetStageByPhase(phaseId) {
    return this._listService.GetStageByPhase(phaseId);
  }

  GetMilestoneByStage(stageId) {
    return this._listService.GetMilestoneByStage(stageId);
  }

  GetTaskById(taskId,UserID) {
    return this._tasksService.GetTaskById(taskId,UserID);
  }

  GetProjectMenuItems(projectId) {
    return this._listService.GetProjectMenu(projectId);
  }

  DeleteList(listDTO) {
    return this._listService.DeleteList(listDTO);
  }

  getloggedInUser() {
    return this._userAuthService.getloggedInUser();
  }

  setloggedInUser(userId) {
    return this._userAuthService.setloggedInUser(userId);
  }

  //Custom Report RESTFUL API calls
  GetCustomReportPopupData() {
    return this._customReportService.GetCustomReportPopupData();
  }

  CreateReportSchema(data) {
    return this._customReportService.CreateReportSchema(data);
  }

  GetReportSchemaById(reportId) {
    return this._customReportService.GetReportSchemaById(reportId);
  }

  GetReportNames() {
    return this._customReportService.GetReportNames();
  }
  GenerateReport(ReportName) {
    return this._customReportService.GenerateReport(ReportName);
  }
  GetReports() {
    return this._customReportService.GetReports();
  }

  GetReportByReportId(reportId: string) {
    return this._customReportService.GetReportByReportId(reportId);
  }

  GetProjectReport(SpaceId: string, ManagerId: string) {
      return this._customReportService.GetProjectReport(SpaceId, ManagerId);
  }

  GetProjectsbyMilestone(data) {
    return this._customReportService.GetProjectsbyMilestone(data);
  }
  ReportProducts(data) {
    return this._customReportService.ReportProducts(data);
  }


  GetP2ReviewProjects(data) {
    return this._customReportService.GetP2ReviewProjects(data);
  }

  GetAllMilestones() {
    return this._customReportService.GetAllMilestones();
  }

  GetProductLine(){
    return this._customReportService.GetProductLine();
  }
  GetProductOwners()
  {
    return this._customReportService.GetProductOwners();
  }
  GetProjectsByProductlines(model)
  {
    return this._customReportService.GetProjectsByProductlines(model);
  }
  GetOverdueMilestone(model)
  {
    return this._customReportService.GetOverdueMilestone(model);
  }
  //Ends Custom Report RESTFUL API calls

  getTaskHistory(taskId) {
    return this._tasksService.GetTaskHistory(taskId);
  }

  getSubTasks(taskId) {
    return this._tasksService.GetSubTasks(taskId);
  }

  deleteTaskDocument(fileGuid, userGuid) {
    return this._tasksService.DeleteTaskDocument(fileGuid, userGuid);
  }
  GetEffectedTasks(TaskID,TaskStatus,ActualEndDate,PlannedEndDate){
    return this._tasksService.GetEffectedTasks(TaskID,TaskStatus,ActualEndDate,PlannedEndDate);
  }
  //Start Reviews
  saveReview(reviewObj) {
    return this._reviewactionService.SaveReview(reviewObj);
  }
  GetReviewsByProjectId(pId){
    return this._reviewactionService.GetReviewsByProjectId(pId);
  }
  updateReview(reviewObj) {
    return this._reviewactionService.UpdateReview(reviewObj);
  }

  deleteReview(reviewObj) {
    return this._reviewactionService.DeleteReview(reviewObj);
  }

  getReviews(userId){
    return this._reviewactionService.GetReviews(userId);
  }

  GetCurrentWeek(){
    return this._reviewactionService.GetCurrentWeek();
  }
  GetDistinctPhases()
  {
    return this._reviewactionService.GetDistinctPhases();
  }
  GetAttributes()
  {
    return this._reviewactionService.GetAttributes();
  }
  GetPrevReviews(ProjectID)
  {
    return this._reviewactionService.GetPrevReviews(ProjectID);
  }
  SaveRemarks(model)
  {
    return this._reviewactionService.SaveRemarks(model);
  }
  //End Reviews

  //Start Custom Fields
  GetProductByUser(userGuid){
    return this._customfieldsService.GetProductByUser(userGuid);
  }  
  GetAllActiveProjects()
  {
    return this._customfieldsService.GetAllActiveProjects();
  }
  IsOwner(projectId,userGuid){
    return this._customfieldsService.IsOwner(projectId,userGuid);
  }  
  
  /*BEGIN - Added by suresh*/
  ProductList(userGuid, Type, PE){
    return this._customfieldsService.ProductList(userGuid, Type, PE);
  }
  
  GetP2ProjectByID(productId) {
    return this._projectDetailsService.GetP2ProjectByID(productId);
  }

  // InsertMeeting(meetingObj) {
  //   return this._projectDetailsService.InserMeeting(meetingObj);
  // }

  SaveMeeting(model) {
    return this._projectDetailsService.SaveMeeting(model);
  }

  // GetMeetings(meetingObj) {
  //   return this._projectDetailsService.GetMeetings(meetingObj);
  // }
  /*END - Added by suresh*/

  GetCustomGroups(){
    return this._customfieldsService.GetCustomGroups();
  }

  GetFieldsByGroupID(GroupID, ProductID, ApplicationID){
    return this._customfieldsService.GetFieldsByGroupID(GroupID, ProductID, ApplicationID);
  }

  SaveCustomFields(model){
    return this._customfieldsService.SaveCustomFields(model);
  }

  DataSourceAsQuery(datasource){
    return this._customfieldsService.DataSourceAsQuery(datasource);
  }

  GetDataDependent(datasource,Value){
    return this._customfieldsService.GetDataDependent(datasource,Value);
  }
  GetDependentAttributes(CustomFieldID){
    return this._customfieldsService.GetDependentAttributes(CustomFieldID);
  }

  GetHistory(ProductID,CustomFieldID)
  {
    return this._customfieldsService.GetHistory(ProductID,CustomFieldID);
  }
  GetProjectInfoFromPDM(projectName){
    return this._customfieldsService.GetProjectInfoFromPDM(projectName);
  }

  getProjectDescription(){
    return this._projectDetailsService.getProjectDescription();
  }

  saveProjectDesc(data){
    return this._projectDetailsService.saveProjectDesc(data);
  }

  deleteProjectDesc(data){
    return this._projectDetailsService.deleteProjectDesc(data);
  }
  //End Custom Fields
  GetReportData(){
    return this._projectDetailsService.GetReportData();
  }

  GetManagerProjects(userid){
    return this._projectDetailsService.GetManagerProjects(userid);
  }

  GetActiveProjects()
  {
    return this._projectDetailsService.GetActiveProjects();
  }
  GetProjectByID(projectId){
    return this._projectDetailsService.GetProjectByID(projectId);
  }

  UpdateProjectActualStartDate(ProjectID,ChangeDate){
    return this._projectDetailsService.UpdateProjectActualStartDate(ProjectID,ChangeDate);
  }

  UpdateTaskAssignee(model)
  {
    return this._projectDetailsService.UpdateTaskAssignee(model);
  }

  GetManageMilestoneDates(model)
  {
    return this._projectDetailsService.GetManageMilestoneDates(model);
  }
  
  GetDates(ProjectID,model,Update,By)
  {
    return this._projectDetailsService.GetDates(ProjectID,model,Update,By);
  }
  CreateSecurityToken(){
    return this._projectDetailsService.CreateSecurityToken();
  }
  UpdateSecurityToken(token){
    return this._projectDetailsService.UpdateSecurityToken(token);
  }
  GetMenusByUser(userId,applicationid)
  {
    return this._projectDetailsService.GetMenusByUser(userId,applicationid);
  }
  
  downloadDMSFile(filename,newname){
    return this._projectDetailsService.downloadDMSFile(filename,newname);
  }
  AddUserTracking(model){
    return this._projectDetailsService.AddUserTracking(model);
  }
}
