import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

    barData: any;
    pieData: any;
    department: any;
    departmentError: any;
    customers1: any;
    selectedCustomers1: any;
    departmentsTypesData: any;
    spaceList: [];
    projectManagerList: [];
    constructor(private facadeService: FacadeService) {
    }
    ngOnInit(): void {
        this.facadeService.getSpaceList().subscribe((spaces) => {
            this.spaceList = spaces.returnObject;
        });
        this.facadeService.getProjectManagerUsers().subscribe((managers) => {
            if (managers.returnObject) {
                managers.returnObject.forEach(data => {
                    data.managerName = data.firstName + " " + data.lastName;
                });
            }

            this.projectManagerList = managers.returnObject;
        });
        this.GetProjectInformation("", "");
        this.barData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Effort Completed',
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: 'rgb(255, 99, 132)',
                    data: [65, 59, 80, 81, 56, 55, 40]
                },
                {
                    label: 'Effort Remaining',
                    backgroundColor: 'rgb(54, 162, 235)',
                    borderColor: 'rgb(54, 162, 235)',
                    data: [28, 48, 40, 19, 86, 27, 90]
                }
            ]
        };
        this.pieData = {
            labels: ['A', 'B', 'C'],
            datasets: [
                {
                    data: [540, 325, 702, 421],
                    backgroundColor: [
                        'rgb(54, 162, 235)',
                        'rgb(255, 99, 132)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)'
                    ]
                }]
        };
    }

    GetProjectInformation(spaceId: string, managerId: string) {
        this.facadeService.GetProjectReport(spaceId, managerId).subscribe((data) => {
            console.log(data.returnObject);
        });
    }

    onSpaceSelection(event: any) {
        if (event != undefined) {
            console.log(event.value.spaceId);
        }
    }

    onManagerSelection(event: any) {
        if (event != undefined) {
            console.log(event.value.userGuid);
        }
    }
}
