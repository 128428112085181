<form
  [formGroup]="createProjectform"
  (ngSubmit)="onSubmit(createProjectform.value)"
>

  <p-toast position="top-right" key="tl"></p-toast>
  <div class="p-grid p-fluid">
    <div class="p-col-12">
      <div class="card">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-12 p-md-2">
            <a routerLink="{{ backLink }}">
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-chevron-left"
                iconPos="left"
                label="Back"
                class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                style="width: 50%"
              ></button
            ></a>
          </div>
          <div class="p-col-12 p-md-8" style="text-align: center">
            <h4 style="margin-top: 5px">View Project</h4>
          </div>
          <div class="p-col-12 p-md-2"></div>
        </div>
        <hr />
        <div class="p-grid p-fluid">
          <div class="p-col-12">
            <div class="p-grid">
              <div class="p-col-12"></div>
              <div class="p-col-12 p-md-4">
                <label
                  >Product Line
                </label>
                <input
                type="text"
                pInputText
                placeholder="Product Line"
                [(ngModel)]="productLine"
                formControlName="frmproductLine"
                disabled
              />
              </div>
              <!-- <div class="p-col-12 p-md-1">
                <div
                  class="p-field"
                  [formGroup]="createSpaceform"
                  style="margin-top: 19px"
                >
                  <button
                    pButton
                    pRipple
                    type="button"
                    pTooltip="Create New Space"tooltipPosition="top"
                    label=""
                    (click)="showSpaceModal()"
                    icon="pi pi-plus"
                    Pointer="cursor"
                    style="height:35px; float:right"
                  ></button>
                  <p-dialog
                    header="Create New Space"
                    [(visible)]="spacedisplay"
                    modal="modal"
                    showEffect="fade"
                    [style]="{ width: '40%' }"
                  >
                    <hr />
                    <label for="spacename"
                      >Space Name<span style="color: red">*</span>
                    </label>
                    <input
                      #newSpacetxtCtrl
                      [(ngModel)]="newSpacetxt"
                      type="text"
                      pInputText
                      placeholder="Space Name"
                      required="true"
                      requiredMessage="The input must not be empty"
                      formControlName="newSpace"
                    />
                    <small
                      class="p-invalid"
                      *ngIf="
                        spaceSubmitted &&
                        createSpaceform.controls.newSpace.hasError('required')
                      "
                      >Space is not mentioned.</small
                    >

                    <span>
                      <div
                        class="p-col-12 p-md-12"
                        style="vertical-align: center"
                        *ngIf="responseError != ''"
                      >
                        <small class="p-invalid" style="float: right">{{
                          responseError
                        }}</small>
                      </div>
                      <div class="p-col-12 p-md-12" style="float: left">
                        <button
                          pButton
                          type="button"
                          label="Create"
                          class="p-button-success"
                          icon="pi pi-check"
                          iconPos="left"
                          style="margin-left: 10px; width: 100px; float: right"
                          (click)="onSpaceSubmit(newSpacetxtCtrl.value)"
                        ></button>
                      </div>
                    </span>
                  </p-dialog>
                </div>
              </div> -->

              <div class="p-col-12 p-md-4">
                <label for="ProjecName"
                  >Project Name
                </label>
                <input
                  type="text"
                  pInputText
                  placeholder="Project Name"
                  [(ngModel)]="projecName"
                  formControlName="ProjectName"
                  required="true"
                  requiredMessage="The input must not be empty"
                  disabled
                />
                <small
                  class="p-invalid"
                  *ngIf="
                    submitted &&
                    createProjectform.controls.ProjectName.hasError('required')
                  "
                  >Project name is required</small
                >
              </div>
              <div class="p-col-12 p-md-4">
                <label for="state"
                  >Product Engineer
                </label>
                <p-dropdown
                  [options]="projectManagerList"
                  [(ngModel)]="selectedProjectmanager"
                  placeholder="Select Product Engineer"
                  optionLabel="managerName"
                  required="true"
                  formControlName="ProjectManager"
                  [showClear]="true"
                  requiredMessage="The input must not be empty"
                  [disabled]=true
                ></p-dropdown>
                <small
                  class="p-invalid"
                  *ngIf="
                    submitted &&
                    createProjectform.controls.ProjectManager.hasError(
                      'required'
                    )
                  "
                  >Product Engineer is required
                </small>
              </div>

              <div class="p-col-12 p-md-4">
                <label for="startDate"
                  >Target Start Date
                </label>
                <p-calendar
                  [showIcon]="true"
                  inputId="startDate"
                  [(ngModel)]="startDate"
                  (onSelect)="setEndDate(startDate, endDate, 'planned')"
                  formControlName="ProjectStartDate"
                  [minDate]="minimumDate"
                  required="true"
                  requiredMessage="The input must not be empty"
                  disabled
                >
                </p-calendar>
                <small
                  class="p-invalid"
                  *ngIf="
                    submitted &&
                    createProjectform.controls.ProjectStartDate.hasError(
                      'required'
                    )
                  "
                  >Date is required
                </small>
              </div>

              <div class="p-col-12 p-md-4">
                <label for="endDate"
                  >Target End Date
                </label>
                <p-calendar
                  [showIcon]="true"
                  inputId="endDate"
                  [(ngModel)]="endDate"
                  formControlName="TargetDate"
                  required="true"
                  [minDate]="endMinimumDate"
                  requiredMessage="The input must not be empty"
                  disabled
                ></p-calendar>
                <small
                  class="p-invalid"
                  *ngIf="
                    submitted &&
                    createProjectform.controls.TargetDate.hasError('required')
                  "
                  >Date is required
                </small>
              </div>

              <div class="p-col-12 p-md-4">
                <label for="revisedStartDate"
                  >Current Start Date
                </label>
                <p-calendar
                  [showIcon]="true"
                  inputId="revisedStartDate"
                  [(ngModel)]="revisedStartDate"
                  formControlName="frmRevisedStartDate"
                  (onSelect)="setEndDate(revisedStartDate, revisedEndDate, 'revised')"
                  required="true"
                  [minDate]="revisedMinimumDate"
                  requiredMessage="The input must not be empty"
                  [disabled]=true
                ></p-calendar>
                <small
                  class="p-invalid"
                  *ngIf="
                    submitted &&
                    createProjectform.controls.frmRevisedStartDate.hasError('required')
                  "
                  >Date is required
                </small>
              </div>

              <div class="p-col-12 p-md-4">
                <label for="revisedEndDate"
                  >Current End Date
                </label>
                <p-calendar
                  [showIcon]="true"
                  inputId="revisedEndDate"
                  [(ngModel)]="revisedEndDate"
                  formControlName="frmrevisedEndDate"
                  required="true"
                  [minDate]="revisedEndMinimumDate"
                  requiredMessage="The input must not be empty"
                  [disabled]=true
                ></p-calendar>
                <small
                  class="p-invalid"
                  *ngIf="
                    submitted &&
                    createProjectform.controls.frmrevisedEndDate.hasError('required')
                  "
                  >Date is required
                </small>
              </div>

              <div class="p-col-12 p-md-4">
                <label for="actualStartDate"
                  >Actual Start Date
                </label>
                <p-calendar
                  [showIcon]="true"
                  inputId="actualStartDate"
                  [(ngModel)]="actualStartDate"
                  (onSelect)="setEndDate(actualStartDate, actualEndDate, 'actual')"
                  formControlName="frmactualStartDate"
                  required="false"
                  [minDate]="actualMinimumDate"
                  requiredMessage="The input must not be empty"
                  [disabled]=true
                ></p-calendar>
                <!-- <small
                  class="p-invalid"
                  *ngIf="
                    submitted &&
                    createProjectform.controls.frmactualStartDate.hasError('required')
                  "
                  >Date is required
                </small> -->
              </div>

              <div class="p-col-12 p-md-4">
                <label for="actualEndDate"
                  >Actual End Date<span style="color: red"></span>
                </label>
                <p-calendar
                  [showIcon]="true"
                  inputId="actualEndDate"
                  [(ngModel)]="actualEndDate"
                  formControlName="frmactualEndDate"
                  required="false"
                  [minDate]="actualEndMinimumDate"
                  requiredMessage="The input must not be empty"
                  [disabled]=true
                ></p-calendar>
                <!-- <small
                  class="p-invalid"
                  *ngIf="
                    submitted &&
                    createProjectform.controls.frmactualEndDate.hasError('required')
                  "
                  >Date is required
                </small> -->
              </div>

              <div class="p-col-12 p-md-4">
                <label for="projectStatus"
                  >Project Status
                </label>
                <input
                type="text"
                pInputText
                placeholder="Project Status"
                [(ngModel)]="projectStatusMdl"
                formControlName="projectStatusFC"
                disabled
              />
              </div>
              <div class="p-col-12 p-md-4">
                <label for="projectDescription"
                  >Project Description
                </label>
                <p-dropdown
                  [options]="projectDescOptions"
                  [(ngModel)]="projectDescMdl"
                  placeholder="Project Description"
                  optionLabel="projectDescriptionName"
                  required="true"
                  formControlName="frmprojectDescription"
                  requiredMessage="The input must not be empty"
                  [disabled]=true
                >
                </p-dropdown>
                <small
                  class="p-invalid"
                  *ngIf="
                    submitted &&
                    createProjectform.controls.frmprojectDescription.hasError(
                      'required'
                    )
                  "
                  >Status is required
                </small>
              </div>
              <!-- <div class="p-col-12">
                <label for="description">Description</label>
                <textarea
                  id="description"
                  type="text"
                  rows="4"
                  [(ngModel)]="description"
                  formControlName="Description"
                  pInputTextarea
                ></textarea>
              </div> -->
            </div>

            <div class="card-header p-grid">
              <div class="p-col-12 p-md-12" style="vertical-align: center">
                <small class="p-invalid" style="float: right">{{
                  responseError
                }}</small>
              </div>
              <!-- <div class="p-col-12 p-md-8" style="vertical-align: center"></div>
              <div class="p-col-12 p-md-2" style="vertical-align: center" *ngIf="LoggedInUser">
                <button
                  pButton
                  type="submit"
                  label="{{ formType === 'Create' ? 'Save' : 'Update' }}"
                  class="p-button-success"
                  icon="pi pi-check"
                  iconPos="left"
                  style="margin-left: 10px"
                ></button>
              </div> -->
              <!-- <div class="p-col-12 p-md-2" style="vertical-align: center" *ngIf="formType=='Create'">
                <span class="p-input-icon-left" style="float: right">
                  <button
                    pButton
                    type="button"
                    label="Reset"
                    class="p-button-secondary"
                    icon="pi pi-refresh"
                    iconPos="left"
                    style="margin-left: 10px"
                    (click)="resetForm()"
                  ></button>
                </span>
              </div> -->
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</form>
<div
  style="
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.2;
    z-index: 9999;
  "
  *ngIf="showLoading"
>
  <i
    class="pi pi-spin pi-spinner"
    style="position: fixed; top: 50%; left: 50%; font-size: 5rem"
  ></i>
</div>
