export class Project {
    ProjectId: string;
    SpaceId: string;
    ProjectName: string;
    ProjectManagerId?: number;
    ProjectStartDate?: string;
    TargetDate?: string;
    RevisedStartDate?:string;
    ActualStartDate?: string;
    RevisedEndDate?:string;
    ActualEndDate?:string;
    Revision?: string;
    ProjectStatus: number;
    CreatedBy: string;
    CreatedDate: Date;
    ProjectDescriptionId: number;
    CustomFields: CustomFields[];
    ProductID?: string;
}

export class CustomFields {
    EntityId: string;
    CustomFieldId: number;
    CustomFieldName: string;
    Value: string;
}