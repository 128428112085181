import { Component, OnInit } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
@Component({
  selector: 'app-addreview',
  templateUrl: './addreview.component.html',
  styleUrls: ['./addreview.component.scss']
})
export class AddreviewComponent implements OnInit {

  showLoading: boolean;
  projects:any[];
  selectedproject:any;
  projectID : any;
  projectInformation:any;
  currentweekNo:number =0;
  currentWeekRemarks:string="";
  PrevReviews:any[];
  cols1:any[];
  viewmode:boolean=true;
  Show:boolean = false;
  filteredProdType: any[];
  Error: string = "";
  userId = sessionStorage.getItem("userGuid"); 
  userFeature:boolean = false;
  constructor(private _facadeService: FacadeService, ) { }

  ngOnInit(): void {
    this.showLoading=true;
    this.cols1 = [
      { field: 'workWeek', header: 'Work Week' },
      { field: 'reviewNotes', header: 'Review Notes' },
      { field: 'reviewByName', header: 'Review By' },
      { field: 'reviewDate', header: 'Review Date' }
    ];
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PM_AP");
        if(featureExist!=null && featureExist != undefined)
          this.userFeature = true;
      }
    }
    this.getProductByuser(this.userId);
    this.GetCurrentWeek();
  }

  getProductByuser(userId) {
    if(this.userFeature)
    {
      this._facadeService
      .GetActiveProjects()
      .subscribe((data) => {
        this.projects = data.returnObject;
      });
    }
    else
    {
      this._facadeService
      .GetManagerProjects(userId)
      .subscribe((data) => {
        this.projects = data.returnObject;
      });
     }
  }
  GetCurrentWeek()
  {
    this._facadeService
    .GetCurrentWeek()
    .subscribe((data) => {
      this.currentweekNo = data.returnObject
      this.showLoading=false;
    });
  }

  productListChange(projectid)
  {
    this.showLoading=true;
    this.projectID = projectid;
    this.Error = "";
    this._facadeService
    .GetPrevReviews(projectid)
    .subscribe((data) => {
      this.projectInformation = data.returnObject;
      this.Show = true;
      this.PrevReviews = data.returnObject.prevReviews;
      this.currentWeekRemarks = this.projectInformation.currentWeekRemarks;
      var today = (new Date()).getDay();
      if(today < 4)
        this.viewmode = false;
      else
        this.viewmode = true;

     
      this.showLoading=false;
    });
  }

  filterproductListChange(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.projects.length; i++) {
        let product= this.projects[i];
        if (product.projectName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(product);
        }
    }    
    this.filteredProdType = filtered;
}

  SaveRemarks()
  {
    this.showLoading=true;
    this.Error = "";
    if(this.currentWeekRemarks != undefined && this.currentWeekRemarks != null && this.currentWeekRemarks != "")
    {
      if(this.projectInformation.currentWeekRemarks ==  this.currentWeekRemarks)
        this.Error = "Remarks are same, Please update remarks to save";
      else
      {
        if(this.projectID != undefined && this.projectID != null && this.projectID != ""
        && this.currentweekNo != undefined && this.currentweekNo != null && this.currentweekNo != 0)
        {
          var model = {
            "ProjectID":this.projectID,
            "WeekNo":this.currentweekNo,
            "Remarks":this.currentWeekRemarks,
            "AddedBy":this.userId
          }
          this._facadeService
          .SaveRemarks(model)
          .subscribe((data) => {
            if(data.returnCode == 0)
            {
              this.productListChange(this.projectID);
              this.Error = "Saved successfully";
            }
            else
              this.Error = data.returnObject;
          });
        }
        else
          this.Error = "Some error ocuured, Please refresh the page again";
      }
    }
    else
      this.Error = "Please enter remarks";
    this.showLoading=false;
  }

}
