<div class="p-grid table-demo">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
          <h5 style="color:#495057; padding:15px 0 0 15px;">Manage Milestone Cycle Time
           
          </h5>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-3 card-header" style="margin-top: 25px;">
                <!--
                <p-dropdown [options]="projects" [(ngModel)]="selectedProject" placeholder="Select Project"
                  (onChange)="SelectProject()" optionLabel="projectName"></p-dropdown> -->
                  <label style="text-align: right;">Project:&nbsp;&nbsp;</label>
                <p-autoComplete [(ngModel)]="selectedProject" [suggestions]="filteredProdType" class="width350"  placeholder="Select Project"
                (completeMethod)="filterproductListChange($event)"   field="projectName" [dropdown]="true" (onSelect)="SelectProject()">
                <ng-template>
                  <div>
                    <div>{{ filteredProdType.projectName }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
        </div>
        <hr />
        <div class="p-grid p-fluid" *ngIf="productselected">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-12 p-md-4 p-xl-4">
                  <div
                    class="card no-gutter widget-overview-box widget-overview-box-1"
                    style="min-height: 137px"
                  >
                    <ul class="widget-image-list">
                      <li>
                        <span style="font-weight: bold;" >Project Name:</span>
                        <span>{{projectInformation?.projectName}}</span>
                      </li>
                      <li>
                        <span style="font-weight: bold;" >Product Engineer:</span>
                        <span>{{ projectInformation != undefined ? projectInformation.owner : "" }}</span>
                      </li>
                      <li>
                        <span style="font-weight: bold;" >Project Status:</span>
                        <span>{{ projectInformation != undefined ? projectInformation.projectStatusName: "" }}</span>
                      </li>
                      <li>
                        <span style="font-weight: bold;" >Die Code:</span>
                        <span>{{ projectInformation != undefined ? projectInformation.dieCode: "" }}</span>
                      </li>
                      <li>
                        <span style="font-weight: bold;" >Mfg Part #:</span>
                        <span>{{ projectInformation != undefined ? projectInformation.mfgPart: "" }}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="p-col-12 p-md-4 p-xl-4">
                  <div
                    class="card no-gutter widget-overview-box widget-overview-box-1"
                    style="min-height: 137px"
                  >
                    <ul class="widget-image-list">
                      <li>
                        <span style="font-weight: bold;" >Package Name:</span>
                        <span>{{ projectInformation != undefined ? projectInformation.packageOption: "" }}</span>
                      </li>
                      <li>
                        <span style="font-weight: bold;" >Need CP Trim:</span>
                        <span>{{ projectInformation != undefined ? projectInformation.cpTrim: "" }}</span>
                      </li>
                      <li>
                        <span style="font-weight: bold;" >Package Type:</span>
                        <span>{{ projectInformation != undefined ? projectInformation.packageType: "" }}</span>
                      </li>
                      <li>
                        <span style="font-weight: bold;" >Qual Type:</span>
                        <span>{{ projectInformation != undefined ? projectInformation.qualType: "" }}</span>
                      </li>
                      <li>
                        <span style="font-weight: bold;" >Active Phase:</span>
                        <span>{{ projectInformation != undefined ? projectInformation.currentPhase : "" }}</span>
                       </li>
                    </ul>
                  </div>
                </div>

                <div class="p-col-12 p-md-4 p-xl-4">
                  <div
                    class="card no-gutter widget-overview-box widget-overview-box-1"
                    style="min-height: 137px"
                  >
                    <ul class="widget-image-list">
                      <li>
                        <span style="font-weight: bold;" >Start Date:</span>
                        <span>{{projectInformation?.projectStartDate|date:"MM/dd/yyyy"}}</span>
                      </li>
                      <li>
                        <span style="font-weight: bold;" >End Date:</span>
                        <span>{{projectInformation?.targetDate|date:"MM/dd/yyyy"}}</span>
                      </li>
                       <li>
                        <span style="font-weight: bold;" >Project Description:</span>
                        <button
                        pButton
                        type="button"
                        pTooltip="Project Description" tooltipPosition="right" 
                        class="p-button-primary"
                        (click)="projectdescriptiondisplay = true"
                        icon="pi pi-eye"
                        style=" height:25px; width:25px; margin-bottom:-10px; margin-top:-10px;"
                      ></button>
                       </li>
                      <li>
                        <span style="font-weight: bold;" >Attributes:</span>
                        <span *ngIf="projectInformation != undefined">
                          <!-- target="_blank" href="{{appBaseUrl}}customfields?projectId={{projectInformation.productId}}&pid={{projectInformation.projectId}}"> -->
                            <button (click)="open(projectInformation.productId,projectInformation.projectId)"  pButton pRipple type="button" icon="pi pi-th-large" pTooltip="Additional Attributes" tooltipPosition="right" 
                           iconPos="left" class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                        </span>
                      </li>
                      <li>
                        <span style="font-weight: bold;" >OverLap/Cycle Time:</span>
                        <button
                        pButton
                        type="button"
                        pTooltip="Overlap / Cycle Time Information" tooltipPosition="right" 
                        class="p-button-primary"
                        (click)="Overlapinfo = true"
                        icon="pi pi-key"
                        style=" height:25px; width:25px; margin-bottom:-10px; margin-top:-10px;"
                      ></button>
                       </li>
                    </ul>
                  </div>
                </div>
            <div class="p-col-12 p-md-12 p-xl-12">
                <p-table (sortFunction)="customSort($event)" [customSort]="true"
                    #dt
                    [value]="tasksListsByProject" id="excel-table" 
                    [(selection)]="selectedtasksListsByProject"
                    dataKey="id"
                    styleClass="p-datatable-striped p-datatable-customers"
                    [scrollable]="true" scrollHeight="300px"
                    [rowHover]="true"
                    [rows]="10"
                    [paginator]="false"
                    [filterDelay]="0"
                  >
                    <ng-template pTemplate="header">
                      <tr >
                        <th pSortableColumn="workTaskId" style="text-align:center; width:130px;">
                          Milestone Id
                          <p-sortIcon field="workTaskId"></p-sortIcon>
                        </th>
                        <th pSortableColumn="taskName" style="text-align:center;width:40%;">
                          Milestone
                          <p-sortIcon field="taskName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="duration" style="text-align:center;width: 90px;">
                          Duration
                          <p-sortIcon field="duration"></p-sortIcon>
                        </th>
                        <th pSortableColumn="isWeekend" style="text-align:center;width: 120px;">
                          IsWeekend
                          <p-sortIcon field="isWeekend"></p-sortIcon>
                        </th>
                        <th pSortableColumn="plannedStartDate" style="text-align:center;width: 150px;">
                            Target Start Date
                            <p-sortIcon field="plannedStartDate"></p-sortIcon>
                          </th>
                        <th pSortableColumn="plannedEndDate" style="text-align:center;width: 150px;">
                          Target End Date
                          <p-sortIcon field="plannedEndDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="startDate" style="text-align:center;width: 150px;">
                          Current Start Date
                          <p-sortIcon field="startDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dueDate" style="text-align:center;width: 150px;">
                          Current End Date
                          <p-sortIcon field="dueDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="overlapDays" style="text-align:center;width: 120px;">
                          Overlap Days
                          <p-sortIcon field="overlapDays"></p-sortIcon>
                        </th>
                      </tr>
                      <tr>
                        <th>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              dt.filter(
                                $event.target.value,
                                'workTaskId',
                                'contains'
                              )
                            "
                            placeholder="Milestone Id"
                            class="p-column-filter"
                            style="width: 85%; height:25px; margin-left:10px; "
                          />
                        </th>
                        <th>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              dt.filter(
                                $event.target.value,
                                'taskName',
                                'contains'
                              )
                            "
                            placeholder="Milestone"
                            class="p-column-filter"
                            style="width: 95%; height:25px;"
                          />
                        </th>
                        <th>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              dt.filter(
                                $event.target.value,
                                'duration',
                                'contains'
                              )
                            "
                            placeholder="Duration"
                            class="p-column-filter"
                            style="width: 95%; height:25px;"
                          />
                        </th>
                        <th style="text-align:center;">
                          <input
                          pInputText
                          type="text"
                          (input)="
                            dt.filter(
                              $event.target.value,
                              'isWeekend',
                              'contains'
                            )
                          "
                          placeholder="IsWeekend"
                          class="p-column-filter"
                          style="width: 90%; height:25px;"
                        />
                        </th>
                        <th style="text-align:center;">
                            <input
                            pInputText
                            type="text"
                            (input)="
                              dt.filter(
                                $event.target.value,
                                'plannedStartDate',
                                'contains'
                              )
                            "
                            placeholder="Target Start date"
                            class="p-column-filter"
                            style="width: 95%; height:25px;"
                          />
                          </th>
                        <th style="text-align:center;">
                          <input
                          pInputText
                          type="text"
                          (input)="
                            dt.filter(
                              $event.target.value,
                              'plannedEndDate',
                              'contains'
                            )
                          "
                          placeholder="Target End date"
                          class="p-column-filter"
                          style="width: 95%; height:25px;"
                        />
                        </th>
                        <th style="text-align:center;">
                          <input
                          pInputText
                          type="text"
                          (input)="
                            dt.filter(
                              $event.target.value,
                              'startDate',
                              'contains'
                            )
                          "
                          placeholder="Current Start date"
                          class="p-column-filter"
                          style="width: 95%; height:25px;"
                        />
                        </th>
                        <th style="text-align:center;">
                          <input
                          pInputText
                          type="text"
                          (input)="
                            dt.filter(
                              $event.target.value,
                              'dueDate',
                              'contains'
                            )
                          "
                          placeholder="Current End date"
                          class="p-column-filter"
                          style="width: 95%; height:25px;"
                        />
                        </th>
                        <th>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              dt.filter(
                                $event.target.value,
                                'overlapDays',
                                'contains'
                              )
                            "
                            placeholder="Overlap Days"
                            class="p-column-filter"
                            style="width: 95%; height:25px;"
                          />
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-task>
                      <tr class="p-selectable-row">
                        <td style="padding-left:15px;text-align:left;width:130px;" >
                          <span class="data-style" >{{ task.workTaskId }}</span>
                        </td>
                        <td style="padding-left:15px;text-align:left;width:40%;" >
                          <span class="data-style">{{ task.taskName }}</span>
                        </td>
                        <td style="text-align: center;width: 90px;">
                            <input (change)="CycleTimeChange(task.taskId,$event)" 
                                (keypress)="numberOnly($event)" type="text" maxlength="3" style="width: 50px;" [(ngModel)]="task.duration" pInputText/>
                        </td>
                        <td style="text-align: center;width: 120px;">
                          <!-- <span *ngIf="task.isWeekend === true">Yes</span><span *ngIf="task.isWeekend === false">No</span> -->
                          <p-checkbox binary="task.isWeekend" [(ngModel)]="task.isWeekend" 
                          [value]="task.isWeekend" (click)="IsWeekedRequired(task.taskId,$event)"></p-checkbox>
                        </td>
                        <td style="text-align:center;width: 150px;">
                            <span *ngIf="task.plannedStartDate == null || task.plannedStartDate == '01/01/1970'">--</span>
                            <span *ngIf="task.plannedStartDate != null && task.plannedStartDate != '01/01/1970'" >
                                {{ task.plannedStartDate}}</span>
                          </td>
                        <td style="text-align:center;width: 150px;">
                          <span *ngIf="task.plannedEndDate == null || task.plannedEndDate == '01/01/1970'">--</span>
                          <span *ngIf="task.plannedEndDate != null && task.plannedEndDate != '01/01/1970'" >
                              {{ task.plannedEndDate}}</span>
                        </td>
                        <td style="text-align:center;width: 150px;">
                          <span *ngIf="task.startDate == null || task.startDate == '01/01/1970' || task.startDate == 'Invalid Date'">--</span>
                          <span *ngIf="task.startDate != null && task.startDate != '01/01/1970' && task.startDate != 'Invalid Date'">{{ task.startDate}}</span>
                        </td>
                        <td style="text-align:center;width: 150px;">
                          <span *ngIf="task.dueDate == null || task.dueDate == '01/01/1970' || task.dueDate == 'Invalid Date'">--</span>
                          <span *ngIf="task.dueDate != null && task.dueDate != '01/01/1970' && task.dueDate != 'Invalid Date'">
                          <span [style.color]="task.bold ? 'red' : ''" [style.font-weight]="task.bold ? 'bold' : ''">{{ task.dueDate }}</span></span>
                        </td>
                        <td style="text-align:center;width: 120px;" >
                          <span class="data-style">{{ task.overlapDays }}</span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="9">No milestone are available to display</td>
                      </tr>
                    </ng-template>
                  </p-table>
            </div>
            <div class="p-col-12 p-md-10" style="vertical-align: center;text-align: right;">
              <small class="p-invalid" style="float: right;margin-top: 7px; font-size: 1rem;">{{responseError}}</small>
            </div>
            <div class="p-col-12 p-md-2">
              <button [disabled]="userFeature || tasksListsByProject?.length == 0" pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Update"
              class="p-button-success" (click)="Getdates(true)"
              style="width:120px; float:right; margin-left:10px;"></button>
          </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%; background-color: #fff;opacity: 0.2; z-index: 9999;" 
  *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem"></i>
  </div>


<p-dialog
header="OverLap / Cycle Time"
[(visible)]="Overlapinfo"
modal="modal"
showEffect="fade"
[style]="{ 'width': '60%' }"
>
<hr />

<div style="padding: 20px;" *ngIf="projectInformation != undefined">
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-xl-12">
      <div
        class="card no-gutter widget-overview-box widget-overview-box-1"
        style="min-height: 137px"
      >
        <ul class="widget-image-list">
          <li>
            <span style="font-weight: bold;" >Layout Overlap Design Days:</span>
            <span>{{projectInformation.layoutOverlap}}</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Proportionate Cycle Time - Layout Engineers:</span>
            <span>{{ projectInformation.layoutCycleTime }} %</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Number of Assigned Layout Engineeres:</span>
            <span>{{ projectInformation.layoutAssignCount }}</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Proportionate Cycle Time - Designers:</span>
            <span>{{ projectInformation.designCycleTime }} %</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Number of Assigned Designers:</span>
            <span>{{ projectInformation.designAssignCount }}</span>
          </li>
          
        </ul>
      </div>
    </div>
  </div>
</div>
</p-dialog>

<p-dialog
header="Description"
[(visible)]="projectdescriptiondisplay"
modal="modal"
showEffect="fade"
[style]="{ 'width': '40%' }"
>
<hr />

<div style="padding: 20px;" *ngIf="projectInformation != undefined">
  {{projectInformation.revision}}
</div>
</p-dialog>