import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import { DatePipe} from '@angular/common'
import {AppCodeModule} from './app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightmenuComponent} from './app.rightmenu.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppSearchComponent} from './app.search.component';
import {AppFooterComponent} from './app.footer.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';

import {CountryService} from './demo/service/countryservice';
import {EventService} from './demo/service/eventservice';
import {ProductService} from './demo/service/productservice';
import {BreadcrumbService} from './app.breadcrumb.service';
import {MenuService} from './app.menu.service';
import { ProjectlistComponent } from './demo/projectlist/projectlist.component';
import { PhasetreeComponent } from './demo/phasetree/phasetree.component';
import { ProjectdashboardComponent } from './demo/projectdashboard/projectdashboard.component';
import { CreateprojectComponent } from './demo/createproject/createproject.component';
import { AdminComponent } from './demo/admin/admin.component';
import { TasklistComponent } from './demo/tasklist/tasklist.component';
import { ReviewsComponent } from './demo/reviews/reviews.component';
import { ReportsComponent } from './demo/reports/reports.component';
import{CreatetaskComponent} from './demo/createtask/createtask.component';
import { CreatereportComponent } from './demo/createreport/createreport.component';
import { ViewreportComponent } from './demo/viewreport/viewreport.component';
import {KeysPipe} from './demo/domain/KeysPipe';
import { ManagespaceComponent } from './demo/admin/managespace/managespace.component'
import {KeysPipeAll} from './demo/domain/KeysPipe';
import { CustomfieldsComponent } from './demo/customfields/customfields.component';
import { ManageprojectdescriptionComponent } from './demo/admin/manageprojectdescription/manageprojectdescription.component';
import { ProposedprojectscheduleComponent } from './demo/proposedprojectschedule/proposedprojectschedule.component';
import { MonitoringreviewsComponent } from './demo/admin/monitoringreviews/monitoringreviews.component';
import { AllreportComponent } from './demo/allreport/allreport.component';
import { ActualdateComponent } from './demo/admin/actualdate/actualdate.component';
import { OverlayComponent } from './demo/admin/overlay/overlay.component';
import { ProportionaltimeComponent } from './demo/admin/proportionaltime/proportionaltime.component';
import { TaskattributesComponent } from './demo/admin/taskattributes/taskattributes.component';
import { ReportComponent } from './demo/admin/report/report.component';
import { EntrypointComponent } from './demo/entrypoint/entrypoint.component';
import { ProjectsbymilestoneComponent } from './demo/projectsbymilestone/projectsbymilestone.component';
import { HelpguideComponent } from './demo/helpguide/helpguide.component';
import { AddreviewComponent } from './demo/addreview/addreview.component';
import { P2summaryreportComponent } from './demo/p2summaryreport/p2summaryreport.component';
import { P2summaryComponent } from './demo/p2summary/p2summary.component';
import { CycletimeComponent } from './demo/cycletime/cycletime.component';
import { OverduemilestoneComponent } from './demo/overduemilestone/overduemilestone.component';
import { ProjectsumarryComponent } from './demo/projectsumarry/projectsumarry.component';
import { TokenInterceptor } from "./token.interceptor";
import { ManagedatesComponent } from './demo/managedates/managedates.component';
import { BnNgIdleService } from './bn-ng-idle.service';
import { ManageassigneeComponent } from './demo/manageassignee/manageassignee.component';
@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppRightmenuComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppSearchComponent,
        AppFooterComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        ProjectlistComponent,
        PhasetreeComponent,
        ProjectdashboardComponent,
        CreateprojectComponent,
        AdminComponent,
        TasklistComponent,
        ReviewsComponent,
        ReportsComponent,
        CreatetaskComponent,
        CreatereportComponent,
        ViewreportComponent,
        KeysPipe,
        KeysPipeAll,
        ManagespaceComponent,
        CustomfieldsComponent,
        ManageprojectdescriptionComponent,
        ProposedprojectscheduleComponent,
        MonitoringreviewsComponent,
        AllreportComponent,
        ActualdateComponent,
        OverlayComponent,
        ProportionaltimeComponent,
        TaskattributesComponent,
        ReportComponent,
        EntrypointComponent,
        ProjectsbymilestoneComponent,
        HelpguideComponent,
        AddreviewComponent,
        P2summaryreportComponent,
        P2summaryComponent,
        CycletimeComponent,
        OverduemilestoneComponent,
        ProjectsumarryComponent,
        ManagedatesComponent,
        ManageassigneeComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        CountryService, EventService,
        ProductService, MenuService, BreadcrumbService,DatePipe,BnNgIdleService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
