import { Component, OnInit } from '@angular/core';
import { Product } from '../domain/product';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { FacadeService } from 'src/app/facade/facade.service';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from "src/injector";
import { FormGroup } from '@angular/forms';
import { CustomReportsList, OptionItems, OptionsData } from '../domain/CustomReports';
import * as XLSX from 'xlsx'; 
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-reviews',
    templateUrl: './reviews.component.html',
    styles: [`:host ::ng-deep .p-multiselect {
		min-width: 15rem;
	}

	:host ::ng-deep .multiselect-custom-virtual-scroll .p-multiselect {
		min-width: 20rem;
	}

	:host ::ng-deep .multiselect-custom .p-multiselect-label {
		padding-top: .25rem;
		padding-bottom: .25rem;

	}


	:host ::ng-deep .multiselect-custom .country-item.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}

	:host ::ng-deep .multiselect-custom .country-item.country-item-value img.flag {
		width: 17px;
	}

	:host ::ng-deep .multiselect-custom .country-item {
		display: flex;
		align-items: center;
	}

	:host ::ng-deep .multiselect-custom .country-item img.flag {
		width: 18px;
		margin-right: .5rem;
	}

	:host ::ng-deep .multiselect-custom .country-placeholder {
		padding: 0.25rem;
	}

	:host ::ng-deep .p-colorpicker {
		width: 2.5em
	}
    `]
})

export class ReviewsComponent implements OnInit {
    products: Product[];
    display: boolean;
    reportPopupDisplay: boolean = false;
    editDisplay: boolean;
    selectedMulti: any;
    countries: any[];
    projecName: any;
    projectManagerList: [];
    projectId: string;
    submitted: boolean = false;
    responseError: string;
    Customreport: FormGroup;
    showLoading: boolean = false;
  
    optionsTaskNames: OptionItems[];
    optionsCustomAttributes: OptionItems[];
    optionsTaskAttributes: OptionItems[];
    CustomReportsLists: CustomReportsList[];
    //Edit popupdata
    editReportName: string;
    editoptionsCustomAttributes: OptionItems[];
    editoptionsTaskAttributes: OptionItems[];
    editoptionsTaskNames: OptionItems[];
    editTaskName: string;
    editCustomReportId: string;
    rptActionType: string;
    userFeature:boolean=false;
    constructor(
        private breadcrumbService: BreadcrumbService,
        private facadeService: FacadeService, 
        private datePipe: DatePipe,
        @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard', routerLink: ['/'] }
        ]);
    }
  
    ngOnInit() {
        const userFeatures = sessionStorage.getItem('userFeatures');
        if (userFeatures) {
          var feature = JSON.parse(userFeatures);
          if(feature.length>0){
            var featureExist = feature.find(m=>m.code=="PM_DSBL_View");
            if(featureExist!=null && featureExist != undefined)
              this.userFeature = true;
          }
        }
        this.GetReports();
    }
  
    GetReports() {
        this.showLoading = true;
        this.facadeService.GetReports().subscribe(data => {
            this.showLoading = false;
            data.returnObject.forEach(e => {
                if(e.createdDate != '' || e.createdDate != null){
                    // let createdDate = new Date(e.createdDate) 
                    // e.createdDate = createdDate.toLocaleDateString()
                    e.createdDate = this.datePipe.transform( e.createdDate, 'MM/dd/yyyy');
                }
            });
            this.CustomReportsLists = data.returnObject;
        });
    }
    exportExcel() {
        /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "Monitoring Reviews.xlsx");
    }
    delete_row (ws, row_index){
        let range = XLSX.utils.decode_range(ws["!ref"])
        for(var R = row_index; R < range.e.r; ++R){
            for(var C = range.s.c; C <= range.e.c; ++C){
              ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
              if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
                ws[this.ec(R+1, C)].v  = "";
            }
        }
        range.e.r--
        delete (ws['D1'])
        ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
      }
      ec(r, c) {
        return XLSX.utils.encode_cell({r:r,c:c})
      }
    print() {
        window.print();
    }
  }
  
  