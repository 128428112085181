import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-p2summaryreport',
  templateUrl: './p2summaryreport.component.html',
  styleUrls: ['./p2summaryreport.component.scss']
})
export class P2summaryreportComponent implements OnInit {
    cols:any;
    cols1:any;
    cols2:any;
    cols3:any;
    showLoading:boolean;
    testtemplateData:any;  
    constructor() { }
  
    ngOnInit(): void {
  
      this.cols = [
        { field: 'projectdescription', header: 'Project Description', dynamicPlaceHolder: 'Search' },
        { field: 'project', header: 'Project', dynamicPlaceHolder: 'Search' },
        { field: 'phase', header: 'Phase', dynamicPlaceHolder: 'Search' },
        { field: 'diecode', header: 'Die Code', dynamicPlaceHolder: 'Search' },
        { field: 'mfgpart', header: 'Mfg Part', dynamicPlaceHolder: 'Search' },
        { field: 'productfunction', header: 'Product Function', dynamicPlaceHolder: 'Search' },
        { field: 'package', header: 'Package', dynamicPlaceHolder: 'Search' },
        { field: 'marketinglead', header: 'Marketing Lead', dynamicPlaceHolder: 'Search' },
        { field: 'productengineer', header: 'Product Engineer', dynamicPlaceHolder: 'Search' },
        { field: 'selectattribute', header: 'Select Attribute', dynamicPlaceHolder: 'Search' },
      ];
      this.cols1 = [
        { field: 'tapeoutdate', header: 'Tape-out Date', dynamicPlaceHolder: 'Search' },
        { field: 'tapeoutpassdate', header: 'Tape-out Pass Date', dynamicPlaceHolder: 'Search' },
        { field: 'fabout', header: 'Fab/out', dynamicPlaceHolder: 'Search' },
        { field: 'cob', header: 'COB', dynamicPlaceHolder: 'Search' },
        { field: 'assemblyout', header: 'Assembly Out', dynamicPlaceHolder: 'Search' },
        { field: 'untestedshipout', header: 'Untested Ship Out', dynamicPlaceHolder: 'Search' },
        { field: 'ftout', header: 'FT Out', dynamicPlaceHolder: 'Search' },
        { field: 'testedshipout', header: 'Tested Ship Out', dynamicPlaceHolder: 'Search' },
        { field: 'rttestpgm', header: 'RT Test Pgm', dynamicPlaceHolder: 'Search' },
        { field: 'esdlu', header: 'ESD/LU', dynamicPlaceHolder: 'Search' },
        { field: 'guntest', header: 'Gun Test', dynamicPlaceHolder: 'Search' },
        { field: 'htol500hrs', header: 'HTOL 500hrs', dynamicPlaceHolder: 'Search' },        
        { field: 'pkgqualtest', header: 'Pkg Qual Test', dynamicPlaceHolder: 'Search' },
        { field: 'appsroadtestqual', header: 'Apps Road Test Qual', dynamicPlaceHolder: 'Search' },
        { field: 'rtp', header: 'RTP', dynamicPlaceHolder: 'Search' },
        { field: 'pcn', header: 'PCN', dynamicPlaceHolder: 'Search' }  
      ];
      this.cols2 = [
        { field: 'workweek', header: 'Work Week', dynamicPlaceHolder: 'Search' },
        { field: 'remarks', header: 'Remarks', dynamicPlaceHolder: 'Search' },
        { field: 'addedby', header: 'Added By', dynamicPlaceHolder: 'Search' },
        { field: 'addeddate', header: 'Added Date', dynamicPlaceHolder: 'Search' }  
      ];
      this.cols3 = [
        { field: 'workweek', header: 'Work Week', dynamicPlaceHolder: 'Search' },
        { field: 'meetingnotes', header: 'Meeting Notes', dynamicPlaceHolder: 'Search' },
        { field: 'addedby', header: 'Added By', dynamicPlaceHolder: 'Search' },
        { field: 'addeddate', header: 'Added Date', dynamicPlaceHolder: 'Search' }  
      ];
    }
  
    exportExcel() {
      /* table id is passed over here */   
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      this.delete_row(wb.Sheets.Sheet1, 1);
      /* save to file */
      XLSX.writeFile(wb, "Test Template.xlsx")
  }
  delete_row (ws, row_index){
   let range = XLSX.utils.decode_range(ws["!ref"])
   for(var R = row_index; R < range.e.r; ++R){
       for(var C = range.s.c; C <= range.e.c; ++C){
         ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
         if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
           ws[this.ec(R+1, C)].v  = "";
       }
   }
   range.e.r--
   delete (ws['H1'])
   ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
   return XLSX.utils.encode_cell({r:r,c:c})
  }
   print() {
     window.print();
  }
  
  }
