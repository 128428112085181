import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-actualdate',
  templateUrl: './actualdate.component.html',
  styleUrls: ['./actualdate.component.scss']
})
export class ActualdateComponent implements OnInit {
  showLoading:boolean;
  userGuid = sessionStorage.getItem('userGuid');
  projects:any=[];
  selectedProject:any;
  projectDetails:any;
  responseError:any;
  actualStartDate:any;
  productLine:any;
  projecName:any;
  selectedProjectmanager:any;
  startDate:any;
  endDate:any;
  revisedStartDate:any;
  revisedEndDate:any;
  actualEndDate:any;
  projectStatus:any;
  projectDescMdl:any;
  todaydate=new Date();
  actualMinimumDate = new Date();
  actualEndMinimumDate= new Date();
  ShowProjectDetails:boolean;
  dieCode:any;
  mfgPart:any;
  packageOption:any;
  cpTrim:any;
  packageType:any;
  qualType:any;
  filteredProdType: any[];
  DisableEnter :boolean = true;
  userFeature:boolean = false;
  ActiveProjects:boolean = false;
  DisableUpdate:boolean = false;
  constructor(private facadeService: FacadeService,private datePipe: DatePipe) { }

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PM_DSBLASD");
        if(featureExist!=null && featureExist != undefined)
          this.userFeature = true;

        featureExist = feature.find(m=>m.code=="PM_GAP");
        if(featureExist!=null && featureExist != undefined)
          this.ActiveProjects = true;

        featureExist = feature.find(m=>m.code=="PM_DSBLU");
        if(featureExist!=null && featureExist != undefined)
          this.DisableUpdate = true;
      }
    }
    this.GetManagerProjects();
  }

  GetManagerProjects(){
    this.showLoading = true;
    if(this.ActiveProjects)
    {
      this.facadeService.GetActiveProjects().subscribe(
      (res) => {
          this.showLoading = false;
          this.projects = res.returnObject;  
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      });
    }
    else
    {
      this.facadeService.GetManagerProjects(this.userGuid).subscribe(
      (res) => {
          this.showLoading = false;
          this.projects = res.returnObject;  
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      });
    }
}
SelectProject(){
  this.GetProjectByID();
}
GetProjectByID(){
  this.showLoading = true;
  this.facadeService.GetProjectByID(this.selectedProject.projectId).subscribe(
      (res) => {
          this.showLoading = false;
          this.ShowProjectDetails=true;
          this.projectDetails = res.returnObject;  
          this.productLine=this.projectDetails.productLine;
          this.projecName=this.projectDetails.projectName;
         
          this.projectStatus=this.projectDetails.projectStatus;

          this.startDate = new Date(res.returnObject.projectStartDate);
          this.endDate = new Date(res.returnObject.targetDate);
          this.revisedStartDate = new Date(res.returnObject.revisedStartDate);
          this.revisedEndDate = new Date(res.returnObject.revisedEndDate);
          this.actualStartDate = res.returnObject.actualStartDate == null ? null:new Date(res.returnObject.actualStartDate);
          this.actualEndDate = res.returnObject.actualEndDate == null ? null:new Date(res.returnObject.actualEndDate);
          this.dieCode=res.returnObject.dieCode;
          this.mfgPart=res.returnObject.mfgPart;
          this.packageOption=res.returnObject.packageOption;
          this.cpTrim=res.returnObject.cpTrim;
          this.packageType=res.returnObject.packageType;
          this.qualType=res.returnObject.qualType;
          this.projectDescMdl=res.returnObject.projectDesciption;
          this.selectedProjectmanager=res.returnObject.owner;

          this.DisableEnter = true;
          if(res.returnObject.actualStartDate==null|| res.returnObject.actualStartDate==""||res.returnObject.actualStartDate==undefined )
            this.DisableEnter = false;
                    

          // this.getProjectDescription(this.projectDetails.projectDescriptionId);
          // this.GetProjectManager(this.projectDetails.projectManagerId);
         
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      });
}

filterproductListChange(event) {
  let filtered : any[] = [];
  let query = event.query;
  for(let i = 0; i < this.projects.length; i++) {
      let product= this.projects[i];
      if (product.projectName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(product);
      }
  }    
  this.filteredProdType = filtered;
}

UpdateProjectActualStartDate(){
  this.responseError="";
  if(this.actualStartDate==null||this.actualStartDate==""||this.actualStartDate==undefined){
    this.responseError="Please select Actual Start Date";
  }
  else if(this.actualStartDate > new Date){
    this.responseError="Actual Start Date should be less then or equal to today's date";
  }
  else{
    this.showLoading = true;
    var changedDate = this.datePipe.transform(this.actualStartDate, "MM/dd/yyyy");
    this.facadeService.UpdateProjectActualStartDate(this.selectedProject.projectId,changedDate).subscribe(
        (res) => {
            this.showLoading = false;
             if(res.returnCode==0){
                location.reload();
             }
             else{
                  this.responseError=res.returnMessage;
             }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        });
  }
}
getProjectDescription(projectDescriptionId){
  this.showLoading = true;
  this.facadeService.getProjectDescription().subscribe(
      (res) => {
          this.showLoading = false;
          if(projectDescriptionId != "0") {
            var ProjManager = res.returnObject.find(m=>m.projectDescriptionId==projectDescriptionId);
            this.projectDescMdl=ProjManager.projectDescriptionName;


          } 
      });
}

GetProjectManager(selectedProjectmanagerId) {
  this.facadeService.getProjectManagerUsers()
      .subscribe((managers) => {
          this.showLoading = false;
          if(selectedProjectmanagerId != undefined) {
              var ProjManager = managers.returnObject.find(m=>m.userGuid.toString().toUpperCase()==selectedProjectmanagerId.toString().toUpperCase());
              this.selectedProjectmanager=ProjManager.firstName+" "+ProjManager.lastName;
          }
      });
}
}
