import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { FacadeService } from '../../facade/facade.service';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from "src/injector";
import * as XLSX from 'xlsx';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';
@Component({
    selector: 'app-tasklist',
    templateUrl: './tasklist.component.html',
    styleUrls: ['./tasklist.component.scss']
})

export class TasklistComponent implements OnInit {
    phases: any;
    taskListData: any=[];
    taskListDataN: any=[];
    orderWeek: any;
    selectedOrderWeek: any;
    showLoading: boolean = false;
    Count:number = 0;
    userId = sessionStorage.getItem("userGuid");
    ProductCountFilter: any="";
    selectedfilter ="startsWith";
    constructor(private breadcrumbService: BreadcrumbService,
        private facadeService: FacadeService,private printService:PrintcommonService,
        private datePipe: DatePipe,
        @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard', routerLink: ['/'] }
        ]);
    }

    ngOnInit() {
        sessionStorage.projectName = "";
        sessionStorage.phaseName = "";
        sessionStorage.workTaskId = "";
        sessionStorage.taskSeq = "";
        sessionStorage.taskName = "";
        sessionStorage.predecessorName = "";
        sessionStorage.planedStartDate = "";
        sessionStorage.plannedEndDate = "";
        sessionStorage.taskStatusName = "";
        
        this.getTasksByUserID(this.userId);
        this.getTasksByUserID1(this.userId);
    }

    getTasksByUserID(userId) {
        this.showLoading = true;
        this.taskListData=[];
        this.facadeService.getTasksByUserID1(userId,'N').subscribe(
            (sucess) => {
              this.taskListData = sucess.returnObject;
              if(this.taskListData != null && this.taskListData != undefined)
              {
                // this.taskListData.forEach(e => {
                //     if(e.StartDate != '' || e.StartDate != null){
                //         let StartDate = new Date(e.StartDate+'Z');
                //         e.StartDate = StartDate.toLocaleDateString();
                //         e.planedStartDate = this.datePipe.transform(e.planedStartDate, 'MM/dd/yyyy');
                //     }
                //     if(e.dueDate != '' || e.dueDate != null){
                //         let dueDate = new Date(e.dueDate+'Z'); 
                //         e.dueDate = dueDate.toLocaleDateString();
                //         e.plannedEndDate = this.datePipe.transform(e.plannedEndDate, 'MM/dd/yyyy');
                //     }
                // });
                //this.CountM();
              }
              this.showLoading = false;
            }
        )
    }

    getTasksByUserID1(userId) {
      this.showLoading = true;
      this.taskListData=[];
      this.facadeService.getTasksByUserID1(userId,'Y').subscribe(
          (sucess) => {
            this.taskListDataN = sucess.returnObject;
            if(this.taskListDataN != null && this.taskListDataN != undefined)
            {
              // this.taskListData.forEach(e => {
              //     if(e.StartDate != '' || e.StartDate != null){
              //         let StartDate = new Date(e.StartDate+'Z');
              //         e.StartDate = StartDate.toLocaleDateString();
              //         e.planedStartDate = this.datePipe.transform(e.planedStartDate, 'MM/dd/yyyy');
              //     }
              //     if(e.dueDate != '' || e.dueDate != null){
              //         let dueDate = new Date(e.dueDate+'Z'); 
              //         e.dueDate = dueDate.toLocaleDateString();
              //         e.plannedEndDate = this.datePipe.transform(e.plannedEndDate, 'MM/dd/yyyy');
              //     }
              // });
              this.CountM();
            }
            this.showLoading = false;
          }
      )
  }
  CountM()
    {
      if(this.taskListDataN != undefined && this.taskListDataN != null)
      {
        var temp = this.taskListDataN;
        // if(sessionStorage.projectName != "")
        //   temp = temp.filter(x=> x.projectName.toLowerCase().includes(sessionStorage.projectName.toLowerCase()));
        // if(sessionStorage.phaseName != "")
        //   temp = temp.filter(x=> x.phaseName.toLowerCase().includes(sessionStorage.phaseName.toLowerCase()));
        // if(sessionStorage.workTaskId != "")
        //   temp = temp.filter(x=> x.workTaskId.toLowerCase().includes(sessionStorage.workTaskId.toLowerCase()));
        // if(sessionStorage.taskSeq != "")
        //   temp = temp.filter(x=> x.taskSeq.toLowerCase().includes(sessionStorage.taskSeq.toLowerCase()));
        // if(sessionStorage.taskName != "")
        //   temp = temp.filter(x=> x.taskName.toLowerCase().includes(sessionStorage.taskName.toLowerCase()));
        // if(sessionStorage.predecessorName != "")
        //   temp = temp.filter(x=> x.predecessorName.toLowerCase().includes(sessionStorage.predecessorName.toLowerCase()));
        // if(sessionStorage.planedStartDate != "")
        //   temp = temp.filter(x=> x.planedStartDate.toLowerCase().includes(sessionStorage.planedStartDate.toLowerCase()));
        // if(sessionStorage.plannedEndDate != "")
        //   temp = temp.filter(x=> x.plannedEndDate.toLowerCase().includes(sessionStorage.plannedEndDate.toLowerCase()));
        // if(sessionStorage.taskStatusName != "")
        //   temp = temp.filter(x=> x.taskStatusName.toLowerCase().includes(sessionStorage.taskStatusName.toLowerCase()));
        this.Count = temp.length;
      }
      else
        this.Count = 0;
    }

    onFilter(event, dt) {
      if (event.filteredValue.length == this.taskListDataN.length) {
        this.ProductCountFilter = "";
      }
      else if (event.filteredValue.length != this.taskListDataN.length && event.filteredValue.length==0) {
        this.ProductCountFilter = "";
      }
      else {
        this.ProductCountFilter = event.filteredValue.length+" projects found.";
      }
    }
    
    test(val,field,dt)
    {
      if(field == "projectName")
        sessionStorage.projectName = val;
      else if(field == "phaseName")
        sessionStorage.phaseName = val;
      else if(field == "workTaskId")
        sessionStorage.workTaskId = val;
      else if(field == "taskSeq")
        sessionStorage.taskSeq = val;
      else if(field == "taskName")
        sessionStorage.taskName = val;
      else if(field == "predecessorName")
        sessionStorage.predecessorName = val;
      else if(field == "planedStartDate")
        sessionStorage.planedStartDate = val;
      else if(field == "plannedEndDate")
        sessionStorage.plannedEndDate = val;
      else if(field == "taskStatusName")
        sessionStorage.taskStatusName = val;
      if(this.selectedfilter=="startsWith")
        dt.filter(val, field, 'startsWith');
      else
        dt.filter(val, field, 'contains');      
    
      //dt = dt.filter(val,field, 'startsWith');
      //this.CountM();
    }

    exportExcel() {
      //let element = document.getElementById('excel-table'); 
      var dte = [];
      this.taskListDataN.map(d => (
        {
          "Project": d.projectName,	
          "Phase": d.phaseName,	
          "Milestone Id": d.workTaskId,	
          "Seq": d.taskSeq,	
          "Milestone": d.taskName,	
          "Predecessor": d.predecessor,	
          "Start Date": d.planedStartDate,	
          "End Date": d.plannedEndDate,	
          "Status": d.taskStatusName
        }
      )).forEach((d: any) => {
        dte.push(d);
      });
    
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dte);
      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [{ wpx: 180 }, { wpx: 100 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 130 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 120 }, { wpx: 120 }, { wpx: 120 }];
      //this.delete_row(wb.Sheets.Sheet1, 1);
      / save to file /
      XLSX.writeFile(wb, "Milestone Summary.xlsx");
    }
        
    exportExcel1() {
         /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     ws['!cols'] = [{ wpx : 300 },{ wpx : 80 },{ wpx : 100 },{ wpx : 50 },{ wpx : 250 },{ wpx : 300 },{ wpx : 90 },{ wpx : 90 },{ wpx : 90 }];
     /* save to file */
     XLSX.writeFile(wb, "Milestone Summary.xlsx");
    }
    
    delete_row (ws, row_index){
        let range = XLSX.utils.decode_range(ws["!ref"])
        for(var R = row_index; R < range.e.r; ++R){
            for(var C = range.s.c; C <= range.e.c; ++C){
              ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
              if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
                ws[this.ec(R+1, C)].v  = "";
            }
        }
        range.e.r--
        delete (ws['J1'])
        ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
      }
      ec(r, c) {
        return XLSX.utils.encode_cell({r:r,c:c})
      }
      print() {
        var Content = document.getElementById('invoice-content').innerHTML;
        this.printService.Print(Content,'Milestone Summary','trSearch',true,0);
    }
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;
    
        if (value1 == null && value2 != null)
          result = -1;
        else if (value1 != null && value2 == null)
          result = 1;
        else if (value1 == null && value2 == null)
          result = 0;
        else if (event.field === 'planedStartDate'||event.field === 'plannedEndDate') {
          // Convert the string dates to Date objects for correct sorting
          const date1 = value1 === '--' ? null : new Date(value1);
          const date2 = value2 === '--' ? null : new Date(value2);
    
          // Handle special case: treat "--" as greater or smaller than any date
          if (date1 === null) {
            result = date2 === null ? 0 : -1;
          } else if (date2 === null) {
            result = 1;
          } else {
            result = date1.getTime() - date2.getTime();
          }
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
    
        return (event.order * result);
      });
    }
}