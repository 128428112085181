import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms'
import { FacadeService } from 'src/app/facade/facade.service';
import { CustomFields, Project } from '../domain/project';
import { Space } from '../domain/space';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from "src/injector";

@Component({
    selector: 'app-createproject',
    templateUrl: './createproject.component.html',
    styleUrls: ['./createproject.component.scss'],
    providers: [MessageService]
})

export class CreateprojectComponent implements OnInit {
    customFields: {};
    selectedCustomField: CustomFields[];
    selectedProjectmanager: any;
    selectedProjectmanagerId: any;
    projecName: any;
    selectedSpaceId: string;
    selectedSpace: any;
    startDate: any;
    startDateCanEdit: boolean;
    endDate: any;
    endDateCanEdit: boolean;
    revisedStartDate:any;
    revisedStartDateCanEdit:boolean;
    actualStartDate: any;
    actualStartDateCanEdit: boolean;
    revisedEndDate: any;
    revisedEndDateCanEdit: boolean;
    actualEndDate: any;
    actualEndDateCanEdit: boolean;
    description: any;
    title: any;
    effectiveDate: any;
    getWorkFlowsList: any;
    display: boolean;
    spacedisplay: boolean;
    spaceList: [];
    customFieldList: any;
    createProjectform: FormGroup;
    createSpaceform: FormGroup;
    submitted: boolean;
    projectData: Project;
    projectObj: object;
    userList: [];
    projectManagerList: [];
    projectId: string;
    formType: string;
    minimumDate = new Date();
    endMinimumDate = new Date();
    revisedMinimumDate = new Date();
    revisedEndMinimumDate = new Date();
    actualMinimumDate = new Date();
    actualEndMinimumDate = new Date();
    spaceSubmitted: boolean;
    showLoading = false;
    backLink : string;
    responseError: string;
    projectStatusOptions: any;
    projectDescOptions: any;
    projectStatusMdl: any;
    projectDescMdl: any;
    projectStatus?: number;
    newSpacetxt:string;
    projectDescriptionId: string;
    ProductID:string="";
    changeManagementMessage: string = 'Use Change Management to edit this value';
    userGuid = sessionStorage.getItem('userGuid');
    LoggedInUser:boolean=true;
    productLine:any;
    constructor(
        private breadcrumbService: BreadcrumbService,
        private formBuilder: FormBuilder,
        private facadeService: FacadeService,
        private messageService: MessageService,
        private primengConfig: PrimeNGConfig,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard', routerLink: ['/'] }
        ]);
    }

    ngOnInit() {
        this.formType='Create';
        this.backLink = "/projectlist";
        this.route.paramMap.subscribe(params => {
            this.showLoading = true;
            if(params.get('id') != null) {
                this.formType='Edit';
                this.projectId = params.get('id');
                sessionStorage.FromReport = false;
                this.backLink = "/phasetree/"+this.projectId;
                this.getProjectDetails(params.get('id'));
                //this.GetAllCustomField(params.get('id'));
            }
            else{
                //this.GetAllCustomField('');
                this.GetAllSpace();        
                this.GetProjectManager();
                this.getStatus();
                this.getProjectDescription();
            }
        });

        this.primengConfig.ripple = true;
        this.projectData = new Project();
        this.projectData.CustomFields = new Array<CustomFields>()
        this.createProjectform = this.formBuilder.group({
            frmproductLine: new FormControl('', Validators.required),
            ProjectName: new FormControl('', Validators.required),
            ProjectManager: new FormControl('', Validators.required),
            ProjectStartDate: new FormControl('', Validators.required),
            TargetDate: new FormControl('', Validators.required),
            frmRevisedStartDate: new FormControl('', Validators.required),
            frmrevisedEndDate: new FormControl('', Validators.required),
            frmactualStartDate: new FormControl(''),            
            frmactualEndDate: new FormControl(''),
            projectStatusFC: new FormControl('',Validators.required),
            frmprojectDescription: new FormControl('',Validators.required),
            Description: new FormControl(''),
            customFieldValue: new FormControl([])
        });

        this.createSpaceform = this.formBuilder.group({
            newSpace: new FormControl('', Validators.required)
        });
        
    }

    getStatus(){
        this.showLoading = true;
        this.facadeService.GetTaskcreateProjectoptions().subscribe((data) => {
            this.showLoading = false;
            if (data.returnObject.taskStatus) {
                this.projectStatusOptions = data.returnObject.taskStatus;
                if (this.projectStatus != undefined) {
                    // this.projectStatusMdl = data.returnObject.taskStatus.filter(ele => {
                    //     return ele.id == this.projectStatus;
                    // })[0];
                    this.projectStatusMdl = this.projectStatus;
                }
                else {
                    this.projectStatusMdl = "New";
                }
            }
        });
    }

    setEndDate(startDate, endDate, fromdatetext){
        if(fromdatetext == 'planned'){
            if(startDate > endDate)
                this.endDate = new Date(startDate)
            this.endMinimumDate = new Date(startDate);
        }
        else if(fromdatetext == 'revised'){
            if(startDate > endDate)
                this.revisedEndDate = new Date(startDate)
            this.revisedEndMinimumDate = new Date(startDate);
        }
        else if(fromdatetext == 'actual'){
            if(startDate > endDate)
                this.actualEndDate = new Date(startDate)
            this.actualEndMinimumDate = new Date(startDate);
        }
    }

    GetProjectManager() {
        this.facadeService.getProjectManagerUsers()
            .subscribe((managers) => {
                this.showLoading = false;
                this.projectManagerList = managers.returnObject;
                if(this.selectedProjectmanagerId != undefined) {
                    this.selectedProjectmanager = managers.returnObject.filter(element => {
                        return element.userGuid.toString().toUpperCase() === this.selectedProjectmanagerId.toString().toUpperCase();
                      })[0];
                }
                if (managers.returnObject) {
                    managers.returnObject.forEach(data => {
                        data.managerName = data.firstName + " " + data.lastName;
                    });
                }
                
            });
    }

    saveSpace(space) {
        this.responseError = "";
        this.showLoading = true;
        this.spaceSubmitted = true;
        let spaceDTO = new Space();
        spaceDTO.SpaceName = space;
        spaceDTO.CreatedBy = this.facadeService.getloggedInUser().userguid;

        this.facadeService.saveSpace(spaceDTO).subscribe(
            (response) => {
                this.showLoading = false;
                if (response.returnCode == 0) {
                    this.GetAllSpace();
                    //this.showToast("success", "Success", "New space created successfully");
                    this.spacedisplay = false;
                    this.createSpaceform.reset();
                }
                else {
                    this.responseError = response.returnMessage;
                    //this.showToast("error", "Error", "Error Saving Space");
                }
            }
        );
        this.spaceSubmitted = false;
    }

    showSpaceModal(){
        this.newSpacetxt = '';
        this.responseError = '';
        this.spacedisplay = true;
        this.createSpaceform.reset();
    }

    GetAllSpace() {
        this.facadeService.getSpaceList()
            .subscribe((spaces) => {
                this.showLoading = false;
                this.spaceList = spaces.returnObject; 
                if(this.selectedSpaceId != undefined) {
                    this.selectedSpace = spaces.returnObject.find(element => {
                        return element.spaceId === this.selectedSpaceId;
                      });
                }        
            });
    }

    GetAllUsers() {
        this.facadeService.getUserList()
            .subscribe((users) => {
                this.userList = users.returnObject;
            });
    }

    GetAllCustomField(projectId) {
        this.showLoading = true;
        this.facadeService.getCustomFieldList(projectId).subscribe((fields) => {
            if (fields.returnObject) {
                this.showLoading = false;
                fields.returnObject.forEach(data => {
                    if (!data.fieldValue) {
                        data.fieldValue = "";
                    }

                });
            }
            this.customFieldList = fields.returnObject;
        })
    }

    onSpaceSubmit(spaceVal){
        this.spaceSubmitted = true;
        this.createSpaceform.controls.newSpace.setValue(spaceVal);
        if (this.createSpaceform.valid) {            
            const spaceData = this.createSpaceform.controls.newSpace.value;
            this.saveSpace(spaceData);
            this.spaceSubmitted = false;
        } 
    }

    onSubmit(obj) {
        this.submitted = true;
        if (this.createProjectform.valid) {
            const projectFormData = this.createProjectform.value;
            this.CreateProject(projectFormData, this.customFieldList);
            this.submitted = false;
        } 
    }

    CreateProject(projectFormData: any, customFieldList: any) {
        this.responseError = "";
        this.projectData.CustomFields = new Array<CustomFields>()
        //Send Project ID for Edit
        this.projectData.ProjectId = this.projectId;
        this.projectData.SpaceId = projectFormData.Space.spaceId;
        this.projectData.ProjectName = projectFormData.ProjectName;
        this.projectData.ProjectManagerId = projectFormData.ProjectManager.userGuid;
        this.projectData.ProjectStartDate = this.formType=='Create'?new Date(projectFormData.ProjectStartDate).toDateString():new Date(this.startDate).toDateString();
        this.projectData.TargetDate = this.formType=='Create'?new Date(projectFormData.TargetDate).toDateString():new Date(this.endDate).toDateString();
        this.projectData.RevisedStartDate = this.formType=='Create'?new Date(projectFormData.frmRevisedStartDate).toDateString():new Date(this.revisedStartDate).toDateString();
        this.projectData.ActualStartDate = this.formType=='Create'?new Date(projectFormData.frmactualStartDate).toDateString():new Date(this.actualStartDate).toDateString();
        this.projectData.RevisedEndDate = this.formType=='Create'?new Date(projectFormData.frmrevisedEndDate).toDateString():new Date(this.revisedEndDate).toDateString();
        this.projectData.ActualEndDate = this.formType=='Create'?new Date(projectFormData.frmactualEndDate).toDateString():new Date(this.actualEndDate).toDateString();
        
        this.projectData.Revision = projectFormData.Description;
        this.projectData.ProjectStatus = parseInt(projectFormData.projectStatusFC.id);
        this.projectData.ProjectDescriptionId = projectFormData.frmprojectDescription.projectDescriptionId;
        
        this.projectData.CreatedBy = this.facadeService.getloggedInUser().userguid;
        this.projectData.CreatedDate = new Date();
        this.projectData.ProductID = projectFormData.productId;
        // customFieldList.forEach(element => {
        //     let cf = new CustomFields();
        //     cf.CustomFieldId = element.customFieldId;
        //     cf.Value = element.value;
        //     cf.EntityId = this.projectId;//As of now we dont have entityid(project id) send empty
        //     this.projectData.CustomFields.push(cf);
        // });
        this.showLoading = true;
        this.facadeService.createProject(this.projectData).subscribe(
            (response) => {
                this.showLoading = false;
                if (response.returnCode == 0) {
                    //this.showToast('success', 'Success', "Project Saved Successfully");
                    //this.resetForm();
                    this.router.navigate(['/projectlist']);
                }
                else {
                    this.responseError = response.returnMessage
                    //this.showToast("error", "Error", response.returnMessage);
                }
            });
    }

    showToast(severity, status, message) {
        this.messageService.add({ key: 'tl', severity: severity, summary: status, detail: message });
    }

    resetForm() {
        this.createProjectform.reset();
        this.submitted=false;
        this.customFieldList.forEach(e => { e.value = ''; });
    }

    getProjectDetails(projectId){
        this.showLoading = true;
        this.facadeService.getProjectDetailsById(projectId).subscribe(
            (res) => {
                if(this.formType=='Edit'){
                    //this.createProjectform.controls['ProjectStartDate'].disable();
                    //this.createProjectform.controls['TargetDate'].disable();
                }
                this.projecName = res.returnObject.projectName;
                this.productLine = res.returnObject.productLine;
                if(this.userGuid!=res.returnObject.projectManagerId){
                    this.LoggedInUser=false;
                }
                this.startDate = new Date(res.returnObject.projectStartDate);
                this.startDateCanEdit = res.returnObject.projectStartDateCanEdit;
                if(res.returnObject.projectStartDateCanEdit == false){
                    this.createProjectform.controls['ProjectStartDate'].disable();
                }

                this.endDate = new Date(res.returnObject.targetDate);                
                this.endDateCanEdit = res.returnObject.targetDateCanEdit;
                if(res.returnObject.targetDateCanEdit == false){
                    this.createProjectform.controls['TargetDate'].disable();
                }

                this.revisedStartDate = new Date(res.returnObject.revisedStartDate);
                this.revisedStartDateCanEdit = res.returnObject.revisedStartDateCanEdit;
                if(res.returnObject.revisedStartDateCanEdit == false){
                    this.createProjectform.controls['frmRevisedStartDate'].disable();
                }

                this.revisedEndDate = new Date(res.returnObject.revisedEndDate);
                this.revisedEndDateCanEdit = res.returnObject.revisedEndDateCanEdit;
                if(res.returnObject.revisedEndDateCanEdit == false){
                    this.createProjectform.controls['frmrevisedEndDate'].disable();
                }

                this.actualStartDate = res.returnObject.actualStartDate == null ? null:new Date(res.returnObject.actualStartDate);
                this.actualStartDateCanEdit = res.returnObject.actualStartDateCanEdit;
                if(res.returnObject.actualStartDateCanEdit == false){
                    this.createProjectform.controls['frmactualStartDate'].disable();
                }

                this.actualEndDate = res.returnObject.actualEndDate == null ? null:new Date(res.returnObject.actualEndDate);
                this.actualEndDateCanEdit = res.returnObject.actualEndDateCanEdit;
                if(res.returnObject.actualEndDateCanEdit == false){
                    this.createProjectform.controls['frmactualEndDate'].disable();
                }

                this.description = res.returnObject.revision;
                this.selectedSpaceId = res.returnObject.spaceId
                this.selectedProjectmanagerId = res.returnObject.projectManagerId
                this.projectStatus = res.returnObject.projectStatus;
                this.projectDescriptionId = res.returnObject.projectDescriptionId;
                this.ProductID = res.returnObject.productId;
                this.getStatus();
                this.GetAllSpace();        
                this.GetProjectManager();
                this.getProjectDescription();
            });
    }
    markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
          control.markAsTouched();
    
          if (control.controls) {
            this.markFormGroupTouched(control);
          }
        });
      }
    getProjectDescription(){
        this.showLoading = true;
        this.facadeService.getProjectDescription().subscribe(
            (res) => {
                this.showLoading = false;
                this.projectDescOptions = res.returnObject; 
                if(this.projectDescriptionId != "0") {
                    this.projectDescMdl = res.returnObject.find(element => {
                        return element.projectDescriptionId == this.projectDescriptionId;
                      });
                } 
            });
    }
}
