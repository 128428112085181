<div class="layout-sidebar-right" [ngClass]="{'layout-sidebar-right-active': app.rightMenuActive}"
  (click)="app.rightMenuClick = true">
  <div class="p-col-12 p-md-12 p-lg-12">
    <h5>FactoryPRO</h5>
    <hr>
      <a a (click)="goToLink('http://132.148.242.138/docmgt/#/dashboard')"><button pButton pRipple type="button" label="Document Management" icon="pi pi-file"
      class="p-button-secondary p-mr-2 p-mb-2" style="width:100%"></button></a>

      <a a (click)="goToLink('http://132.148.242.138/prdmgt/#/')"><button pButton pRipple type="button" label="Product Management" icon="pi pi-desktop"
      class="p-button-secondary p-mr-2 p-mb-2" style="width:100%"></button></a>

      <a routerLink="/"><button pButton pRipple type="button" label="Change Management" icon="pi pi-ticket"
      class="p-button-secondary p-mr-2 p-mb-2" style="width:100%"></button></a>

      <a routerLink="/"><button pButton pRipple type="button" label="Issue Tracking" icon="pi pi-money-bill"
      class="p-button-secondary p-mr-2 p-mb-2" style="width:100%"></button></a>
  </div>

</div>