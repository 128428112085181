import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  constructor(private httpService: HttputilityService) { }

  getTimeLines(userGuid, days){
    return this.httpService.Get('PMAPI',`TimeLine/GetTimeLines?userGuid=`+userGuid+"&days="+days);
  }
}
