<div class="p-grid table-demo">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
          <h5 style="color:#495057; padding:15px 0 0 15px;">Manage Milestone Dates</h5>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-4 card-header" style="margin-top: 25px;">
                <label style="text-align: right; float: right;">Project:&nbsp;&nbsp;</label>
                <p-autoComplete [(ngModel)]="selectedProject" [suggestions]="filteredProdType" class="width350"  placeholder="Select Project"
                (completeMethod)="filterproductListChange($event)" field="projectName" [dropdown]="true" (onSelect)="GetProjectByID()">
                <ng-template>
                  <div>{{ filteredProdType.projectName }}</div>
                </ng-template>
              </p-autoComplete>
            </div>
        </div>
        <hr />
        <div class="p-grid p-fluid" *ngIf="productselected">
            <div class="p-col-12">
            <p-accordion>
                <p-accordionTab header="Product Details" [selected]="false">
                    <div class="p-col-12">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-4 p-xl-4">
                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px">
                                <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;" >Project Name:</span>
                                    <span>{{projectInformation?.projectName}}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Product Engineer:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.owner : "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Project Status:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.projectStatusName: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Die Code:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.dieCode: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Mfg Part #:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.mfgPart: "" }}</span>
                                </li>
                                </ul>
                            </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-xl-4">
                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px" >
                                <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;" >Package Name:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.packageOption: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Need CP Trim:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.cpTrim: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Package Type:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.packageType: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Qual Type:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.qualType: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Active Phase:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.currentPhase : "" }}</span>
                                </li>
                                </ul>
                            </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-xl-4">
                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px" >
                                <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;" >Start Date:</span>
                                    <span>{{projectInformation?.projectStartDate|date:"MM/dd/yyyy"}}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >End Date:</span>
                                    <span>{{projectInformation?.targetDate|date:"MM/dd/yyyy"}}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Project Description:</span>
                                    <button  pButton type="button" pTooltip="Project Description" tooltipPosition="right"  class="p-button-primary" (click)="projectdescriptiondisplay = true" 
                                    icon="pi pi-eye" style=" height:25px; width:25px; margin-bottom:-10px; margin-top:-10px;" ></button>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Attributes:</span>
                                    <span *ngIf="projectInformation != undefined">
                                    <button (click)="open(projectInformation.productId,projectInformation.projectId)"  pButton pRipple type="button" icon="pi pi-th-large" pTooltip="Additional Attributes" tooltipPosition="right" 
                                    iconPos="left" class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                                    </span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >OverLap/Cycle Time:</span>
                                    <button pButton type="button" pTooltip="Overlap / Cycle Time Information" tooltipPosition="right" class="p-button-primary"
                                    (click)="Overlapinfo = true" icon="pi pi-key" style=" height:25px; width:25px; margin-bottom:-10px; margin-top:-10px;" ></button>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div></div>
                </p-accordionTab></p-accordion>
           
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-xl-12">
                        <p-table (sortFunction)="customSort($event)" [customSort]="true" #dt
                            [value]="tasksListsByProject" id="excel-table" [(selection)]="selectedtasksListsByProject"
                            dataKey="taskId" styleClass="p-datatable-striped p-datatable-customers"
                            [rowHover]="true" [rows]="10" [paginator]="false" [filterDelay]="0"  >
                            <ng-template pTemplate="header">
                            <tr >
                                <th pSortableColumn="taskSeq" style="text-align:center; width:60px;">Seq<p-sortIcon field="workTaskId"></p-sortIcon></th>
                                <th pSortableColumn="taskName" style="text-align:center;width:180px">Milestone<p-sortIcon field="taskName"></p-sortIcon> </th>
                                <th pSortableColumn="duration" style="text-align:center;width: 60px;">Duration<p-sortIcon field="duration"></p-sortIcon></th>
                                <th pSortableColumn="overlapDays" style="text-align:center;width: 60px;">Overlap Days<p-sortIcon field="overlapDays"></p-sortIcon></th>
                                <th pSortableColumn="isWeekend" style="text-align:center;width: 80px;">Is Weekend <p-sortIcon field="isWeekend"></p-sortIcon></th>
                                <th pSortableColumn="plannedStartDate" style="text-align:center;width: 100px;">
                                    Target Start Date<p-sortIcon field="plannedStartDate"></p-sortIcon> </th>
                                <th pSortableColumn="plannedEndDate" style="text-align:center;width: 125px;">
                                    Target End Date <p-sortIcon field="plannedEndDate"></p-sortIcon> </th>
                                <th pSortableColumn="currentStartDate" style="text-align:center;width: 100px;">
                                    Current Start Date<p-sortIcon field="currentStartDate"></p-sortIcon></th>
                                <th pSortableColumn="currentEndDate" style="text-align:center;width: 125px;">
                                    Current End Date <p-sortIcon field="currentEndDate"></p-sortIcon></th>
                                <th pSortableColumn="actualStartDate" style="text-align:center;width: 125px;">
                                    Actual Start Date<p-sortIcon field="actualStartDate"></p-sortIcon></th>
                                <th pSortableColumn="actualEndDate" style="text-align:center;width: 125px;">
                                    Actual End Date <p-sortIcon field="actualEndDate"></p-sortIcon></th>
                                <th pSortableColumn="predecessor" style="text-align:center;width: 120px;">Predecessor<p-sortIcon field="predecessor"></p-sortIcon></th>
                                <th pSortableColumn="status" style="text-align:center;width: 100px;">Task Status<p-sortIcon field="status"></p-sortIcon></th>
                                <th style="width: 50px;">Override Reason</th>
                            </tr>
                            <tr>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'workTaskId','contains')"
                                    placeholder="Milestone Id" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'taskName','contains')"
                                    placeholder="Milestone" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'duration','contains')"
                                    placeholder="Duration" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'overlapDays','contains')"
                                    placeholder="Overlap Days" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'isWeekend','contains')"
                                    placeholder="IsWeekend" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'plannedStartDate','contains')"
                                    placeholder="Target Start Date" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'plannedEndDate','contains')"
                                    placeholder="Target End Date" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'currentStartDate','contains')"
                                    placeholder="Current Start Date" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'currentEndDate','contains')"
                                    placeholder="Current End Date" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'actualStartDate','contains')"
                                    placeholder="Actual Start Date" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'actualEndDate','contains')"
                                    placeholder="Actual End Date" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'predecessor','contains')"
                                    placeholder="Predecessor" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'status','contains')"
                                    placeholder="Status" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th></th>
                            </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-task>
                            <tr class="p-selectable-row">
                                <td style="text-align:center;width:60px;"><span class="data-style" >{{ task.taskSeq }}</span></td>
                                <td style="padding-left:15px;text-align:left;width:180px;"><span class="data-style">{{ task.taskName }}</span></td>
                                <td style="text-align: center;width: 60px;"><span class="data-style">{{ task.duration }}</span></td>
                                <td style="text-align: center;width: 60px;"><span class="data-style">{{ task.overlapDays }}</span></td>
                                <td style="text-align: center;width: 80px;">
                                    <span *ngIf="task.isWeekend === true">Yes</span><span *ngIf="task.isWeekend === false">No</span>
                                </td>
                                <td style="text-align: center;width: 100px;" [ngStyle]="{'background': task.targetOverride === true ? '#ffe5b4': '','font-weight' : task.targetOverride === true ? 'bold': ''}">
                                    <span *ngIf="task.plannedStartDate != '--' && task.plannedStartDate != null && task.duration != 0">{{task.plannedStartDate | date:'MM/dd/yyyy'}}</span>
                                    <span *ngIf="task.plannedStartDate == '--' || task.plannedStartDate == null || task.duration == 0">--</span>
                                </td>
                                <td style="text-align: center;width: 125px;" [ngStyle]="{'background': task.targetOverride === true ? '#ffe5b4': '','font-weight' : task.targetOverride === true ? 'bold': ''}">
                                    <span *ngIf="task.plannedEndDate != '--' && task.plannedEndDate != null && task.duration != 0 && !TargerLock && task.editable"
                                    [style.color]="task.bold ? 'red' : ''" [style.font-weight]="task.bold ? 'bold' : ''">
                                        <p-calendar [showIcon]="true" placeholder="Target End Date" inputId="plannedEndDate" [(ngModel)]="task.plannedEndDate"
                                        [disabledDays]="task.isWeekend == false ? [0,6] : ''"
                                        (onClickOutside)="Getdates(task.taskId,'TE',task.taskSeq)" (onSelect)="Getdates(task.taskId,'TE',task.taskSeq)"></p-calendar>
                                    </span>
                                    <span *ngIf="task.plannedEndDate != '--' && task.plannedEndDate != null && task.duration != 0 && (TargerLock || !task.editable)"
                                    [style.color]="task.bold ? 'red' : ''" [style.font-weight]="task.bold ? 'bold' : ''">{{task.plannedEndDate | date:'MM/dd/yyyy'}}</span>
                                    <span *ngIf="task.plannedEndDate == '--' || task.plannedEndDate == null || task.duration == 0">--</span>
                                </td>
                                <td style="text-align: center;width: 100px;" [ngStyle]="{'background': task.currentOverride === true ? '#ffe5b4': '','font-weight' : task.currentOverride === true ? 'bold': ''}">
                                    <span *ngIf="task.currentStartDate != '--' && task.currentStartDate != null && task.duration != 0">{{task.currentStartDate | date:'MM/dd/yyyy'}}</span>
                                    <span *ngIf="task.currentStartDate == '--' || task.currentStartDate == null || task.duration == 0">--</span>
                                </td>
                                <td style="text-align: center;width: 125px;" [ngStyle]="{'background': task.currentOverride === true ? '#ffe5b4': '','font-weight' : task.currentOverride === true ? 'bold': ''}">
                                    <span *ngIf="task.currentEndDate != '--' && task.currentEndDate != null && task.duration != 0 && task.editable">
                                        <p-calendar [showIcon]="true" placeholder="Current End Date" inputId="currentEndDate" [(ngModel)]="task.currentEndDate"
                                        [disabledDays]="task.isWeekend == false ? [0,6] : ''"
                                        (onClickOutside)="Getdates(task.taskId,'CE',task.taskSeq)" (onSelect)="Getdates(task.taskId,'CE',task.taskSeq)"></p-calendar>
                                    </span>
                                    <span *ngIf="task.currentEndDate != '--' && task.currentEndDate != null && task.duration != 0 && !task.editable">{{task.currentEndDate | date:'MM/dd/yyyy'}}</span>
                                    <span *ngIf="task.currentEndDate == '--' || task.currentEndDate == null || task.duration == 0">--</span>
                                </td>
                                <td style="text-align: center;width: 125px;" [ngStyle]="{'background': task.actualStartOverride === true ? '#ffe5b4': '','font-weight' : task.actualStartOverride === true ? 'bold': ''}">
                                    <span *ngIf="task.actualStartDate != '--' && task.duration != 0">
                                        <p-calendar [showIcon]="true" placeholder="Actual Start Date" inputId="actualStartDate" [(ngModel)]="task.actualStartDate"
                                        [disabledDays]="task.isWeekend == false ? [0,6] : ''" [disabled]="!task.canEditActual" [minDate]="task.minactual" 
                                        (onClickOutside)="Getdates(task.taskId,'AS',task.taskSeq)" (onSelect)="Getdates(task.taskId,'AS',task.taskSeq)"></p-calendar>
                                    </span>
                                    <span *ngIf="task.actualStartDate == '--' || task.duration == 0">--</span>
                                </td>
                                <td style="text-align: center;width: 125px;" [ngStyle]="{'background': task.actualEndOverride === true ? '#ffe5b4': '','font-weight' : task.actualEndOverride === true ? 'bold': ''}">
                                    <span *ngIf="task.actualEndDate != '--' && task.duration != 0">
                                        <p-calendar [showIcon]="true" placeholder="Actual End Date" inputId="actualEndDate" [(ngModel)]="task.actualEndDate"
                                        [disabledDays]="task.isWeekend == false ? [0,6] : ''" [minDate]="task.actualStartDateVAL"[disabled]="!task.canEditActual"
                                        (onClickOutside)="Getdates(task.taskId,'AE',task.taskSeq)" (onSelect)="Getdates(task.taskId,'AE',task.taskSeq)"></p-calendar>
                                    </span>
                                    <span *ngIf="task.actualEndDate == '--' || task.duration == 0">--</span>
                                </td>
                                <td style="text-align: center;width: 120px;"><span class="data-style">{{ task.predecessor }}</span></td>
                                <td style="text-align: center;width: 100px;"><span class="data-style">{{ task.status }}</span></td>
                                <td style="text-align:center;width: 50px;" >
                                    <a> 
                                        <button pButton type="button" icon="pi pi-plus-circle" pTooltip="Override Reason"
                                        tooltipPosition="left" class="p-button-raised p-button-primary"
                                        (click)="Edit(task)"   [ngClass]="{'p-button-success': task.reason != ''}"
                                        style="width:25px; height:25px;text-align:center;"></button>
                                    </a>
                                </td>
                            </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="9">No milestone are available to display</td>
                            </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="p-col-12 p-md-8" style="vertical-align: center;text-align: right;">
                        <small class="p-invalid" style="float: right;margin-top: 7px; font-size: 1rem;">{{responseError}}</small>
                    </div>
                    <div class="p-col-12 p-md-4">
                        <button pButton pRipple type="button" icon="pi pi-refresh" iconPos="left" label="Reset"
                        class="p-button-secondary" (click)="getTaskListByproject()" style="width:120px; float:right; margin-left:10px;"></button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Update"
                        class="p-button-success" (click)="update()" style="width:120px; float:right; margin-left:10px;"></button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%; background-color: #fff;opacity: 0.2; z-index: 9999;"  *ngIf="showLoading">
<i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem"></i>
</div>
<p-dialog header="OverLap / Cycle Time" [(visible)]="Overlapinfo" modal="modal" showEffect="fade" [style]="{ 'width': '60%' }" >
<hr />
<div style="padding: 20px;" *ngIf="projectInformation != undefined">
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-xl-12">
      <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px" >
        <ul class="widget-image-list">
          <li>
            <span style="font-weight: bold;" >Layout Overlap Design Days:</span>
            <span>{{projectInformation.layoutOverlap}}</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Proportionate Cycle Time - Layout Engineers:</span>
            <span>{{ projectInformation.layoutCycleTime }} %</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Number of Assigned Layout Engineeres:</span>
            <span>{{ projectInformation.layoutAssignCount }}</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Proportionate Cycle Time - Designers:</span>
            <span>{{ projectInformation.designCycleTime }} %</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Number of Assigned Designers:</span>
            <span>{{ projectInformation.designAssignCount }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</p-dialog>

<p-dialog header="Description" [(visible)]="projectdescriptiondisplay" modal="modal" showEffect="fade" [style]="{ 'width': '40%' }" >
<hr />
<div style="padding: 20px;" *ngIf="projectInformation != undefined">{{projectInformation.revision}}</div>
</p-dialog>

<p-dialog header="Add Override Reason" [(visible)]="addDocsDisplay" modal="modal" showEffect="fade" [style]="{ width: '60%' }">
    <hr /><br>
    <div class="p-grid">
      <div class="p-field p-col-12 p-md-1"></div>
      <div class="p-field p-col-12 p-md-1" style="float: right; padding-right: 15px; font-weight: bold;">Milestone:</div>
      <div class="p-field p-col-12 p-md-9"><label> {{ Task}}</label></div>
      <div class="p-field p-col-12 p-md-1"></div>
      <div class="p-field p-col-12 p-md-1"></div>
      <div class="p-field p-col-12 p-md-1" style="margin-top: -20px;float: right; padding-right: 15px; font-weight: bold;">Override Reason:</div>
      <div class="p-field p-col-12 p-md-10" style="margin-top: -20px;">
          <textarea pInputTextarea type="text" rows="3" [(ngModel)]="comments" placeholder="Enter Override Reason" style="width: 70%;"></textarea>
      </div>
      <div class="p-field p-col-12 p-md-1"></div>
      <div class="p-col-12" style="float: right;margin-top: -40px;">
        <button pButton type="button" label="Add" pTooltip="Add" (click)="Add()" tooltipPosition="right" icon="pi pi-plus" style="width:25px;height:25px;"
          class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2" style="width: 100px;height:25px; float: right;margin-left:120px;  margin-top: 20px;"></button>
      </div>
      <div class="p-col-12" style="float: right;margin-top: -20px;"><small class="p-invalid">{{AddError}}</small></div>
    </div>
  </p-dialog>