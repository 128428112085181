<div class="p-grid table-demo">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
          <h5 style="color:#495057; padding:15px 0 0 15px;">Manage Milestone Assignee's</h5>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-4 card-header" style="margin-top: 25px;">
                <label style="text-align: right; float: right;">Project:&nbsp;&nbsp;</label>
                <p-autoComplete [(ngModel)]="selectedProject" [suggestions]="filteredProdType" class="width350"  placeholder="Select Project"
                (completeMethod)="filterproductListChange($event)" field="projectName" [dropdown]="true" (onSelect)="GetProjectByID()">
                <ng-template>
                  <div>{{ filteredProdType.projectName }}</div>
                </ng-template>
              </p-autoComplete>
            </div>
        </div>
        <hr />
        <div class="p-grid p-fluid" *ngIf="productselected">
            <div class="p-col-12">
            <p-accordion>
                <p-accordionTab header="Product Details" [selected]="false">
                    <div class="p-col-12">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-4 p-xl-4">
                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px">
                                <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;" >Project Name:</span>
                                    <span>{{projectInformation?.projectName}}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Product Engineer:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.owner : "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Project Status:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.projectStatusName: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Die Code:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.dieCode: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Mfg Part #:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.mfgPart: "" }}</span>
                                </li>
                                </ul>
                            </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-xl-4">
                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px" >
                                <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;" >Package Name:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.packageOption: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Need CP Trim:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.cpTrim: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Package Type:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.packageType: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Qual Type:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.qualType: "" }}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Active Phase:</span>
                                    <span>{{ projectInformation != undefined ? projectInformation.currentPhase : "" }}</span>
                                </li>
                                </ul>
                            </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-xl-4">
                            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px" >
                                <ul class="widget-image-list">
                                <li>
                                    <span style="font-weight: bold;" >Start Date:</span>
                                    <span>{{projectInformation?.projectStartDate|date:"MM/dd/yyyy"}}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >End Date:</span>
                                    <span>{{projectInformation?.targetDate|date:"MM/dd/yyyy"}}</span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Project Description:</span>
                                    <button  pButton type="button" pTooltip="Project Description" tooltipPosition="right"  class="p-button-primary" (click)="projectdescriptiondisplay = true" 
                                    icon="pi pi-eye" style=" height:25px; width:25px; margin-bottom:-10px; margin-top:-10px;" ></button>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >Attributes:</span>
                                    <span *ngIf="projectInformation != undefined">
                                    <button (click)="open(projectInformation.productId,projectInformation.projectId)"  pButton pRipple type="button" icon="pi pi-th-large" pTooltip="Additional Attributes" tooltipPosition="right" 
                                    iconPos="left" class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                                    </span>
                                </li>
                                <li>
                                    <span style="font-weight: bold;" >OverLap/Cycle Time:</span>
                                    <button pButton type="button" pTooltip="Overlap / Cycle Time Information" tooltipPosition="right" class="p-button-primary"
                                    (click)="Overlapinfo = true" icon="pi pi-key" style=" height:25px; width:25px; margin-bottom:-10px; margin-top:-10px;" ></button>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div></div>
                </p-accordionTab></p-accordion>
           
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-xl-12">
                        <p-table (sortFunction)="customSort($event)" [customSort]="true" #dt
                            [value]="tasksListsByProject" id="excel-table" [(selection)]="selectedtasksListsByProject"
                            dataKey="taskId" styleClass="p-datatable-striped p-datatable-customers"
                            [rowHover]="true" [rows]="10" [paginator]="false" [filterDelay]="0"  >
                            <ng-template pTemplate="header">
                            <tr >
                                <th pSortableColumn="taskSeq" style="text-align:center; width:60px;">Seq<p-sortIcon field="workTaskId"></p-sortIcon></th>
                                <th pSortableColumn="taskName" style="text-align:center;width:180px">Milestone<p-sortIcon field="taskName"></p-sortIcon> </th>
                                <th pSortableColumn="duration" style="text-align:center;width: 70px;">Duration<p-sortIcon field="duration"></p-sortIcon></th>
                                <th pSortableColumn="startDate" style="text-align:center;width: 100px;">
                                    Start Date<p-sortIcon field="startDate"></p-sortIcon> </th>
                                <th pSortableColumn="dueDate" style="text-align:center;width: 100px;">
                                    End Date <p-sortIcon field="dueDate"></p-sortIcon> </th>
                                <th pSortableColumn="predecessor" style="text-align:center;width: 150px;">Predecessor<p-sortIcon field="predecessor"></p-sortIcon></th>
                                <th pSortableColumn="status" style="text-align:center;width: 100px;">Task Status<p-sortIcon field="status"></p-sortIcon></th>
                                <th pSortableColumn="allowMultipleAssignee" style="text-align:center;width: 120px;">
                                    Allow Multiple Assignee<p-sortIcon field="allowMultipleAssignee"></p-sortIcon> </th>
                                <th pSortableColumn="assigneeName" style="text-align:center;width: 150px;">
                                    Assignee<p-sortIcon field="assigneeName"></p-sortIcon> </th>
                                <th style="text-align:center;width: 150px;">Proposed</th>
                            </tr>
                            <tr>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'workTaskId','contains')"
                                    placeholder="Milestone Id" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'taskName','contains')"
                                    placeholder="Milestone" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'duration','contains')"
                                    placeholder="Duration" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'startDate','contains')"
                                    placeholder="Start Date" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'dueDate','contains')"
                                    placeholder="End Date" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'predecessor','contains')"
                                    placeholder="Predecessor" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'status','contains')"
                                    placeholder="Status" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'allowMultipleAssignee','contains')"
                                    placeholder="Allow Multiple Assignee" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th><input pInputText type="text" (input)="dt.filter($event.target.value,'assigneeName','contains')"
                                    placeholder="Assignee" class="p-column-filter" style="width: 95%; height:25px;" /></th>
                                <th></th>
                            </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-task>
                            <tr class="p-selectable-row">
                                <td style="text-align:center;width:60px;"><span class="data-style" >{{ task.taskSeq }}</span></td>
                                <td style="padding-left:15px;text-align:left;width:180px;"><span class="data-style">{{ task.taskName }}</span></td>
                                <td style="text-align: center;width: 70px;"><span class="data-style">{{ task.duration }}</span></td>
                                <td style="text-align: center;width: 100px;">
                                    <span *ngIf="task.startDate != '--' && task.startDate != null && task.duration != 0">{{task.startDate | date:'MM/dd/yyyy'}}</span>
                                    <span *ngIf="task.startDate == '--' || task.startDate == null || task.duration == 0">--</span>
                                </td>
                                <td style="text-align: center;width: 100px;">
                                    <span *ngIf="task.dueDate != '--' && task.dueDate != null && task.duration != 0">{{task.dueDate | date:'MM/dd/yyyy'}}</span>
                                    <span *ngIf="task.dueDate == '--' || task.dueDate == null || task.duration == 0">--</span>
                                </td>
                                <td style="text-align: left;padding-left: 15px; width: 150px;"><span class="data-style">{{ task.predecessor }}</span></td>
                                <td style="text-align: left; padding-left:15px; width: 100px;"><span class="data-style">{{ task.status }}</span></td>
                                <td style="text-align: center;width: 120px;">
                                    <span *ngIf="task.allowMultipleAssignee === true">Yes</span><span *ngIf="task.allowMultipleAssignee === false">No</span>
                                </td>
                                <td style="text-align: left;padding-left: 15px; width: 150px;"><span class="data-style">{{ task.assigneeName }}</span></td>
                                <td style="text-align: center;width: 180px;">
                                    <p-dropdown [options]="task.teamData" [(ngModel)]="task.taskAssignees1" class="text" *ngIf="!task.allowMultipleAssignee"
                                        placeholder="Select Assignee" optionLabel="assignedName" (onChange)="AssigneeChange($event,task.taskId)"
                                        [disabled] = "task.status == 'Completed'" autoWidth="false" [style]="{'width':'95%'}"
                                    ></p-dropdown>
                                    <p-multiSelect [options]="task.teamData" [(ngModel)]="task.taskAssignees" *ngIf="task.allowMultipleAssignee" 
                                    placeholder="Select Assignee" optionLabel="assignedName" class="text"  (onChange)="AssigneeMultiChange($event,task.taskId)"
                                    [disabled] = "task.status == 'Completed'" autoWidth="false" [style]="{'width':'95%'}"
                                    ></p-multiSelect>
                                </td>
                            </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="10">No milestone are available to display</td>
                            </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="p-col-12 p-md-8" style="vertical-align: center;text-align: right;">
                        <small class="p-invalid" style="float: right;margin-top: 7px; font-size: 1rem;">{{responseError}}</small>
                    </div>
                    <div class="p-col-12 p-md-4">
                        <button pButton pRipple type="button" icon="pi pi-refresh" iconPos="left" label="Reset"
                        class="p-button-secondary" (click)="getTaskListByproject(false)" style="width:120px; float:right; margin-left:10px;"></button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Update"
                        class="p-button-success" (click)="update()" style="width:120px; float:right; margin-left:10px;"></button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%; background-color: #fff;opacity: 0.2; z-index: 9999;"  *ngIf="showLoading">
<i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem"></i>
</div>
<p-dialog header="OverLap / Cycle Time" [(visible)]="Overlapinfo" modal="modal" showEffect="fade" [style]="{ 'width': '60%' }" >
<hr />
<div style="padding: 20px;" *ngIf="projectInformation != undefined">
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-xl-12">
      <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px" >
        <ul class="widget-image-list">
          <li>
            <span style="font-weight: bold;" >Layout Overlap Design Days:</span>
            <span>{{projectInformation.layoutOverlap}}</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Proportionate Cycle Time - Layout Engineers:</span>
            <span>{{ projectInformation.layoutCycleTime }} %</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Number of Assigned Layout Engineeres:</span>
            <span>{{ projectInformation.layoutAssignCount }}</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Proportionate Cycle Time - Designers:</span>
            <span>{{ projectInformation.designCycleTime }} %</span>
          </li>
          <li>
            <span style="font-weight: bold;" >Number of Assigned Designers:</span>
            <span>{{ projectInformation.designAssignCount }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</p-dialog>

<p-dialog header="Description" [(visible)]="projectdescriptiondisplay" modal="modal" showEffect="fade" [style]="{ 'width': '40%' }" >
<hr />
<div style="padding: 20px;" *ngIf="projectInformation != undefined">{{projectInformation.revision}}</div>
</p-dialog>
