import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppCrudComponent } from './pages/app.crud.component';
import { AppCalendarComponent } from './pages/app.calendar.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { ProjectlistComponent } from './demo/projectlist/projectlist.component';
import { PhasetreeComponent } from './demo/phasetree/phasetree.component';
import { ProjectdashboardComponent } from './demo/projectdashboard/projectdashboard.component';
import { CreateprojectComponent } from './demo/createproject/createproject.component';
import { AdminComponent } from './demo/admin/admin.component';
import { TasklistComponent } from './demo/tasklist/tasklist.component';
import { ReviewsComponent } from './demo/reviews/reviews.component';
import { ReportsComponent } from './demo/reports/reports.component';
import { CreatetaskComponent } from './demo/createtask/createtask.component';
import { CreatereportComponent } from './demo/createreport/createreport.component';
import { ViewreportComponent } from './demo/viewreport/viewreport.component';
import { ManagespaceComponent } from './demo/admin/managespace/managespace.component'
import { CustomfieldsComponent } from './demo/customfields/customfields.component';
import { ManageprojectdescriptionComponent } from './demo/admin/manageprojectdescription/manageprojectdescription.component'
import { ProposedprojectscheduleComponent } from './demo/proposedprojectschedule/proposedprojectschedule.component';
import {  MonitoringreviewsComponent} from './demo/admin/monitoringreviews/monitoringreviews.component';
import {  ActualdateComponent} from './demo/admin/actualdate/actualdate.component';
import {  OverlayComponent} from './demo/admin/overlay/overlay.component';
import {  ProportionaltimeComponent} from './demo/admin/proportionaltime/proportionaltime.component';
import {  TaskattributesComponent} from './demo/admin/taskattributes/taskattributes.component';
import {  ReportComponent} from './demo/admin/report/report.component';
import {  AllreportComponent} from './demo/allreport/allreport.component';
import {AuthGuard} from './guard/auth.guard';
import { EntrypointComponent } from './demo/entrypoint/entrypoint.component';
import { ProjectsbymilestoneComponent } from './demo/projectsbymilestone/projectsbymilestone.component';
import { HelpguideComponent } from './demo/helpguide/helpguide.component';
import { AddreviewComponent } from './demo/addreview/addreview.component';
import { P2summaryreportComponent } from './demo/p2summaryreport/p2summaryreport.component';
import { P2summaryComponent } from './demo/p2summary/p2summary.component';
import { CycletimeComponent } from './demo/cycletime/cycletime.component';
import { ProjectsumarryComponent } from './demo/projectsumarry/projectsumarry.component';
import { OverduemilestoneComponent } from './demo/overduemilestone/overduemilestone.component';
import { ManagedatesComponent } from './demo/managedates/managedates.component';
import { ManageassigneeComponent } from './demo/manageassignee/manageassignee.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    { path: 'entrypoint', component:EntrypointComponent},
                    { path: '', component: ProjectdashboardComponent,canActivate:[AuthGuard] },
                    { path: 'dashboard', component: ProjectdashboardComponent,canActivate:[AuthGuard] },
                    { path: 'createreport', component: CreatereportComponent,canActivate:[AuthGuard] },
                    { path: 'viewreport/:reportId/:isFrom', component: ViewreportComponent ,canActivate:[AuthGuard]},
                    { path: 'admin', component: AdminComponent ,canActivate:[AuthGuard]},
                    { path: 'managespace', component: ManagespaceComponent ,canActivate:[AuthGuard]},
                    { path: 'manageprojectdescription', component: ManageprojectdescriptionComponent,canActivate:[AuthGuard] },
                    { path: 'createtask/:listId/:typeId', component: CreatetaskComponent,canActivate:[AuthGuard] },
                    { path: 'edittask/:taskId', component: CreatetaskComponent,canActivate:[AuthGuard] },
                    { path: 'tasklist', component: TasklistComponent,canActivate:[AuthGuard] },
                    { path: 'reviews', component: ReviewsComponent ,canActivate:[AuthGuard]},
                    { path: 'reports', component: ReportsComponent ,canActivate:[AuthGuard]},
                    { path: 'createproject', component: CreateprojectComponent,canActivate:[AuthGuard] },
                    { path: 'editproject/:id', component: CreateprojectComponent ,canActivate:[AuthGuard]},
                    { path: 'phasetree/:id', component: PhasetreeComponent,canActivate:[AuthGuard] },
                     { path: 'projectlist', component: ProjectlistComponent,canActivate:[AuthGuard] },
                    {path: "customfields", component:CustomfieldsComponent,canActivate:[AuthGuard]},
                    {path: "proposedprojectschedule", component:ProposedprojectscheduleComponent,canActivate:[AuthGuard]},
                    {path: "monitoringreviews", component:MonitoringreviewsComponent,canActivate:[AuthGuard]},
                    {path: "report", component:ReportComponent,canActivate:[AuthGuard]},
                    {path: "allreports", component:AllreportComponent,canActivate:[AuthGuard]},
                    {path: "projectsbymilestone", component:ProjectsbymilestoneComponent,canActivate:[AuthGuard]},
                    {path: "projectsummary", component:ProjectsumarryComponent,canActivate:[AuthGuard]},

                    { path: 'overduemilestone', component: OverduemilestoneComponent,canActivate:[AuthGuard] },
                    { path: 'actualdate', component: ActualdateComponent,canActivate:[AuthGuard] },
                    { path: 'overlay', component: OverlayComponent ,canActivate:[AuthGuard]},
                    { path: 'proportionaltime', component: ProportionaltimeComponent ,canActivate:[AuthGuard]},
                    { path: 'taskattributes', component: TaskattributesComponent,canActivate:[AuthGuard] },
                    { path: 'helpguide', component: HelpguideComponent,canActivate:[AuthGuard]},
                    { path: 'addreview', component: AddreviewComponent,canActivate:[AuthGuard]},
                    { path: 'p2summaryreport', component: P2summaryreportComponent,canActivate:[AuthGuard]},
                    { path: 'p2summary', component: P2summaryComponent,canActivate:[AuthGuard]},
                    { path: 'cycletime', component: CycletimeComponent,canActivate:[AuthGuard]},
                    { path: 'managedates', component: ManagedatesComponent,canActivate:[AuthGuard]},
                    { path: 'manageassignee', component: ManageassigneeComponent,canActivate:[AuthGuard]},
                    // {path: 'pages/crud', component: AppCrudComponent},
                    // {path: 'pages/calendar', component: AppCalendarComponent},
                    // {path: 'pages/invoice', component: AppInvoiceComponent},
                    // {path: 'pages/help', component: AppHelpComponent}
                ]
            },
            { path: 'error', component: AppErrorComponent },
            { path: 'access', component: AppAccessdeniedComponent },
            //{ path: 'notfound', component: AppNotfoundComponent },
            { path: 'login', component: AppLoginComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
