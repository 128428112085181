// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  UMSAPI: "http://localhost:10412/api",
  DMSAPI: "http://132.148.242.138/FPDMS/api",
  PMAPI: "https://localhost:5001/api"
  // UMSAPI: "http://132.148.242.138/FPUMS/api",
  // DMSAPI: "http://132.148.242.138/FPDMS/api",
  // PMAPI: "http://132.148.242.138/FPPM/api"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
