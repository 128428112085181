import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/injector';

@Component({
  selector: 'app-entrypoint',
  templateUrl: './entrypoint.component.html',
  styleUrls: ['./entrypoint.component.scss']
})
export class EntrypointComponent implements OnInit {
  constructor(private route: Router,
    private _facadeService: FacadeService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object, private router: ActivatedRoute) { }
  showLoading = false;
  ngOnInit(): void {

    var guid = this.router.snapshot.queryParamMap.get('userguid');
    var userId = this.router.snapshot.queryParamMap.get('userId');
    var IsDCCQuery = this.router.snapshot.queryParamMap.get('IsDCC');
    var token = this.router.snapshot.queryParamMap.get('access_token');
    var destination = this.router.snapshot.queryParamMap.get('destination');
    var projectId = this.router.snapshot.queryParamMap.get('projectId');
    var userIdint = parseInt(userId);
    var taskId = this.router.snapshot.queryParamMap.get('taskid');
    var appliId = this.router.snapshot.queryParamMap.get('appliId');
    var clientId = this.router.snapshot.queryParamMap.get('clientId');
    sessionStorage.appliId = appliId;
    sessionStorage.clientId = clientId;
    sessionStorage.userId = userIdint;
    sessionStorage.userGuid = guid;
    sessionStorage.IsDCC = IsDCCQuery;
    var bearer_token = this.router.snapshot.queryParamMap.get('bt');
    sessionStorage.bt = bearer_token;
    sessionStorage.setItem("access_token", token);
    this.showLoading = true;
    this._facadeService.UpdateSecurityToken(token).subscribe(
      (data) => {
        if (data.returnObject) {
          if (destination == "phasetree") {
            sessionStorage.FromReport = false;
            this.route.navigate(["/phasetree/" + projectId]);
          }
          else if (destination == "edittask") {
            this.route.navigate(["/edittask/" + taskId]);
          }
          else {
            this.route.navigate(["/dashboard", { IsDCC: IsDCCQuery }]);
          }
        }
        else {
          location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
