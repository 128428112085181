import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-proposedprojectschedule',
  templateUrl: './proposedprojectschedule.component.html',
  styleUrls: ['./proposedprojectschedule.component.scss']
})
export class ProposedprojectscheduleComponent implements OnInit {

  scheduleData:any[]=[];
  expandedRows: {} = {};
  showLoading:boolean;
  constructor( private route: ActivatedRoute,private _facadeService: FacadeService) { }

  ngOnInit(): void { 
    var prjId = this.route.snapshot.queryParamMap.get('prjId');
    var proposed = this.route.snapshot.queryParamMap.get('proposed');
    var tid = this.route.snapshot.queryParamMap.get('tid');
    // this.GetProjectDetails(prjId,proposed,tid);
  }
  // GetProjectDetails(prjId,proposed,tid){
  //   var model={
  //     ProjectId:prjId,
  //     ProjectTasks:[{+2
  //       TaskId:tid,
  //       PlannedEndDate:new Date(proposed)
  //     }]
  //   }
  //   this.showLoading = true;
  //   this._facadeService.ProjectChangeManagementValidation(model).subscribe(
  //     (data) => {
  //       this.showLoading = false;
  //       let proposed:any;
  //       console.log(data.returnObject);
  //       if(data.returnObject!=null && data.returnObject.length>0){
  //         data.returnObject.forEach((value1, key) => {
  //           if(value1.projectDates!=null && value1.projectDates.length>0){
  //             value1.projectDates.forEach((value, key) => {
  //               let convertactiondate = new Date(value.current); 
  //               value.current =  convertactiondate.toLocaleDateString();
  //               value.proposed=proposed;
  //             });
  //             this.scheduleData.push(value1);
  //           }
  //         });
  //         const thisRef = this;
  //   thisRef.expandedRows[this.scheduleData[0].name] = true;
  //   this.expandedRows = Object.assign({}, this.expandedRows);
  //       }
  //     },
  //     (error) => {
  //       this.showLoading = false;
  //       console.log(error);
  //     } 
  //   );
  // }
}
