<div class="p-grid table-demo">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Project Actual Start Date
         
        </h5>
      </div>
      <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-3 card-header" style="margin-top: 25px;">
              <!-- 
              <p-dropdown [options]="projects" [(ngModel)]="selectedProject" placeholder="Select Project"
                (onChange)="SelectProject()" optionLabel="projectName"></p-dropdown> -->
                <label style="text-align: right;">Project:&nbsp;&nbsp;</label>
                <p-autoComplete [(ngModel)]="selectedProject" [suggestions]="filteredProdType"  (onSelect)="SelectProject()" class="width350"  placeholder="Select Project"
                (completeMethod)="filterproductListChange($event)"   field="projectName" [dropdown]="true">
                <ng-template>
                  <div>
                    <div>{{ filteredProdType.projectName }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
          </div>
      </div>
      <hr />
      <div class="p-grid p-fluid">
      <div class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-3">
            <label>Product Line</label>
            <input type="text" pInputText placeholder="Product Line" [(ngModel)]="productLine" disabled />
          </div>
          <div class="p-col-12 p-md-3">
            <label for="ProjecName">Project Name</label>
            <input type="text" pInputText placeholder="Project Name" [(ngModel)]="projecName" disabled />
          </div>
          <div class="p-col-12 p-md-3">
            <label for="state">Product Engineer</label>
            <input type="text" pInputText placeholder="Product Engineer" [(ngModel)]="selectedProjectmanager" disabled />
          </div>
          <div class="p-col-12 p-md-3">
            <label for="state">Die Code</label>
            <input type="text" pInputText placeholder="Die Code" [(ngModel)]="dieCode" disabled />
          </div>
          <div class="p-col-12 p-md-3">
            <label for="state">Mfg Part #</label>
            <input type="text" pInputText placeholder="Mfg Part #" [(ngModel)]="mfgPart" disabled />
          </div>
          <div class="p-col-12 p-md-3">
            <label for="state">Package Name</label>
            <input type="text" pInputText placeholder="Package Name" [(ngModel)]="packageOption" disabled />
          </div>
          <div class="p-col-12 p-md-3">
            <label for="state">Need CP Trim</label>
            <input type="text" pInputText placeholder="Need CP Trim" [(ngModel)]="cpTrim" disabled />
          </div>
          <div class="p-col-12 p-md-3">
            <label for="state">Package Type</label>
            <input type="text" pInputText placeholder="Package Type" [(ngModel)]="packageType" disabled />
          </div>
          <div class="p-col-12 p-md-3">
            <label for="state">Qual Type</label>
            <input type="text" pInputText placeholder="Qual Type" [(ngModel)]="qualType" disabled />
          </div>
          <div class="p-col-12 p-md-3">
            <label for="startDate">Target Start Date</label>
            <p-calendar [showIcon]="true" placeholder="Target Start Date" inputId="startDate" [(ngModel)]="startDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-col-12 p-md-3">
            <label for="endDate">Target End Date</label>
            <p-calendar [showIcon]="true" placeholder="Target End Date" inputId="endDate" [(ngModel)]="endDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-col-12 p-md-3">
            <label for="revisedStartDate">Current Start Date</label>
            <p-calendar [showIcon]="true" placeholder="Current Start Date" inputId="revisedStartDate" [(ngModel)]="revisedStartDate" [disabled]=true>
            </p-calendar>
          </div>
          <div class="p-col-12 p-md-3">
            <label for="revisedEndDate">Current End Date</label>
            <p-calendar [showIcon]="true" placeholder="Current End Date" inputId="revisedEndDate" [(ngModel)]="revisedEndDate" [disabled]=true>
            </p-calendar>
          </div>
          <div class="p-col-12 p-md-3">
            <label for="actualStartDate">Actual Start Date</label>
            <p-calendar [showIcon]="true" placeholder="Actual Start Date" inputId="actualStartDate" [(ngModel)]="actualStartDate"
              [minDate]="revisedStartDate" disabledWeekends="true"  [disabled]="DisableEnter && userFeature"></p-calendar>
          </div>
          <div class="p-col-12 p-md-3">
            <label for="actualEndDate">Actual End Date<span style="color: red"></span></label>
            <p-calendar [showIcon]="true" placeholder="Actual End Date" inputId="actualEndDate" [(ngModel)]="actualEndDate"
              [minDate]="revisedStartDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-col-12 p-md-3">
            <label for="projectStatus">Project Status</label>
            <input type="text" pInputText placeholder="Project Status" [(ngModel)]="projectStatus" disabled />
          </div>
          <div class="p-col-12 p-md-3">
            <label for="projectDescription">Project Description</label>
            <input type="text" pInputText placeholder="Project Desccription" [(ngModel)]="projectDescMdl" disabled />
          </div>
          <div class="p-col-12 p-md-6" style="vertical-align: center;text-align: right;">
            <small class="p-invalid" style="float: right">{{responseError}}</small>
          </div>
          <div class="p-col-12 p-md-3">
            <button [disabled]="DisableUpdate" pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Update"  *ngIf="ShowProjectDetails"
            class="p-button-success" (click)="UpdateProjectActualStartDate()"
            style="width:120px; float:right; margin-left:10px;margin-top: 10px;"></button>
        </div>

        </div>
      </div>
      </div>
    </div>
  </div>
</div>


<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%; background-color: #fff;opacity: 0.2; z-index: 9999;" 
*ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem"></i>
</div>