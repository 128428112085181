import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetDataQuery } from '../demo/domain/customfields';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class CustomfieldsService {

  constructor(private httpService: HttputilityService) { }

  GetProductByUser(userGuid){
    return this.httpService.Get('PMAPI', 'Project/GetProductsbyUser?UserID='+ userGuid);
  }  
  GetAllActiveProjects(){
    return this.httpService.Get('PMAPI', 'Project/GetAllActiveProjects');
  }  
  IsOwner(projectId,userGuid){
    return this.httpService.Get('PMAPI', 'Product/IsOwner?ProjectID='+projectId+'&UserID='+ userGuid);
  }  

  GetCustomGroups(){
    return this.httpService.Get('ProdctAPI', 'CustomFields/GetCustomGroups');
  }

  GetFieldsByGroupID(GroupID, ProductID, ApplicationID){
    return this.httpService.Get('ProdctAPI', 'CustomFields/GetFieldsByGroupID?GroupID='+ GroupID +'&ProductID='+ ProductID +'&ApplicationID='+ ApplicationID);
  }

  SaveCustomFields(model){
    return this.httpService.Add('ProdctAPI', 'CustomFields/SavePMCustomFields', 'httpOptions', model);
  }

  DataSourceAsQuery(datasource){
    return this.httpService.Get('ProdctAPI', 'CustomFields/Getdata?Query='+ datasource);
  }
  GetDataDependent(datasource,Value){
    return this.httpService.Get('UMSAPI','customFields/GetDataDependent?Query='+ datasource + "&Value=" + Value);
  }
  GetDependentAttributes(CustomFieldID){
    return this.httpService.Get('UMSAPI','customFields/GetDependentAttributes?CustomFieldID='+ CustomFieldID);
  }

  GetProjectInfoFromPDM(projectName){
    return this.httpService.Get('ProdctAPI', 'Product/GetProductIDbyName?Name='+ projectName);
  }

  GetHistory(ProductID,CustomFieldID){
    return this.httpService.Get('ProdctAPI', 'CustomFields/GetHistory?ProductID='+ ProductID + '&CustomFieldID=' + CustomFieldID);
  }
  
  //Added by suresh
  ProductList(UserID,Type,PE) {
    // return this.httpService.Get('ProdctAPI', 'Dashboard/GetUserProducts?UserID=' + UserID + '&Type=' + Type+'&PE='+ PE);
    return this.httpService.Get('ProdctAPI', 'Dashboard/GetDashboardProducts');
  }
}
