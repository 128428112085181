
import { Component, OnInit } from '@angular/core';
import { ReviewDto } from '../domain/reviewaction';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { FacadeService } from 'src/app/facade/facade.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx'; 

@Component({
    selector: 'app-viewreport',
    templateUrl: './viewreport.component.html',
    styles: [`:host ::ng-deep .p-multiselect {
		min-width: 15rem;
	}

	:host ::ng-deep .multiselect-custom-virtual-scroll .p-multiselect {
		min-width: 20rem;
	}

	:host ::ng-deep .multiselect-custom .p-multiselect-label {
		padding-top: .25rem;
		padding-bottom: .25rem;

	}


	:host ::ng-deep .multiselect-custom .country-item.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}

	:host ::ng-deep .multiselect-custom .country-item.country-item-value img.flag {
		width: 17px;
	}

	:host ::ng-deep .multiselect-custom .country-item {
		display: flex;
		align-items: center;
	}

	:host ::ng-deep .multiselect-custom .country-item img.flag {
		width: 18px;
		margin-right: .5rem;
	}

	:host ::ng-deep .multiselect-custom .country-placeholder {
		padding: 0.25rem;
	}

	:host ::ng-deep .p-colorpicker {
		width: 2.5em
	}
    `]


})
export class ViewreportComponent implements OnInit {
    projects: any;
    projectsData: any;
    reportViewData: any;
    cols: any[];
    colsHeader: string[] = [];
    colsReportData: any[] = [];
    exportColumns: any[];
    items: any[] = [];
    reviewNotesDisplay: boolean = false;
    reportName:string;
    reviewProjectId:string;
    reviewProjectName:string;
    reviewDate: any;
    reviewNotes: string;
    reviewNotesOld:string;
    reviewNotesControlOld:FormControl;
    createReviewForm: FormGroup;
    showLoading: boolean = false;
    isReviewNotesSubmitted: boolean;
    reviewProjectNameControl: FormControl;
    reviewDateControl: FormControl;
    reviewNotesControl: FormControl;
    responseError:string;
    IsFrom:string;
    reviewDates:any=[];
    ShowOldNotes:boolean=true;
    userGuid = sessionStorage.getItem('userGuid');
    constructor(
        private breadcrumbService: BreadcrumbService,
        private facadeService: FacadeService,
        private datePipe: DatePipe,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard', routerLink: ['/'] }
        ]);
    }

    ngOnInit() {
        this.cols = [
            { field: 'documentType', header: 'Type' },
            { field: 'documentTypeCode', header: 'Code' },
            { field: 'active', header: 'Status' },
        ];

        this.createReviewForm = this.formBuilder.group({
            reviewProjectNameControl: new FormControl(''),
            reviewDateControl: new FormControl(''),
            reviewNotesControl: new FormControl('', [Validators.required]),
            reviewNotesControlOld: new FormControl('')
        })
        
        this.route.paramMap.subscribe(params => {
            this.showLoading = true;
            this.facadeService.GetReportByReportId(params.get("reportId")).subscribe(data => {
                this.showLoading = false;
                if (data.returnObject != undefined) {
                    let reportData = JSON.parse(data.returnObject);
                    if (reportData.length > 0) {
                        this.colsReportData = reportData;
                    }
                }
            });

            this.facadeService.GetReportSchemaById(params.get("reportId")).subscribe(data => {
                if (data.returnObject != undefined) {
                    this.reportName = data.returnObject.reportName;
                }
            });
        });

        this.route.paramMap.subscribe(params => {
           this.IsFrom= params.get("isFrom");
        });
    }

    print() {
        window.print();
    }
    SelectReview(){
        var selectData = this.reviewDates.find(m=>m.reviewDate==this.reviewDate.reviewDate);
        if(selectData!=null){
            this.reviewNotesOld=selectData.reviewNotes;
        }
        let rev = this.datePipe.transform(this.reviewDate.reviewDate, "MM/dd/yyyy");
        let cuur = this.datePipe.transform(new Date(), "MM/dd/yyyy");
        if(rev==cuur){
            this.ShowOldNotes=false;
            this.reviewNotes=selectData.reviewNotes;
        }
        else{
            this.reviewNotes="";
            this.ShowOldNotes=true;
        }
    }
    exportExcel() {
        /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "ProjectList.xlsx");
    }

    delete_row (ws, row_index){
        let range = XLSX.utils.decode_range(ws["!ref"])
        for(var R = row_index; R < range.e.r; ++R){
            for(var C = range.s.c; C <= range.e.c; ++C){
                ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            }
        }
        range.e.r--
        ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
      }
      ec(r, c) {
        return XLSX.utils.encode_cell({r:r,c:c})
      }

    showReviewNotes(obj){
        //this.createReviewForm.reset();
        this.getCurrentDateTime();
        this.reviewNotesDisplay = true;
        this.reviewProjectName = obj["Project Name"];
        this.reviewProjectId = obj["Project ID"];
        this.reviewNotes = "";
        this.responseError = '';
        this.createReviewForm.controls["reviewNotesControl"].reset();
        this.createReviewForm.controls["reviewProjectNameControl"].disable();
        // this.createReviewForm.controls["reviewDateControl"].disable();
        this.GetReviewsByProjectId();
    }
    BackClick(){
if(this.IsFrom=="M"){
    this.router.navigate(["/monitoringreviews"]);
}
else{
    this.router.navigate(["/reviews"]);
}
    }
    saveReview(){
        this.isReviewNotesSubmitted = true
        if(this.createReviewForm.valid){
            debugger
            if(this.reviewDate!=null && this.reviewDate!=""
            && this.reviewDate!=undefined&&this.reviewDate.reviewGuid!=null
            &&this.reviewDate.reviewGuid!=""&&this.reviewDate.reviewGuid!=undefined){
                let reviewObj = new ReviewDto();
                reviewObj.reviewNotes = this.reviewNotes;
                reviewObj.updatedBy = this.userGuid;
                reviewObj.reviewGuid=this.reviewDate.reviewGuid;
                this.showLoading = true;
                this.facadeService.updateReview(reviewObj).subscribe(data => {
                    this.showLoading = false;
                    if (data.returnCode == 0) {
                        this.isReviewNotesSubmitted = false;
                        this.reviewNotesDisplay = false;
                    }
                    else{
                        this.responseError = data.returnMessage;
                    }
                });
            }
            else{
                let reviewObj = new ReviewDto();
                reviewObj.projectGuid = this.reviewProjectId;
                if(this.reviewDate!=null && this.reviewDate!=""&& 
                this.reviewDate!=undefined&&this.reviewDate.reviewDate!=null&&
                this.reviewDate.reviewDate!=""&&this.reviewDate.reviewDate!=undefined){
                    reviewObj.reviewDate = new Date(this.reviewDate.reviewDate);
                }
                else{
                    reviewObj.reviewDate = new Date(this.reviewDate);
                }
                reviewObj.reviewNotes = this.reviewNotes;
                reviewObj.createdBy = this.userGuid;
                this.showLoading = true;
                this.facadeService.saveReview(reviewObj).subscribe(data => {
                    this.showLoading = false;
                    if (data.returnCode == 0) {
                        this.isReviewNotesSubmitted = false;
                        this.reviewNotesDisplay = false;
                    }
                    else{
                        this.responseError = data.returnMessage;
                    }
                });
            }
        }
    }

    getCurrentDateTime() {
        let date = new Date();
        var Str = 
            ("00" + (date.getMonth() + 1)).slice(-2) 
            + "/" + ("00" + date.getDate()).slice(-2) 
            + "/" + date.getFullYear() + " " 
            + ("00" + date.getHours()).slice(-2) + ":" 
            + ("00" + date.getMinutes()).slice(-2);
            //+ ":" + ("00" + date.getSeconds()).slice(-2); 
        this.reviewDate = Str; 
    }

    GetReviewsByProjectId(){
        this.reviewDates=[];
        this.showLoading = true;
        this.facadeService.GetReviewsByProjectId(this.reviewProjectId).subscribe(data => {
            this.showLoading = false;
            if(data.returnObject!=null && data.returnObject.length>0){
                let revDates=[];
                data.returnObject.forEach((value, key) => {
                    let convertactiondate = new Date(value.reviewDate+'Z'); 
                     value.reviewDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
                      revDates.push(value);
                   });
                   this.reviewDates=revDates;
                this.reviewProjectName=data.returnObject[0].projectName;
                this.reviewNotesOld=data.returnObject[0].reviewNotes;
                let rev = this.datePipe.transform(data.returnObject[0].reviewDate, "MM/dd/yyyy");
                let cuur = this.datePipe.transform(new Date(), "MM/dd/yyyy");
                if(rev==cuur){
                    this.reviewDate=data.returnObject[0];
                    this.ShowOldNotes=false;
                    this.reviewNotes=data.returnObject[0].reviewNotes;
                }
                else{
                    this.ShowOldNotes=false;
                    this.reviewNotes="";
                    this.getCurrentDateTime();
                let convertactiondate = new Date(this.reviewDate+'Z');
                var reviewDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
                this.reviewDates.push({reviewDate:reviewDate});
                this.reviewDate=this.reviewDates.find(m=>m.reviewDate==reviewDate);
                }
            }
            else{
                let reD =[];
                let convertactiondate = new Date(this.reviewDate+'Z');
                this.reviewDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();

                reD.push({reviewDate:this.reviewDate});
                this.reviewDates=reD;
                this.ShowOldNotes=false;
            }
        });
    }
}


