import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';


@Injectable({
  providedIn: 'root'
})

export class TasksService {

  constructor(
    private httpService: HttputilityService
  ) { }
  getTasksByProject(projectId, listId, type) {
    return this.httpService.Get('PMAPI', 'Tasks/GetTasksByProject?projectId=' + projectId + '&listId=' + listId + '&type=' + type);
  }
  GetTasksManageAssignee(projectId) {
    return this.httpService.Get('PMAPI', 'Tasks/GetTasksManageAssignee?ProjectID=' + projectId);
  }

  getTasksByUserID(userGUID) {
    return this.httpService.Get('PMAPI', 'Tasks/GetTasksByUserID/' + userGUID);
  }

  getTasksByUserID1(userGUID,download) {
    return this.httpService.Get('PMAPI', 'Tasks/GetTasksByUserID1?UserGUID=' + userGUID+'&download='+download);
  }

  GetTaskcreateProjectoptions() {
    return this.httpService.Get('PMAPI', 'Tasks/GetTasksDropdownOptions');
  }
  // ProjectChangeManagementValidation(model){
  //   return this.httpService.Add('PMAPI','ProjectChangeManagementValidation','httpOptions',model);
    
  // }
  GetTaskListPreInformation(listid: string, listType: number) {
    return this.httpService.Get('PMAPI', 'Lists/GetTaskListPreInformation/' + listid + '/' + listType);
  }

  TaskDeActive(taskId) {
    //return this.httpService.Add('PMAPI', `Tasks/TaskDeActive/`+, 'httpOptions', null);
    return this.httpService.Get('PMAPI', 'Tasks/TaskDeActive/' + taskId);
  }

  GetTasksDropdownOptionsByProject(projectId) {
    return this.httpService.Get('PMAPI', 'Tasks/GetTasksDropdownOptionsByProject/' + projectId);
  }

  CreateTask(data) {
    return this.httpService.PostMultiPart('PMAPI', `Tasks/CreateTask`, 'httpOptions', data)
  }

  GetTaskById(taskid,UserID) {
    return this.httpService.Get('PMAPI', 'Tasks/GetTaskByID/'+ taskid+ '?UserID='+UserID );
  }

  GetUserKeyValue() {
    return this.httpService.Get('UMSAPI', `user/GetUserKeyValue`)
  }

  GetAllDieCodes() {
    return this.httpService.Get('UMSAPI', `customfields/GetAllDieCode`)
  }
  GetAllMFGParts() {
    return this.httpService.Get('UMSAPI', `customfields/GetAllMFGParts`)
  }

  GetTaskHistory(taskId){
    return this.httpService.Get('PMAPI', 'Tasks/GetTaskHistory/' + taskId);
  }

  GetSubTasks(taskId){
    return this.httpService.Get('PMAPI', 'Tasks/GetSubTasks/' + taskId);
  }

  DeleteTaskDocument(fileGuid,userGuid){
    return this.httpService.Get('PMAPI', 'Tasks/DeleteTaskFile/' + fileGuid+"/"+userGuid);
  }
  GetEffectedTasks(TaskID,ChangeDate,Type,Update)
  {
    return this.httpService.Get('PMAPI', 'Tasks/GetEffectedTasks?TaskID='+TaskID+'&ChangeDate='+ChangeDate+'&Type='+Type+'&Update='+Update);
  }
}