import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from 'src/app/facade/facade.service';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-allreport',
  templateUrl: './allreport.component.html',
  styleUrls: ['./allreport.component.scss']
})
export class AllreportComponent implements OnInit {

  reportsData:any=[];
  showLoading:boolean;
  reviewNotesDisplay:boolean;
  projectId:any;
  responseError:any;
  reviewNotes:any;
  userGuid = sessionStorage.getItem('userGuid');
  allReviews:any=[];
  ReviewGuid:any;
  @ViewChild('fs') fs: ElementRef;

  @HostBinding('class.is-fullscreen') isFullscreen = false;

  isActive = false;
  constructor(private facadeService: FacadeService,private printService: PrintcommonService,private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.GetReport();
  }
  GetReport(){
    this.showLoading = true;
    this.facadeService.GetReportData().subscribe(
        (res) => {
            this.showLoading = false;
            this.reportsData = res.returnObject; 
        });
  }
  exportExcel(){
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "All Projects.xlsx");
  }
  
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['S1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}
print() {
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,'All Projects','',false,0);
}
  openFullscreen(): void {
    this.isFullscreen = true;
    const el = this.fs.nativeElement;

    if (!document.fullscreenElement) {  // current working methods
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.msRequestFullscreen) {

        el.msRequestFullscreen();
      }
    }

    setTimeout(() => {
      this.isActive = true;
    }, 500);
  }

  closeFullscreen(): void {
    this.isFullscreen = false;
    this.isActive =  false;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
  AddNotes(projectGuid){
      this.reviewNotesDisplay=true;
      this.projectId=projectGuid;
      this.GetReviewsByProjectId();
  }
  saveReview(){
    this.showLoading = true;
      
      if(this.ReviewGuid!=""&&this.ReviewGuid!=null&&this.ReviewGuid!=undefined){
        let reviewObj = {
          projectGuid : this.projectId,
         reviewNotes : this.reviewNotes,
         updatedBy : this.userGuid,
         reviewGuid:this.ReviewGuid
        }
        this.facadeService.updateReview(reviewObj).subscribe(data => {
          this.showLoading = false;
          if (data.returnCode == 0) {
              this.reviewNotesDisplay = false;
              location.reload();
          }
          else{
              this.responseError = data.returnMessage;
          }
      });
      }
      else{
        let reviewObj = {
          projectGuid : this.projectId,
         reviewNotes : this.reviewNotes,
         createdBy : this.userGuid,
         reviewDate:new Date()
        }
        this.facadeService.saveReview(reviewObj).subscribe(data => {
          this.showLoading = false;
          if (data.returnCode == 0) {
              this.reviewNotesDisplay = false;
             location.reload();
          }
          else{
              this.responseError = data.returnMessage;
          }
      });
      }
  }

  GetReviewsByProjectId(){
    this.reviewNotes="";
    this.ReviewGuid="";
    this.showLoading = true;
    this.allReviews=[];
    this.facadeService.GetReviewsByProjectId(this.projectId).subscribe(data => {
        this.showLoading = false;
        if(data.returnObject!=null && data.returnObject.length>0){
            let revDates=[];
            data.returnObject.forEach((value, key) => {
                let convertactiondate = new Date(value.reviewDate+'Z'); 
                 value.reviewDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
                  revDates.push(value);
               });
               this.allReviews =revDates;
                this.reviewNotes=data.returnObject[0].reviewNotes;
                let rev = this.datePipe.transform(data.returnObject[0].reviewDate, "MM/dd/yyyy");
                let cuur = this.datePipe.transform(new Date(), "MM/dd/yyyy");
                if(rev==cuur){
                  this.ReviewGuid=data.returnObject[0].reviewGuid;
                    this.reviewNotes=data.returnObject[0].reviewNotes;
                }
                else{
                  this.ReviewGuid="";
                  this.reviewNotes="";
                }
        }
        else{
          this.ReviewGuid="";
          this.reviewNotes="";
        }
    });
}
}
