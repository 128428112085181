import { Component, OnInit, ViewChild} from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from 'primeng/table';
import { Router} from '@angular/router';
import {SelectItem, SortEvent} from 'primeng/api';
import { dateToLocalArray } from '@fullcalendar/core/datelib/marker';
import { DatePipe } from '@angular/common';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-projectsbymilestone',
  templateUrl: './projectsbymilestone.component.html',
  styleUrls: ['./projectsbymilestone.component.scss']
})
export class ProjectsbymilestoneComponent implements OnInit {

  loading: "loading";
  showLoading:boolean;
  cols: any[];
  projects: any[];
  selectedprojects: any[];
  @ViewChild('dt') table: Table;

  milestones:any[];
  selectedmilestone:any;

  status: SelectItem[];
  selectedStatus:any[]=[];

  period: any[];
  selectedPeriod:any;
  datesdisplay:boolean = false;
  editStartDate:any;
  editEndDate:any;
  Name:string = "";
  error:string = "";
  Count:number = 0;
constructor(
  private _facadeService: FacadeService,
  private datePipe: DatePipe,
  private router: Router,public datepipe: DatePipe,private printService:PrintcommonService
) {}

ngOnInit() {
  this.showLoading = true;
  
  this.cols = [
    { field: 'projectName', header: 'Project' },
    { field: 'dieCode', header: 'Die Code' },
    { field: 'mfgPart', header: 'Mfg Part' },
    { field: 'currentPhase', header: 'Current Phase' },
    { field: 'targetEndDate', header:'Target End Date' },
    { field: 'currentEndDate', header: 'Current End Date' },
    { field: 'actualEndDate', header: 'Actual End Date' },
    { field: 'taskStatus', header: 'Milestone Status' },
  ];
  sessionStorage.projectName = "";
  sessionStorage.dieCode = "";
  sessionStorage.mfgPart = "";
  sessionStorage.currentPhase = "";
  sessionStorage.targetEndDate = "";
  sessionStorage.currentEndDate = "";
  sessionStorage.actualEndDate = "";
  sessionStorage.taskStatus = "";
  this.status = [ 
    {label: 'New', value: 'New'},
    {label: 'In-Process', value: 'In-Process'},
    {label: 'Completed', value: 'Completed'},
    {label: 'EOL', value: 'EOL'},
    {label: 'OBS', value: 'OBS'},
    {label: 'Waiting for Discontinuation', value: 'Waiting for Discontinuation'},
    {label: 'Seek Clarification for Discontinuation', value: 'Seek Clarification for Discontinuation'},
    {label: 'Hold', value: 'Hold'},
    {label: 'Discontinued', value: 'Discontinued'},
];
  this.period = [ 
    {id: 'currentmonth', name: 'Current Month'},
    {id: 'nextmonth', name: 'Next Month'},
    {id: 'next30days', name: 'Next 30 Days'},
    {id: 'custom', name: 'Custom'}
  ];
  if(sessionStorage.getItem('reportperiod') != null && sessionStorage.getItem('reportperiod') != undefined)
  {
    if(sessionStorage.getItem('reportperiod') != "")
    {
      this.selectedPeriod =  this.period.find(m=>m.id==sessionStorage.getItem('reportperiod') );
      if(this.selectedPeriod.id == "custom")
      {
        this.datesdisplay = true;
        this.editStartDate = "";
        this.editEndDate = "";
        if(sessionStorage.getItem('reportstartdate') != null && sessionStorage.getItem('reportstartdate') != undefined)
          this.editStartDate = this.datepipe.transform(sessionStorage.getItem('reportstartdate'), 'MM/dd/yyyy');
        if(sessionStorage.getItem('reportenddate') != null && sessionStorage.getItem('reportenddate') != undefined)
          this.editEndDate = this.datepipe.transform(sessionStorage.getItem('reportenddate'), 'MM/dd/yyyy');
      }
    }
    else
    {
      sessionStorage.reportperiod = "currentmonth";
      this.selectedPeriod =  this.period.find(m=>m.id=='currentmonth');
    }
  }
  else
  {
    sessionStorage.reportperiod = "currentmonth";
    this.selectedPeriod =  this.period.find(m=>m.id=='currentmonth');
  }
  let ok = false;
  if(sessionStorage.getItem('reportstatus') != null && sessionStorage.getItem('reportstatus') != undefined)
  {
    var projectstats = [];
    projectstats = sessionStorage.getItem('reportstatus').split(',');
    projectstats.forEach((element) => {
      this.selectedStatus.push(element);
    });
    ok = true;
  }
  if(!ok)
  {
    this.selectedStatus.push('New');
    this.selectedStatus.push('In-Process');
    // this.selectedStatus.push('Completed');
    this.selectedStatus.push('Waiting for Discontinuation');
    this.selectedStatus.push('Seek Clarification or Discontinuation');
    sessionStorage.reportstatus = this.selectedStatus;
  }
  
  this.GetMilestones();
  //this.getprojects();
}
GetMilestones() {
  this._facadeService
  .GetAllMilestones()
  .subscribe((data) => {
    this.milestones = data.returnObject;
    if(sessionStorage.getItem('reportmilestone') != null && sessionStorage.getItem('reportmilestone') != undefined)
    {
      if(sessionStorage.getItem('reportmilestone') != "")
      {
        this.selectedmilestone =  this.milestones.find(m=>m.taskName==sessionStorage.getItem('reportmilestone') );
        this.getprojects();
      }
    }
    else
      this.showLoading=false;
  });
}
milestonechange()
{
  sessionStorage.reportmilestone = this.selectedmilestone.taskName;
}
projectstatuschange()
{
  var projectstats = [];
    this.selectedStatus.forEach((element) => {
      projectstats.push(element);
    });

  sessionStorage.reportstatus = projectstats;
}
startdate()
{
  sessionStorage.reportstartdate = this.editStartDate;
}
enddate()
{
  sessionStorage.reportenddate = this.editEndDate;
}
phasetree(PID)
{
  sessionStorage.FromReport = true;
  sessionStorage.From = "ReportMilestone";
  this.router.navigate(["/phasetree/"+PID]);
}
Dates()
{
  sessionStorage.reportperiod = this.selectedPeriod.id;
  this.error = "";
  this.datesdisplay = false;
  this.editStartDate = "";
  this.editEndDate = "";
  if(this.selectedPeriod.id == "custom")
    this.datesdisplay = true;
}
getprojects() {
  this.error = "";
  if(this.selectedmilestone == null || this.selectedmilestone == undefined)
    this.error = "Please select the milestone";
  else if(this.selectedPeriod.id == "custom" && (this.editStartDate == null || this.editStartDate == undefined || this.editStartDate == ""))
    this.error = "Please enter start date";
  else if(this.selectedPeriod.id == "custom" && (this.editEndDate == null || this.editEndDate == undefined || this.editEndDate == ""))
    this.error = "Please enter end date";
  else if(this.selectedPeriod.id == "custom" && new Date(this.editEndDate) <= new Date(this.editStartDate) )
    this.error = "Start Date should be less then End Date";
  else
  {
    this.showLoading = true;
    this.Name = this.selectedmilestone.taskName;
    this.cols = [
      { field: 'projectName', header: 'Project' },
      { field: 'dieCode', header: 'Die Code' },
      { field: 'mfgPart', header: 'Mfg Part' },
      { field: 'currentPhase', header: 'Current Phase' },
      { field: 'targetEndDate', header: this.Name + ' Target End Date' },
      { field: 'currentEndDate', header:  this.Name + ' Current End Date' },
      { field: 'actualEndDate', header:  this.Name + ' Actual End Date' },
      { field: 'taskStatus', header: 'Milestone Status' },
    ];
    var projectstats = [];
    this.selectedStatus.forEach((element) => {
      projectstats.push(element);
    });
    var searchModel={
      'taskName':this.selectedmilestone.taskName,
      'periodType':this.selectedPeriod.id,
      'startDate':this.editStartDate == null || this.editStartDate == undefined || this.editStartDate == ""? new Date() : new Date(this.editStartDate),
      'endDate':this.editEndDate == null || this.editEndDate == undefined || this.editEndDate == ""? new Date() : new Date(this.editEndDate),
      'projectStatus':projectstats,

    }
    this._facadeService.GetProjectsbyMilestone(searchModel).subscribe(
      (data) => {
          this.projects = data.returnObject;  
          this.projects.forEach(element => {
            element.bold = false;
            if(element.duration > 0)
            {
              if(element.targetEndDate != null && element.targetEndDate != '--' && element.currentEndDate != null && element.currentEndDate != '--' 
              && (element.taskStatus == 'New' || element.taskStatus == 'InProgress'))
              {
                  if(Date.parse(element.targetEndDate)){  if (new Date(element.targetEndDate) < new Date())  element.bold = true;  }
                  else{  if (element.targetEndDate < new Date().toLocaleDateString())  element.bold = true;  }
                  if(Date.parse(element.currentEndDate)) {  if (new Date(element.currentEndDate) < new Date())  element.bold = true;  }
                  else {  if (element.currentEndDate < new Date().toLocaleDateString())  element.bold = true;  }
              }
              if(element.actualEndDate == '1970-01-01T00:00:00')
                element.actualEndDate="--";
            }
            else
            {
              element.actualEndDate="--";
              element.targetEndDate="--";
              element.currentEndDate="--";
            }
            
            // if(element.actualEndDate == '1970-01-01T00:00:00'){
            //   element.actualEndDate="--";
            //   element.targetEndDate="--";
            //   element.currentEndDate="--";
            
            // }
            // else{
            
            // element.targetEndDate = this.datePipe.transform( element.targetEndDate, 'MM/dd/yyyy');
            // element.currentEndDate = this.datePipe.transform( element.currentEndDate, 'MM/dd/yyyy');
            // element.actualEndDate = this.datePipe.transform( element.actualEndDate, 'MM/dd/yyyy');
          
            // }
        });
        this.CountM();
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
}
CountM()
{
  if(this.projects != undefined && this.projects != null)
  {
    var temp = this.projects;
    if(sessionStorage.projectName != "")
      temp = temp.filter(x=> x.projectName.toLowerCase().includes(sessionStorage.projectName.toLowerCase()));
    if(sessionStorage.dieCode != "")
      temp = temp.filter(x=> x.dieCode.toLowerCase().includes(sessionStorage.dieCode.toLowerCase()));
    if(sessionStorage.mfgPart != "")
      temp = temp.filter(x=> x.mfgPart.toLowerCase().includes(sessionStorage.mfgPart.toLowerCase()));
    if(sessionStorage.currentPhase != "")
      temp = temp.filter(x=> x.currentPhase.toLowerCase().includes(sessionStorage.currentPhase.toLowerCase()));
    if(sessionStorage.targetEndDate != "")
      temp = temp.filter(x=> x.targetEndDate.toLowerCase().includes(sessionStorage.targetEndDate.toLowerCase()));
    if(sessionStorage.currentEndDate != "")
      temp = temp.filter(x=> x.currentEndDate.toLowerCase().includes(sessionStorage.currentEndDate.toLowerCase()));
    if(sessionStorage.actualEndDate != "")
      temp = temp.filter(x=> x.actualEndDate.toLowerCase().includes(sessionStorage.actualEndDate.toLowerCase()));
    if(sessionStorage.taskStatus != "")
      temp = temp.filter(x=> x.taskStatus.toLowerCase().includes(sessionStorage.taskStatus.toLowerCase()));
    this.Count = temp.length;
  }
  else
    this.Count = 0;
}

test(val,field,dt)
{
  if(field == "projectName")
    sessionStorage.projectName = val;
  else if(field == "dieCode")
    sessionStorage.dieCode = val;
  else if(field == "mfgPart")
    sessionStorage.mfgPart = val;
  else if(field == "currentPhase")
    sessionStorage.currentPhase = val;
  else if(field == "targetEndDate")
    sessionStorage.targetEndDate = val;
  else if(field == "currentEndDate")
    sessionStorage.currentEndDate = val;
  else if(field == "actualEndDate")
    sessionStorage.actualEndDate = val;
  else if(field == "taskStatus")
    sessionStorage.taskStatus = val;
  dt = dt.filter(val,field, 'contains');
  this.CountM();
}

exportExcel() {
  /* table id is passed over here */   
  let element = document.getElementById('excel-table'); 
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  this.delete_row(wb.Sheets.Sheet1, 1);
  ws['!cols'] = [{ wpx : 200 },{ wpx : 80 },{ wpx : 150 },{ wpx : 80 },{ wpx : 200 },{ wpx : 200 },{ wpx : 200 },{ wpx : 90 }];
  /* save to file */
  XLSX.writeFile(wb, "Projects by "+ this.Name +".xlsx");
}

delete_row (ws, row_index){
let range = XLSX.utils.decode_range(ws["!ref"])
for(var R = row_index; R < range.e.r; ++R){
    for(var C = range.s.c; C <= range.e.c; ++C){
      ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
      if(ws[this.ec(R+1, C)].v == "&nbsp;&nbsp;" || ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
        ws[this.ec(R+1, C)].v  = "";
    }
}
range.e.r--
delete (ws['I1'])
ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
return XLSX.utils.encode_cell({r:r,c:c})
}

print() {
var Content = document.getElementById('invoice-content').innerHTML;
this.printService.Print(Content,'Projects by '+ this.Name,'trSearch',true,0);
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'targetEndDate'||event.field === 'currentEndDate'||event.field === 'actualEndDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    if(e.actualEndDate == '1970-01-01T00:00:00'){
      e.actualEndDate="--";
      e.targetEndDate="--";
      e.currentEndDate="--";
    }
    else{
    e.targetEndDate = this.datePipe.transform( e.targetEndDate, 'MM/dd/yyyy');
    e.currentEndDate = this.datePipe.transform( e.currentEndDate, 'MM/dd/yyyy');
    e.actualEndDate = this.datePipe.transform( e.actualEndDate, 'MM/dd/yyyy');
  }
  });
}
}
