//ListType are Phase-1, Stage-2, Milestone-3
export class List
{
    Lid:number =0;
    ListId: string;
    ProjectId:string;
    ModuleId:string;
    ListName:string;
    ListOwnerId:string;
    ListStatus: number;
    Active:string;
    ListSeq: number;
    CreatedBy: string;
    CreatedDate: Date;
    UpdatedBy: string;
    UpdatedDate: Date;
    ListParent: string;
    ProductPhaseID: string;
    ListType: number;
    ListTypeName: string;
}

export enum ListEnum {
    Phase = 1,
    Stage = 2,
    MileStone = 3,
    Project = 4
  }