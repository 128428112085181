export class OptionItems {
    Id: string;
    Name: string;
}


export class OptionsData {
    ReportId:string;
    ReportName: string ;
    TaskName: string;
    CreatedBy:string;
    CustomAttributes : OptionItems[];
    TaskNames : OptionItems[];
    TaskAttributes : OptionItems[];
}

export class CustomReportsList
{
    reportId:string;
    reportName:string;
    taskName:string;
    createdBy:string;
    createdDate:Date;
    updateddBy:string;
    updatedDate:Date;
}

