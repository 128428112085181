import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewactionService {

  constructor(private httpService: HttputilityService) { }

  SaveReview(reviewObj){
    return this.httpService.Add('PMAPI', `ReviewAction/CreateReview`, 'httpOptions', reviewObj)
  }

  UpdateReview(reviewObj){
    return this.httpService.Add('PMAPI', `ReviewAction/UpdateReview`, 'httpOptions', reviewObj)
  }

  DeleteReview(reviewObj){
    return this.httpService.Add('PMAPI', `ReviewAction/DeleteReview`, 'httpOptions', reviewObj)
  }

  GetReviews(userId){
    return this.httpService.Get('PMAPI', 'ReviewAction/GetAllReviews?userId=' + userId);
  }
  GetReviewsByProjectId(pId){
    return this.httpService.Get('PMAPI', 'ReviewAction/GetReviewsByProjectId?projectId=' + pId);
  }

  GetCurrentWeek()
  {
    return this.httpService.Get('PMAPI', 'ReviewAction/GetCurrentWeek');
  }
  GetDistinctPhases()
  {
    return this.httpService.Get('PMAPI', 'Lists/GetDistinctListNames');
  }
  GetAttributes()
  {
    return this.httpService.Get('PMAPI', 'Report/GetAttributes');
  }
  GetPrevReviews(ProjectID)
  {
    return this.httpService.Get('PMAPI', 'ReviewAction/GetPrevReviews?ProjectID=' + ProjectID);
  }
  SaveRemarks(model)
  {
    return this.httpService.Add('PMAPI', `ReviewAction/SaveRemarks`, 'httpOptions', model);
  }
}
