import { Injectable } from '@angular/core';
import {UserInfo} from '../demo/domain/userInformation'
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class UserauthService {

  constructor(private httpService: HttputilityService) { }

  userInfo: UserInfo;

  getloggedInUser(){
    this.userInfo = new UserInfo();
    this.userInfo.userguid = sessionStorage.getItem('userGuid');
    this.userInfo.firstName = sessionStorage.getItem('firstName');
    this.userInfo.profileDisplayExist = sessionStorage.getItem('profileDisplayExist') == "true" ? true : false;
    this.userInfo.profileDisplayPic = sessionStorage.getItem('profileDisplayPic');
    this.userInfo.applications = JSON.parse(sessionStorage.getItem('applications'));
    this.userInfo.userId = parseInt(sessionStorage.getItem('userId'));
    return this.userInfo;
  }

  setloggedInUser(userId){
    return this.httpService.Get('UMSAPI',`user/GetUserDetails?UserGUID=`+userId);
  }
}
