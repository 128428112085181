<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card">
            <h4 style="margin-top:5px;">Proposed Project Schedule</h4>
            <hr>
            
            <div >
                  <div style="min-height:200px;margin-top: -14px;border: 2px solid #e9ecef;">
                    <p-table [value]="scheduleData" [expandedRowKeys]="expandedRows" dataKey="name" rowExpandMode="single" styleClass="p-datatable-striped">
                      <ng-template pTemplate="header">
                          <tr>
                              <th class="width50"></th>
                              <th>Item</th>
                              <th>Name</th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-dates let-expanded="expanded">
                        <tr style="background-color:#eee;border-bottom: 3px solid #fff;">
                          <td class="width50">
                                  <button type="button" pButton pRipple [pRowToggler]="dates"
                                      class="p-button-text p-button-rounded p-button-plain"
                                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                              </td>
                              <td style="padding-left: 200px;">{{dates.item}}</td>
                              <td style="padding-left: 200px;">{{dates.name}} </td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="rowexpansion" let-dates>
                          <tr style="border: 2px solid #e9ecef;">
                              <td colspan="3">
                                  <div class="p-p-3">
                                      <p-table [value]="dates.projectDates" dataKey="name">
                                          <ng-template pTemplate="header">
                          <tr>
                              <th>Name</th>
                              <th>Current</th>
                              <th>Proposed</th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-date>
                          <tr>
                              <td style="padding-left: 15px;">{{date.name}}</td>
                              <td style="text-align: center;">{{date.current}}</td>
                              <td style="text-align: center;">
                                <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="date.proposed"  [disabled]="isReadOnly"></p-calendar>
                              </td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                          <tr>
                              <td colspan="4">No Data found.</td>
                          </tr>
                      </ng-template>
                  </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
        </p-table>
              </div>
            </div>
             </div>
    </div>
  </div>
  <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem" ></i>
  </div>

