import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FacadeService } from 'src/app/facade/facade.service';
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { AppRoutingModule } from 'src/app/app-routing.module';

@Component({
  selector: 'app-managedates',
  templateUrl: './managedates.component.html',
  styleUrls: ['./managedates.component.scss']
})
export class ManagedatesComponent implements OnInit {

showLoading:boolean;
userGuid = sessionStorage.getItem('userGuid');
projects:any=[];
selectedProject:any;
projectInformation: any;
projectStartDate:Date;
responseError:any;
tasksListsByProject: any[];
Overlapinfo:boolean = false;
selectedtasksListsByProject: any[];
projectdescriptiondisplay: boolean = false;
productselected:boolean = false;
constructor(private facadeService: FacadeService,private router: Router, private datePipe: DatePipe,) { }
filteredProdType: any[];
TargerLock:boolean=true;
addDocsDisplay: boolean;
Task:string = "";
AddError:string = "";
TaskID: string = "";
comments:string = "";
Add()
{
  this.AddError = "";
  this.responseError = "";
  if (this.TaskID == null || this.TaskID == "" || this.TaskID == undefined)
    this.AddError = "Please select milestone";
  else if (this.comments == null || this.comments == "" || this.comments == undefined)
    this.AddError = "Please enter reason";
  else {
    this.comments = this.comments.replace("<p>", ""); 
    this.comments = this.comments.replace("</p>", ""); 

    this.showLoading = true;
    this.tasksListsByProject.forEach(x=>{
      if(x.taskId.toLowerCase() == this.TaskID.toLowerCase())
        x.reason = this.comments;
    });
    this.addDocsDisplay = false;
    this.comments = "";
    this.TaskID = "";
    this.Task = "";
    this.showLoading = false;
  }
}
Edit(row)
{
  this.AddError = "";
  this.TaskID = row.taskId;
  this.Task = row.taskName;
  this.comments = row.reason;
  this.addDocsDisplay = true;
}

ngOnInit(): void {
  this.GetProjects();
}

GetProjects(){
  this.showLoading = true;
  this.facadeService.GetActiveProjects().subscribe(
    (res) => {
      this.projects = res.returnObject;
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    });
}
filterproductListChange(event) {
  let filtered : any[] = [];
  let query = event.query;
  for(let i = 0; i < this.projects.length; i++) {
    let product= this.projects[i];
    if (product.projectName.toLowerCase().indexOf(query.toLowerCase()) == 0)
      filtered.push(product);
  }    
  this.filteredProdType = filtered;
}
GetProjectByID(){
  this.showLoading = true;
  this.facadeService.getProjectDetailsById(this.selectedProject.projectId).subscribe(
    (success) => {
      if (success.returnCode == 0) 
      {
        this.TargerLock = true;
        this.productselected = true;
        if(success.returnObject.projectStartDate != '' || success.returnObject.projectStartDate != null){
            success.returnObject.projectStartDate = new Date(success.returnObject.projectStartDate).toLocaleDateString()
        }
        if(success.returnObject.targetDate != '' || success.returnObject.targetDate != null){
            success.returnObject.targetDate = new Date(success.returnObject.targetDate).toLocaleDateString()
        }
        this.projectStartDate = success.returnObject.projectStartDate;
        this.projectInformation = success.returnObject;
        if(this.projectInformation.actualStartDate==null|| this.projectInformation.actualStartDate==""||this.projectInformation.actualStartDate==undefined )
          this.TargerLock = false;
        this.getTaskListByproject();
      }
      else
      {
        this.responseError = "Unable to retrieve Project Information. We are working to get it.";
        this.productselected = false;
      }
    });
}
getTaskListByproject() {
  this.showLoading = true;
  this.responseError = "";
  this.facadeService.getTaskListByProject(this.selectedProject.projectId, this.selectedProject.projectId, "Project").subscribe(
    (sucess) => {
      if (sucess.returnObject) {
        this.tasksListsByProject = sucess.returnObject.taskList;
        this.tasksListsByProject.forEach(element => {
          element.reason = "";
          element.assigneeId = "N";
          element.editable = false;
          if (element.statusO == 'New' || element.statusO == 'InProgress')
            element.editable = true;
          if(element.plannedEndDate != null && element.plannedEndDate != '01/01/1970')
          {
            if (new Date(element.plannedEndDate) < new Date() && (element.status == 'New' || element.status == 'InProgress'))
              element.bold = true;
            else 
              element.bold = false;
          }
          else 
            element.bold = false;
          if(element.currentStartDate != null && element.currentStartDate != '01/01/1970' && element.currentStartDate != "--")
          {
            let dte = new Date(element.currentStartDate);
            dte.setDate(dte.getDate()-30);
            element.minactual = new Date(dte.toString());
          }
          else 
            element.minactual = new Date(this.projectStartDate);
          if(element.actualStartDate != null && element.actualStartDate != '01/01/1970' && element.actualStartDate != "--")
            element.actualStartDateVAL = new Date(element.actualStartDate);
        });
      }
      this.showLoading = false;
    }
  )
}

Getdates(taskid,type,seq)
{
  this.showLoading = true;
  this.responseError = "";
  var ok = true;
  if(this.tasksListsByProject!=null && this.tasksListsByProject.length>0){
    this.tasksListsByProject.forEach(element => {
      if(element.taskId.toLowerCase() == taskid.toLowerCase())
      {
        if(type == "AS" && ok)
        {
          if(element.actualStartDate == undefined || element.actualStartDate == null || element.actualStartDate == "--" || element.actualStartDate == "")
          {
            this.responseError = "Please enter actual start date";
            ok = false; this.showLoading = false;
          }
          else if(new Date(element.actualStartDate) > new Date())
          {
            element.actualStartDate = "";
            this.responseError = "Actual start date should be less then or equal to today's date";
            ok = false; this.showLoading = false;
          }
          else
            element.actualStartDate = this.datePipe.transform(element.actualStartDate, "MM/dd/yyyy");
        }
        if(type == "AE" && ok)
        {
          if(element.actualEndDate == undefined || element.actualEndDate == null || element.actualEndDate == "--" || element.actualEndDate == "")
          {
            this.responseError = "Please enter actual end date";
            ok = false; this.showLoading = false;
          }
          else if(new Date(element.actualEndDate) > new Date())
          {
            element.actualEndDate = "";
            this.responseError = "Actual end date should be less then or equal to today's date";
            ok = false; this.showLoading = false;
          }
          else
            element.actualEndDate = this.datePipe.transform(element.actualEndDate, "MM/dd/yyyy");
        }
        if((type == "AE" || type == "AS") && ok)
        {
          if (element.actualEndDate != undefined && element.actualEndDate != null && element.actualEndDate != "--" && element.actualEndDate != "")
          {
            if(element.actualStartDate == undefined || element.actualStartDate == null || element.actualStartDate == "--" || element.actualStartDate == "")
            {
              this.responseError = "Actual start date is required";
              ok = false; this.showLoading = false;
            }
            else if(new Date(element.actualStartDate) > new Date(element.actualEndDate))
            {
              element.actualEndDate = "";
              this.responseError = "Actual start date should be less then or equal to actual end date";
              ok = false; this.showLoading = false;
            }
            else
              element.status = "Completed";
          }
          else
            element.status = "InProgress";
        }
        if(type == "CE" && ok)
        {
          if(element.currentEndDate == undefined || element.currentEndDate == null || element.currentEndDate == "--" || element.currentEndDate == "")
          {
            this.responseError = "Please enter current end date";
            ok = false; this.showLoading = false;
          }
          else
            element.currentEndDate = this.datePipe.transform(element.currentEndDate, "MM/dd/yyyy");
        }
        if(type == "TE" && ok)
        {
          if(element.plannedEndDate == undefined || element.plannedEndDate == null || element.plannedEndDate == "--" || element.plannedEndDate == "")
          {
            this.responseError = "Please enter target end date";
            ok = false; this.showLoading = false;
          }
          else
            element.plannedEndDate = this.datePipe.transform(element.plannedEndDate, "MM/dd/yyyy");
        }
        if(ok)
        {   
          if(type == "AS")
          {
            if(this.datePipe.transform(element.actualStartDate, "MM/dd/yyyy") != this.datePipe.transform(element.actualStartDateO, "MM/dd/yyyy")
            && element.statusO == "Completed")
            {
              element.assigneeId = "Y";
              element.actualStartOverride = true;
            }
            else
              element.actualStartOverride = false;
          }
          if(type == "AE")
          {
            if(this.datePipe.transform(element.actualEndDate, "MM/dd/yyyy") != this.datePipe.transform(element.actualEndDateO, "MM/dd/yyyy")
            && element.statusO == "Completed")
            {
              element.assigneeId = "Y";
              element.actualEndOverride = true;
            }
            else
              element.actualEndOverride = false;
          }
          if(type == "CE")
          {
            if(this.datePipe.transform(element.currentEndDate, "MM/dd/yyyy") != this.datePipe.transform(element.currentEndDateO, "MM/dd/yyyy"))
            {
              element.assigneeId = "Y";
              element.currentOverride = true;
            }
            else
              element.currentOverride = false;
          }
          if(type == "TE")
          {
            if(this.datePipe.transform(element.plannedEndDate, "MM/dd/yyyy") != this.datePipe.transform(element.plannedEndDateO, "MM/dd/yyyy"))
            {
              element.assigneeId = "Y";
              element.targetOverride = true;
            }
            else
              element.targetOverride = false;
          }
        }
      }
    });
  }
  if(ok)
  {
    var targetchange = false;
    if(type == "TE")
      targetchange = true;
    var model = {
      "Tasks":this.tasksListsByProject,
      "Update":false,
      "UpdatedBy": this.userGuid,
      "TargetChange" :targetchange,
      "TaskSeq" : seq
    }
    this.facadeService.GetManageMilestoneDates(model).subscribe(
      (data) => {
        if(data.returnCode==0){
          this.tasksListsByProject = data.returnObject;
          this.tasksListsByProject.forEach(element => {
            element.editable = false;
            if (element.statusO == 'New' || element.statusO == 'InProgress')
              element.editable = true;
            if(element.plannedEndDate != null && element.plannedEndDate != '01/01/1970')
            {
              if (new Date(element.plannedEndDate) < new Date() && (element.status == 'New' || element.status == 'InProgress'))
                element.bold = true;
              else 
                element.bold = false;
            }
            else 
              element.bold = false;
            if(element.currentStartDate != null && element.currentStartDate != '01/01/1970' && element.currentStartDate != "--")
            {
              let dte = new Date(element.currentStartDate);
              dte.setDate(dte.getDate()-30);
              element.minactual = new Date(dte.toString());
            }
            else 
              element.minactual = new Date(this.projectStartDate);
            if(element.actualStartDate != null && element.actualStartDate != '01/01/1970' && element.actualStartDate != "--")
              element.actualStartDateVAL = new Date(element.actualStartDate);
          }); 
          this.showLoading = false;  
        }
        else
        {
          this.showLoading = false;
          this.responseError = data.returnObject.taskList[0].taskName;
        }
      },
      (error) => {
        this.showLoading = false;
        this.responseError = error;
        console.log(error);
      } 
    );
  }
}
update()
{
  this.showLoading = true;
  this.responseError = ""
  let ok = true;
  var taskname = "";
  this.tasksListsByProject.forEach(element => {
    if(element.assigneeId == "Y" && element.reason == "" && ok)
    {
      taskname = element.taskName;
      ok = false;
    }
  });
  if(ok)
  {
    var model = {
      "Tasks":this.tasksListsByProject,
      "Update":true,
      "UpdatedBy": this.userGuid,
      "TargetChange" :false,
      "TaskSeq" : 0
    }
    this.facadeService.GetManageMilestoneDates(model).subscribe(
      (data) => {
        if(data.returnCode==0){
          this.tasksListsByProject = data.returnObject;
          this.tasksListsByProject.forEach(element => {
            if(element.plannedEndDate != null && element.plannedEndDate != '01/01/1970')
            {
              element.editable = false;
              if (element.statusO == 'New' || element.statusO == 'InProgress')
                element.editable = true;

              if (new Date(element.plannedEndDate) < new Date() && (element.status == 'New' || element.status == 'InProgress'))
                  element.bold = true;
              else 
                  element.bold = false;
            }
            else 
                element.bold = false;
          });
          this.responseError = "Saved successfully";
          this.showLoading = false;
        }
        else
        {
          this.responseError = data.returnObject[0].reason;
          this.showLoading = false;
        }
      },
      (error) => {
        this.showLoading = false;
        this.responseError = error;
        console.log(error);
      } 
    );
  }
  else
  {
    this.showLoading = false;
    this.responseError = "Please add reason for "+ taskname + " milestone as it has been edited";
  }
}
open(val,val1)
{
  this.router.navigate(['/customfields',{ projectId: val, pid: val1 }]);     
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'plannedStartDate'||event.field === 'plannedEndDate'||event.field === 'currentStartDate'
    ||event.field === 'currentEndDate'||event.field === 'actualStartDate'||event.field === 'actualEndDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });
}
}
