import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Product } from '../../domain/product';
import { BreadcrumbService } from '../../../app.breadcrumb.service';
import { FacadeService } from 'src/app/facade/facade.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { OptionItems, CustomReportsList } from '../../domain/CustomReports';
import { Space } from '../../domain/space';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from "src/injector";
import * as XLSX from 'xlsx'; 

@Component({
    selector: 'app-managespace',
    templateUrl: './managespace.component.html',

    styles: [`:host ::ng-deep .p-multiselect {
		min-width: 15rem;
	}

	:host ::ng-deep .multiselect-custom-virtual-scroll .p-multiselect {
		min-width: 20rem;
	}

	:host ::ng-deep .multiselect-custom .p-multiselect-label {
		padding-top: .25rem;
		padding-bottom: .25rem;

	}


	:host ::ng-deep .multiselect-custom .country-item.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}

	:host ::ng-deep .multiselect-custom .country-item.country-item-value img.flag {
		width: 17px;
	}

	:host ::ng-deep .multiselect-custom .country-item {
		display: flex;
		align-items: center;
	}

	:host ::ng-deep .multiselect-custom .country-item img.flag {
		width: 18px;
		margin-right: .5rem;
	}

	:host ::ng-deep .multiselect-custom .country-placeholder {
		padding: 0.25rem;
	}

	:host ::ng-deep .p-colorpicker {
		width: 2.5em
	}
    `],
    providers: [MessageService]
})
export class ManagespaceComponent implements OnInit {


    products: Product[];
    display: boolean;
    reportPopupDisplay: boolean = false;
    editDisplay: boolean;
    selectedMulti: any;
    countries: any[];
    projecName: any;
    projectManagerList: [];
    projectId: string;
    submitted: boolean = false;
    createSpaceform: FormGroup;
    responseError: string;
    spacedisplay: boolean;
    Customreport: FormGroup;
    showLoading: boolean = false;
    spaceSubmitted: boolean;
    optionsTaskNames: OptionItems[];
    optionsCustomAttributes: OptionItems[];
    optionsTaskAttributes: OptionItems[];
    CustomReportsLists: CustomReportsList[];
    SpaceLists: Space[];
    //Edit popupdata
    editReportName: string;
    editoptionsCustomAttributes: OptionItems[];
    editoptionsTaskAttributes: OptionItems[];
    editoptionsTaskNames: OptionItems[];
    editTaskName: string;
    editCustomReportId: string;
    rptActionType: string;
    spaceName: string = "";
    editSpaceId: string = "";
    spaceDeleteConfirm: boolean = false;
    deletespaceName: string = '';
    deletespaceId: string = '';
    constructor(
        private breadcrumbService: BreadcrumbService,
        private facadeService: FacadeService, private formBuilder: FormBuilder,
        @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard', routerLink: ['/'] }
        ]);
    }

    ngOnInit() {
        this.GetAllSpace();

        this.createSpaceform = this.formBuilder.group({
            newSpace: new FormControl('', Validators.required)
        });
    }

    onSpaceSubmit(spaceVal) {
        this.spaceSubmitted = true;
        this.createSpaceform.controls.newSpace.setValue(spaceVal);
        if (this.createSpaceform.valid) {
            const spaceData = this.createSpaceform.controls.newSpace.value;
            this.saveSpace(spaceData);
            this.spaceSubmitted = false;
        }
    }

    saveSpace(space) {
        this.responseError = "";
        this.spaceSubmitted = true;
        let spaceDTO = new Space();
        spaceDTO.SpaceName = space;
        spaceDTO.CreatedBy = this.facadeService.getloggedInUser().userguid;
        if (this.editSpaceId != null && this.editSpaceId != "") {
            spaceDTO.SpaceId = this.editSpaceId;
        }
        this.showLoading = true;
        this.facadeService.saveSpace(spaceDTO).subscribe(
            (response) => {
                this.showLoading = false;
                if (response.returnCode == 0) {
                    this.GetAllSpace();
                    this.createSpaceform.reset();
                    this.spacedisplay = false;
                }
                else {
                    this.responseError = response.returnMessage;
                }
            }
        );
        this.spaceSubmitted = false;
    }

    onSpaceEdit(spaceId: string, spaceName: string) {
        this.responseError = "";
        console.log("spaceId" + spaceId);
        console.log("spaceName" + spaceName);
        this.spaceName = spaceName;
        this.spacedisplay = true;
        this.editSpaceId = spaceId;
    }

    onSpaceDelete(spaceId: string, spaceName: string) {
        debugger
        this.responseError = "";
        console.log("spaceId" + spaceId);
        console.log("spaceName" + spaceName);
        this.deletespaceName = spaceName;
        this.spaceDeleteConfirm = true;
        this.deletespaceId = spaceId;
    }

    onSpaceDeleteConfirmed() {
        debugger
        console.log(this.deletespaceName)
        console.log(this.deletespaceId)
        this.responseError = "";
        this.showLoading = true;
        let spaceDTO = new Space();
        spaceDTO.SpaceName = this.deletespaceName;
        spaceDTO.SpaceId = this.deletespaceId;
        spaceDTO.CreatedBy = this.facadeService.getloggedInUser().userguid;
        this.showLoading = true;
        this.facadeService.deleteSpace(spaceDTO).subscribe(
            (response) => {
                this.spaceDeleteConfirm = false;
                if (response.returnCode == 0) {
                    this.GetAllSpace();
                }
                else {
                    this.responseError = response.returnMessage;
                }
            }
        );
    }

    GetAllSpace() {
        this.showLoading = true;
        this.facadeService.getSpaceList()
            .subscribe((spaces) => {
                this.showLoading = false;
                if (spaces.returnObject != undefined && spaces.returnObject != null) {
                    this.SpaceLists = spaces.returnObject;
                }
            });
    }

    exportExcel() {
          /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "Space List.xlsx");
    }
    
   
delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['C1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }

    print() {
        window.print();
    }

    onClose() {
        this.reportPopupDisplay = false;
    }
}
