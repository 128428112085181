import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'keys'})
export  class KeysPipe implements PipeTransform {
  transform(value, args:string[]) : any {
    let keys = [];
    for (let key in value) {
      //console.log("Pipe: "+key);
      if(key != 'ProjectID')
        keys.push(key);
    }
    return keys;
  }
}

@Pipe({name: 'keysall'})
export  class KeysPipeAll implements PipeTransform {
  transform(value, args:string[]) : any {
    let keys = [];
    for (let key in value) {
        keys.push(key);
    }
    return keys;
  }
}