import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from "primeng/table";
import { FacadeService } from 'src/app/facade/facade.service';
import { AdminService } from 'src/app/services/admin.service';
import { BreadcrumbService } from '../../../app.breadcrumb.service';
import {Proportionate } from 'src/app/demo/domain/admin';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { BASE_API_URL_TOKEN } from 'src/injector';
import { Inject } from '@angular/core';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import * as XLSX from 'xlsx'; 
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-proportionaltime',
  templateUrl: './proportionaltime.component.html',
  styleUrls: ['./proportionaltime.component.scss'],
  providers: [MessageService],
})
export class ProportionaltimeComponent implements OnInit {
  showLoading: boolean = false;
  ProportionateList: Proportionate[];
  Maindisplay:boolean=false;
  TaskName:string='';
  Level:number = 0;
  Value:number = 0;
  createform: FormGroup;

  historydisplay :boolean=false;
  HistoryTask:string="";
  HistoryLevel:string="";
  HistoryCols: any[];
  History: any[];
  selectedHistory: any[];
  @ViewChild("dtHistory") tableHistory: Table;

  responseError: string;
  Name:string;
  DeleteConfirm:boolean=false;
  ID:number;
  userFeature:boolean=false;
  constructor(private adminService: AdminService,private facadeService: FacadeService,
    private breadcrumbService: BreadcrumbService, private formBuilder: FormBuilder,
    private printService:PrintcommonService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
      this.breadcrumbService.setItems([
        { label: 'Dashboard', routerLink: ['/'] }
      ]);
    }

  ngOnInit(): void {
    this.HistoryCols = [
      { field: "editedByName", header: "Edited By" },
      { field: "editedDate", header: "Edited Date" },
      { field: "oldValue", header: "Old Proportionate Cycle Time(%)" },
      { field: "newValue", header: "New Proportionate Cycle Time(%)" },
    ];

    this.GetProportionateTime();

    this.createform = this.formBuilder.group({
      fName: new FormControl('', Validators.required),
      fLevel: new FormControl('', Validators.required),
      fValue: new FormControl('', Validators.required),
    });
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PM_DSBL_AEDB");
        if(featureExist!=null && featureExist != undefined)
          this.userFeature = true;
      }
    }
  }

  Details(ID,stask,slevel) {
    this.showLoading = true;
    this.historydisplay = true;
    this.HistoryTask = stask;
    this.HistoryLevel = slevel;
    this.adminService.GetProportionateTimeHistory(ID).subscribe(
      (data) => {
        this.History = data.returnObject;
        this.History.forEach((value, key) => {
          if ( value.editedDate !== null &&  value.editedDate !== undefined ) {
            value.editedDate = new Date(value.editedDate + "Z");
            value.editedDate = (value.editedDate);
          }
        });
        this.showLoading= false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  GetProportionateTime() {
    this.showLoading = true;
    this.adminService.GetProportionateTime()
      .subscribe((res) => {
        this.showLoading = false;
        if (res.returnObject != undefined && res.returnObject != null) {
          this.ProportionateList = res.returnObject;
        }
      });
  }

  onSubmit() {
    this.showLoading = true;
    if (this.createform.valid) {
      this.saveProjectDesc();
    }
    else
      this.showLoading = false;
  }

  saveProjectDesc() {
    this.responseError = "";
    let DTO = new Proportionate();
    DTO.taskName = this.TaskName;
    DTO.level = this.Level;
    DTO.value = this.Value;
    DTO.actionBy = this.facadeService.getloggedInUser().userguid;

    if (this.ID != null && this.ID != 0) {
      DTO.ProportionateId = this.ID;
    }
    this.showLoading = true;
    this.adminService.AddProportionateTime(DTO).subscribe(
      (response) => {
        this.showLoading = false;
        if(response.returnCode == 1000){
          this.responseError = response.returnObject;
        }
        if (response.returnCode == 0) {
            this.GetProportionateTime();
            this.createform.reset();
            this.Maindisplay = false;
            this.TaskName = '';
            this.Level = 0;
            this.Value = 0;
            this.ID = 0;
        }
        else {
          this.responseError = response.returnMessage;
        }
      }
    );
  }
  onAdd()
  {
    this.TaskName = '';this.ID = 0;this.Level=0;this.Value=0; this.Maindisplay = true;
    this.createform.reset();this.responseError='';
    this.createform.controls['fName'].enable();
      this.createform.controls['fLevel'].enable();
  
  }

  onEdit(ID: number, taskName: string,level:number,value:number) {
    this.responseError = "";
    this.TaskName = taskName;
    this.Level = level;
    this.Value = value;
    this.Maindisplay = true;
    this.ID = ID;
    this.createform.controls['fName'].disable();
    this.createform.controls['fLevel'].disable();
  }

  onDelete(ID: number, taskName: string,level:number,value:number) {
    this.responseError = "";
    this.Name = taskName + " - " + level + " - " + value;
    this.DeleteConfirm = true;
    this.ID = ID;
  }

  onDeleteConfirmed() {
    this.responseError = "";
    this.showLoading = true;
    this.adminService.DeleteProportionateTime(this.ID,this.facadeService.getloggedInUser().userguid).subscribe(
      (response) => {
        this.DeleteConfirm = false;
        if (response.returnCode == 0) {
          this.ID= 0;
          this.GetProportionateTime();
        }
        else {
          this.responseError = response.returnMessage;
        }
      }
    );
  }

  exportExcel() {
         /* table id is passed over here */   
         let element = document.getElementById('excel-table'); 
         const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
         /* generate workbook and add the worksheet */
         const wb: XLSX.WorkBook = XLSX.utils.book_new();
         XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
         this.delete_row(wb.Sheets.Sheet1, 1);
         ws['!cols'] = [{ wpx : 220 },{ wpx : 100 },{ wpx : 150 },{ wpx : 70 }];
         /* save to file */
         XLSX.writeFile(wb, "Proportionate Time List.xlsx");
  }

  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;&nbsp;" || ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['D1'])
    delete (ws['E1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }

  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Proportionate Time List','trSearch',true,2);
}

}
