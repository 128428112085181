<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto;">
                <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">P2 Summary</h5>
                <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                    <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
                        tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                        style="float:right;height:25px; width:25px;"></button>
                    <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()"
                        pTooltip="Download" tooltipPosition="top"
                        class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style="float:right; height:25px; width:25px;"></button>
                </span>
            </div>
            <br>
            <p-accordion>
                <p-accordionTab header="Projects" [selected]="true">
                    <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
                        <p-table #dt [value]="testtemplateData" id="excel-table" [columns]="cols" sortMode="multiple"
                            class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                            selectionMode="multiple" dataKey="id" [globalFilterFields]="['projectdescription','project','phase','diecode','mfgpart',
                    'productfunction','package','marketinglead','productengineer','selectattribute']">
                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                <tr>
                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                        [ngClass]="{'projectdescription':i == 0,'project': i == 1,'phase':i == 2,'diecode': i == 3,
                                'mfgpart':i== 4,'productfunction': i == 5,'package':i== 6,'marketinglead':i== 7,'productengineer': i == 8,
                                'selectattribute':i== 9}">
                                        {{col.header}}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th style="width:80px;">Actions</th>
                                </tr>
                                <tr>
                                    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                        <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                            (input)="dt.filter($event.target.value, col.field, col.contains)"
                                            style="margin: -10px 0 -10px 0;width: 95%; height:20px;">
                                    </th>
                                    <th></th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr [pSelectableRow]="rowData">
                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                        <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                                            {{ rowData[col.field] }}
                                        </td>
                                        <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 4" style="text-align: left;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 5" style="text-align: left;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 6" style="text-align: left;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                    </ng-container>
                                    <td style="text-align: center;">
                                        <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                            class="p-button-raised p-button-primary"
                                            style="width:25px; height:25px;"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7" style="text-align: left;">No data found.</td>
                                </tr>
                            </ng-template>
                        </p-table>

                    </div>
                </p-accordionTab>
            </p-accordion>
            <br><br>
            <div class="p-grid">
                <div class="p-col-12 p-md-4 p-xl-4">
                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px">
                        <ul class="widget-image-list">
                            <li>
                                <span style="font-weight: bold;">Project Description:</span>
                                <span></span>
                            </li>
                            <li>
                                <span style="font-weight: bold;">Project:</span>
                                <span></span>
                            </li>
                            <li>
                                <span style="font-weight: bold;">Phase:</span>
                                <span></span>
                            </li>
                            <li>
                                <span style="font-weight: bold;">Die Code:</span>
                                <span></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="p-col-12 p-md-4 p-xl-4">
                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px">
                        <ul class="widget-image-list">
                            <li>
                                <span style="font-weight: bold;">Mfg Part #:</span>
                                <span></span>
                            </li>
                            <li>
                                <span style="font-weight: bold;">Product Function:</span>
                                <span></span>
                            </li>
                            <li>
                                <span style="font-weight: bold;">Package:</span>
                                <span></span>
                            </li>
                            <li>
                                <span style="font-weight: bold;">Marketing Lead:</span>
                                <span></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="p-col-12 p-md-4 p-xl-4">
                    <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px">
                        <ul class="widget-image-list">
                            <li>
                                <span style="font-weight: bold;">Product Engineer:</span>
                                <span></span>
                            </li>
                            <li>
                                <span style="font-weight: bold;">Select Attribute:</span>
                                <span></span>
                            </li>
                            <li>
                                <span style="font-weight: bold;">Start Date:</span>
                                <span></span>
                            </li>
                            <li>
                                <span style="font-weight: bold;">End Date:</span>
                                <span></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <br><br>
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
                <p-table #dt [value]="testtemplateData" id="excel-table" [columns]="cols1" sortMode="multiple"
                    class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                    dataKey="id"
                    [globalFilterFields]="['tapeoutdate','tapeoutpassdate','fabout','cob','assemblyout',
                    'untestedshipout','ftout','testedshipout','rttestpgm','esdlu','guntest','htol500hrs','pkgqualtest','appsroadtestqual','rtp','pcn']">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                [ngClass]="{'tapeoutdate':i == 0,'tapeoutpassdate': i == 1,'fabout':i == 2,'cob': i == 3,
                                'assemblyout':i== 4,'untestedshipout': i == 5,'ftout': i == 6,'testedshipout':i== 7,'rttestpgm':i== 8,
                                'esdlu':i == 9,'guntest': i == 10,'htol500hrs':i == 11,'pkgqualtest': i == 12,'appsroadtestqual':i== 13,'rtp': i == 14,'pcn':i== 15}">
                                {{col.header}}
                            </th>
                        </tr>
                        <!-- <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="dt.filter($event.target.value, col.field, col.contains)"
                                    style="margin: -10px 0 -10px 0;width: 95%; height:20px;">
                            </th>
                        </tr> -->
                    </ng-template>

                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                                    {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 5" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 6" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 7" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 8" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 9" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 10" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 11" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 12" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 13" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 14" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 15" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="16" style="text-align: left;">No data found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <br>
            <div class="card">
            <div class="p-field p-col-12 p-md-12 p-xl-12">
                <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 100px">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-10 p-sm-12">
                            <label for="CurrentWeekRemarks">Current Week Remarks</label>
                            <textarea id="CurrentWeekRemarks" type="text" rows="2" [maxLength]="300" pInputTextarea
                                placeholder="Current Week Remarks">
                    </textarea>
                        </div>
                        <div class="p-field p-col-12 p-md-2 p-sm-12">
                            <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                                class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                style="width: 100%;margin-top: 35px;"></button>
                        </div>
                    </div>
                </div>
            </div>
            <p-accordion>
                <p-accordionTab header="Previous Remarks" [selected]="true">
                    <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
                        <p-table #dt [value]="testtemplateData" id="excel-table" [columns]="cols2" sortMode="multiple"
                            class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                            selectionMode="multiple" dataKey="id"
                            [globalFilterFields]="['workweek','remarks','addedby','addeddate']">
                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                <tr>
                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                        [ngClass]="{'workweek':i == 0,'remarks':i == 1,'addedby': i == 2,'addeddate':i == 3}">
                                        {{col.header}}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                </tr>
                                <tr>
                                    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                        <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                            (input)="dt.filter($event.target.value, col.field, col.contains)"
                                            style="margin: -10px 0 -10px 0;width: 95%; height:20px;">
                                    </th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr [pSelectableRow]="rowData">
                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                        <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                                            {{ rowData[col.field] }}
                                        </td>
                                        <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3" style="text-align: left;">No data found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-accordionTab>
            </p-accordion>
            </div>
            <div class="card">
            <div class="p-field p-col-12 p-md-12 p-xl-12">
                <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 100px">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-10 p-sm-12">
                            <label for="MeetingNotes">Current Week Meeting Notes</label>
                            <textarea id="MeetingNotes" type="text" rows="2" [maxLength]="300" pInputTextarea
                                placeholder="Meeting Notes">
                    </textarea>
                        </div>
                        <div class="p-field p-col-12 p-md-2 p-sm-12">
                            <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                                class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                style="width: 100%;margin-top: 35px;"></button>
                        </div>
                    </div>
                </div>
            </div>
            <p-accordion>
                <p-accordionTab header="Previous Meeting Notes" [selected]="true">
                    <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
                        <p-table #dt [value]="testtemplateData" id="excel-table" [columns]="cols3" sortMode="multiple"
                            class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                            selectionMode="multiple" dataKey="id"
                            [globalFilterFields]="['workweek','meetingnotes','addedby','addeddate']">
                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                <tr>
                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                        [ngClass]="{'workweek':i == 0,'meetingnotes':i == 1,'addedby': i == 2,'addeddate':i == 3}">
                                        {{col.header}}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                </tr>
                                <tr>
                                    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                        <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                            (input)="dt.filter($event.target.value, col.field, col.contains)"
                                            style="margin: -10px 0 -10px 0;width: 95%; height:20px;">
                                    </th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr [pSelectableRow]="rowData">
                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                        <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                                            {{ rowData[col.field] }}
                                        </td>
                                        <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3" style="text-align: left;">No data found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
        </div>
    </div>
</div>