import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FacadeService } from 'src/app/facade/facade.service';
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-manageassignee',
  templateUrl: './manageassignee.component.html',
  styleUrls: ['./manageassignee.component.scss']
})
export class ManageassigneeComponent implements OnInit {

  showLoading:boolean;
  userGuid = sessionStorage.getItem('userGuid');
  projects:any=[];
  selectedProject:any;
  projectInformation: any;
  projectStartDate:Date;
  responseError:any;
  tasksListsByProject: any[];
  Overlapinfo:boolean = false;
  selectedtasksListsByProject: any[];
  projectdescriptiondisplay: boolean = false;
  productselected:boolean = false;
  constructor(private facadeService: FacadeService,private router: Router, private datePipe: DatePipe,) { }
  filteredProdType: any[];
  ngOnInit(): void {
    this.GetProjects();
  }
  
  GetProjects(){
    this.showLoading = true;
    this.facadeService.GetActiveProjects().subscribe(
      (res) => {
        this.projects = res.returnObject;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      });
  }
  filterproductListChange(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.projects.length; i++) {
      let product= this.projects[i];
      if (product.projectName.toLowerCase().indexOf(query.toLowerCase()) == 0)
        filtered.push(product);
    }    
    this.filteredProdType = filtered;
  }
  GetProjectByID(){
    this.showLoading = true;
    this.facadeService.getProjectDetailsById(this.selectedProject.projectId).subscribe(
      (success) => {
        if (success.returnCode == 0) 
        {
          this.productselected = true;
          if(success.returnObject.projectStartDate != '' || success.returnObject.projectStartDate != null){
              success.returnObject.projectStartDate = new Date(success.returnObject.projectStartDate).toLocaleDateString()
          }
          if(success.returnObject.targetDate != '' || success.returnObject.targetDate != null){
              success.returnObject.targetDate = new Date(success.returnObject.targetDate).toLocaleDateString()
          }
          this.projectStartDate = success.returnObject.projectStartDate;
          this.projectInformation = success.returnObject;
          this.getTaskListByproject(false);
        }
        else
        {
          this.responseError = "Unable to retrieve Project Information. We are working to get it.";
          this.productselected = false;
        }
      });
  }
  getTaskListByproject(respose) {
    this.showLoading = true;
    if(!respose)
      this.responseError = "";
    this.facadeService.GetTasksManageAssignee(this.selectedProject.projectId).subscribe(
      (sucess) => {
        this.tasksListsByProject = sucess.returnObject;
        if(this.tasksListsByProject != null && this.tasksListsByProject != undefined)
        {
          if(this.tasksListsByProject.length > 0)
          {
            this.tasksListsByProject.forEach(e => {
              e.proposedAssigned = e.taskAssignees;
              e.proposedAssignedName = e.assigneeName;
              var t = e.taskAssignees[0];
              e.taskAssignees1 = t;
            });
          }
        }
        if(respose)
          this.responseError = "Saved successfully";
        this.showLoading = false;
      }
    )
  }

  AssigneeChange(event,id) {
    this.showLoading=true;
    this.tasksListsByProject.forEach(e => {
      if(e.taskId == id)
      {
        e.proposedAssignedName = event.value.assignedName;
        e.proposedAssigned = [];
        e.proposedAssigned.push(event.value);
      }
    });
    this.showLoading = false;
  }

  AssigneeMultiChange(event,id) {
    this.showLoading=true;
    this.tasksListsByProject.forEach(e => {
      if(e.taskId == id)
      {
        e.proposedAssigned = [];
        var a = "";
        if(event.value.length > 0)
        {
          event.value.forEach(q => {
            a = a + q.assignedName + ", ";
            e.proposedAssigned.push(q);
          });
        }
        e.proposedAssignedName = a.trim();
      }
    });
    this.showLoading = false;
  }
  
  update()
  {
    this.showLoading = true;
    this.responseError = "";
    let ok = false;
    var changedtasks = [];
    this.tasksListsByProject.forEach(element => {
      if(element.proposedAssignedName != element.assigneeName)
      {
        var Assignee = [];
        if(element.proposedAssigned != null && element.proposedAssigned != undefined)
        {
          element.proposedAssigned.forEach(e => {
            Assignee.push(e.assigneeId);
          });
        }
        var model = {
          "TaskId": element.taskId,
          "UpdatedBy": this.userGuid,
          "Assignee":Assignee,
        }
        changedtasks.push(model);
        ok = true;
      }
    });
    if(ok)
    {
      this.facadeService.UpdateTaskAssignee(changedtasks).subscribe(
        (data) => {
          if(data.returnCode==0){
            this.getTaskListByproject(true);
          }
          else
          {
            this.responseError = data.returnObject;
            this.showLoading = false;
          }
        },
        (error) => {
          this.showLoading = false;
          this.responseError = error;
          console.log(error);
        } 
      );
    }
    else
    {
      this.showLoading = false;
      this.responseError = "Please change atleast one assignee to upadte";
    }
  }
  open(val,val1)
  {
    this.router.navigate(['/customfields',{ projectId: val, pid: val1 }]);     
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
  
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (event.field === 'startDate'||event.field === 'dueDate') {
        // Convert the string dates to Date objects for correct sorting
        const date1 = value1 === '--' ? null : new Date(value1);
        const date2 = value2 === '--' ? null : new Date(value2);
  
        // Handle special case: treat "--" as greater or smaller than any date
        if (date1 === null) {
          result = date2 === null ? 0 : -1;
        } else if (date2 === null) {
          result = 1;
        } else {
          result = date1.getTime() - date2.getTime();
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
  
      return (event.order * result);
    });
  }
  }
  