export class Overlap {
    overlapId: number;
    isError: boolean;
    errorMessage: string;
    taskName:string;
    level:number;
    value:number;
    actionBy: string;
}

export class Proportionate {
    ProportionateId: number;
    isError: boolean;
    errorMessage: string;
    taskName:string;
    level:number;
    value:number;
    actionBy: string;
}

export class TaskAttributes {
    id: number;
    isError: boolean;
    errorMessage: string;
    taskName:string;
    type:string;
    customFieldId:string;
    value:number;
    actionBy: string;
}