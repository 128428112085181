<div class="layout-dashboard">
	<br>
	<div class="p-grid">
		<div class="p-col-12 p-md-6 p-xl-4">
			<a routerLink="/projectlist">	<div class="card no-gutter widget-overview-box widget-overview-box-1">
			  <span class="overview-icon">
				<i class="pi pi-briefcase"></i>
			  </span>
			  <span class="overview-title">My Projects</span>
			  <div class="p-grid overview-detail">
				<div class="p-col-6"> 
				  <div class="overview-number">{{tilesData.activeOverdueProjectsCount}}
				  </div>
				  <div class="overview-subtext">Active</div>
				</div>
				<div class="p-col-6">
				  <div class="overview-number">{{tilesData.holdProjectsCount}}
				  </div>
				  <div class="overview-subtext">Hold</div>
				</div>
			  </div>
			</div></a>
		</div>
		<div class="p-col-12 p-md-6 p-xl-4">
		<a routerLink="/tasklist"><div class="card no-gutter widget-overview-box widget-overview-box-2">
			<span class="overview-icon">
			<i class="pi pi-check-square"></i>
			</span>
			<span class="overview-title">My Milestones</span>
			<div class="p-grid overview-detail">
			<div class="p-col-6">
				<div class="overview-number">{{tilesData.totalTasksCount}}</div>
				<div class="overview-subtext">Assigned</div>
			</div>
			<div class="p-col-6">
				<div class="overview-number">
				{{tilesData.completedTasksCount}}
				</div>
				<div class="overview-subtext">Completed</div>
			</div>
			</div>
		</div></a>
		</div>
		<div class="p-col-12 p-md-6 p-xl-4">
		<a routerLink="/tasklist"><div class="card no-gutter widget-overview-box widget-overview-box-3">
			<span class="overview-icon">
			<i class="pi pi-calendar-minus"></i>
			</span>
			<span class="overview-title">Overdue</span>
			<div class="p-grid overview-detail">
			<div class="p-col-6">
				<a routerLink="/projectlist">
				<div class="overview-number">{{tilesData.overDueProjectsCount}}</div>
				<div class="overview-subtext">Projects</div>
			</a>
			</div>
			<div class="p-col-6">
				<a routerLink="/tasklist">
				<div class="overview-number">{{tilesData.overDueTasksCount}}</div>
				<div class="overview-subtext">Milestones</div>
			</a>
			</div>
			</div>
		</div></a>
		</div>
		<div class="p-col-12 p-lg-8">
			<div class="card revenue" style="height:400px;">
				<h4 style="padding-bottom:15px;">Projects by Phase</h4>
				<hr>
				<div *ngIf="isChartDataAvailable;else Chartemptymessage;" class="revenue-chart-container" style="width:550px;height:450px;margin-left: 20%;margin-top: 3%;">
				<p-chart
					type="pie"
					id="revenue-chart"
					[data]="revenueChart"
					[options]="options"
				></p-chart>
				</div>
				<ng-template #Chartemptymessage>
					<div style="text-align: center;">No projects found...</div>
			</ng-template>
			</div>
		</div>

		<div class="p-col-12 p-lg-4">
			<div class="card" style="height:400px; overflow-y: scroll;">
				<div class="card-header" style="padding-bottom:0px;">
				<h4>Timeline</h4>  
						<p-dropdown [options]="TimelineOptions" [(ngModel)]="selectedTimelineOption" optionLabel="name" (onChange)="GetTimeLines($event.value)"
					styleClass="dashbard-demo-dropdown" [ngStyle]="{'min-width': '8rem'}"></p-dropdown>      
				</div>
				<hr>
				<div class="widget-timeline" *ngIf='timeLines != undefined && timeLines.length > 0'>
					<div *ngFor="let item of timeLines;">
						<div class="timeline-event">
							<span class="timeline-event-icon" style="background-color:#64B5F6">
								<i class="pi {{item.icon}}"></i>
							</span>
							<div class="timeline-event-title">{{item.title}}</div>
							<div class="timeline-event-detail">
							<div *ngIf="item.entityType == 4">
								<!-- <a href="{{appBaseUrl}}/phasetree/{{item.entityId}}">{{item.entityName}}</a>  -->
								<a (click)="openproject(item.entityId)" style="cursor: pointer">{{item.entityName}}</a> 
								{{item.subTitle}} on {{item.actionDate}}
							</div>
							<div *ngIf="item.entityType == 1 || item.entityType == 2 || item.entityType == 3">
								{{item.entityName}} {{item.subTitle}} on {{item.actionDate| date: "MM/dd/yyyy"}}
							</div>
							<div *ngIf="item.entityType == 5">
								<!-- <a href="{{appBaseUrl}}/edittask/{{item.entityId}}">{{item.entityName}}</a>  -->
								<a (click)="opentask(item.entityId)" style="cursor: pointer">{{item.entityName}}</a> 
								{{item.subTitle}} on {{item.actionDate}}
							</div>
	
							</div>
						</div>
					</div>				
				</div>
				<div class="widget-timeline" *ngIf='timeLines == undefined || timeLines.length == 0' style="text-align: center;">
						<h4>No timeline found...</h4>
				</div>
		 	</div>
		 </div> 
		 
		 <div class="p-col-12">
			<div class="card" style="min-height:600px;">
				<div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
					<h5 style="color:#495057; padding:15px 0 0 15px;">Active Project Summary - {{Count}} </h5> 
				    <span class="p-field-radiobutton" style="float:left;">
						<label style="margin-right: 10px;">Filter by:</label>
						<p-radioButton value="startsWith" [(ngModel)]="selectedfilter"></p-radioButton>
						<label style="margin-right: 10px;">startsWith</label>
						<p-radioButton value="Contains" [(ngModel)]="selectedfilter"></p-radioButton>
						<label>Contains</label>
					  </span>
				  	<span class="p-input-icon-left" style="float:right;">
						<!-- <button pButton type="button" pTooltip="Refersh" label="Refresh Chart" tooltipPosition="right" (click)="refreshchart()"
						icon="pi pi-undo" class="p-button-raised p-button-primary" style="height:25px;width: 150px; margin-right: 20px; margin-bottom: 10px;"></button> -->
		
						<p-checkbox name="groupname" (click)="getProjectsR()" label="All Projects" binary="false" inputId="binary" class="padding" 
						[(ngModel)]="AllProductsValue" ></p-checkbox>
						<span class="p-input-icon-left">
							<!-- <i class="pi pi-search"></i><input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." style="margin-top: 6px;margin-bottom:6px;" />  -->
							<button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;width:25px; height:25px;margin-top: 12px;" ></button> 
							<button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 12px;" (click)="exportExcel()"></button>
						</span>
					</span> 
				</div>
				<div  class="invoice invoice-header" id="invoice-content">
					<p-table #dt [value]="projects" id="excel-table" dataKey="id" styleClass="p-datatable-striped" class="table-align"
						[rowHover]="true" [rows]="10" [paginator]="false" [scrollable]="true" scrollHeight="380px"[filterDelay]="0" (onFilter)="onFilter($event, dt)">
						<ng-template pTemplate="header" class="invoice-items">
							<tr>
								<th style="width:80px;">Actions</th>
								<th style="width: 40%;">Project Name</th>
								<th style="width:200px;">Active Phase</th>
								<th style="width:150px;">Start Date</th>
								<th style="width:150px;"> End Date</th>
								<th style="width: 15%;">Product Engineer</th>
								<th style="width: 15%;">Project Status</th>
							</tr>
							<tr>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
							</tr> 
							<tr id="trSearch">
								<th></th>
								<th><input pInputText type="text" (input)="test($event.target.value,'projectName',dt)" placeholder="Project Name" class="p-column-filter" style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
								<th><input pInputText type="text" (input)="test($event.target.value,'phaseName',dt)" placeholder="Active Phase" class="p-column-filter"  style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
								<th><input pInputText type="text" (input)="test($event.target.value,'projectStartDate',dt)" placeholder="Start date" class="p-column-filter"  style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
								<th><input pInputText type="text" (input)="test($event.target.value,'targetDate',dt)" placeholder="End date" class="p-column-filter"  style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
								<th><input pInputText type="text" (input)="test($event.target.value,'projectManagerName',dt)" placeholder="Product Engineer" class="p-column-filter"  style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
								<th><input pInputText type="text" (input)="test($event.target.value,'projectStatusName',dt)" placeholder="Project Status" class="p-column-filter"  style="margin: -10px 0 -10px 0; width: 95%;height:25px"></th>
							</tr> 
							<tr>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
							</tr> 
						</ng-template>
						<ng-template pTemplate="body" let-project>
							<tr *ngIf="project.phaseName==''" [pSelectableRow]="rowData" style="background-color:#008EBD;color:white;border: 2px;vertical-align: middle;">
								<td  style="text-align:center;width:80px;"></td>
								<th style="padding-left:15px;width:40%;font-size: medium;"><span class="data-style">{{project.projectName}}</span></th>
								<td style="text-align:center;width:200px;font-weight: bold;"></td>
								<td style="text-align:center;width:150px;"><span ></span></td>
								<td style="text-align:center;width:150px;"><span></span></td>
								<td style="padding-left:15px;width:15%;"><span ></span></td>
								<td style="padding-left:15px;width:15%;"><span ></span></td>
							</tr>
							<tr *ngIf="project.phaseName!=''" [pSelectableRow]="rowData" style="vertical-align: middle;">
								<td  style="text-align:center;width:80px;">
									<span><button (click)=openproject(project.projectId) pButton pRipple type="button"  icon="pi pi-eye"  
										pTooltip="View"tooltipPosition="right"  class="p-button-raised p-button-primary p-mr-2 p-mb-2" 
										style="margin-top:5px;height:25px; width:25px;"></button>
									</span>
								</td>
								<td style="padding-left:15px;width:40%;"><span class="data-style">{{project.projectName}}</span></td>
								<!-- <td  style="text-align:center;width:200px;" ><span >{{project.phaseName}}</span></td> -->
								<td style="text-align:center;width:200px;font-weight: bold;"  [ngStyle]="{'color': project.phaseName === 'P0' ? '#c14a09': project.phaseName === 'Review' ? '#FFA500' : project.phaseName === 'P1' ? '#008000' : project.phaseName === 'P2' ?  '#0000FF': project.phaseName === 'P3 RTS' ? '#3b2820': project.phaseName === 'P3 Production' ? '#800080': project.phaseName === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ project.phaseName}}</td>
								<td style="text-align:center;width:150px;"><span >{{project.projectStartDate}}</span></td>
								<td style="text-align:center;width:150px;"><span>{{project.targetDate}}</span></td>
								<td style="padding-left:15px;width:15%;"><span >{{project.projectManagerName}}</span></td>
								<td style="padding-left:15px;width:15%;"><span >{{project.projectStatusName}}</span></td>
							</tr>
						</ng-template>
						<ng-template pTemplate="footer">
							<tr>
								<td colspan="7"> {{ProductCountFilter}} </td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="7">No projects found...</td>
							</tr>
						</ng-template>
					</p-table> 
				</div>
			</div>
		</div>
   </div>
</div>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;" *ngIf="showLoading">
	<i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>
