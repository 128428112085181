<div class="p-grid table-demo">
<div class="p-col-12">
  <div class="card" style="min-height:600px;">
    <div class="card-header" style=" border-radius: 5px; height:40px; ">
      <h5 style="color:#495057; padding:15px 0 0 15px;"></h5> 
    </div> 
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:40px; ">
      <h5 style="color:#495057; padding:15px 0 0 15px;">Milestone Summary - {{Count}}</h5> 
      <span class="p-field-radiobutton" style="float:left;">
        <label style="margin-right: 10px;">Filter by:</label>
        <p-radioButton value="startsWith" [(ngModel)]="selectedfilter"></p-radioButton>
        <label style="margin-right: 10px;">startsWith</label>
        <p-radioButton value="Contains" [(ngModel)]="selectedfilter"></p-radioButton>
        <label>Contains</label>
      </span>
      <span class="p-input-icon-left" style="float:right;margin-top:2px; margin-right:10px;">  
        <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print"tooltipPosition="top"  (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;margin-top:5px; height:25px; width:25px;"></button>
        <button pButton pRipple type="button" icon="pi pi-download"pTooltip="Download"tooltipPosition="top"  (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:15px ;margin-top:5px; height:25px; width:25px; "></button>
      </span>
    </div> 
    <div  class="invoice invoice-header" id="invoice-content">
      <p-table
        #dt
        [value]="taskListData" id="excel-table" 
        dataKey="id"  
        styleClass="p-datatable-striped"
        [rowHover]="true"
        [scrollable]="true" scrollHeight="600px"
        [rows]="10"
        [paginator]="false"
        [filterDelay]="0"
        (onFilter)="onFilter($event, dt)"
      >
      <ng-template pTemplate="header">
        <tr>
          <th style="width:5%;">Actions</th>
          <th style="width:10%;padding-left: 2%;">Project</th>
          <th style="width:10%;padding-left: 2%;">Phase</th>
          <th style="width:10%;padding-left: 2%;">Milestone Id</th>
          <th style="width:5%;padding-left: 2%;">Seq</th>
          <th style="width:10%;padding-left: 2%;">Milestone</th>
          <th style="width:10%;padding-left: 2%;">Predecessor</th>
          <th style="width:10%;padding-left: 2%;">Start Date</th>
          <th style="width:10%;padding-left: 2%;">End Date</th>
          <th style="width:10%;padding-left: 2%;">Status</th>
        </tr>
        <tr id="trSearch">
          <th style="width:5%;"></th>
          <th>
            <input
              pInputText
              type="text" (input)="test($event.target.value,'projectName',dt)"
              placeholder="Project"
              style="width:95%; height:25px;">
          </th>
          <th>
            <input
            pInputText
            type="text" (input)="test($event.target.value,'phaseName',dt)"
            placeholder="Phase"
            style="width:95%; height:25px;">
          </th>
          <th>
            <input
              pInputText
              type="text" (input)="test($event.target.value,'workTaskId',dt)"
              placeholder="Milestone Id"
              style="width:95%; height:25px;">
          </th>
          <th>
            <input
              pInputText
              type="text" (input)="test($event.target.value,'taskSeq',dt)"
              placeholder="Seq"
              style="width:95%; height:25px;">
          </th>
          <th>
            <input
              pInputText
              type="text" (input)="test($event.target.value,'taskName',dt)"
              placeholder="Milestone"
              style="width:95%; height:25px;">
          </th>
          <th>
            <input
              pInputText
              type="text" (input)="test($event.target.value,'predecessor',dt)"
              placeholder="Predecessor"
              style="width:95%; height:25px;">
          </th>
          <th>
            <input pInputText type="text" (input)="test($event.target.value,'planedStartDate',dt)" placeholder="Start date" style="width:95%; height:25px; ">
          </th>
          <th>
            <input pInputText type="text" (input)="test($event.target.value,'plannedEndDate',dt)" placeholder="End date" style="width:95%; height:25px; ">
          </th>
          <th>
            <input pInputText type="text" (input)="test($event.target.value,'taskStatusName',dt)" placeholder="Status" style="width:95%; height:25px; ">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-task>
        <tr *ngIf="task.tid!=0" [pSelectableRow]="rowData" style="vertical-align: middle;">
          <td style="width:5%;text-align: center;">
            <span><a routerLink="/edittask/{{ task.taskId }}"> 
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-eye"
                  pTooltip="View"tooltipPosition="right" 
                  class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                  style="height:25px; width:25px; text-align:center;"
                ></button></a
            ></span>
          </td>
          <td style="width:10%;padding-left: 1%;">
            <span class="data-style">{{ task.projectName }}</span>
          </td>
          <!-- <td style="text-align:center;width:130px;"  [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080': rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">
            <span>{{ task.phaseName }}</span>
          </td> -->
          <td  style="width:10%;padding-left: 1%;text-align: center;" [ngStyle]="{'color': task.phaseName === 'P0' ? '#c14a09': task.phaseName === 'Review' ? '#FFA500' :task.phaseName === 'P1' ? '#008000' :task.phaseName === 'P2' ?  '#0000FF': task.phaseName === 'P3 RTS' ? '#3b2820': task.phaseName === 'P3 Production' ? '#800080': task.phaseName === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ task.phaseName }}</td>
          <td style="width:10%;padding-left: 1%;">
            <span class="data-style">{{ task.workTaskId }}</span>
          </td>
          <td style="width:5%;padding-left: 1%;text-align: center;">
            <span>{{ task.taskSeq }}</span>
          </td>
          <td style="width:10%;padding-left: 1%;">
            <span class="data-style">{{ task.taskName }}</span>
          </td>
          <td style="width:10%;padding-left: 1%;">
            <span class="data-style">{{ task.predecessor }}</span>
          </td>
          <td style="width:10%;padding-left: 1%;text-align: center;">
            <span *ngIf="task.planedStartDate == null || task.planedStartDate == '01/01/1970'">--</span>
            <span *ngIf="task.planedStartDate != null && task.planedStartDate != '01/01/1970'">{{ task.planedStartDate }}</span>
          </td>
          <td style="width:10%;padding-left: 1%;text-align: center;">
            <span *ngIf="task.plannedEndDate == null || task.plannedEndDate == '01/01/1970'">--</span>
            <span *ngIf="task.plannedEndDate != null && task.plannedEndDate != '01/01/1970'">{{ task.plannedEndDate }}</span>
          </td>
          <td style="width:10%;padding-left: 1%;">
            <span >{{ task.taskStatusName }}</span>
          </td>
        </tr>
        <tr *ngIf="task.tid==0" [pSelectableRow]="rowData" style="background-color:#008EBD;color:white;border: 2px;vertical-align: middle;">
          <td style="width:5%;">
          </td>
          <td style="width:10%;padding-left: 1%;">
            <span class="data-style">{{ task.projectName }}</span>
          </td>
          <td  style="width:10%;padding-left: 1%;"></td>
          <td style="width:10%;padding-left: 1%;">
          </td>
          <td style="width:5%;padding-left: 1%;">
          </td>
          <td style="width:10%;padding-left: 1%;">
          </td>
          <td style="width:10%;padding-left: 1%;">
          </td>
          <td style="width:10%;padding-left: 1%;">
          </td>
          <td style="width:10%;padding-left: 1%;">
          </td>
          <td style="width:10%;padding-left: 1%;">
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td colspan="10"> {{ProductCountFilter}} </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10">No milestone found...</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;" *ngIf="showLoading">
	<i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>