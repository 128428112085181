import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { Inject } from '@angular/core';  
import{BASE_API_URL_TOKEN} from "src/injector";
import { API_ENDPOINTS } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class HttputilityService {

  constructor(private http: HttpClient, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      "accept": "text/plain"
    })
  };

  public httpOptionsFile = {
    headers: new HttpHeaders({
    'Content-Disposition': 'multipart/form-data',
    })
  };

  Add(baseUrl, routeUrl, httpOptionsType, data) {
    if(baseUrl=="UMSAPI"){
      return this.http.post<any>(
        API_ENDPOINTS.UMSAPI + routeUrl,
        data,
        this.httpOptions
      );
    }
    else if(baseUrl=="PMAPI"){
      return this.http.post<any>(
        API_ENDPOINTS.PLMAPI + routeUrl,
        data,
        this.httpOptions
      );
    }
    else if(baseUrl=="ProdctAPI"){
      return this.http.post<any>(
        API_ENDPOINTS.PLMAPI + routeUrl,
        data,
        this.httpOptions
      );
    }
  }

  PostMultiPart(baseUrl, routeUrl, httpOptionsType, data) { 
    if(baseUrl=="UMSAPI"){
      return this.http.post<any>(
        API_ENDPOINTS.UMSAPI + routeUrl,
        data,
        this.httpOptionsFile
      );
    }
    else if(baseUrl=="PMAPI"){
      return this.http.post<any>(
        API_ENDPOINTS.PLMAPI + routeUrl,
        data,
        this.httpOptionsFile
      );
    }
    else if(baseUrl=="ProdctAPI"){
      return this.http.post<any>(
        API_ENDPOINTS.PLMAPI + routeUrl,
        data,
        this.httpOptionsFile
      );
    }
  }

  Get(baseUrl, routeUrl): Observable<any> {
    if(baseUrl=="UMSAPI"){
      return this.http
      .get<any>(API_ENDPOINTS.UMSAPI  + routeUrl)
      .pipe(catchError(this.handleError));
    }
    else if(baseUrl=="PMAPI"){
      return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
    }
    else if(baseUrl=="ProdctAPI"){
      return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
    }
  }
  downloadFile(routeUrl): any {
    return this.http.get(API_ENDPOINTS.PLMAPI + routeUrl, {responseType: 'blob'});
  }

  GetR(baseUrl, routeUrl): Observable<Blob> {
    if(baseUrl=="UMSAPI"){
      return this.http.get(API_ENDPOINTS.UMSAPI + routeUrl, {
        responseType: 'blob',
              })
              .pipe(catchError(this.handleError)
        );
    }
    else if(baseUrl=="PMAPI"){
      return this.http.get(API_ENDPOINTS.PLMAPI + routeUrl, {
        responseType: 'blob',
              })
              .pipe(catchError(this.handleError)
        );
    }
    else if(baseUrl=="ProdctAPI"){
      return this.http.get(API_ENDPOINTS.PLMAPI + routeUrl, {
        responseType: 'blob',
              })
              .pipe(catchError(this.handleError)
        );
    }
  }

  Delete(baseUrl, routeUrl) {
    if(baseUrl=="UMSAPI"){
      return this.http.delete<any>(API_ENDPOINTS.UMSAPI +routeUrl);
    }
    else if(baseUrl=="PMAPI"){
      return this.http.delete<any>(API_ENDPOINTS.PLMAPI +routeUrl);
    }
    else if(baseUrl=="ProdctAPI"){
      return this.http.delete<any>(API_ENDPOINTS.PLMAPI +routeUrl);
    }
   
  }

  handleError(error) {
    return throwError(error.message);
  }
  AddAuthh(routeUrl, data) {
    return this.http.post<any>(API_ENDPOINTS.UMSAuthAPI + routeUrl, data, this.httpOptions);
  }
  downloadDMSFile(routeUrl): any {
    return this.http.get(API_ENDPOINTS.PLMAPI + routeUrl, {responseType: 'blob'});
  }
}
