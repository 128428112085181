<div class="p-grid">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12">
        <div class="card">
          <h4>Portfolio </h4>
          <hr />

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-4">
              <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:130px;">


                <div class="p-grid overview-detail">
                  <div class="p-col-3">
                    <span class="overview-icon">
                      <i class="pi pi-briefcase"></i>
                    </span>
                    <span class="overview-title">Projects</span>
                  </div>
                  <div class="p-col-3">
                    <div class="overview-number">14
                    </div>
                    <div class="overview-subtext">Total</div>
                  </div>
                  <div class="p-col-3">
                    <div class="overview-number">5
                    </div>
                    <div class="overview-subtext">Overdue</div>
                  </div>
                  <div class="p-col-3">
                    <div class="overview-number">4
                    </div>
                    <div class="overview-subtext">On Track</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:130px;">


                <div class="p-grid overview-detail">
                  <div class="p-col-3">
                    <span class="overview-icon">
                      <i class="pi pi-briefcase"></i>
                    </span><br>
                    <span class="overview-title">Effort</span>
                  </div>
                  <div class="p-col-3">
                    <div class="overview-number">300
                    </div>
                    <div class="overview-subtext">Total</div>
                  </div>
                  <div class="p-col-3">
                    <div class="overview-number">250
                    </div>
                    <div class="overview-subtext">Completed</div>
                  </div>
                  <div class="p-col-3">
                    <div class="overview-number">50
                    </div>
                    <div class="overview-subtext">Remaining</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:130px;">


                <div class="p-grid overview-detail">
                  <div class="p-col-4">
                    <span class="overview-icon">
                      <i class="pi pi-briefcase"></i>
                    </span>
                    <span class="overview-title">Projects Ratio</span>
                  </div>
                  <div class="p-col-4">
                    <div class="overview-number">45%
                    </div>
                    <div class="overview-subtext">Completed</div>
                  </div>
                  <div class="p-col-4">
                    <div class="overview-number">55%
                    </div>
                    <div class="overview-subtext">Pending</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="Department">Product Engineer<span style="color: red">*</span></label>
              <p-dropdown inputId="Department" [options]="projectManagerList" placeholder="Product Engineer"
                (onChange)="onManagerSelection($event)" optionLabel="managerName" [showClear]="true">
              </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="Department">Spaces List<span style="color: red">*</span></label>
              <p-dropdown inputId="space" [options]="spaceList" placeholder="Spaces List" optionLabel="spaceName" 
              (onChange)="onSpaceSelection($event)" [showClear]="true">
              </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <div class="card" style="min-height:382px;">
                <h4>Project By Status</h4>
                <hr><br><br>
                <p-chart type="pie" [data]="pieData"></p-chart>
              </div>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <div class="card" style="min-height:382px;">
                <h4>Effort By Project </h4>
                <hr><br><br>

                <p-chart type="bar" [data]="barData"></p-chart>
              </div>
            </div>
            <div class="p-col-12">
              <p-table #dt [value]="customers1" [(selection)]="selectedCustomers1" dataKey="id"
                styleClass="p-datatable-striped" [rowHover]="true" [rows]="10" [paginator]="false" [filterDelay]="0"
                [globalFilterFields]="['name','country.name','representative.name','status']">

                <ng-template pTemplate="header">
                  <tr>

                    <th pSortableColumn="projectname">Product Engineer
                      <p-sortIcon field="projectname"></p-sortIcon>
                    </th>
                    <th pSortableColumn="startdate"> Start Date
                      <p-sortIcon field="startdate"></p-sortIcon>
                    </th>
                    <th pSortableColumn="enddate"> Finish Date
                      <p-sortIcon field="enddate"></p-sortIcon>
                    </th>
                    <th pSortableColumn="activity">Progress
                      <p-sortIcon field="activity"></p-sortIcon>
                    </th>
                    <th pSortableColumn="projectmanager">Total Effort
                      <p-sortIcon field="projectmanager"></p-sortIcon>
                    </th>
                    <th pSortableColumn="activity">Remaining
                      <p-sortIcon field="Remaining"></p-sortIcon>
                    </th>
                    <th pSortableColumn="activity">Completed
                      <p-sortIcon field="Completed"></p-sortIcon>
                    </th>

                  </tr>
                  <!-- <tr>
                <th>
                            <input pInputText type="text" (input)="dt.filter($event.target.value, 'projectname', 'startsWith')" placeholder="Project Name" class="p-column-filter"style="width:90%">
                </th>
                <th>
                            <p-multiSelect [options]="phases" placeholder="Search by Phase" (onChange)="onPhaseChange($event)" styleClass="p-column-filter" optionLabel="value" >
                                <ng-template let-option pTemplate="item">
                                   <div class="p-multiselect-phase-option">
                                       
                                        <span class="p-ml-1">{{option.label}}</span>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </th>
                <th>
                            <input pInputText type="text" (input)="dt.filter($event.target.value, 'stage', 'startsWith')" placeholder="Stage" class="p-column-filter"style="width:90%">
                </th>
                <th>
                            <input pInputText type="text" (input)="dt.filter($event.target.value, 'milestone', 'startsWith')" placeholder="Milestone" class="p-column-filter" style="width:90%">
                </th>
                <th>
                            <p-calendar (onSelect)="onDateSelect($event)" (onClearClick)="dt.filter('', 'date', 'equals')" [showButtonBar]="true" styleClass="p-column-filter" placeholder="Start Date" [readonlyInput]="true" dateFormat="yy-mm-dd"></p-calendar>
                        </th>
                <th>
                            <p-calendar (onSelect)="onDateSelect($event)" (onClearClick)="dt.filter('', 'date', 'equals')" [showButtonBar]="true" styleClass="p-column-filter" placeholder="End Date" [readonlyInput]="true" dateFormat="yy-mm-dd"></p-calendar>
                        </th>
                <th>
                            <input pInputText type="text" (input)="dt.filter($event.target.value, 'projectmanager', 'startsWith')" placeholder="Project Manager" class="p-column-filter" style="width:90%">
                </th>
                <th></th>
        
                 </tr> -->
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                  <tr class="p-selectable-row">
                    <!-- <td>
                     <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                   </td> -->
                    <td>
                      <span class="p-column-title">Product Engineer</span>
                      <span class="data-style"> Jazzman</span>
                    </td>


                    <td>
                      <span class="p-column-title">Start Date</span>
                      <span class="data-style"> 30-06-2019</span>
                    </td>
                    <td>
                      <span class="p-column-title">Finish Date</span>
                      <span class="data-style"> 30-06-2020</span>
                    </td>
                    <td>
                      <span class="p-column-title">Progress</span>
                      <span class="data-style"> Sharath</span>
                    </td>
                    <td>
                      <span class="p-column-title">Total Effort</span>
                      <span class="data-style"> Total Effort</span>
                    </td>
                    <td>
                      <span class="p-column-title">Remaining</span>
                      <span class="data-style"> Remaining</span>
                    </td>
                    <td>
                      <span class="p-column-title">Completed</span>
                      <span class="data-style"> Completed</span>
                    </td>

                  </tr>

                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="7">No customers found.</td>
                  </tr>
                </ng-template>
              </p-table>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>