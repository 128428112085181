<form>
    <p-toast position="top-right" key="popup"></p-toast>
    <div class="p-grid table-demo">
      <div class="p-col-12">
        <div class="card" style="min-height:600px;">
          <div class="card-header"  style="border-radius: 5px;height:40px; width:99.8% " >
            <h5 style="color:#495057; padding:15px 0 0 15px;">
            </h5>
            <span class="p-input-icon-left" style="float:right;">
              <button pButton type="button" label="Add" (click)="onAdd()" [disabled]="userFeature"
                class="p-button-raised p-button-primary p-mr-2 p-mb-2" icon="pi pi-plus" iconPos="left"
                style="margin-right:5px;"></button> 
            </span>
          </div>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
            <h5 style="color:#495057; padding:15px 0 0 15px;">Milestone Attributes</h5>
            <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
  
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float:right; width:25px; height:25px;"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right;height:25px; width:25px; "></button>
            </span>
          </div>
  
          <div class="invoice invoice-header" id="invoice-content">
            <p-table #dt [value]="TaskAttributesList" id="excel-table" dataKey="id" styleClass="p-datatable-striped" [rowHover]="true"
            [scrollable]="true" scrollHeight="600px"
               [paginator]="false" [filterDelay]="0">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="taskName" style="width: 30%;">
                    Milestone
                    <p-sortIcon field="taskName"></p-sortIcon>
                  </th>
                  <th pSortableColumn="customFieldName" style="width: 25%;">
                    Custom Attribute
                    <p-sortIcon field="customFieldName"></p-sortIcon>
                  </th>
                  <th pSortableColumn="typeName" style="width: 25%;">
                    Value
                    <p-sortIcon field="typeName"></p-sortIcon>
                  </th>
                  <th pSortableColumn="value" style="width: 20%;">
                    Cycle Time
                    <p-sortIcon field="value"></p-sortIcon>
                  </th>
                  <th style="width:100px;">
                    Actions
                  </th>
                  <th style="width:100px;">
                    History
                  </th>
                </tr>
                <tr id="trSearch">
                  <th>
                    <input pInputText type="text" (input)="
                      dt.filter($event.target.value, 'taskName', 'contains')
                    " placeholder="Milestone" class="p-column-filter"
                      style="width: 95%; height:25px; padding-left:15px;" />
                  </th>
                  <th>
                    <input pInputText type="text" (input)="
                      dt.filter($event.target.value, 'customFieldName', 'contains')
                    " placeholder="Custom Attribute" class="p-column-filter"
                      style="width: 95%; height:25px; padding-left:15px;" />
                  </th>
                  <th>
                    <input pInputText type="text" (input)="
                      dt.filter($event.target.value, 'typeName', 'contains')
                    " placeholder="Value" class="p-column-filter"
                      style="width: 95%; height:25px; padding-left:15px;" />
                  </th>
                  <th>
                    <input pInputText type="text" (input)="
                      dt.filter($event.target.value, 'value', 'contains')
                    " placeholder="Cycle Time" class="p-column-filter"
                      style="width: 95%; height:25px; padding-left:15px;" />
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-List>
                <tr class="p-selectable-row">
                  <td style="padding-left:15px;width: 30%;"><span class="data-style">{{ List.taskName }}</span></td>
                  <td style="padding-left:15px;width: 25%;"><span class="data-style">{{ List.customFieldName }}</span></td>
                  <td style="padding-left:15px;width: 25%;"><span class="data-style">{{ List.typeName }}</span></td>
                  <td style="text-align: center;width: 20%;"><span>{{ List.value }}</span></td>
                  <td style="text-align:center;width:100px;">
                    <span>
                      <button pButton pRipple type="button" (click)="onEdit(List.id,List.taskName,List.customFieldId,List.type,List.value)"
                        icon="pi pi-pencil" pTooltip="Edit" tooltipPosition="right" [disabled]="userFeature"
                        class="p-button-raised p-button-primary" style="margin-left:15px;height:25px; width:25px;">
                      </button>
                      <button pButton pRipple type="button" (click)="onDelete(List.id,List.taskName,List.customFieldName,List.typeName)"
                      icon="pi pi-trash" pTooltip="Delete" tooltipPosition="right" [disabled]="userFeature"
                      class="p-button-raised p-button-danger" style="margin-left:15px;height:25px; width:25px;">
                    </button>
                    </span>
                  </td>
                  <td style="text-align: center; width:100px;">
                    <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="Details(List.id,List.taskName,List.customFieldName,List.typeName)"
                    icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                  </td> 
                </tr>
              </ng-template>
  
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="6">No records found...</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  
  </form>
  
  <p-dialog header="History" [(visible)]="historydisplay" modal="modal" showEffect="fade" [style]="{width:'70%'}" >
    <hr>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12">

      <div>
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-4">
      <label style="padding-right:5px; margin-left:5px;font-weight: bold;">Milestone: <span style="color:#2e81e9; padding-left:5px;">{{HistoryTask}}</span></label>
      </div>
        <div class="p-col-12 p-md-4">
        <label style="padding-right:5px; margin-left:5px;font-weight: bold;">Custom Attribute: <span style="color:#2e81e9;padding-left:5px;">{{HistoryLevel}}</span></label>
      </div>
      <div class="p-col-12 p-md-4">
        <label style="padding-right:5px; margin-left:5px;font-weight: bold;">Value: <span style="color:#2e81e9;padding-left:5px;">{{HistoryLevel1}}</span></label>
      </div>
      </div></div>

      </div>
</div>

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <p-table #dtHistory [value]="History" [columns]="HistoryCols" sortMode="multiple" [autoLayout]="true"
            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedHistory"> 
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr><th style="text-align: center;width: 60px;">S.No</th>
                        <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
                            {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                    <tr [pSelectableRow]="rowData" >
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 1" style="text-align: center;">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</td>
                        <td *ngIf="i == 2" style="text-align: center; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center; padding-left:15px;">{{ rowData[col.field] }}</td>
                    </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr><td colspan="5">No records found...</td></tr>
                </ng-template>
            </p-table>
        </div>
    </div>          
  </p-dialog>

  <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
  </div>
  
  <p-dialog header="Delete Attribute Cycle Time" [(visible)]="DeleteConfirm" modal="modal" showEffect="fade" 
  [style]="{ width: '40%' }">
  <hr>
  
  Are you sure, you want to delete {{Name}} ?
  <br /><br />
  
  <span>
    <div class="p-col-12 p-md-12" style="vertical-align: center">
      <small class="p-invalid" style="float:right;">{{responseError}}</small>
    </div>
    <div class="p-col-12 p-md-12" style="float: left;">
      <button pButton type="button" label="Delete" class="p-button-danger" icon="pi pi-trash"
        iconPos="left" style="margin-left: 10px; width: 100px; float: right"
        (click)="onDeleteConfirmed()"></button>
    </div>
  </span>
  </p-dialog>
  <div [formGroup]="createform">
    <p-dialog header="Milestone Attribute Cycle Time" [(visible)]="Maindisplay" modal="modal" showEffect="fade" 
      [style]="{ width: '40%' }">
      <hr>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2"></div>
        <div class="p-field p-col-12 p-md-8">
      <label for="Name">Milestone: <span styles="color: red">* </span></label>
      <input type="text" pInputText placeholder="Milestone" required="true" formControlName="fName" [(ngModel)]="TaskName" requiredMessage="The input must not be empty" /> 
      <br><br>
      <label for="custom">Custom Attribute: <span style="color: red">* </span></label>
      <p-dropdown [options]="customfieldsList" [(ngModel)]="customfield"
      placeholder="Custom Attribute" optionLabel="fieldName" required="true"
      (onChange)="onChanging($event)"
      formControlName="FCCustom" requiredMessage="The input must not be empty">
  </p-dropdown>
  <br>
      <label for="custom">Value: <span style="color: red">* </span></label>
      <p-dropdown [options]="TypeList" [(ngModel)]="Type"
      placeholder="Value" optionLabel="name" required="true"
      formControlName="Ftype" requiredMessage="The input must not be empty">
  </p-dropdown>
    <br>
      <label for="value">Cycle Time: <span style="color: red">* </span></label>
      <p-inputNumber mode="decimal" [useGrouping]="false" [minFractionDigits]="0" formControlName="fValue" padControl="false" minValue = "1"
      [(ngModel)]="Value" placeholder="Cycle Time">
   </p-inputNumber>
  </div>
  <div class="p-field p-col-12 p-md-2"></div> 
</div>
      <span>
        <div class="p-col-12 p-md-12" style="vertical-align: center">
          <small class="p-invalid" style="float:right;">{{responseError}}</small>
        </div>
        <div class="p-col-12 p-md-12" style="float: right;">
          <button pButton type="button" label="Save" class="p-button-success" icon="pi pi-check"
            iconPos="left" style="margin-left: 10px; width: 100px; float: right;"
            (click)="onSubmit()"></button>
        </div>
      </span>
    </p-dialog>
  </div>