<div class="p-grid p-fluid">
  <div class="p-col-12">
    <div class="card" style="min-height: 600px">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-md-10" style="float: left">
          <h4 style="margin-top: 5px">{{Title}}
          </h4>
        </div>
        <div class=" p-col-12 p-md-2" *ngIf="isReadOnly">
          <!-- <a routerLink="/phasetree/{{editProjectName}}"> -->
             <button
            pButton
            pRipple
            type="button"
            icon="pi pi-chevron-left"
            iconPos="left"
            label="Back"
            class="p-button-raised p-button-primary p-mr-2 p-mb-2"
            style="width:50%;float: right;"
           (click)=phasetree(editProjectName)
          ></button>
        <!-- </a> -->
      </div>

      <hr />
      <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;width:1000px">
        <div class="p-col-12 p-md-6">
         <!-- <label for="Phase"> Project<span style="color: red">*</span></label>
           <p-dropdown
            [options]="ManageCustomFields"
            [(ngModel)]="selectedManageCustomFields"
            required="true"
            placeholder="Select Project"
            optionLabel="projectName"
            requiredMessage="The input must not be empty"
            (onChange)="productListChange($event.value)"
          >
          </p-dropdown>
          <small class="p-invalid"
              >{{ productNameError }}</small>  -->
              <label>Project<span style="color: red">*</span></label>
              <p-autoComplete [(ngModel)]="selectedManageCustomFields" [suggestions]="filteredProdType"  placeholder="Select Project"
              (completeMethod)="filterproductListChange($event)"   field="projectName" [dropdown]="true" (onSelect)="productListChange()">
              <ng-template>
                <div>
                  <div>{{ filteredProdType.projectName }}</div>
                </div>
              </ng-template>
            </p-autoComplete>
            <small class="p-invalid">{{ productNameError }}</small>  
        </div>
        <div class="p-col-12 p-md-6">
          <label> Group<span style="color: red">*</span></label>
          <p-dropdown
            [options]="CustomFieldgroups"
            [(ngModel)]="selectedManageCustomGroups"
            required="true"
            placeholder="Select Group"
            optionLabel="customGroupName"
            requiredMessage="The input must not be empty"
            (onChange)="groupChange($event.value)"
          >
          </p-dropdown>
          <small class="p-invalid"
             >{{groupNameError}}</small> 
          <p-confirmDialog appendTo="body" key="confirm-document" header="Alert" acceptLabel="Yes" rejectLabel="No" [(visible)]="confirmDeleteDocument"></p-confirmDialog>
       
        </div>
      </div>
      <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;width:90%;margin-top: 20px;">
        <p-table
        #dt
        [value]="CustomFieldgroupId"
        [(selection)]="selectedCustomField"
        dataKey="customFieldID"
        styleClass="p-datatable-gridlines p-datatable-striped"
        [rowHover]="true"
        [paginator]="false"
        [filterDelay]="0">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="fieldname" style="width:320px">
              Custom Attribute
              <p-sortIcon field="fieldname"></p-sortIcon>
            </th>
            <th pSortableColumn="phase">
              Value
              <p-sortIcon field="phase"></p-sortIcon>
            </th>
            <th pSortableColumn="ownerApplication" style="width:160px">
              Owner
              <p-sortIcon field="ownerApplication"></p-sortIcon>
            </th>
            <th pSortableColumn="isChangeMangement" style="width: 125px;">
              CCB Approval
              <p-sortIcon field="isChangeMangement"></p-sortIcon>
            </th>
            <th style="width: 60px;">
              History
            </th>
          </tr>
        </ng-template>
        <ng-template  pTemplate="body" let-product> 
          <tr>
            <td style="padding-left: 15px;">{{ product.fieldName }}</td>            
            <td>
              <select class="select-css width100" *ngIf="product.entryType === 'DropDown'" [(ngModel)]="datasourcelist[product.customFieldID.toString()].value"
               aria-placeholder="Select" [disabled]="product.isOwner === false || isReadOnly === true "
              (change)="CustomAttribute(datasourcelist[product.customFieldID.toString()].value,product.customFieldID,product.parentDependent)" >
                <option *ngFor="let item of datasourcelist[product.customFieldID.toString()].list" [value]="item.id">
                  {{item.name}}
                </option>
              </select>
              <input *ngIf="product.entryType === 'TextBox' && product.length > 0" style="text-align: center;"
              [(ngModel)]="datasourcelist[product.customFieldID.toString()].value"
                type="text" pInputText  (change)="keyPressNumbers($event,product.fieldDataType)" [attr.maxlength]="product.length"
                [disabled]="product.isOwner === false || isReadOnly === true" />
                <input *ngIf="product.entryType === 'TextBox' && product.length == 0" style="text-align: center;"
                [(ngModel)]="datasourcelist[product.customFieldID.toString()].value"
                  type="text" pInputText  (change)="keyPressNumbers($event,product.fieldDataType)" [attr.maxlength] = "10"
                  [disabled]="product.isOwner === false || isReadOnly === true" />
            </td>
            <td style="padding-left: 15px;">{{ product.ownerApplication }}</td>
            <td style="text-align: center;"><span *ngIf="product.isChangeMangement === true">Yes</span><span *ngIf="product.isChangeMangement === false">No</span></td> 
            <td style="text-align: center;">
              <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="Details(product.customFieldID,product.fieldName)"
              icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
            </td> 
          </tr>   
             
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No custom fields found...</td>
          </tr>
        </ng-template>
      </p-table>
      </div>
      <br />
      <div class="p-grid p-fluid" style="margin-right: 85px; width: 100%; margin-top: 20px;" *ngIf="!isReadOnly">
        <div class="p-col-12 p-md-8"><small class="p-invalid"
          >{{ tableError }}</small> </div>
        <div class="p-col-12 p-md-2">
          <button 
            pButton
            type="button"
            icon="pi pi-check"
            iconPos="left"
            label="Save"
            class="p-button-success"
            (click)="SaveCustomFields()"
            [disabled]="userFeature"
          ></button>
        </div>
        <div class="p-col-12 p-md-2">
          <button 
            pButton
            type="button"
            icon="pi pi-refresh"
            iconPos="left"
            label="Reset"
            (click)="Reset()"
            class="p-button-secondary"
            [disabled]="userFeature"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Custom Attribute History" [(visible)]="historydisplay" modal="modal" showEffect="fade" [style]="{width:'70%'}" >
  <hr>
  <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-12">
          <h5 style="text-align:center">{{FieldName}}</h5>
          <p-table #dtHistory [value]="History" [columns]="HistoryCols" sortMode="multiple" [autoLayout]="true"
          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedHistory"> 
              <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr><th style="text-align: center;width: 60px;">S.No</th>
                      <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
                          {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                  <tr [pSelectableRow]="rowData" >
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                      <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                      <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                      <td *ngIf="i == 1" style="text-align: center;">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm'}}</td>
                      <td *ngIf="i == 2" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                      <td *ngIf="i == 3" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                  </ng-container>
                  </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                  <tr><td colspan="5">No records found...</td></tr>
              </ng-template>
          </p-table>
      </div>
  </div>          
</p-dialog>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500 !Important;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>

<p-dialog header="Message" [(visible)]="ErrorDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
  <p>{{ Error }}</p>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="ErrorDisplay=false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>