import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  [x: string]: any;

  constructor(private httpService: HttputilityService) { }

  GetOverlapDays() {
    return this.httpService.Get('PMAPI', 'Admin/GetOverlapDays')
  }
  GetOverlapsHistory(ID) {
    return this.httpService.Get('PMAPI', 'Admin/GetOverlapHistory?ID='+ID)
  }

  GetProportionateTimeHistory(ID) {
    return this.httpService.Get('PMAPI', 'Admin/GetProportionateTimeHistory?ID='+ID)
  }

  GetTaskCustomAttributesHistory(ID) {
    return this.httpService.Get('PMAPI', 'Admin/GetTaskCustomAttributesHistory?ID='+ID)
  }

  AddOverlapDays(DTO) {
    return this.httpService.Add('PMAPI', 'Admin/AddOverlapDays', 'httpOptions', DTO)
  }

  DeleteOverlapDays(ID,ActionBy) {
    return this.httpService.Add('PMAPI', 'Admin/DeleteOverlapDays?ID='+ ID+'&ActionBy='+ActionBy, 'httpOptions', '')
  }

  GetProportionateTime() {
    return this.httpService.Get('PMAPI', 'Admin/GetProportionateTime')
  }

  AddProportionateTime(DTO) {
    return this.httpService.Add('PMAPI', 'Admin/AddProportionateTime', 'httpOptions', DTO)
  }

  DeleteProportionateTime(ID,ActionBy) {
    return this.httpService.Add('PMAPI', 'Admin/DeleteProportionateTime?ID='+ ID+'&ActionBy='+ActionBy, 'httpOptions', '')
  }

  GetTaskCustomAttributese() {
    return this.httpService.Get('PMAPI', 'Admin/GetTaskCustomAttributese')
  }

  AddTaskCustomAttributes(DTO) {
    return this.httpService.Add('PMAPI', 'Admin/AddTaskCustomAttributes', 'httpOptions', DTO)
  }

  DeleteTaskCustomAttributes(ID,ActionBy) {
    return this.httpService.Add('PMAPI', 'Admin/DeleteTaskCustomAttributes?ID='+ ID+'&ActionBy='+ActionBy, 'httpOptions','')
  }

  GetAllDropDownCustomFields() {
    return this.httpService.Get('PMAPI', 'Admin/GetAllDropDownCustomFields')
  }
  GetMasterData(CustomFieldID) {
    return this.httpService.Get('PMAPI', 'Admin/GetMasterData?CustomFieldID='+CustomFieldID)
  }
}


