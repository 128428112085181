import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private httpService: HttputilityService
  ) { }

  getTilesData(Admin,UserGUID){
    return this.httpService.Get('PMAPI','Tiles/GetTilesCount?ModuleID='+Admin+'&UserGUID='+UserGUID);
  }
  Chart(UserGUID){
    return this.httpService.Get('PMAPI','Tiles/GetDashboardPieChart?UserGUID='+UserGUID);
  }

  // getProjects(Admin,UserGUID){
  //   return this.httpService.Get('PMAPI','Projects/GetProjects?ModuleID=' + Admin +'&UserGUID='+UserGUID);
  // }

  getProjects(Admin,UserGUID,download){
    return this.httpService.Get('PMAPI','Projects/GetProjects?ModuleID=' + Admin +'&UserGUID='+UserGUID+'&download='+download);
  }

  getDepartments() {
    return this.httpService.Get('UMSAPI',"user/GetDepartments");
  }
  login(model) {
    return this.httpService.AddAuthh("user/Login", model);
  }
}
