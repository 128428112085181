import { Component, OnInit, ViewChild} from '@angular/core';
import { FacadeService } from '../../facade/facade.service';import { PrintcommonService } from 'src/app/services/printcommon.service';
import { Router} from '@angular/router';
import { BASE_API_URL_TOKEN } from "src/injector";
import { Inject } from '@angular/core';
import {SelectItem} from 'primeng/api';
import * as XLSX from 'xlsx'; 
import { Table } from 'primeng/table';
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';


@Component({
  selector: 'app-projectsumarry',
  templateUrl: './projectsumarry.component.html',
  styleUrls: ['./projectsumarry.component.scss']
})
export class ProjectsumarryComponent implements OnInit {
  loading: "loading";
  showLoading:boolean;
  cols: any[];
  products: any[];
  productsN: any[];
  selectedProducts: any[];
  @ViewChild('dt') table: Table;
  ProductCount:number = 0;

  selectedfilter ="startsWith";
  status: SelectItem[];
  line: any[];
  l:string[];
  selectedLine:any[]=[];
  selectedStatus:any[]=[];
constructor(
  private _facadeService: FacadeService,
  public printService: PrintcommonService,
  private router: Router,
  private datePipe: DatePipe,
  @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
) {}

ngOnInit() {
  this.showLoading = true;
  
  this.cols = [
    { field: 'projectName', header: 'Project' },
    { field: 'dieCode', header: 'Die Code' },
    { field: 'mfgPart', header: 'Mfg Part' },
    { field: 'productLine', header: 'Product Line' },
    { field: 'projectOwner', header: 'Project Engineer' },
    { field: 'projectStatus', header: 'Project Status' },
    { field: 'currentPhase', header: 'Phase' },
    { field: 'projectStartDate', header: 'Start Date' },
    { field: 'targetDate', header: 'End Date' },
  ];
  this.status = [ 
    {label: 'New', value: 'New'},
    {label: 'In-Process', value: 'In-Process'},
    {label: 'Completed', value: 'Completed'},
    {label: 'EOL', value: 'EOL'},
    {label: 'OBS', value: 'OBS'},
    {label: 'Waiting for Discontinuation', value: 'Waiting for Discontinuation'},
    {label: 'Seek Clarification for Discontinuation', value: 'Seek Clarification for Discontinuation'},
    {label: 'Hold', value: 'Hold'},
    {label: 'Discontinued', value: 'Discontinued'},
  ];

  let ok = false;
    if(sessionStorage.getItem('sumstatus') != null && sessionStorage.getItem('sumstatus') != undefined)
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('sumstatus').split(',');
      projectstats.forEach((element) => {
        this.selectedStatus.push(element);
      });
      ok = true;
    }
    if(!ok)
    {
      this.selectedStatus.push('New');
      this.selectedStatus.push('In-Process');
      // this.selectedStatus.push('Completed');
      this.selectedStatus.push('Waiting for Discontinuation');
      this.selectedStatus.push('Seek Clarification for Discontinuation');
      sessionStorage.sumstatus = this.selectedStatus;
    }

  sessionStorage.projectName = "";
  sessionStorage.dieCode = "";
  sessionStorage.mfgPart = "";
  sessionStorage.productLine = "";
  sessionStorage.projectOwner = "";
  sessionStorage.projectStatus = "";
  sessionStorage.currentPhase = "";
  sessionStorage.projectStartDate = "";
  sessionStorage.targetDate = "";

  this.GetProductLne();
}
GetProductLne() {
  this._facadeService.GetProductLine()
  .subscribe((data) => {
    this.line = data.returnObject;
    let ok = false;
    if(sessionStorage.getItem('sline') != null && sessionStorage.getItem('sline') != undefined && sessionStorage.getItem('sline').indexOf("object") <= -1)
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('sline').split(',');
      projectstats.forEach((element) => {
        this.line.forEach((ln) => {
          if(ln.id == element)
            this.selectedLine.push(ln);
        });
      });
      ok = true;
    }
    if(!ok)
    {
      this.line.forEach((ln) => {
        this.selectedLine.push(ln);
      });
      var lm=[];
      this.selectedLine.forEach((element) => {
        lm.push(element.id);
      });
      sessionStorage.sline = lm;
    }
    this.getproducts(this.selectedLine,'true');
    this.getproductsN(this.selectedLine,'true');
  });
}
getproducts3()
{
  this.getproducts(this.selectedLine,'true');
  this.getproductsN(this.selectedLine,'true');
}
Count()
{
if(this.productsN != undefined && this.productsN != null)
{
  var temp = this.productsN;
  if(sessionStorage.projectName != "")
    temp = temp.filter(x=> x.projectName.toLowerCase().includes(sessionStorage.projectName.toLowerCase()));
  if(sessionStorage.dieCode != "")
    temp = temp.filter(x=> x.dieCode.toLowerCase().includes(sessionStorage.dieCode.toLowerCase()));
  if(sessionStorage.mfgPart != "")
    temp = temp.filter(x=> x.mfgPart.toLowerCase().includes(sessionStorage.mfgPart.toLowerCase()));
  if(sessionStorage.productLine != "")
    temp = temp.filter(x=> x.productLine.toLowerCase().includes(sessionStorage.productLine.toLowerCase()));
  if(sessionStorage.projectOwner != "")
    temp = temp.filter(x=> x.projectOwner.toLowerCase().includes(sessionStorage.projectOwner.toLowerCase()));
  if(sessionStorage.projectStatus != "")
    temp = temp.filter(x=> x.projectStatus.toLowerCase().includes(sessionStorage.projectStatus.toLowerCase()));
  if(sessionStorage.currentPhase != "")
    temp = temp.filter(x=> x.currentPhase.toLowerCase().includes(sessionStorage.currentPhase.toLowerCase()));
  if(sessionStorage.projectStartDate != "")
    temp = temp.filter(x=> x.projectStartDate.toLowerCase().includes(sessionStorage.projectStartDate.toLowerCase()));
  if(sessionStorage.targetDate != "")
    temp = temp.filter(x=> x.targetDate.toLowerCase().includes(sessionStorage.targetDate.toLowerCase()));
  this.ProductCount = temp.length;
}
else
  this.ProductCount = 0;
}
test(val,field,dt)
{
if(field == "projectName")
  sessionStorage.projectName = val;
else if(field == "dieCode")
  sessionStorage.dieCode = val;
else if(field == "mfgPart")
  sessionStorage.mfgPart = val;
else if(field == "productLine")
  sessionStorage.productLine = val;
else if(field == "projectOwner")
  sessionStorage.projectOwner = val;
else if(field == "projectStatus")
  sessionStorage.projectStatus = val;
else if(field == "currentPhase")
  sessionStorage.currentPhase = val;
else if(field == "projectStartDate")
  sessionStorage.projectStartDate = val;
else if(field == "targetDate")
  sessionStorage.targetDate = val;

if(this.selectedfilter=="startsWith")
  dt.filter(val, field, 'startsWith');
else
  dt.filter(val, field, 'contains');      
//this.Count();
}

getproducts(l,Type) {
  this.showLoading = true;
  var lm = [];
  if(Type == 'false')
  {
    this.selectedLine.forEach((element) => {
        lm.push(element.id);
    });
    sessionStorage.sline = lm;
    sessionStorage.sumstatus = this.selectedStatus;
  }
  else
  {
    l.forEach((element) => {
      lm.push(element.id);
    });
  }
  const searchModel={
    productLineID:lm,
    productStatus:this.selectedStatus,
    Download : 'N'
  }
  this._facadeService.ReportProducts(searchModel).subscribe(
    (data) => {
        this.products = data.returnObject;
        if(this.products.length > 0)
        {
            this.products.forEach(e => {
                if(e.projectStartDate != '' || e.projectStartDate != null){
                    let projectStartDate = new Date(e.projectStartDate) 
                    //e.projectStartDate = projectStartDate.toLocaleDateString()
                    e.projectStartDate = this.datePipe.transform( e.projectStartDate, 'MM/dd/yyyy');
                }
                if(e.targetDate != '' || e.targetDate != null){
                    let targetDate = new Date(e.targetDate) 
                    //e.targetDate = targetDate.toLocaleDateString()
                    e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
                }
            });
        }
        
        //this.Count();
        this.showLoading = false;
    },
    (error) => {
      console.log(error);
    }
  );
}

getproductsN(l,Type) {
  this.showLoading = true;
  var lm = [];
  if(Type == 'false')
  {
    this.selectedLine.forEach((element) => {
        lm.push(element.id);
    });
    sessionStorage.sline = lm;
    sessionStorage.sumstatus = this.selectedStatus;
  }
  else
  {
    l.forEach((element) => {
      lm.push(element.id);
    });
  }
  const searchModel={
    productLineID:lm,
    productStatus:this.selectedStatus,
    Download : 'Y'
  }
  this._facadeService.ReportProducts(searchModel).subscribe(
    (data) => {
        this.productsN = data.returnObject;
        if(this.productsN.length > 0)
        {
            this.productsN.forEach(e => {
                if(e.projectStartDate != '' || e.projectStartDate != null){
                    let projectStartDate = new Date(e.projectStartDate) 
                    e.projectStartDate = projectStartDate.toLocaleDateString()
                    // e.projectStartDate = this.datePipe.transform( e.projectStartDate, 'MM/dd/yyyy');
                }
                if(e.targetDate != '' || e.targetDate != null){
                    let targetDate = new Date(e.targetDate) 
                    e.targetDate = targetDate.toLocaleDateString()
                    // e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
                }
            });
        }
        this.Count();
        this.showLoading = false;
    },
    (error) => {
      console.log(error);
    }
  );

}

download() {
  //let element = document.getElementById('excel-table'); 
  var dte = [];
  this.productsN.map(d => (
    {
      "Project": d.projectName,
      "Die Code": d.dieCode,
      "Mfg Part": d.mfgPart,
      "Product Line": d.productLine,
      "Project Engineer": d.projectOwner,
      "Project Status": d.projectStatus,   
      "Phase": d.currentPhase,   
      "Start Date": d.projectStartDate,  
      "End Date": d.targetDate   
    }
  )).forEach((d: any) => {
    dte.push(d);
  });

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dte);
  / generate workbook and add the worksheet /
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  ws['!cols'] = [{ wpx: 180 }, { wpx: 100 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 130 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 120 }, { wpx: 120 }, { wpx: 120 }];
  //this.delete_row(wb.Sheets.Sheet1, 1);
  / save to file /
  XLSX.writeFile(wb, "Project Summary.xlsx");
}

download1() {
  let element = document.getElementById('excel-table'); 
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  this.delete_row(wb.Sheets.Sheet1, 1);
  ws['!cols'] = [{ wpx : 220 },{ wpx : 80 },{ wpx : 150 },{ wpx : 70 },{ wpx : 120 },{ wpx : 120 },{ wpx : 90 },{ wpx : 100 },{ wpx : 100 }];
  /* save to file */
  XLSX.writeFile(wb, "Projects Summary.xlsx");
}

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "&nbsp;&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['J1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}
Details(ProjectID) {
  sessionStorage.FromReport = true;
  sessionStorage.From = "ReportSummary";
  this.router.navigate(["/phasetree/"+ProjectID]);
}
print(SearchID) {
    var Content = document.getElementById('invoice-content').innerHTML;
    var Title = "Projects Summary";
    this.printService.Print(Content,Title,SearchID,true,0);

}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'projectStartDate'||event.field === 'targetDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    e.projectStartDate = this.datePipe.transform( e.projectStartDate, 'MM/dd/yyyy');
    e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
  });
}
}
