<div class="p-col-12">
    <div class="card">
        <div class="card-header" style=" border-radius: 5px;height:40px; margin-bottom:5px; ">
            <h5 style="color:#495057; padding:15px 0 0 15px;"></h5>
            <span class="p-input-icon-left" style="float:right; margin-right:5px;">  
                
                <label style="padding-right:10px; margin-left:10px;">Product Line:</label>
           <p-multiSelect class="multiselect-custom" defaultLabel="Select Product Line"  optionLabel="name" [options]="line" [(ngModel)]="selectedLine" >
                <ng-template let-value pTemplate="selectedItems">
                    <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                        <div>option.name</div>
                    </div>
                    <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
                        Select Product Line
                    </div>
                </ng-template>
            </p-multiSelect> 
            <label style="padding-right:10px; margin-left:10px;">Project Status:</label>
            <p-multiSelect class="multiselect-custom" defaultLabel="Select Project Status" [options]="status" [(ngModel)]="selectedStatus">
                <ng-template let-value pTemplate="selectedItems">
                    <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                        <div>option.name</div>
                    </div>
                    <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
                        Select Project Status
                    </div>
                </ng-template>
            </p-multiSelect>
              <a (click)="getproducts3()">
                <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search" class="p-button-raised p-button-primary "  style="width:auto; float:right; margin-left:10px;"></button>
            </a>
         </span>
        </div> 
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Projects Summary - {{ProductCount}} </h5> 
        <span class="p-field-radiobutton" style="float:left;">
          <label style="margin-right: 10px;">Filter by:</label>
          <p-radioButton value="startsWith" [(ngModel)]="selectedfilter"></p-radioButton>
          <label style="margin-right: 10px;">startsWith</label>
          <p-radioButton value="Contains" [(ngModel)]="selectedfilter"></p-radioButton>
          <label>Contains</label>
        </span>  
        <span class="p-input-icon-left" style="float: right; margin-top: 10px">
          <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" (click)="print('trSearch')" 
              class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
              style="float: right; width:25px; height:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" 
              class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
              style="float: right; margin-left:15px; width:25px; height:25px;" (click)="download()"></button>
      </span>
    </div>
    <div class="invoice invoice-header" id="invoice-content">           
 <p-table #dt [value]="products"  id="excel-table"  [columns]="cols"  [loading]="loading" [autoLayout]="true"
styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedProducts"
[scrollable]="true" scrollHeight="600px"
 dataKey="projectId"> 
<ng-template pTemplate="header" let-columns class="invoice-items">
<tr>              
  <th style="text-align: center;width: 80px;">Actions</th>
    <th *ngFor="let col of columns;let i = index" 
    [ngClass]="{'dieCode': i == 1 ||  i == 7 ||  i == 8, 'productLine': i == 3,  'phase': i == 6,'w':i==0 || i==2, 'w1': i==4 || i==5}" >
    {{ col.header }}</th>
</tr>
<tr id="trSearch">
  <th style="text-align: center;width: 80px;"></th>
  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
    <input pInputText type="text" (input)="test($event.target.value,col.field,dt)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:18px"/>
</th>
</tr>
</ng-template>
<ng-template pTemplate="body" let-rowData let-columns="columns">
  <tr *ngIf="rowData.projectId!=''" [pSelectableRow]="rowData" style="vertical-align: middle;">
    <td style="text-align: center;width: 80px;">
    <button pButton type="button" pTooltip="View"  tooltipPosition="right"  (click)="Details(rowData.projectId)"
    icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
  </td>
  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 25%;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 1" style="text-align: left; padding-left:15px; width:110px;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 25%;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 3" style="text-align: center; padding-left:15px; width:120px;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width: 12%;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width: 12%;">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 6" style="text-align: center;padding-left:10px;width:110px;font-weight: bold;" [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080': rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ rowData[col.field] }}</td>
    <td *ngIf="i == 7" style="text-align: center; width:110px;">{{ rowData[col.field]}}</td> 
    <td *ngIf="i == 8" style="text-align: center; width:110px;">{{ rowData[col.field]}}</td>
  </ng-container>
</tr>
 <tr *ngIf="rowData.projectId==''" [pSelectableRow]="rowData" style="background-color:#008EBD;color:white;border: 2px;vertical-align: middle;">
  <td style="text-align: center;width: 80px;"></td>
 <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
  <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 25%;">{{ rowData[col.field] }}</td>
  <td *ngIf="i == 1" style="text-align: left; padding-left:15px; width:110px;"></td>
  <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 25%;"></td>
  <td *ngIf="i == 3" style="text-align: left; padding-left:15px; width:120px;"></td>
  <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width: 12%;"></td>
  <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width: 12%;"></td>
  <td *ngIf="i == 6" style="text-align: left;padding-left:10px;width:110px;font-weight: bold;"></td>
  <td *ngIf="i == 7" style="text-align: center; width:110px;"></td> 
  <td *ngIf="i == 8" style="text-align: center; width:110px;"></td>
</ng-container>
</tr>
</ng-template>
<ng-template pTemplate="emptymessage">
<tr>
    <td colspan="10" style="text-align: left;">No records found...</td>
</tr>
</ng-template>
 </p-table>
    </div>
    </div>
</div>



<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500 !important;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>