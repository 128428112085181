<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card" style="min-height:600px; margin:-10px;margin-top:-20px;font-size: 12px;">
            <p-accordion>
                <p-accordionTab header="Search Criteria" [selected]="false">
            <div class="p-fluid p-formgrid p-grid" style="font-size: 12px;"> 
                <div class="p-field p-col-12 p-xl-2">
                    <label style="padding-right:10px; margin-left:10px;">Attributes:</label>
                    <p-multiSelect [options]="Attributes" defaultLabel="Select Attributes" [selectionLimit]="3" [(ngModel)]="selectedAttributes" optionLabel="name" [virtualScroll]="true" 
                    [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                </div> 
                <div class="p-field p-col-12 p-xl-2">
                    <label style="padding-right:10px; margin-left:10px;">Phases:</label>
                    <p-multiSelect [options]="Phases" defaultLabel="Select Phases" [(ngModel)]="selectedPhases" optionLabel="name" [virtualScroll]="true" 
                    [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                </div> 
                <div class="p-field p-col-12 p-xl-2">
                  <label style="padding-right:10px; margin-left:10px;">Project Status:</label>
                  <p-multiSelect defaultLabel="Select Project Status" [options]="status" optionLabel="name" [(ngModel)]="selectedStatus" [virtualScroll]="true"
                    [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                  </div>        
                  <div class="p-field p-col-12 p-xl-2">
                  <label>Start Date:<span style="color: red"></span></label>
                    <p-calendar [showIcon]="true" inputId="txtStartDate" [(ngModel)]="editStartDate"></p-calendar>
                  </div>
                  <div class="p-field p-col-12 p-xl-2">
                  <label>End Date:<span style="color: red"></span></label>
                  <p-calendar [showIcon]="true" inputId="txtEndDate" [(ngModel)]="editEndDate"></p-calendar>            
                  </div>
                  <div class="p-field p-col-12 p-xl-2">
                    <label style="color: red;width:auto;">{{error}}&nbsp;</label>    
                    <a (click)="getproducts()">
                      <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search" 
                      class="p-button-raised p-button-primary" style="width:100%;float:left;font-size: 12px;"></button>
                  </a>
                  </div>            
            </div>
</p-accordionTab></p-accordion>
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:25px;">
                <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;font-size:14px;">P2 Review Summary<span style="padding-left:70px;font-size:12px;">Current Week: WW{{currentWeekNumber}}</span></h5>
            </div>
            <div  class="invoice invoice-header" id="invoice-content">
             <!-- [scrollable]="true" scrollHeight="185px" -->
                <p-table #dt [value]="activeproducts" [columns]="cols" sortMode="multiple" [autoLayout]="true" [scrollable]="true" scrollHeight="220px"
                styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedprojects"> 
                <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>              
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" style="white-space:wrap;font-size: 12px;" 
                    [ngClass]="{'width90': i == 1,'width80': i == 11 ,'width85':i == 2 ,'width160':i == 3 , 'width100': i == 4 || i == 5,'width110': i == 6 || i == 7 || i == 8 || i == 9 || i == 10 ,
                    'w15': i == 0 || i == 12 || i == 13 || i == 14}">
                    {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
                    <th style="width:50px;font-size:12px; text-align: center;">Actions</th>
                </tr>
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
                        <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.contains)" 
                        [placeholder]="col.dynamicPlaceHolder" style="margin: -10px 0 -10px 0; width: 95%;height:20px;font-size: 12px;"/>
                    </th>
                    <th style="text-align: center"></th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" (click)="selectrow(rowData.projectId,rowData.projectOwnerID)" [style.background]="rowData.select == true ? '#e8ccc2' : ''">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 1" style="text-align: center;font-size: 12px;width: 90px">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center;font-size: 12px;width: 85px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center;font-size: 12px; width:160px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 4 || i == 5" style="text-align: left; padding-left:15px;font-size: 12px; width:100px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 6 || i == 7 || i == 8 || i == 9 || i == 10" style="text-align: left; padding-left:15px;font-size: 12px;width: 110px;">
                            {{ rowData[col.field] }}</td>
                        <td *ngIf="i == 11" style="text-align: center; font-size: 12px;width: 80px;font-weight: bold;"  [ngStyle]="{'color': rowData[col.field] === 'P0' ? '#c14a09': rowData[col.field] === 'Review' ? '#FFA500' : rowData[col.field] === 'P1' ? '#008000' : rowData[col.field] === 'P2' ?  '#0000FF': rowData[col.field] === 'P3 RTS' ? '#3b2820': rowData[col.field] === 'P3 Production' ? '#800080': rowData[col.field] === 'EOL/OB' ? '#2196F3' :'#D32F2F'}">{{ rowData[col.field] }}</td>
                        <td *ngIf="i != 1 && i != 2 && i != 3 && i != 4 && i != 5 && i != 6 && i != 7 && i != 8 && i != 9 && i != 10 && i != 11" 
                        style="text-align: left;padding-left:15px;font-size: 12px;width: 15%;"> {{rowData[col.field]}}</td>
                      </ng-container>
                      <td style="text-align: center;width:50px;">
                        <button pButton type="button" icon="pi pi-eye" pTooltip="View" tooltipPosition="right"
                                        class="p-button-raised p-button-primary" (click)="phasetree(rowData.projectId)"
                                        style="width:20px; height:20px;"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="13" style="text-align: left;font-size:12px;">No data found.</td>
                </tr>
                </ng-template>

                </p-table>
            </div>
 
            <br>
            <div class="p-fluid p-formgrid p-grid" *ngIf="showdetails">
                <div class="p-field p-col-12 p-md-12">
                    <p-table #dtmilestone [value]="milestonesData" [columns]="cols4" class="table-align" styleClass="p-datatable-striped" [filterDelay]="0">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr><th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" style="font-size: 12px;">{{col.header}}</th></tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                    <td *ngIf="i == 0" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold1 ? 'red' : ''" [style.font-weight]="rowData.bold1 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color1"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold2 ? 'red' : ''" [style.font-weight]="rowData.bold2 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color2"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 2" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold3 ? 'red' : ''" [style.font-weight]="rowData.bold3 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color3"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 3" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold4 ? 'red' : ''" [style.font-weight]="rowData.bold4 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color4"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 4" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold5 ? 'red' : ''" [style.font-weight]="rowData.bold5 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color5"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 5" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold6 ? 'red' : ''" [style.font-weight]="rowData.bold6 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color6"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 6" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold7 ? 'red' : ''" [style.font-weight]="rowData.bold7 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color7"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 7" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold8 ? 'red' : ''" [style.font-weight]="rowData.bold8 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color8"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 8" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold9 ? 'red' : ''" [style.font-weight]="rowData.bold9 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color9"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 9" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold10 ? 'red' : ''" [style.font-weight]="rowData.bold10 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color10"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 10" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold11 ? 'red' : ''" [style.font-weight]="rowData.bold11 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color11"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 11" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold12 ? 'red' : ''" [style.font-weight]="rowData.bold12 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color12"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 12" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold13 ? 'red' : ''" [style.font-weight]="rowData.bold13 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color13"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 13" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold14 ? 'red' : ''" [style.font-weight]="rowData.bold14 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color14"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 14" style="text-align: center;font-size: 12px;">
                                        <span *ngIf="rowIndex == 2" [style.color]="rowData.bold15 ? 'red' : ''" [style.font-weight]="rowData.bold15 ? 'bold' : ''"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex == 3" [style.color]="rowData.color15"> {{rowData[col.field]}}</span>
                                        <span *ngIf="rowIndex != 2 && rowIndex != 3">{{rowData[col.field]}}</span>
                                    </td>
                                </ng-container>
                            </tr>

                            <!-- <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                    <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                                        {{ rowData[col.field] }}
                                    </td> 
                                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                        {{ rowData[col.field] }}
                                    </td>
                                    <td *ngIf="i == 0" style="text-align: center;padding-left:15px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: center;padding-left:15px;">
                                        <span [style.color]="rowData.bold ? 'red' : ''" [style.font-weight]="rowData.bold ? 'bold' : ''">
                                        {{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="i == 2" style="text-align: center;padding-left:15px;">
                                        <span [style.color]="rowData.color">
                                        {{rowData[col.field]}}</span>
                                    </td>                               
                                </ng-container>
                            </tr> -->
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="15" style="text-align: left;font-size:12px;">No data found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="p-field p-col-12 p-md-12">
                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                        <div class="p-fluid p-formgrid p-grid" style="margin-bottom: -25px;margin-top: -8px;font-size:12px;">
                            <div class="p-field p-col-12 p-md-2">
                                <label>Current Week Remarks: </label>
                                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save" (click)="SaveReview()" [disabled]="isDisable || viewmode"
                                class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                style="width: auto;height:20px;font-size: 12px;"></button>
                                <button pButton type="button" icon="pi pi-eye" iconPos="left" label="Previous Remarks"  (click)="ShowRemarks()"
                                class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                                style="width: auto;height:20px;font-size: 12px;"></button>
                                <small class="p-invalid">{{ RemarksError }}</small> 
                            </div>
                            <div class="p-field p-col-12 p-md-10">
                                <textarea type="text" rows="4" [maxLength]="300" pInputTextarea style="font-size: 12px;"
                                placeholder="Current Week Remarks" [(ngModel)]="rnote" [readonly]="isDisable || viewmode" >
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-12 p-xl-12">
                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                        <div class="p-fluid p-formgrid p-grid" style="margin-bottom: -25px;margin-top: -8px;font-size:12px;">
                            <div class="p-field p-col-12 p-md-2">
                                <label>Current Week Meeting Notes:</label>
                                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save" (click)="SaveMeeting()"
                                    class="p-button-raised p-button-success p-mr-2 p-mb-2" [disabled]="isDisable"
                                    style="width: auto;height:20px;font-size: 12px;"></button>
                                <button pButton type="button" icon="pi pi-eye" iconPos="left" label="Previous Notes"  (click)="ShowMeetings()"
                                    class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                                    style="width: auto;height:20px;font-size: 12px;"></button>
                            </div>
                            <div class="p-field p-col-12 p-md-10">
                                <textarea id="MeetingNotes" type="text" rows="4" [maxLength]="300" pInputTextarea style="font-size: 12px;"
                                placeholder="Current Week Meeting Notes" [(ngModel)]="mnote" [readonly]="isDisable" >
                                </textarea>
                                <small class="p-invalid">{{ MeetingsError }}</small> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Remarks" [(visible)]="remarksDisplay" modal="modal" showEffect="fade"
[style]="{ width: '80%' }">
<hr />
<div class="p-fluid p-formgrid p-grid">
    <p-table #dtreview [value]="reviewData" [columns]="cols2" sortMode="multiple"
        class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple">
        <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                    [ngClass]="{'workweek':i == 0,'createdby': i == 2,'createddate':i == 3}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                        (input)="dtreview.filter($event.target.value, col.field, col.contains)"
                        style="margin: -10px 0 -10px 0;width: 95%; height:20px;">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: center;">
                        {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                        {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                        {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 3" style="text-align: center;">
                        {{rowData[col.field] | date: "MM/dd/yyyy"}}
                    </td>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4" style="text-align: left;">No data found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>
</p-dialog>

<p-dialog header="Meeting Notes" [(visible)]="meetingsDisplay" modal="modal" showEffect="fade"
[style]="{ width: '80%' }">
<hr /><br>
<div class="p-fluid p-formgrid p-grid">
    <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
        <p-table #dtmeeting [value]="meetingData" [columns]="cols3" sortMode="multiple"
            class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
            selectionMode="multiple" dataKey="id"
            [globalFilterFields]="['workweek','meetingnotes','name','createdDate']">
            <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                        [ngClass]="{'workweek':i == 0,'createdby': i == 2,'createddate':i == 3}">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                        <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                            (input)="dtmeeting.filter($event.target.value, col.field, col.contains)"
                            style="margin: -10px 0 -10px 0;width: 95%; height:20px;">
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;">
                            {{ rowData[col.field] }}
                        </td>
                        <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 3" style="text-align: center;">
                            {{rowData[col.field] | date: "MM/dd/yyyy"}}
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3" style="text-align: left;">No data found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
</p-dialog>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>