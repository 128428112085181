<div class="p-grid">
  <p-toast position="top-right" key="tl"></p-toast>
  <div class="p-col-12">
    <div class="card" style="min-height: 600px">
      <div class="p-grid">
        <div class="p-col-12 p-md-2 p-xl-2">
          <p-panelMenu [model]="panelMenuItems"> </p-panelMenu>
          <!-- Create Phase Form -->
          <span class="listitem-value" style="float: right; margin-bottom: 20px; height: 20px"
            [formGroup]="createPhaseform">
            <p-dialog header="View Phase" [(visible)]="phasedisplay" modal="modal" showEffect="fade"
              [style]="{ width: '50%' }" (onHide)="phaseDialogCancel()">
              <hr />
              <div class="p-grid">


                <div class="p-col-12 p-md-12 p-xl-12">
                  <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-4">
                      <label for="Project">
                        Project Name</label>

                      <input style="opacity: 0.9;" type="text" pInputText
                        placeholder="{{ projectInformation?.projectName}}" [disabled]="true" />

                      <br />
                    </div>

                    <div class="p-col-12 p-md-4">
                      <label for="Phase Name">Phase Name </label>
                      <input #newPhasetxt type="text" pInputText placeholder="Phase Name" required="true"
                        formControlName="phaseNameControl" [(ngModel)]="editPhaseName"
                        requiredMessage="The input must not be empty" disabled />
                      <small class="p-invalid"
                        *ngIf="isCreatePhaseSubmitted && createPhaseform.controls.phaseNameControl.hasError('required')">
                        Phase name is required</small>
                      <br />
                    </div>

                    <div class="p-col-12 p-md-4">
                      <label for="Project">
                        Phase Status</label>
                      <input type="text" pInputText placeholder="Project Status" [(ngModel)]="listStatusOptionsMdl"
                        formControlName="phaseStatusDdlControl" disabled />
                      <!-- <p-dropdown [options]="listStatusOptions" [(ngModel)]="listStatusOptionsMdl"
                      placeholder="Phase Status" optionLabel="name" required="true" [disabled]="true"
                      formControlName="phaseStatusDdlControl" requiredMessage="The input must not be empty">
                    </p-dropdown> -->
                      <small class="p-invalid"
                        *ngIf="isCreatePhaseSubmitted && createPhaseform.controls.phaseStatusDdlControl.hasError('required')">
                        Status is required</small>
                      <br />
                    </div>
                  </div>
                  <br />
                  <!-- <div class="p-grid p-fluid" *ngIf="LoggedInUser">
                    <div class="p-col-12 p-md-12" style="vertical-align: center">
                      <small class="p-invalid" style="float:right;">{{responseError}}</small>
                    </div>
                    <div class="p-col-12 p-md-9"></div>
                    <div class="p-col-12 p-md-3">
                      <button
                        pButton
                        type="button"
                        label="Save"
                        class="p-button-success"
                        icon="pi pi-plus"
                        (click)="createPhase(newPhasetxt.value)"
                      ></button>
                    </div>
                  </div> -->
                </div>


              </div>
            </p-dialog>
          </span>
          <br />
          <!-- Create Stage Form -->
          <span class="listitem-value" style="float: right; margin-bottom: 20px; height: 20px"
            [formGroup]="createStageform">
            <p-dialog header="View Stage" [(visible)]="stagedisplay" modal="modal" showEffect="fade"
              [style]="{ width: '50%' }">
              <hr />
              <div class="p-grid">

                <div class="p-col-12 p-md-12 p-xl-12">
                  <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-6">
                      <label for="Project">
                        Project Name</label>
                      <input type="text" pInputText placeholder="{{projectInformation?.projectName}}"
                        [disabled]="true" />


                    </div>

                    <div class="p-col-12 p-md-6">
                      <label for="Project">
                        Phase Name </label>
                      <p-dropdown [options]="createStageOptions" [(ngModel)]="createStageMdl" placeholder="Phase Name"
                        optionLabel="name" required="true" formControlName="phaseNameDdlControl"
                        requiredMessage="The input must not be empty" [disabled]=true>
                      </p-dropdown>
                      <small class="p-invalid" *ngIf="
                          isCreateStageSubmitted &&
                          createStageform.controls.phaseNameDdlControl.hasError(
                            'required'
                          )
                        ">
                        Phase name is required</small>

                    </div>
                    <div class="p-col-12 p-md-6">
                      <label for="Phase Name">Stage Name</label>
                      <input #newStagetxt type="text" pInputText placeholder="Stage Name" required="true"
                        [(ngModel)]="editStageName" formControlName="stageNameInputControl"
                        requiredMessage="The input must not be empty" disabled />
                      <small class="p-invalid" *ngIf="
                          isCreateStageSubmitted &&
                          createStageform.controls.stageNameInputControl.hasError(
                            'required'
                          )
                        ">Stage Name is required.</small>

                    </div>

                    <div class="p-col-12 p-md-6">
                      <label for="Project">Stage Status</label>
                      <p-dropdown [options]="listStatusOptions" [(ngModel)]="createstagestatus"
                        placeholder="Phase Status" optionLabel="name" required="true"
                        formControlName="stageStatusDdlControl" [disabled]=true
                        requiredMessage="The input must not be empty">
                      </p-dropdown>

                    </div>
                  </div>
                  <br />
                  <!-- <div class="p-grid p-fluid" *ngIf="LoggedInUser">
                    <div class="p-col-12 p-md-12" style="vertical-align: center">
                      <small class="p-invalid" style="float:right;">{{responseError}}</small>
                    </div>
                    <div class="p-col-12 p-md-9"></div>
                    <div class="p-col-12 p-md-3">
                      <button
                        pButton
                        type="button"
                        label="Save"
                        class="p-button-success"
                        icon="pi pi-plus"
                        (click)="createStage(newStagetxt.value)"
                      ></button>
                    </div>
                  </div> -->
                </div>

              </div>
            </p-dialog>
          </span>
          <br />
          <!-- Create Milestone Form -->
          <span class="listitem-value" style="float: right; margin-bottom: 0px; height: 20px"
            [formGroup]="createMilestoneform">
            <p-dialog header="View Milestone" [(visible)]="milestonedisplay" modal="modal" showEffect="fade"
              [style]="{ width: '50%' }">
              <hr />
              <div class="p-grid">

                <div class="p-col-12 p-md-12 p-xl-12">
                  <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-4">
                      <label for="Project">Project Name</label>
                      <input type="text" pInputText placeholder="{{projectInformation?.projectName}}"
                        [disabled]="true" />

                    </div>
                    <div class="p-col-12 p-md-4">
                      <label for="Project">
                        Phase Name</label>
                      <p-dropdown [options]="createMilestonePhaseOptions" [(ngModel)]="createMilestonePhaseOptionsMdl"
                        placeholder="Phase Name" optionLabel="name" required="true"
                        (onChange)="onClickGetStageByPhase($event)" formControlName="milestonePhaseDdlControl"
                        requiredMessage="The input must not be empty" [disabled]=true>
                      </p-dropdown>
                      <small class="p-invalid" *ngIf="
                          isCreateMilestoneSubmitted &&
                          createMilestoneform.controls.milestonePhaseDdlControl.hasError(
                            'required'
                          )
                        ">Phase name is required</small>

                    </div>
                    <div class="p-col-12 p-md-4">
                      <label for="Project">
                        Stage Name</label>
                      <p-dropdown inputId="milestoneStage" [options]="createMilestoneStageOptions"
                        [(ngModel)]="createMilestoneStageOptionsMdl" placeholder="Stage Name" optionLabel="name"
                        required="true" formControlName="milestoneStageDdlControl"
                        requiredMessage="The input must not be empty" [disabled]=true>
                      </p-dropdown>
                      <small class="p-invalid" *ngIf="
                          isCreateMilestoneSubmitted &&
                          createMilestoneform.controls.milestoneStageDdlControl.hasError(
                            'required'
                          )
                        ">Stage name is required</small>

                    </div>
                    <div class="p-col-12 p-md-6">
                      <label for="Phase Name">Milestone Name</label>
                      <input #newMilestonetxt type="text" pInputText [(ngModel)]="editMilestone"
                        placeholder="Milestone Name" required="true" formControlName="milestoneNameInputControl"
                        requiredMessage="The input must not be empty" disabled />
                      <small class="p-invalid" *ngIf="
                          isCreateMilestoneSubmitted &&
                          createMilestoneform.controls.milestoneNameInputControl.hasError(
                            'required'
                          )
                        ">Milestone name is required</small>

                    </div>
                    <div class="p-col-12 p-md-6">
                      <label for="Project">Milestone Status</label>
                      <p-dropdown [options]="listStatusOptions" [(ngModel)]="createmilestonestatus"
                        placeholder="Milestone Status" optionLabel="name" required="true"
                        formControlName="milestoneStatusDdlControl" [disabled]=true
                        requiredMessage="The input must not be empty">
                      </p-dropdown>
                      <br />
                    </div>
                  </div>


                  <br />
                  <!-- <div class="p-grid p-fluid" *ngIf="LoggedInUser">
                    <div class="p-col-12 p-md-12" style="vertical-align: center">
                      <small class="p-invalid" style="float:right;">{{responseError}}</small>
                    </div>
                    <div class="p-col-12 p-md-9"></div>
                    <div class="p-col-12 p-md-3">
                      <button
                        pButton
                        type="button"
                        label="Save"
                        class="p-button-success"
                        icon="pi pi-plus"
                        (click)="createMilestone(newMilestonetxt.value)"
                      ></button>
                    </div>
                  </div> -->
                </div>

              </div>
            </p-dialog>
          </span>

        </div>

        <div class="p-col-12 p-md-10 p-xl-10">
          <div class="card">
            <div class="card-header" style=" border-radius: 5px; height:40px;">
              <button pButton pRipple type="button" *ngIf="FromReport == 'true'" icon="pi pi-chevron-left"
                iconPos="left" label="Back" (click)=report() class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                style="width:100px"></button>

              <h5 style="color: #495057; padding: 15px 0 0 15px"> </h5>
              <span class="p-input-icon-left"
                style="float: right;  height:25px; margin-bottom:10px; margin-right:-5px;">

                <p-splitButton label="Actions" icon="pi pi-plus" [model]="filterItems"
                  styleClass="p-button-info p-mr-2 p-mb-2"></p-splitButton>
              </span>
            </div>
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:40px;">
              <h5 style="color: #495057; padding: 15px 0 0 15px">
                Project (Milestone) Details
              </h5>

            </div>

            <p-accordion>
              <p-accordionTab header="{{ editDeleteListDTO.ListName }}" [selected]="true">
                <div class="p-grid p-fluid" *ngIf="ShowActualDate">
                  <div class="p-col-12">
                    <div class="p-grid">
                      <div class="p-col-12 p-md-4" style="vertical-align: center;text-align: right;margin-top: 16px;">
                        <small class="p-invalid" style="float: right">{{Error}}</small>
                      </div>
                      <div class="p-col-12 p-md-4" style="margin-top: 16px;"><label style="float: right;">Update Actual Start Date:</label></div>
                      <div class="p-col-12 p-md-2" style="margin-top: 10px;">
                        <p-calendar [showIcon]="true" placeholder="Actual Start Date" [(ngModel)]="actualStartDate"
                          [minDate]="revisedStartDate" disabledWeekends="true"></p-calendar>
                      </div>
                      <div class="p-col-12 p-md-2">
                        <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Update" 
                        class="p-button-success" (click)="UpdateProjectActualStartDate()"
                        style="width:120px; margin-left:10px;margin-top: 10px;"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="productdetails">
                  <div class="p-grid">
                    <div class="p-col-12 p-md-4 p-xl-4">
                      <div class="card no-gutter widget-overview-box widget-overview-box-1">
                        <ul class="widget-image-list">
                          <li>
                            <span style="font-weight: bold;">Project Name:</span>
                            <span>{{projectInformation?.projectName}}</span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">Product Engineer:</span>
                            <span>{{ projectInformation != undefined ? projectInformation.owner : "" }}</span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">Project Status:</span>
                            <span>{{ projectInformation != undefined ? projectInformation.projectStatusName: ""
                              }}</span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">Die Code:</span>
                            <span>{{ projectInformation != undefined ? projectInformation.dieCode: "" }}</span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">Mfg Part #:</span>
                            <span>{{ projectInformation != undefined ? projectInformation.mfgPart: "" }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="p-col-12 p-md-4 p-xl-4">
                      <div class="card no-gutter widget-overview-box widget-overview-box-1">
                        <ul class="widget-image-list">
                          <li>
                            <span style="font-weight: bold;">Package Name:</span>
                            <span>{{ projectInformation != undefined ? projectInformation.packageOption: "" }}</span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">Need CP Trim:</span>
                            <span>{{ projectInformation != undefined ? projectInformation.cpTrim: "" }}</span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">Package Type:</span>
                            <span>{{ projectInformation != undefined ? projectInformation.packageType: "" }}</span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">Qual Type:</span>
                            <span>{{ projectInformation != undefined ? projectInformation.qualType: "" }}</span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">Active Phase:</span>
                            <span>{{ projectInformation != undefined ? projectInformation.currentPhase : "" }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="p-col-12 p-md-4 p-xl-4">
                      <div class="card no-gutter widget-overview-box widget-overview-box-1">
                        <ul class="widget-image-list">
                          <li>
                            <span style="font-weight: bold;">Start Date:</span>
                            <span>{{projectInformation?.projectStartDate|date:"MM/dd/yyyy"}}</span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">End Date:</span>
                            <span
                              *ngIf="projectInformation?.targetDate == null || projectInformation?.targetDate == '01/01/1970'">--</span>
                            <span
                              *ngIf="projectInformation?.targetDate != null && projectInformation?.targetDate != '01/01/1970'">
                              {{projectInformation?.targetDate|date:"MM/dd/yyyy"}}</span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">Project Description:</span>
                            <button pButton type="button" pTooltip="Project Description" tooltipPosition="right"
                              class="p-button-primary" (click)="projectdescriptiondisplay = true" icon="pi pi-eye"
                              style=" height:25px; width:25px; margin-bottom:-10px; margin-top:-10px;"></button>
                          </li>
                          <li>
                            <span style="font-weight: bold;">Attributes:</span>
                            <span *ngIf="projectInformation != undefined">
                              <!-- target="_blank" href="{{appBaseUrl}}customfields?projectId={{projectInformation.productId}}&pid={{projectInformation.projectId}}"> -->
                              <button (click)="open(projectInformation.productId,projectInformation.projectId)" pButton
                                pRipple type="button" icon="pi pi-th-large" pTooltip="Additional Attributes"
                                tooltipPosition="right" iconPos="left" class="p-button-raised p-button-primary"
                                style="height:25px; width:25px;"></button>
                            </span>
                          </li>
                          <li>
                            <span style="font-weight: bold;">OverLap/Cycle Time:</span>
                            <button pButton type="button" pTooltip="Overlap / Cycle Time Information"
                              tooltipPosition="right" class="p-button-primary" (click)="Overlapinfo = true"
                              icon="pi pi-key"
                              style=" height:25px; width:25px; margin-bottom:-10px; margin-top:-10px;"></button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>

            <div class="p-col-12">
              <p-tabView>
                <p-tabPanel header="List" leftIcon="pi pi-list">
                  <div class="card-header" style="background-color: #f8f9fa; border-radius: 5px">
                    <h5 style="color: #495057; padding: 15px 0 0 15px">
                      Milestones
                    </h5>
                    <span style="float: right; margin-top: 5px">
                      <a *ngIf="PDMbtnDisplay"
                        style="padding-right: 15px; vertical-align: top; font-size: 14px; text-decoration: underline;font-weight: bold;"
                        (click)="PMToken()" href="javascript:;"> Product Details </a>
                      <a (click)="helpdisplay = true">
                        <button pButton pRipple type="button" icon="pi pi-exclamation-circle" pTooltip="Help"
                          tooltipPosition="top" class="p-button-rounded p-button-primary p-mr-2 p-mb-2"
                          style=" margin-left:1px;height:25px; width:25px; ">
                        </button>
                      </a>
                      <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"
                        tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style=" margin-left:1px;height:25px; width:25px; " (click)="exportExcel()"></button>
                      <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                        class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right" (click)="print()"
                        style="height:25px; width:25px; "></button>
                    </span>
                  </div>
                  <div class="invoice invoice-header" id="invoice-content">
                    <!-- styleClass="p-datatable-customers" -->
                    <p-table #dt [value]="tasksListsByProject" id="excel-table"
                      [(selection)]="selectedtasksListsByProject" dataKey="id"
                      styleClass="p-datatable-striped p-datatable-customers" [rowHover]="true" [scrollable]="true"
                      scrollHeight="400px" [rows]="10" [paginator]="false" [filterDelay]="0">
                      <ng-template pTemplate="header">
                        <tr>
                          <th pSortableColumn="workTaskId" style="text-align:center; width:130px;">
                            Milestone Id
                            <p-sortIcon field="workTaskId"></p-sortIcon>
                          </th>
                          <th pSortableColumn="taskName" style="text-align:center;width:20%">
                            Milestone
                            <p-sortIcon field="taskName"></p-sortIcon>
                          </th>
                          <th pSortableColumn="assigneeName" style="text-align:center;width:20%">
                            Assignee
                            <p-sortIcon field="assigneeName"></p-sortIcon>
                          </th>
                          <th pSortableColumn="duration" style="text-align:center;width: 90px;">
                            Duration
                            <p-sortIcon field="duration"></p-sortIcon>
                          </th>
                          <th pSortableColumn="plannedEndDate" style="text-align:center;width:140px">
                            Target End Date
                            <p-sortIcon field="plannedEndDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="startDate" style="text-align:center;width:100px">
                            Start Date
                            <p-sortIcon field="startDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="dueDate" style="text-align:center;width:100px">
                            End Date
                            <p-sortIcon field="dueDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="status" style="text-align:center;width:110px">
                            Status
                            <p-sortIcon field="status"></p-sortIcon>
                          </th>
                          <th style="width: 50px;">
                            Actions
                          </th>
                        </tr>
                        <tr id=trSearch>
                          <th>
                            <input pInputText type="text" (input)="
                              dt.filter(
                                $event.target.value,
                                'workTaskId',
                                'contains'
                              )
                            " placeholder="Milestone Id" class="p-column-filter"
                              style="width: 85%; height:25px; margin-left:10px; " />
                          </th>
                          <th>
                            <input pInputText type="text" (input)="
                              dt.filter(
                                $event.target.value,
                                'taskName',
                                'contains'
                              )
                            " placeholder="Milestone" class="p-column-filter" style="width: 95%; height:25px;" />
                          </th>
                          <th>
                            <input pInputText type="text" (input)="
                              dt.filter(
                                $event.target.value,
                                'assigneeName',
                                'contains'
                              )
                            " placeholder="Assignee" class="p-column-filter" style="width: 95%; height:25px;" />
                          </th>
                          <th>
                            <input pInputText type="text" (input)="
                              dt.filter(
                                $event.target.value,
                                'duration',
                                'contains'
                              )
                            " placeholder="Duration" class="p-column-filter" style="width: 95%; height:25px;" />
                          </th>
                          <th style="text-align:center;">
                            <input pInputText type="text" (input)="
                            dt.filter(
                              $event.target.value,
                              'plannedEndDate',
                              'contains'
                            )
                          " placeholder="Target End date" class="p-column-filter" style="width: 95%; height:25px;" />

                          </th>
                          <th style="text-align:center;">
                            <input pInputText type="text" (input)="
                            dt.filter(
                              $event.target.value,
                              'startDate',
                              'contains'
                            )
                          " placeholder="Start date" class="p-column-filter" style="width: 95%; height:25px;" />
                          </th>

                          <th style="text-align:center;">
                            <input pInputText type="text" (input)="
                            dt.filter(
                              $event.target.value,
                              'dueDate',
                              'contains'
                            )
                          " placeholder="End date" class="p-column-filter" style="width: 95%; height:25px;" />

                          </th>
                          <th style="text-align:center;">
                            <input pInputText type="text" (input)="
                            dt.filter(
                              $event.target.value,
                              'status',
                              'contains'
                            )
                          " placeholder="Status" class="p-column-filter" style="width: 90%; height:25px;" />

                          </th>
                          <th></th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-task>
                        <tr class="p-selectable-row">
                          <td style="padding-left:15px;text-align:left;width:130px;">
                            <span class="data-style">{{ task.workTaskId }}</span>
                          </td>
                          <td style="padding-left:15px;text-align:left;width:20%">
                            <span class="data-style">{{ task.taskName }}</span>
                          </td>
                          <td style="padding-left:15px;text-align:left;width:20%">
                            <span class="data-style">{{ task.assigneeName }}</span>
                          </td>
                          <td style="text-align:center;width:90px">
                            <span>{{ task.duration }}</span>
                          </td>
                          <td style="text-align: center;width:140px;" [ngStyle]="{'background': task.targetOverride === true ? '#ffe5b4': '','font-weight' : task.targetOverride === true ? 'bold': ''}">
                            <span *ngIf="task.plannedEndDate == null || task.plannedEndDate == '01/01/1970'">--</span>
                            <span *ngIf="task.plannedEndDate != null && task.plannedEndDate != '01/01/1970'">
                              {{ task.plannedEndDate|date:"MM/dd/yyyy"}}</span>
                          </td>
                          <td style="text-align: center;width:100px;" [ngStyle]="{'background': (task.currentOverride === true || task.actualStartOverride === true) ? '#ffe5b4': '','font-weight' : (task.currentOverride === true || task.actualStartOverride === true) ? 'bold': ''}">
                            <span
                              *ngIf="task.startDate == null || task.startDate == '01/01/1970' || task.startDate == 'Invalid Date'">--</span>
                            <span
                              *ngIf="task.startDate != null && task.startDate != '01/01/1970' && task.startDate != 'Invalid Date'">{{
                              task.startDate | date: "MM/dd/yyyy"}}</span>
                          </td>
                          <td style="text-align: center;width:100px;" [ngStyle]="{'background': (task.currentOverride === true || task.actualEndOverride === true) ? '#ffe5b4': '','font-weight' : (task.currentOverride === true || task.actualEndOverride === true) ? 'bold': ''}">
                          <span
                              *ngIf="task.dueDate == null || task.dueDate == '01/01/1970' || task.dueDate == 'Invalid Date'">--</span>
                            <span
                              *ngIf="task.dueDate != null && task.dueDate != '01/01/1970' && task.dueDate != 'Invalid Date'">
                              <span [style.color]="task.bold ? 'red' : ''"
                                [style.font-weight]="task.bold ? 'bold' : ''">{{ task.dueDate | date:
                                "MM/dd/yyyy"}}</span></span>
                          </td>
                          <td style="text-align:center;width:110px">
                            <span>{{ task.status }}</span>
                          </td>
                          <td style="text-align:center;width: 50px;">
                            <span><a routerLink="/edittask/{{ task.taskId }}">
                                <button pButton type="button" pTooltip="Edit" tooltipPosition="right"
                                  class="p-button-raised p-button-primary p-mr-2 p-mb-2" icon="pi pi-pencil"
                                  style="height:25px; width:25px;"></button></a></span>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td colspan="9">No milestone are available to display</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </p-tabPanel>
                <p-tabPanel header="Board" leftIcon="pi pi-id-card">
                  <div class="card-header" style="background-color: #f8f9fa; border-radius: 5px">
                    <h5 style="color: #495057; padding: 15px 0 0 15px">
                      Milestone Board
                    </h5>
                    <span style="float: right; margin-top: 5px">

                      <!-- <a (click)="buildCreateTaskUrl();" *ngIf="showCreateTask">
                        <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-plus"
                        pTooltip="Create Task"tooltipPosition="top" 
                        class="p-button-rounded p-button-primary p-mr-2 p-mb-2"
                        style=" margin-left:1px;height:25px; width:25px; ">
                     
                       
                      </button>
                    </a> -->
                      <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"
                        tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style=" margin-left:1px;height:25px; width:25px;" (click)="exportExcel()"></button>
                      <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                        class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right" (click)="print()"
                        style="height:25px; width:25px;"></button>
                    </span>
                  </div>
                  <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-3">
                      <div>
                        <span>
                          <p-button label="New" icon="pi pi-check-square" iconPos="left" styleClass="p-button-info"
                            badge="{{ taskBoardNewCount }}" badgeClass="p-badge-danger">
                          </p-button>
                        </span>
                      </div>
                      <br />
                      <div class="card" style="border: 1px solid #4271dd" *ngFor="let item of this.taskBoardNew">

                        <h6>
                          {{ item.workTaskId }} : <a routerLink="/edittask/{{ item.taskId }}">{{ item.taskName }}</a>
                          <br>
                          Owner: {{ item.assigneeName }}
                          <br>
                          Due:<span
                            *ngIf="item.shortDuedate == null || item.shortDuedate == '01/01/1970' || item.shortDuedate == 'Invalid Date'">--</span>
                          <span
                            *ngIf="item.shortDuedate != null && item.shortDuedate != '01/01/1970' && item.shortDuedate != 'Invalid Date'">{{
                            item.shortDuedate | date: "MM/dd/yyyy"}}</span>

                        </h6>
                      </div>
                    </div>
                    <div class="p-col-12 p-md-3">
                      <div>
                        <span>
                          <p-button label="InProgress" icon="pi pi-check-square" iconPos="left"
                            styleClass="p-button-warning" badge="{{ taskBoardInprogressCount }}"
                            badgeClass="p-badge-danger"></p-button>
                        </span>
                      </div>
                      <br />
                      <div class="card" style="border: 1px solid #fbc508" *ngFor="let item of taskBoardInprogress">
                        {{ item.workTaskId }} : <a routerLink="/edittask/{{ item.taskId }}">{{ item.taskName }}</a>
                        <br>
                        Owner: {{ item.assigneeName }}
                        <br>
                        Due: <span
                          *ngIf="item.shortDuedate == null || item.shortDuedate == '01/01/1970' || item.shortDuedate == 'Invalid Date'">--</span>
                        <span
                          *ngIf="item.shortDuedate != null && item.shortDuedate != '01/01/1970' && item.shortDuedate != 'Invalid Date'">{{
                          item.shortDuedate | date: "MM/dd/yyyy"}}</span>


                      </div>
                    </div>

                    <div class="p-col-12 p-md-3">
                      <div>
                        <span>
                          <p-button label="NotRequired" icon="pi pi-check-square" iconPos="left"
                            styleClass="p-button-secondary" badge="{{ taskBoardHoldCount }}"
                            badgeClass="p-badge-danger"></p-button>
                        </span>
                      </div>
                      <br />
                      <div class="card" style="border: 1px solid #a5a7a5" *ngFor="let item of taskBoardHold">
                        {{ item.workTaskId }} : <a routerLink="/edittask/{{ item.taskId }}">{{ item.taskName }}</a>
                        <br>
                        Owner: {{ item.assigneeName }}
                        <!-- <br>
                          Due: <span *ngIf="item.shortDuedate == null || item.shortDuedate == '01/01/1970' || item.shortDuedate == 'Invalid Date'">--</span>
                          <span *ngIf="item.shortDuedate != null && item.shortDuedate != '01/01/1970' && item.shortDuedate != 'Invalid Date'">{{ item.shortDuedate | date: "MM/dd/yyyy"}}</span> -->

                      </div>
                    </div>
                    <div class="p-col-12 p-md-3">
                      <div>
                        <span>
                          <p-button label="Completed" icon="pi pi-check-square" iconPos="left"
                            styleClass="p-button-success" badge="{{ taskBoardCompleteCount }}"
                            badgeClass="p-badge-danger"></p-button>
                        </span>
                      </div>
                      <br />
                      <div class="card" style="border: 1px solid #06b312" *ngFor="let item of taskBoardComplete">
                        {{ item.workTaskId }} : <a routerLink="/edittask/{{ item.taskId }}">{{ item.taskName }}</a>
                        <br>
                        Owner: {{ item.assigneeName }}
                        <br>
                        Due: <span
                          *ngIf="item.shortDuedate == null || item.shortDuedate == '01/01/1970' || item.shortDuedate == 'Invalid Date'">--</span>
                        <span
                          *ngIf="item.shortDuedate != null && item.shortDuedate != '01/01/1970' && item.shortDuedate != 'Invalid Date'">{{
                          item.shortDuedate | date: "MM/dd/yyyy"}}</span>


                      </div>
                    </div>
                  </div>
                </p-tabPanel>
                <p-tabPanel header="Schedule" leftIcon="pi pi-calendar">
                  <div class="card-header" style="background-color: #f8f9fa; border-radius: 5px">
                    <h5 style="color: #495057; padding: 10px 0 0 15px">
                      Milestone Schedules
                    </h5>
                    <span style="float: right; margin-top: 5px">


                      <!-- <a (click)="buildCreateTaskUrl();" *ngIf="showCreateTask">
                        <button
                          pButton
                          type="button"
                          pTooltip="Create Task"tooltipPosition="top" 
                          class="p-button-rounded p-button-primary p-mr-2 p-mb-2"
                          icon="pi pi-plus"
                          style="height:25px; width:25px;"
                        ></button
                      ></a> -->
                      <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"
                        tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style=" margin-left:1px;height:25px; width:25px;" (click)="exportExcel()"></button>
                      <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                        class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right" (click)="print()"
                        style="height:25px; width:25px;"></button>
                    </span>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-12" *ngIf="events != undefined && events.length > 0">
                      <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 350px">
                        <p-fullCalendar [events]="events" [options]="options"></p-fullCalendar>
                      </div>
                    </div>
                    <div class="p-col-12" *ngIf="events != undefined && events.length == 0">
                      No milestone are available to display
                    </div>
                  </div>
                </p-tabPanel>
              </p-tabView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<p-dialog header="Help" [(visible)]="helpdisplay" modal="modal" showEffect="fade" [style]="{ 'width': '60%' }">
  <hr />

  <div style="padding: 20px;">
    1. If Milestone Status is New then Current Dates are shown as Start and End Dates.<br />
    2. If Milestone Status is in InProcess then Actual Start Date is displayed in Start Date and Current End Date is
    shown in End Date.<br />
    3. If Milestone Status is Completed then Actual Dates are shown as Start and End Dates.<br />
  </div>
</p-dialog>


<p-confirmDialog key="confirm-delete-item" header="Deleting {{editDeleteListDTO.ListName}}" acceptLabel="Yes"
  rejectLabel="No" [(visible)]="confirmDropDatabaseDialogVisible"></p-confirmDialog>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>

<!--Confirm Modal-->
<span class="listitem-value" style="float: right; margin-bottom: 0px; height: 20px">
  <p-dialog header="Confirm Delete" [(visible)]="deleteConfirmModal" modal="modal" showEffect="fade"
    [style]="{ width: '40%' }">
    <hr />
    <div class="p-grid">

      <div class="p-col-12 p-md-12 p-xl-12">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12" style="vertical-align: center">
            Are you sure, you want to delete <b>{{editDeleteListDTO.ListName}}</b> ?

          </div>
        </div>
        <br />
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12" style="vertical-align: center">
            <small class="p-invalid" style="float:right;">{{responseError}}</small>
          </div>
          <div class="p-col-6 p-md-6"></div>
          <div class="p-col-3 p-md-3">
            <button pButton type="button" label="Ok" class="p-button-success" (click)="deleteItemConfirm()"></button>
          </div>
          <div class="p-col-3 p-md-3">
            <button pButton type="button" label="Cancel" class="p-button-danger"
              (click)="deleteConfirmModal = false"></button>
          </div>
        </div>
      </div>

    </div>
  </p-dialog>
</span>


<p-dialog header="OverLap / Cycle Time" [(visible)]="Overlapinfo" modal="modal" showEffect="fade"
  [style]="{ 'width': '60%' }">
  <hr />

  <div style="padding: 20px;" *ngIf="projectInformation != undefined">
    <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-12">
        <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px">
          <ul class="widget-image-list">
            <li>
              <span style="font-weight: bold;">Layout Overlap Design Days:</span>
              <span>{{projectInformation.layoutOverlap}}</span>
            </li>
            <li>
              <span style="font-weight: bold;">Proportionate Cycle Time - Layout Engineers:</span>
              <span>{{ projectInformation.layoutCycleTime }} %</span>
            </li>
            <li>
              <span style="font-weight: bold;">Number of Assigned Layout Engineeres:</span>
              <span>{{ projectInformation.layoutAssignCount }}</span>
            </li>
            <li>
              <span style="font-weight: bold;">Proportionate Cycle Time - Designers:</span>
              <span>{{ projectInformation.designCycleTime }} %</span>
            </li>
            <li>
              <span style="font-weight: bold;">Number of Assigned Designers:</span>
              <span>{{ projectInformation.designAssignCount }}</span>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</p-dialog>


<p-dialog header="Description" [(visible)]="projectdescriptiondisplay" modal="modal" showEffect="fade"
  [style]="{ 'width': '40%' }">
  <hr />

  <div style="padding: 20px;" *ngIf="projectInformation != undefined">
    {{projectInformation.revision}}
  </div>
</p-dialog>

<p-dialog header="Create {{Action}}" [(visible)]="childprojectdisplay" modal="modal" showEffect="fade"
  [style]="{ 'width': '70%' }">
  <hr />
  <div class="p-grid">
    <div class="p-col-12 p-md-4">
      <label><b style="float: right;">Project:</b></label>
    </div>
    <div class="p-col-12 p-md-8">{{projectInformation?.projectName}}</div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;">
      <label><b style="float: right;">Project Description:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;">
      <p-dropdown [options]="projectdesciptionoption" [(ngModel)]="selectedprojectdescription"
        placeholder="Project Description" optionLabel="name" required="true"
        requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Package'">
      <label><b style="float: right;">Package Type:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Package'">
      <p-dropdown [options]="packagetypeoption" [(ngModel)]="selectedpackagetype" placeholder="Package Type"
        optionLabel="name" required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Package'">
      <label><b style="float: right;">Package Name:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Package'">
      <input type="text" [(ngModel)]="packagename" pInputText placeholder="Package Name" maxlength="20" />
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Foundry'">
      <label><b style="float: right;">Die Code (Root):</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Foundry'">
      <input type="text" [(ngModel)]="diecoderoot" pInputText placeholder="Die Code (Root)" maxlength="6" />
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Silicon' || Action == 'Foundry'">
      <label><b style="float: right;">Die Code Rev(D):</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Silicon' || Action == 'Foundry'">
      <input type="text" [(ngModel)]="diecoded" pInputText placeholder="Die Code Rev(D)" maxlength="1" />
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Silicon' || Action == 'Foundry'">
      <label><b style="float: right;">Die Code Rev(E):</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Silicon' || Action == 'Foundry'">
      <input type="text" [(ngModel)]="diecodee" pInputText placeholder="Die Code Rev(E)" maxlength="1" />
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Packing'">
      <label><b style="float: right;">Packing Option:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Packing'">
      <p-dropdown [options]="packingoption" [(ngModel)]="selectedpackingoption" placeholder="Packing Option"
        optionLabel="name" required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Packing'">
      <label><b style="float: right;">T & R Qty:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Packing'">
      <input type="text" [(ngModel)]="trqty" pInputText placeholder="T & R Qty" maxlength="10" />
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Packing'">
      <label><b style="float: right;">Tube Qty:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Packing'">
      <input type="text" [(ngModel)]="tubeqty" pInputText placeholder="Tube Qty" maxlength="10" />
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Packing'">
      <label><b style="float: right;">Tray Qty:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Packing'">
      <input type="text" [(ngModel)]="trayqty" pInputText placeholder="Tray Qty" maxlength="10" />
    </div>
    <!-- *ngIf="Action == 'Silicon' || Action == 'Package' || Action == 'Packing' || Action == 'Derivative'" -->
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;">
      <label><b style="float: right;">MFG Part #:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;">
      <input type="text" [(ngModel)]="mfgpartSilicon" pInputText placeholder="MFG Part #" maxlength="50" />
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;"
      *ngIf="Action == 'Silicon' || Action == 'Package' || Action == 'Packing' || Action == 'Derivative'">
      <label><b style="float: right;">FG Part #:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;"
      *ngIf="Action == 'Silicon' || Action == 'Package' || Action == 'Packing' || Action == 'Derivative'">
      <input type="text" [(ngModel)]="fgpart" pInputText placeholder="FG Part #" maxlength="50" />
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Derivative'">
      <label><b style="float: right;">Temperature Grade:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Derivative'">
      <p-dropdown [options]="tempgradeoption" [(ngModel)]="selectedtempgrade" placeholder="Temperature Grade"
        optionLabel="name" required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Foundry'">
      <label><b style="float: right;">Foundry:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Foundry'">
      <p-dropdown [options]="foundryoptions" [(ngModel)]="selectedfoundry" placeholder="Foundry" optionLabel="name"
        required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Foundry'">
      <label><b style="float: right;">Foundry Process:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Foundry'">
      <p-dropdown [options]="foundryprocessoptions" [(ngModel)]="selectedfoundryprocess" placeholder="Foundry Process"
        optionLabel="name" required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>

    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Subcon'">
      <label><b style="float: right;">CP Subcon (Primary):</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Subcon'">
      <p-dropdown [options]="CPSubconO" [(ngModel)]="selectedCPSubcon" placeholder="CP Subcon (Primary)"
        optionLabel="name" required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Subcon'">
      <label><b style="float: right;">FT Subcon (Primary):</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Subcon'">
      <p-dropdown [options]="FTSubconO" [(ngModel)]="selectedFTSubcon" placeholder="FT Subcon (Primary)"
        optionLabel="name" required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Subcon'">
      <label><b style="float: right;">Assy Subcon 1 (Primary):</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Subcon'">
      <p-dropdown [options]="AssySubcon1O" [(ngModel)]="selectedAssySubcon1" placeholder="Assy Subcon 1 (Primary)"
        optionLabel="name" required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Subcon'">
      <label><b style="float: right;">Assy Subcon 2 (Primary):</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Subcon'">
      <p-dropdown [options]="AssySubcon2" [(ngModel)]="selectedAssySubcon2" placeholder="Assy Subcon 2 (Primary)"
        optionLabel="name" required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Subcon'">
      <label><b style="float: right;">DPS Subcon (Primary):</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Subcon'">
      <p-dropdown [options]="DPSSubconO" [(ngModel)]="selectedDPSSubcon" placeholder="DPS Subcon (Primary)"
        optionLabel="name" required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Subcon'">
      <label><b style="float: right;">Assy Subcon Process 1:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Subcon'">
      <p-dropdown [options]="AssySubconProcess1O" [(ngModel)]="selectedAssySubconProcess1"
        placeholder="Assy Subcon Process 1" optionLabel="name" required="true"
        requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Subcon'">
      <label><b style="float: right;">Assy Subcon Process 2:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Subcon'">
      <p-dropdown [options]="AssySubconProcess2O" [(ngModel)]="selectedAssySubconProcess2"
        placeholder="Assy Subcon Process 2" optionLabel="name" required="true"
        requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;" *ngIf="Action == 'Subcon'">
      <label><b style="float: right;">DPS Subcon Process:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;" *ngIf="Action == 'Subcon'">
      <p-dropdown [options]="DPSSubconProcessO" [(ngModel)]="selectedDPSSubconProcess" placeholder="DPS Subcon Process"
        optionLabel="name" required="true" requiredMessage="The input must not be empty">
      </p-dropdown>
    </div>

    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem;">
      <label><b style="float: right;">Description:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;">
      <input type="text" [(ngModel)]="description" pInputText placeholder="Description" maxlength="200"
        style="width: 450px;" />
    </div>
    <div class="p-col-12 p-md-4" style="margin-top: -0.5rem; ">
      <label><b style="float: right;">Project Start Date:</b></label>
    </div>
    <div class="p-col-12 p-md-8" style="margin-top: -1rem;">
      <p-calendar [showIcon]="true" [(ngModel)]="MaxStartDate" required="true" [minDate]="minMaxStartDate"
        requiredMessage="The input must not be empty"></p-calendar>
      <span style="color: red">*</span>
    </div>
    <div class="p-col-12 p-md-12" style="margin-top: -1rem;text-align: center; "><small class="p-invalid"
        *ngIf="CPErrorDisplay">{{ChildProjectError}}</small></div>
    <div class="p-col-12 p-md-12" style="margin-top: -1.5rem;">
      <span style="float: right;">
        <button pButton type="button" [disabled]="addchildproject" (click)="AddSilicon()" label="Create"
          icon="pi pi-plus" pTooltip="Create Child Project" tooltipPosition="right"
          class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
          style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
      </span>
    </div>
  </div>
  <br><br><br><br><br><br><br><br><br><br>
</p-dialog>