import { Component, OnInit, ViewChild } from "@angular/core";
import { Table } from "primeng/table";
import { FacadeService } from '../../facade/facade.service';
import {
  ManageCustomFields,
  CustomFieldgroups
} from "../domain/customfields";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";
// import { BASE_API_URL_TOKEN } from "src/app/injectors";
// import { Inject } from "@angular/core";
@Component({
  selector: "app-customfields",
  templateUrl: "./customfields.component.html",
  styleUrls: ["./customfields.component.scss"],
  providers: [ConfirmationService],
})
export class CustomfieldsComponent implements OnInit {
  showLoading: boolean;
  selectedCustomField: any;
  Options: [];
  Title:string="";
  selproductID: any;
  editProjectName:string = "";
  changed: boolean = false;
  selgroupID: any;
  CustomFieldgroupId: any;
  OrgCustomFieldgroupId: any;
  ManageCustomFields: ManageCustomFields[];
  CustomFieldgroups: CustomFieldgroups[];
  selectedManageCustomFields: any;
  selectedManageCustomGroups: CustomFieldgroups;
  applicationID = sessionStorage.getItem("applicationID");
  userId = sessionStorage.getItem("userGuid"); //"2A6E2C91-C022-4743-9691-6FAEC9CEF6E1";
  @ViewChild("dt") table: Table;

  historydisplay :boolean=false;
  FieldName:string="";
  HistoryCols: any[];
  History: any[];
  selectedHistory: any[];
  @ViewChild("dtHistory") tableHistory: Table;

  productNameError: any;
  groupNameError: any;
  tableError: any;
  ErrorDisplay: boolean = false;
  Error: string = "";

  datasourcelist: any = {};
  isReadOnly: boolean=false;
  ProductGUID : string;
  apiResponse: any;
  confirmDeleteDocument = false;
  ID:string="";
  Type:string="";
  canmodify:boolean=false;
  filteredProdType: any[];
  AllActiveProjects:boolean;
  owneris:boolean;
  userFeature:boolean=false;
  constructor(private _facadeService: FacadeService, private router: Router,
    private route: ActivatedRoute,private confirmation: ConfirmationService) {}

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PM_CF");
        if(featureExist!=null && featureExist != undefined)
          this.userFeature = true;

        featureExist = feature.find(m=>m.code=="PM_GAP");
        if(featureExist!=null && featureExist != undefined)
          this.AllActiveProjects = true;

        featureExist = feature.find(m=>m.code=="PM_Owner");
        if(featureExist!=null && featureExist != undefined)
          this.owneris = true;
      }
    }
    this.Title="Manage Custom Attributes";
      this.showLoading=true;
      this.isReadOnly = false;
      this.HistoryCols = [
        { field: "editedByName", header: "Edited By" },
        { field: "editedDate", header: "Edited Date" },
        { field: "oldValueName", header: "Old Value" },
        { field: "newValueName", header: "New Value" },
      ];
      if(this.route.snapshot.paramMap.get('projectId')!=undefined)
      {
        var ii = this.route.snapshot.paramMap.get('projectId');
        if(ii != '0' && ii != '')
        {
          this.isReadOnly = true;
          this.ProductGUID = ii;
          this.editProjectName = this.route.snapshot.paramMap.get('pid');
        }
      } 
      this.getProductByuser(this.userId);
   // }
  }
  phasetree(PID)
  {
    sessionStorage.FromReport = false;
    this.router.navigate(["/phasetree/"+PID]);
  }
  filterproductListChange(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.ManageCustomFields.length; i++) {
        let product= this.ManageCustomFields[i];
        if (product.projectName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(product);
        }
    }    
    this.filteredProdType = filtered;
  }
  Details(CustomFieldID,FieldName) {
    console.log(this.selproductID, CustomFieldID);
    this.showLoading = true;
    this.historydisplay = true;
    this.FieldName = this.ManageCustomFields.find(m=>m.projectId==this.selproductID).projectName + " - " + FieldName;
    this._facadeService.GetHistory(this.selproductID, CustomFieldID).subscribe(
      (data) => {
        this.History = data.returnObject;
        this.History.forEach((value, key) => {
          if ( value.editedDate !== null &&  value.editedDate !== undefined ) {
            value.editedDate = new Date(value.editedDate + "Z");
            value.editedDate = (value.editedDate);
          }
        });
        this.showLoading= false;
        console.log(this.History);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getProductByuser(userId) {
    if(this.AllActiveProjects)
    {
      this._facadeService
      .GetAllActiveProjects()
      .subscribe((data) => {
        this.ManageCustomFields = data.returnObject;
        if(this.isReadOnly)
        {
          this.selectedManageCustomFields = this.ManageCustomFields.find(m=>m.projectId==this.ProductGUID);
          this.Title = this.ManageCustomFields.find(m=>m.projectId==this.ProductGUID).projectName + " - Custom Attributes";
          this.selproductID =  this.ProductGUID;
          this.getCustomGroup();
        }
        else
          this.showLoading=false;
        console.log("this.ManageCustomFields----", this.ManageCustomFields);
      });
    }
    else{
    this._facadeService
      .GetProductByUser(userId)
      .subscribe((data) => {
        this.ManageCustomFields = data.returnObject;
        if(this.isReadOnly)
        {
          this.selectedManageCustomFields = this.ManageCustomFields.find(m=>m.projectId==this.ProductGUID);
          this.Title = this.ManageCustomFields.find(m=>m.projectId==this.ProductGUID).projectName + " - Custom Attributes";
          this.selproductID =  this.ProductGUID;
          this.getCustomGroup();
        }
        else
          this.showLoading=false;
        console.log("this.ManageCustomFields----", this.ManageCustomFields);
      });
    }
  }

  getCustomGroup() {
    this._facadeService.GetCustomGroups().subscribe((data) => {
      this.CustomFieldgroups = data.returnObject;
      if(this.isReadOnly)
      {
        let id;
        let CustomFieldgroups1 = this.CustomFieldgroups.slice(0,1);
        CustomFieldgroups1.forEach((value, key) => {
          id = value.customGroupGuid;
        });
        
        this.selectedManageCustomGroups = this.CustomFieldgroups.find(m=>m.customGroupGuid==id);
        this.selgroupID =  id;
        this.getFieldsByGroupID(
          this.selgroupID,
          this.selproductID,this.applicationID
        );
      }
      this.showLoading=false;
    });
  }

  keyPressNumbers(event,type) {
    if(type.toLowerCase().includes("decimal"))
    {
      var e;
      e = type.toLowerCase().replace("decimal(", "");
      e = e.toLowerCase().replace(")", ""); 
      var splitted = e.split(",");
      var resut;
      if(event.target.value.includes("."))
        resut=  new RegExp("^[0-9]{0," + splitted[0] +"}\.?[0-9]{0," + splitted[1] + "}$").test(event.target.value);
      else
        resut=  new RegExp("^[0-9]{0," + splitted[0] +"}$").test(event.target.value);
      if (!resut)
        event.target.value = "";
    }
    else if(type.toLowerCase().includes("date"))
    {
      let timestamp = Date.parse(event.target.value);
      if (isNaN(timestamp))
        event.target.value = "";
    }
  }

Reset()
{
  this.isReadOnly = false;
  this.selproductID = 0;
  this.selgroupID = 0;
  this.getProductByuser(this.userId);
  this.selectedManageCustomFields = this.ManageCustomFields.find(m=>m.projectId==this.selproductID);
  this.getCustomGroup();
  this.selectedManageCustomGroups = this.CustomFieldgroups.find(m=>m.customGroupGuid==this.selgroupID);
  this.getFieldsByGroupID(
    this.selgroupID,
    this.selproductID,this.applicationID
  );

}

  getFieldsByGroupID(GroupID, ProductID, ApplicationID) {
    this._facadeService
      .GetFieldsByGroupID(GroupID, ProductID, ApplicationID)
      .subscribe((data) => {
        this.CustomFieldgroupId = data.returnObject;
        console.log("this.getFieldsByGroupID----", this.CustomFieldgroupId);
        if (this.CustomFieldgroupId.length > 0) {
          this.OrgCustomFieldgroupId = this.CustomFieldgroupId;//.filter((item) => item.value != null && item.value != undefined);
          console.log(this.OrgCustomFieldgroupId);
          this.CustomFieldgroupId = this.CustomFieldgroupId.sort((n1,n2) => {
            if (n1 > n2) { return 1; }
            if (n1 < n2) { return -1; }
            return 0;
          });

          this.CustomFieldgroupId.forEach((element) => {
            element.length = 0;
            if(element.entryType == "TextBox")
            {
                if(element.fieldDataType.toLowerCase().includes("varchar"))
                {
                  var e;
                  e = element.fieldDataType.toLowerCase().replace("varchar(", "");
                  e = e.toLowerCase().replace(")", ""); 
                  element.length = Number(e);
                  element.fieldName = element.fieldName + " ("+ e + " char)";
                }
                else if(element.fieldDataType.toLowerCase().includes("decimal"))
                {
                  var e;
                  e = element.fieldDataType.toLowerCase().replace("decimal(", "");
                  e = e.toLowerCase().replace(")", ""); 
                  e = e.toLowerCase().replace(" ", ""); 
                  var splitted = e.split(",");
                  if(Number(splitted[1]) > 0)
                    element.length = Number(splitted[0]) + Number(splitted[1]) + 1;
                  else
                    element.length = Number(splitted[0]) + Number(splitted[1]);
                  element.fieldName = element.fieldName + " (";
                  for (let i = 0; i < splitted[0]; i++) {
                    element.fieldName = element.fieldName + "n";
                  }
                  if(Number(splitted[1]) > 0)
                  {
                    element.fieldName = element.fieldName + ".";
                    for (let i = 0; i < splitted[1]; i++) {
                      element.fieldName = element.fieldName + "n";
                    }
                  }
                  element.fieldName = element.fieldName + ")";
                }
                else if(element.fieldDataType.toLowerCase().includes("date"))
                  element.fieldName = element.fieldName + " (mm/dd/yyyy)";
            }
            let colname = element.customFieldID.toString();
            this.datasourcelist[colname] = {
              list: [],
              value: ""
            };
            this.DataSourceAsQuery(element.dataSource, colname, element.value, element.valueName,element.isDependent,element.dependentID);
          });
        }
        this.showLoading=false;
      });
  }

  changedok()
  {
    if(!this.isReadOnly)
    {
      this.changed = false;
      if(this.OrgCustomFieldgroupId != undefined)
      {
        if(this.OrgCustomFieldgroupId.length > 0)
        {
          this.CustomFieldgroupId.forEach((element) => {
            if(this.datasourcelist[element.customFieldID].value != null && this.datasourcelist[element.customFieldID].value != undefined) 
            {
              this.OrgCustomFieldgroupId.forEach((element1) => {
                if(element1.customFieldID == element.customFieldID)
                {
                  if(element1.value != null && element1.value != undefined)
                  {
                    if(element1.value != this.datasourcelist[element.customFieldID].value && !this.changed)
                      this.changed= true;
                  }
                  else{
                    this.changed= true;
                  }
                }
              });
            }
          });
        }
      }
      if(this.changed)
        this.removedocument();
      else
        this._ok();
    }
    else
      this._ok();
  }
  productListChange() {
    if(!this.isReadOnly && this.owneris)
    {
      this._facadeService.IsOwner(this.selectedManageCustomFields.projectId,this.userId).subscribe(
      (data) => {
        this.canmodify=data.returnObject;
        if(!this.canmodify)
        {
          this.productNameError="You don't have access to modify the selected project.";
          this.isReadOnly = true;
        }
        this.showLoading=false;
      },
      (error) => {  console.log(error);    }
      );
    }
    this.getCustomGroup();
    this.ID = this.selectedManageCustomFields.projectId;
    this.Type="Product";
    this.changedok();
  }
  private removedocument() {
      this.confirmation.confirm({
        key: "confirm-document",
        message: "Changed values are not saved. Are you sure you want to continue?",
        accept: () => {
          this._ok();
          this.confirmDeleteDocument = false;
        },
        reject:()=>{
          this._retainvalue();
          this.confirmDeleteDocument = false;
        },
      });
      this.confirmDeleteDocument = false;
  }
  _retainvalue()
  {
    if(this.Type == "Group")
      this.selectedManageCustomGroups = this.CustomFieldgroups.find(m=>m.customGroupGuid==this.selgroupID);
    else
      this.selectedManageCustomFields = this.ManageCustomFields.find(m=>m.projectId==this.selproductID);
  }
  groupChange(selectedGroup) {
    this.ID = selectedGroup.customGroupGuid;
    this.Type="Group";
    this.changedok();
  }
  _ok()
  {
    this.showLoading=true;   
    if(this.Type == "Group")
    {  
      this.selgroupID = this.ID;
      this.groupNameError = '';
    }
    else
    {
      this.selproductID = this.ID;
      this.productNameError = '';
    }
    this.getFieldsByGroupID(
      this.selgroupID,
      this.selproductID,this.applicationID
    );
  }

  SaveCustomFields() {
    this.showLoading=true;
    const copyvalues = [];
    let obj = {}; 
    if (this.selproductID === null || this.selproductID === undefined) {
      this.productNameError = 'Project is not selected';this.showLoading=false;
    } else if (this.selgroupID === null || this.selgroupID === undefined) {
      this.groupNameError = 'Group is not selected';this.showLoading=false;
    } else {
      this.CustomFieldgroupId.forEach((element) => {
        if (//this.datasourcelist[element.customFieldID].value != '' && 
        this.datasourcelist[element.customFieldID].value != null && this.datasourcelist[element.customFieldID].value != undefined) {
          let ok = false;
          if(this.OrgCustomFieldgroupId != undefined)
          {
            if(this.OrgCustomFieldgroupId.length > 0)
            { 
              this.OrgCustomFieldgroupId.forEach((element1) => {
                if(element1.customFieldID == element.customFieldID)
                {
                  if(element1.value != null && element1.value != undefined)
                  {
                    if(element1.value != this.datasourcelist[element.customFieldID].value && !ok)
                      ok= true;
                  }
                  else
                    ok= true;
                }
              });
            }
          }

          if(ok)
          {
            this.tableError = '';    
            obj = {
              entityID: this.selproductID,
              customFieldID: element.customFieldID,
              seq: element.customFieldID,
              customFieldName: element.fieldName,
              value: this.datasourcelist[element.customFieldID].value,
              createdBy: this.userId,
              createdDate: new Date(),
              updatedBy: this.userId,
              updatedDate: new Date(),
            };
            copyvalues.push(obj);
          }
        }
      });
      console.log('copyvalues----',copyvalues);
      if(copyvalues.length > 0)
      {
        this._facadeService
          .SaveCustomFields(copyvalues)
          .subscribe((data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.getFieldsByGroupID(
                this.selgroupID,
                this.selproductID,this.applicationID
              );
              this.changed = false;
              this.showLoading = false;
              this.ErrorDisplay = true;
              this.Error = "Saved successfully.";
            } else {
              this.showLoading = false;
              this.tableError = this.apiResponse.returnMessage;
            }
          });
      }
      else{
        this.tableError = "select/Enter atleast one value";
        this.showLoading=false;
      }
    }
  }

  CustomAttribute(value,customFieldID,ParentDependent){
    if(value != null && value != undefined && value != "" && ParentDependent)
    {
      this._facadeService
      .GetDependentAttributes(customFieldID)
      .subscribe((data) => {
        var list = [];
        list =  data.returnObject;
        if(list != null && list != undefined)
        {
          if(list.length > 0)
          {
            list.forEach(element => {
              this._facadeService
              .GetDataDependent(element.dataSource,value)
              .subscribe((data) => {
                this.showLoading=false;
                this.datasourcelist[element.customFieldID] = {
                  list: data.returnObject,
                  value: "",
                };
              });
            });
          }
        }
      });
    }
  }
  
    DataSourceAsQuery(query, colname,Value,ValueName,isDependent,dependentID) {
      if(this.isReadOnly)
      {
        if(Value != undefined && Value != null)
            this.datasourcelist[colname] = { list: [ {id: Value, name: ValueName}, ], value:  Value,}
      }
      else
      {
        if(isDependent)
        {
          var dep = this.CustomFieldgroupId.filter(x=>x.customFieldID.toString() == dependentID.toString());
          if(dep != null && dep != undefined)
          {
            if(dep[0].value != null && dep[0].value != undefined)
            {
              this._facadeService
              .GetDataDependent(query,dep[0].value)
              .subscribe((data) => {
                this.showLoading=false;
                this.datasourcelist[colname] = {
                  list: data.returnObject,
                  value: Value,
                };
              });
            }
          }
        }
        else
        {
          this._facadeService
          .DataSourceAsQuery(query)
          .subscribe((data) => {
            this.showLoading=false;
            this.datasourcelist[colname] = {
              list: data.returnObject,
              value: Value,
            };
          });
        }
      }
    }
}
