import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(private httpService: HttputilityService) { }

  createList(data) {
    return this.httpService.Add('PMAPI', `Lists/CreateList`, 'httpOptions', data)
  }

  GetProjectMenu(projectId) {
    return this.httpService.Get('PMAPI', 'Lists/GetTreeListByProjectId/' + projectId);
  }

  GetListDetailsByListId(listId) {
    return this.httpService.Get('PMAPI', 'Lists/GetListDetailsByListId/' + listId);
  }

  GetStageByPhase(phaseId) {
    return this.httpService.Get('PMAPI', 'Lists/GetStageByPhase/' + phaseId);
  }

  GetMilestoneByStage(stageId) {
    return this.httpService.Get('PMAPI', 'Lists/GetMilestoneByStage/' + stageId);
  }

  DeleteList(listDTO) {
    return this.httpService.Add('PMAPI', `Lists/DeleteList`, 'httpOptions', listDTO)
  }
}
