export interface Tasks{
    taskId? : string;
    taskName? : string;
    duration? : number;
    assignee? : string;
    startDate? : string;
    dueDate? : string;
}
export interface TaskBoard
{
    assignee? : string;
    priority? : string;
    taskId? : string;
    taskName? : string;
    dueDate? : string;
    shortDuedate? : string;
    taskStatusId? : number;
    taskStatusName? : string;
}

export interface TaskCalender
{
    id? : number;
    title? : string;
    start?:Date;
    end?:Date;
    taskGuid?:string;
}

export class CreateTaskFormViewModel
{
    Tid?:number;
    TaskSeq?: number;
    OverlapDays?: number;
    ProportionateTime?: number;
    TaskId? : string;
    ListID?:string;
    ProjectId? : string; 
    PhaseId? : string; 
    StageId? : string; 
    MilestoneId?:string;
    StartDate? : string; 
    editStartDate? : any;
    DueDate? : string;
    ProjectManager? : string; 
    TaskName? : string;
    Address? : string; 
    TaskParent? : string; 
    Predecessor? : string[]; 
    PlanedStartDate? : string; 
    Duration? : number; 
    RevisedPlannedStartDate? : string; 
    ActualStartDate? : string; 
    PlannedEndDate? : string; 
    RevisedPlannedEndDate? : string;
    ActualEndDate? : string; 
    CriticalPath? : string; 
    CriticalPathSwitch? : boolean = false; //
    CompletedEfforts? : number; 
    BurntHoursToday? : number; 
    EstimatedEfforts? : number;
    Assignee? : string[]; 
    ProgressCompleted? : string; 
    TaskStatus? : string; 
    AttachedDoc? : string; 
    Address2? : string;
    createdBy: string;
    createdDate: Date;
    burntHoursCumulative?:number;
    Notes?:string;
    WorkTaskId?:string;
    ProjectName?:string;

    TaskFiles?: File[];

    IsIncludeweekends:boolean= false;
    IsDateValidationRequired:boolean=false;
    currentOverride:boolean=false;
    targetOverride:boolean=false;
    actualStartOverride:boolean=false;
    actualEndOverride:boolean=false;
    DeptId:number;
    AllowMultipleAssignees:string="N";
}

export class UserNode
{
    id?:number;
    name?:string;
}

export enum ListEnum {
    Phase = 1,
    Stage = 2,
    MileStone = 3,
    Project = 4
  }
  export enum TaskStatusEnum
  {
    New = 1,
    InProgress = 2,
    Hold = 3,
    Completed = 4,
    Rejected = 5,
    NotRequired = 6
  }