import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BASE_API_URL_TOKEN } from 'src/injector';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(@Inject(BASE_API_URL_TOKEN) public baseUrl: Object)
  {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      var access_token = sessionStorage.getItem("access_token");
      if(access_token ==""||access_token ==null||access_token == undefined)
      {
        location.href=this.baseUrl["API_ENDPOINTS"]["UMSUI"];
        return false;
      }
    return true;
  }
}
