import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { FacadeService } from '../../facade/facade.service';
import { Router } from '@angular/router';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from "src/injector";
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from 'src/app/services/printcommon.service';
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';
@Component({
    selector: 'app-projectdashboard',
    templateUrl: './projectdashboard.component.html',
    styleUrls: ['./projectdashboard.component.scss']
})
export class ProjectdashboardComponent implements OnInit {
    projects: any[];
    projectsD: any[];
    AllProductsValue:boolean=false;
    revenueChart: any;
    labels: string[] = [];
    data: number[] = [];
    options: any;
    isChartDataAvailable:boolean=false;
    selectedfilter ="startsWith";

    timeLines: any[];
    showLoading: boolean = false;
    TimelineOptions: any;
    selectedTimelineOption: any;
    userGuid = sessionStorage.getItem('userGuid');
    tilesData = {
        activeProjectsCount: 0,
        activeOverdueProjectsCount: 0,
        pendingProjectsCount: 0,
        totalTasksCount: 0,
        completedTasksCount: 0,
        overDueProjectsCount: 0,
        overDueTasksCount: 0,
        newProjectsCount: 0,
        holdProjectsCount: 0,
        completedProjectsCount: 0,
        rejectedProjectsCount: 0
    }
    Count : number = 0;
    ProductCountFilter: any="";
    constructor(
        private facadeService: FacadeService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private printService:PrintcommonService,
        private datePipe: DatePipe,
        @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard', routerLink: ['/'] }
        ]);
    }

    ngOnInit() {
      this.showLoading = true;
      this.AllProductsValue = false;
      if(sessionStorage.allproducts != undefined && sessionStorage.allproducts != null)
        this.AllProductsValue = sessionStorage.allproducts == "true" ? true : false;

        sessionStorage.projectName = "";
        sessionStorage.phaseName = "";
        sessionStorage.projectStartDate = "";
        sessionStorage.targetDate = "";
        sessionStorage.projectManagerName = "";
        sessionStorage.projectStatusName = "";
        this.TimelineOptions = [
            { name: 'Week',value: 7 },
            { name: '2 Weeks',value: 14 },
            { name: '3 Weeks',value: 21 },
            { name: 'Month',value: 30 },
          ];
        this.getTilesData();
        this.GetTimeLines({ name: 'Week',value: 7 });
        //this.updateChartData(this.userGuid);
        this.getProjects();
        this.getProjectsN();
    }

    openproject(val)
    {
      sessionStorage.FromReport = true;
      sessionStorage.From = "dashboard";
        this.router.navigate(["/phasetree/"+val]);
    }

    opentask(val)
    {
        this.router.navigate(["/edittask/"+val]);
    }

    GetTimeLines(val) 
    {
        this.showLoading = true;
        this.facadeService.getProjectDashboardTimeLines(this.userGuid, val.value).subscribe(
          (sucess) => {
            this.timeLines = sucess.returnObject;
            if(this.timeLines != null)
            {
              if(this.timeLines.length > 0)
              {
                this.timeLines.forEach(e => {
                    let actiondate = new Date(e.actionDate+'Z') 
                    e.actionDate = actiondate. toLocaleDateString() +' '+ actiondate.toLocaleTimeString()
                });
              }
            }
            this.showLoading = false;
          }
        )
    }

    getTilesData() {
      this.showLoading = true;
      this.facadeService.getTilesData(false, this.userGuid).subscribe(
          (sucess) => {
              this.showLoading = false;
              this.tilesData = sucess.returnObject;
          }
      )
    }
  

    updateChartData(userid) {
      this.data = [];
      this.labels = [];
      this.isChartDataAvailable = false;
    this.facadeService.Chart(userid).subscribe(
        (res) => {
          for (let i = 0; i < res.returnObject.length; i++) {
            this.labels.push(res.returnObject[i].phase);
          }
          for (let i = 0; i < res.returnObject.length; i++) {
            this.data.push(res.returnObject[i].count);
            if(res.returnObject[i].count>0){
              this.isChartDataAvailable = true;
            }
          }
          this.revenueChart = {
            labels: this.labels,
            datasets: [
              {
                data: this.data,
                backgroundColor: [
                  // '#2196F3',
                  '#c14a09',
                  '#FFA500',
                  '#008000',
                  '#0000FF',
                  '#3b2820',
                  '#800080',
                  '#2196F3',
                  ],
              },
            ],
          };
  
          this.options = {
            legend: { display: true, position:'right', align:'center', 
            labels: { fontColor: "#7b7c7c",fontSize: 16,padding:24}
          },
        }
        this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }

    refreshchart()
    {
      if(this.AllProductsValue)
      {
        this.updateChartData("");
      }
      else
      {
        this.updateChartData(this.userGuid);

      }
    }

    getProjects() {
      this.showLoading = true;
      sessionStorage.allproducts = this.AllProductsValue;
      if(this.AllProductsValue)
      {
          this.facadeService.getProjects(true, "","N").subscribe(
          (data) => {
            this.projects = data.returnObject;
            if(this.projects != null)
              {
                  if(this.projects.length > 0)
                  {
                      this.projects.forEach(e => {
                          if(e.projectStartDate != '' || e.projectStartDate != null){
                            e.projectStartDate = this.datePipe.transform( e.projectStartDate, 'MM/dd/yyyy');
                          }
                          if(e.targetDate != '' || e.targetDate != null){
                              e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
                          }
                      });
                  }
                  //this.CountM();
                  //this.refreshchart();
              }
            this.showLoading = false;
            },
          (error) => {
            console.log(error);
          }
        );
      }
      else
      {
          this.facadeService.getProjects(false, this.userGuid,"N").subscribe(
          (data) => {
              this.projects = data.returnObject; 
              if(this.projects != null)
              {
                  if(this.projects.length > 0)
                  {
                      this.projects.forEach(e => {
                          if(e.projectStartDate != '' || e.projectStartDate != null){
                              e.projectStartDate = this.datePipe.transform( e.projectStartDate, 'MM/dd/yyyy');
                             
                          }
                          if(e.targetDate != '' || e.targetDate != null){
                              e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
                          }
                      });
                  }
                  //this.CountM();
                  //this.refreshchart();
              }
              this.showLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
      }      
    }

    getProjectsR()
    {
      this.getProjects();  
      this.getProjectsN();  
    }
    
    getProjectsN() {
      this.showLoading = true;
      sessionStorage.allproducts = this.AllProductsValue;
      if(this.AllProductsValue)
      {
          this.facadeService.getProjects(true, "","Y").subscribe(
          (data) => {
            this.projectsD = data.returnObject;
            if(this.projectsD != null)
              {
                  if(this.projectsD.length > 0)
                  {
                      this.projectsD.forEach(e => {
                          if(e.projectStartDate != '' || e.projectStartDate != null){
                            e.projectStartDate = this.datePipe.transform( e.projectStartDate, 'MM/dd/yyyy');
                          }
                          if(e.targetDate != '' || e.targetDate != null){
                              e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
                          }
                      });
                  }
                  this.CountM();
                  this.refreshchart();
              }
            this.showLoading = false;
            },
          (error) => {
            console.log(error);
          }
        );
      }
      else
      {
          this.facadeService.getProjects(false, this.userGuid,"Y").subscribe(
          (data) => {
              this.projectsD = data.returnObject; 
              if(this.projectsD != null)
              {
                  if(this.projectsD.length > 0)
                  {
                      this.projectsD.forEach(e => {
                          if(e.projectStartDate != '' || e.projectStartDate != null){
                              e.projectStartDate = this.datePipe.transform( e.projectStartDate, 'MM/dd/yyyy');
                             
                          }
                          if(e.targetDate != '' || e.targetDate != null){
                              e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
                          }
                      });
                  }
                  this.CountM();
                  this.refreshchart();
              }
              this.showLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
      }      
    }
    
    test(val,field,dt)
    {
      if(field == "projectName")
        sessionStorage.projectName = val;
      else if(field == "phaseName")
        sessionStorage.phaseName = val;
      else if(field == "projectStartDate")
        sessionStorage.projectStartDate = val;
      else if(field == "targetDate")
        sessionStorage.targetDate = val;
      else if(field == "projectManagerName")
        sessionStorage.projectManagerName = val;
      else if(field == "projectStatusName")
        sessionStorage.projectStatusName = val;

      if(this.selectedfilter=="startsWith")
        dt.filter(val, field, 'startsWith');
      else
        dt.filter(val, field, 'contains');      //this.ProductCountFilter = dt.count;
      //this.CountM();
    }

    onFilter(event, dt) {
      if (event.filteredValue.length == this.projects.length) {
        this.ProductCountFilter = "";
      }
      else if (event.filteredValue.length != this.projects.length && event.filteredValue.length==0) {
        this.ProductCountFilter = "";
      }
      else {
        this.ProductCountFilter = event.filteredValue.length+" projects found.";
      }
    }
  
    
    CountM()
    {
      if(this.projectsD != undefined && this.projectsD != null)
      {
        var temp = this.projectsD;
        if(sessionStorage.projectName != "")
            temp = temp.filter(x=> x.projectName.toLowerCase().startsWith(sessionStorage.projectName.toLowerCase()));
        if(sessionStorage.phaseName != "")
            temp = temp.filter(x=> x.phaseName.toLowerCase().startsWith(sessionStorage.phaseName.toLowerCase()));
        if(sessionStorage.projectStartDate != "")
            temp = temp.filter(x=> x.projectStartDate.toLowerCase().startsWith(sessionStorage.projectStartDate.toLowerCase()));
        if(sessionStorage.targetDate != "")
            temp = temp.filter(x=> x.targetDate.toLowerCase().startsWith(sessionStorage.targetDate.toLowerCase()));
        if(sessionStorage.projectManagerName != "")
            temp = temp.filter(x=> x.projectManagerName.toLowerCase().startsWith(sessionStorage.projectManagerName.toLowerCase()));
        if(sessionStorage.projectStatusName != "")
            temp = temp.filter(x=> x.projectStatusName.toLowerCase().startsWith(sessionStorage.projectStatusName.toLowerCase()));
        sessionStorage.dahsboardDT = JSON.stringify(temp);
        this.Count = temp.length;
      }
      else
        this.Count = 0;
    }

    exportExcel() {
      //let element = document.getElementById('excel-table'); 
      var dte = [];
      this.projectsD.map(d => (
        {
          "Project Name": d.projectName,
          "Active Phase": d.phaseName,
          "Start Date": d.projectStartDate,
          "End Date": d.targetDate,
          "Product Engineer": d.projectManagerName,
          "Project Status": d.projectStatusName   
        }
      )).forEach((d: any) => {
        dte.push(d);
      });
  
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dte);
      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [{ wpx: 180 }, { wpx: 100 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 130 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 120 }, { wpx: 120 }, { wpx: 120 }];
      //this.delete_row(wb.Sheets.Sheet1, 1);
      / save to file /
      XLSX.writeFile(wb, "Product Dashboard.xlsx");
    }

    delete_row (ws, row_index){
      let range = XLSX.utils.decode_range(ws["!ref"])
      for(var R = row_index; R < range.e.r; ++R){
          for(var C = range.s.c; C <= range.e.c; ++C){
            ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
              ws[this.ec(R+1, C)].v  = "";
          }
      }
      range.e.r--
      delete (ws['G1'])
      ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
    }
    ec(r, c) {
      return XLSX.utils.encode_cell({r:r,c:c})
    }

    print() {
      var Content = document.getElementById('invoice-content').innerHTML;
      this.printService.Print(Content,'Active Projects','trSearch',true,0);
    }
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;
    
        if (value1 == null && value2 != null)
          result = -1;
        else if (value1 != null && value2 == null)
          result = 1;
        else if (value1 == null && value2 == null)
          result = 0;
        else if (event.field === 'projectStartDate'||event.field ==='targetDate') {
          // Convert the string dates to Date objects for correct sorting
          const date1 = value1 === '--' ? null : new Date(value1);
          const date2 = value2 === '--' ? null : new Date(value2);
    
          // Handle special case: treat "--" as greater or smaller than any date
          if (date1 === null) {
            result = date2 === null ? 0 : -1;
          } else if (date2 === null) {
            result = 1;
          } else {
            result = date1.getTime() - date2.getTime();
          }
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
    
        return (event.order * result);
      });
    
      event.data.forEach(e => {
        e.projectStartDate = this.datePipe.transform( e.projectStartDate, 'MM/dd/yyyy');
        e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
      });
    }
}