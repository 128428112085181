export class ProjectDesc {
    id: number;
    projectDescriptionId: string;
    projectDescriptionName: string;
    createdBy: string;
    createdDate: Date;
    modifiedBy: string;
    modifiedDate: Date;
    isActive: boolean;
    isError:boolean;
    errorMessage: string;
}