

  <div class="p-grid table-demo">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
          <h5 style="color:#495057; padding:15px 0 0 15px;">Reports</h5>
        </div>
        <div class="invoice invoice-header" id="invoice-content">
          <p-table #dt [value]="Lists" id="excel-table" dataKey="id" styleClass="p-datatable-striped" [rowHover]="true" [rows]="4"
            [paginator]="false" [filterDelay]="0">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="reportName">
                    Name
                  <p-sortIcon field="reportName"></p-sortIcon>
                </th>
                <th pSortableColumn="inputFileName">
                  Template
                  <p-sortIcon field="inputFileName"></p-sortIcon>
                </th>
                <th style="width:100px;">
                  Generate
                </th>
              </tr>
              <tr>
                <th>
                  <input pInputText type="text" (input)="
                  dt.filter($event.target.value, 'reportName', 'contains')" placeholder="Name" 
                  class="p-column-filter" style="width: 95%; height:25px; padding-left:15px;" />
                </th>
                <th>
                  <input pInputText type="text" (input)="dt.filter($event.target.value, 'inputFileName', 'contains')"
                    placeholder="Template" class="p-column-filter" style="width: 95%; height:25px; padding-left:15px;" />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-CustomReportsList>
              <tr class="p-selectable-row">
                <td style="padding-left:15px;">
                  <span class="data-style">{{ CustomReportsList.reportName }}</span>
                </td>
                <td style="padding-left:15px;">
                  <span class="data-style">{{CustomReportsList.inputFileName }}</span>
                </td>
                <td style="text-align:center;">
                  <span>
                    <a href="javascript:;" (click)="GetFile(CustomReportsList.reportName)">
                      <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Generate" tooltipPosition="left"
                      class="p-button-raised p-button-primary" style="height:25px; width:25px;">
                      </button>
                    </a>

                    <!-- <a href="{{downloadFile}}{{ CustomReportsList.reportName }}">
                      <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Generate" tooltipPosition="left"
                        class="p-button-raised p-button-primary" style="height:25px; width:25px;">
                      </button>
                    </a> -->
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="3">No reports found...</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>



<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>