<div class="p-grid table-demo">
  <div class="p-col-12">
    <div class="card" style="min-height: 600px">
      <div class=" p-col-12 p-md-2">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-chevron-left"
          iconPos="left"
          label="Back"
          class="p-button-raised p-button-primary p-mr-2 p-mb-2"
          style="width:50%"
         (click)="BackClick()"
        ></button>
      </div>
      <div
        class="card-header"
        style="background-color: #d5e8ef; border-radius: 5px; height: 40px"
      >
      
        <h5 style="color: #495057; padding: 15px 0 0 15px">{{reportName}}</h5>
        <span
          class="p-input-icon-left"
          style="float: right; margin-right: 10px; margin-top: 8px"
        >
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-print"
            pTooltip="Print"
            tooltipPosition="top"
            (click)="print()"
            class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float: right; width: 25px; height: 25px"
          ></button>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-download"
            pTooltip="Download"
            tooltipPosition="top"
            (click)="exportExcel()"
            class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float: right; height: 25px; width: 25px"
          ></button>
        </span>
      </div>
      <div style="min-width:970px; overflow-x: scroll;">
      <div class="invoice invoice-header" id="divReport" >
        <p-table #dt [value]="colsReportData" id="excel-table"  styleClass="p-datatable-striped">
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <ng-container *ngFor="let head of colsReportData[0] | keys">
                <th style="width: 150px;" *ngIf="head!='Project ID'">
                  {{ head }}
                 </th>
              </ng-container>
             
              <th style="text-align: center;width: 150px;">Notes</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-colsReportData>
            <tr>
              <ng-container *ngFor="let list of colsReportData | keys">
              <td style="text-align:left; padding-left:15px;" *ngIf="list!='Project ID'">
              {{ colsReportData[list] }} 
              </td>
            </ng-container>
              <td style="text-align: center" >
                <!-- {{colsReportData["ProjectID"]}} -->
                <span>
                  <button
                    pButton
                    type="button"
                    pTooltip="Add Notes"
                    tooltipPosition="right"
                    (click)="showReviewNotes(colsReportData)"
                    class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                    icon="pi pi-plus"
                    style="margin-top: 5px; height: 25px; width: 25px"
                  ></button>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    </div>
  </div>
</div>

<p-dialog
  [formGroup]="createReviewForm"
  header="Create Notes"
  [(visible)]="reviewNotesDisplay"
  modal="modal"
  showEffect="fade"
  [style]="{ width: '70%' }"
>
  <hr />
  <div class="p-grid" style="height: 500px">
    <!-- <div class="p-col-12 p-md-2"></div> -->
    <div class="p-col-12 ">
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
          <label for="ProjectName"
            >Project Name
          </label>
          <input
            type="text"
            pInputText
            placeholder="Project Name"
            [(ngModel)]="reviewProjectName"
            formControlName="reviewProjectNameControl"
          />
          <br />
        </div>
        <div class="p-col-12 p-md-6">
          <label for="startDate"
            >Review Date
          </label>
          <!-- <input
            type="text"
            pInputText
            placeholder="Review Date"
            [(ngModel)]="reviewDate"
            formControlName="reviewDateControl"
          /> -->
          <p-dropdown [options]="reviewDates" optionLabel="reviewDate" [(ngModel)]="reviewDate" autoWidth="false"
          styleClass="my-dropdown" (onChange)="SelectReview()" formControlName="reviewDateControl"></p-dropdown>
        </div>
        <div class="p-col-12" *ngIf="ShowOldNotes">
          <label for="description">Prev Notes</label>
          <textarea
            id="description"
            type="text"
            rows="5"
            [(ngModel)]="reviewNotesOld"
            formControlName="reviewNotesControlOld"
            pInputTextarea
            disabled
          ></textarea>
        </div>
        <div class="p-col-12">
          <label for="description">Today's Notes</label>
          <textarea
            id="description"
            type="text"
            rows="5"
            [(ngModel)]="reviewNotes"
            formControlName="reviewNotesControl"
            pInputTextarea
          ></textarea>
          <small class="p-invalid" *ngIf="isReviewNotesSubmitted && createReviewForm.controls.reviewNotesControl.hasError('required')">
            Review notes is required</small>
        </div>
      </div>
    </div>
    <!-- <div class="p-col-12 p-md-2"></div> -->
    <div class="p-col-12 p-md-12" style="vertical-align: center">
      <small class="p-invalid" style="float:right;">{{responseError}}</small>
    </div>
    <div class="p-col-12 p-md-8"></div>
    <div class="p-col-12 p-md-4">
      <span
        class="p-input-icon-left"
        style="float: right; margin-right: 10px; margin-top: 8px"
      >
        <button
          pButton
          type="button"
          label="Save"
          icon="pi pi-check"
          iconPos="left"
          class="p-button-raised p-button-success"
          style="margin-top: -5px; margin-bottom: -5px"
          (click)="saveReview()"
        ></button>
        <button
          pButton
          type="button"
          label="Cancel"
          icon="pi pi-refresh"
          iconPos="left"
          (click)="reviewNotesDisplay = false;isReviewNotesSubmitted = false"
          class="p-button-raised p-button-secondary"
          style="margin-top: -5px; margin-bottom: -5px; margin-left: 10px"
        ></button>
      </span>
    </div>
   
  </div>
</p-dialog>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>