import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';

@Injectable({
    providedIn: 'root'
})

export class CustomReportService {
    constructor(private httpService: HttputilityService) { }

    GetCustomReportPopupData() {
        return this.httpService.Get('PMAPI', 'Report/GetCustomReportPreData');
    }

    GetReportSchemaById(reportId) {
        return this.httpService.Get('PMAPI', 'Report/GetReportSchemaById/' + reportId);
    }

    CreateReportSchema(data) {
        return this.httpService.Add('PMAPI', `Report/CreateReportSchema`, 'httpOptions', data)
    }

    GetReportNames()
    {
        return this.httpService.Get('PMAPI', 'Projects/GetReportNames');
    }
    GenerateReport(filename){
    return this.httpService.downloadFile('Projects/GenerateReport?ReportName='+filename);
    }
    GetReports() {
        return this.httpService.Get('PMAPI', 'Report/GetReports');
    }

    GetReportByReportId(reportId: string) {
        return this.httpService.Get('PMAPI', 'Report/GetReportByReportId/' + reportId);
    }

    GetProjectReport(spaceId: string, managerId: string) {
        if (spaceId == "" && managerId == "") {
            return this.httpService.Get('PMAPI', 'Report/GetProjectReport/');
        }
        else {
            return this.httpService.Get('PMAPI', 'Report/GetProjectReport/' + spaceId + '/' + managerId);
        }
    }
    GetProjectsbyMilestone(data) {
        return this.httpService.Add('PMAPI', `Report/GetProjectsbyMilestone`, 'httpOptions', data)
    }
    ReportProducts(data) {
        return this.httpService.Add('PMAPI', `Report/GetProjects`, 'httpOptions', data)
    }
    GetP2ReviewProjects(data) {
        return this.httpService.Add('PMAPI', `Report/GetP2ReviewProjects`, 'httpOptions', data)
    }

    GetAllMilestones() {
        return this.httpService.Get('PMAPI', 'Report/GetAllMilestones');
    }
    GetProductLine()
    {
        return this.httpService.Get('ProdctAPI', 'Template/GetProductLine');
    }
    GetProductOwners(){
        return this.httpService.Get('ProdctAPI','Template/GetProductOwners');
    }
    GetProjectsByProductlines(data)
    {
        return this.httpService.Add('PMAPI', `Project/GetProjectsByProductlines`, 'httpOptions', data)
    }
    GetOverdueMilestone(data)
    {
        return this.httpService.Add('PMAPI', `Report/GetOverdueMilestone`, 'httpOptions', data)
    }
}