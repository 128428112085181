import { Component, OnInit } from '@angular/core';
import { MenuItem, SortEvent } from 'primeng/api';
import { Product } from '../domain/product';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { Customer, Representative } from '../domain/customer';
import { FacadeService } from 'src/app/facade/facade.service';
import { Inject } from '@angular/core';
import { BASE_API_URL_TOKEN } from "src/injector";
import * as XLSX from 'xlsx'; 
import { Router} from '@angular/router';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import { DatePipe } from '@angular/common';
@Component({
    selector: 'app-projectlist',
    templateUrl: './projectlist.component.html',
    styleUrls: ['./projectlist.component.scss']
})

export class ProjectlistComponent implements OnInit {
    cols: any[];
    projects: any[];
    projectsD: any[];
    projectsData:any;
    showLoading: boolean = false;
    Count : number = 0;
    userFeature:boolean=false;
    userId = sessionStorage.getItem("userGuid");
    ProductCountFilter: any="";
    selectedfilter ="startsWith";
    constructor( private breadcrumbService: BreadcrumbService,
        private facadeService: FacadeService,private printService:PrintcommonService,
        private router: Router,
        private datePipe: DatePipe,
        @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard', routerLink: ['/'] }
        ]);
    }

    ngOnInit() {
      const userFeatures = sessionStorage.getItem('userFeatures');
      if (userFeatures) {
        var feature = JSON.parse(userFeatures);
        if(feature.length>0){
          var featureExist = feature.find(m=>m.code=="PM_GP");
          if(featureExist!=null && featureExist != undefined)
            this.userFeature = true;
        }
      }
        sessionStorage.projectName = "";
        sessionStorage.phaseName = "";
        sessionStorage.projectStartDate = "";
        sessionStorage.targetDate = "";
        sessionStorage.projectManagerName = "";
        sessionStorage.projectStatusName = "";
        this.getProjects();       
        this.getProjectsN();
    }
    phasetree(PID)
    {
      sessionStorage.FromReport = true;
      sessionStorage.From = "projectlist";
      this.router.navigate(["/phasetree/"+PID]);
    }
    getProjects() {
        this.showLoading = true;
        this.facadeService.getProjects(this.userFeature, "","N").subscribe(
            (response) => {
                response.returnObject.forEach(e => {
                    if(e.projectStartDate != '' || e.projectStartDate != null){
                        // let projectStartDate = new Date(e.projectStartDate) 
                        // e.projectStartDate = projectStartDate.toLocaleDateString()
                        e.projectStartDate = this.datePipe.transform( e.projectStartDate, 'MM/dd/yyyy');
                    }
                    if(e.targetDate != '' || e.targetDate != null){
                        // let targetDate = new Date(e.targetDate) 
                        // e.targetDate = targetDate.toLocaleDateString()
                        e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
                    }
                });
                this.projects = response.returnObject;
                //this.CountM();
                
                this.showLoading = false;
            }
        )
    }
    getProjectsN() {
      this.showLoading = true;
      this.facadeService.getProjects(this.userFeature, "","Y").subscribe(
          (response) => {
              response.returnObject.forEach(e => {
                  if(e.projectStartDate != '' || e.projectStartDate != null){
                      // let projectStartDate = new Date(e.projectStartDate) 
                      // e.projectStartDate = projectStartDate.toLocaleDateString()
                      e.projectStartDate = this.datePipe.transform( e.projectStartDate, 'MM/dd/yyyy');
                  }
                  if(e.targetDate != '' || e.targetDate != null){
                      // let targetDate = new Date(e.targetDate) 
                      // e.targetDate = targetDate.toLocaleDateString()
                      e.targetDate = this.datePipe.transform( e.targetDate, 'MM/dd/yyyy');
                  }
              });
              this.projectsD = response.returnObject;
              this.CountM();
              
              this.showLoading = false;
          }
      )
  }
  CountM()
    {
      if(this.projectsD != undefined && this.projectsD != null)
      {
        var temp = this.projectsD;
        if(sessionStorage.projectName != "")
        temp = temp.filter(x=> x.projectName.toLowerCase().startsWith(sessionStorage.projectName.toLowerCase()));
        if(sessionStorage.phaseName != "")
        temp = temp.filter(x=> x.phaseName.toLowerCase().startsWith(sessionStorage.phaseName.toLowerCase()));
        if(sessionStorage.projectStartDate != "")
        temp = temp.filter(x=> x.projectStartDate.toLowerCase().startsWith(sessionStorage.projectStartDate.toLowerCase()));
        if(sessionStorage.targetDate != "")
        temp = temp.filter(x=> x.targetDate.toLowerCase().startsWith(sessionStorage.targetDate.toLowerCase()));
        if(sessionStorage.projectManagerName != "")
        temp = temp.filter(x=> x.projectManagerName.toLowerCase().startsWith(sessionStorage.projectManagerName.toLowerCase()));
        if(sessionStorage.projectStatusName != "")
        temp = temp.filter(x=> x.projectStatusName.toLowerCase().startsWith(sessionStorage.projectStatusName.toLowerCase()));
        this.Count = temp.length;
      }
      else
        this.Count = 0;
    }


test(val,field,dt)
{
  if(field == "projectName")
    sessionStorage.projectName = val;
  else if(field == "phaseName")
    sessionStorage.phaseName = val;
  else if(field == "projectStartDate")
    sessionStorage.projectStartDate = val;
  else if(field == "targetDate")
    sessionStorage.targetDate = val;
  else if(field == "projectManagerName")
    sessionStorage.projectManagerName = val;
  else if(field == "projectStatusName")
    sessionStorage.projectStatusName = val;
  if(this.selectedfilter=="startsWith")
    dt.filter(val, field, 'startsWith');
  else
    dt.filter(val, field, 'contains');      
  //this.ProductCountFilter = dt.count;
  //this.CountM();
}

onFilter(event, dt) {
  if (event.filteredValue.length == this.projects.length) {
    this.ProductCountFilter = "";
  }
  else if (event.filteredValue.length != this.projects.length && event.filteredValue.length==0) {
    this.ProductCountFilter = "";
  }
  else {
    this.ProductCountFilter = event.filteredValue.length+" projects found.";
  }
}


openproject(val)
{
  sessionStorage.FromReport = true;
  sessionStorage.From = "projectlist";
    this.router.navigate(["/phasetree/"+val]);
}

exportExcel() {
  //let element = document.getElementById('excel-table'); 
  var dte = [];
  this.projectsD.map(d => (
    {
      "Project Name": d.projectName,
      "Active Phase": d.phaseName,
      "Start Date": d.projectStartDate,
      "End Date": d.targetDate,
      "Product Engineer": d.projectManagerName,
      "Project Status": d.projectStatusName   
    }
  )).forEach((d: any) => {
    dte.push(d);
  });

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dte);
  / generate workbook and add the worksheet /
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  ws['!cols'] = [{ wpx: 180 }, { wpx: 100 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 130 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 120 }, { wpx: 120 }, { wpx: 120 }];
  //this.delete_row(wb.Sheets.Sheet1, 1);
  / save to file /
  XLSX.writeFile(wb, "Product Summary.xlsx");
}
  
      print() {
        var Content = document.getElementById('invoice-content').innerHTML;
        this.printService.Print(Content,'Projects Summary','trSearch',true,0);
    }
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;
    
        if (value1 == null && value2 != null)
          result = -1;
        else if (value1 != null && value2 == null)
          result = 1;
        else if (value1 == null && value2 == null)
          result = 0;
        else if (event.field === 'projectStartDate'||event.field === 'targetDate') {
          // Convert the string dates to Date objects for correct sorting
          const date1 = value1 === '--' ? null : new Date(value1);
          const date2 = value2 === '--' ? null : new Date(value2);
    
          // Handle special case: treat "--" as greater or smaller than any date
          if (date1 === null) {
            result = date2 === null ? 0 : -1;
          } else if (date2 === null) {
            result = 1;
          } else {
            result = date1.getTime() - date2.getTime();
          }
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        } else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
    
        return (event.order * result);
      });
    }
            
}
