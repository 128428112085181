import { Component, OnInit,ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Router } from "@angular/router";
import { Table } from 'primeng/table';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-p2summary',
  templateUrl: './p2summary.component.html',
  styleUrls: ['./p2summary.component.scss']
})
export class P2summaryComponent implements OnInit {
    userId = sessionStorage.getItem('userGuid');
    activeproducts: any[];
    selectedprojects:any;
    @ViewChild('dt') table: Table;
    @ViewChild('dtmeeting') tableMeeting: Table;
    @ViewChild('dtmilestone') tableMilestone: Table;
    @ViewChild('dtreview') tableReview: Table;
    cols:any[];
    cols2:any[];
    cols3:any[];
    cols4:any[];
    showLoading:boolean;
    remarksDisplay: boolean;
    meetingsDisplay: boolean;
    
    projectId: any;
    projectDetailsData: any;
    milestonesData: any;
    reviewData: any;
    meetingData: any;
    showdetails: boolean = false;
    Orgmnote: any;
    Orgrnote: any;
    mnote: any;
    rnote: any;
    viewmode:boolean=false;

    RemarksError:any;
    MeetingsError:any;
    currentWeekNumber:number = 0;
    isDisable:boolean = false;

    status: any[];
    selectedStatus:any[]=[];

    Phases: any[];
    selectedPhases:any[]=[];

    Attributes:any[];
    selectedAttributes:any[]=[];

    error:string = "";
    editStartDate:any;
    editEndDate:any;
    userFeature:boolean=false;
    constructor( 
      private _facadeService: FacadeService,
      private router: Router,public datepipe: DatePipe) { }
    ngOnInit(): void {
      const userFeatures = sessionStorage.getItem('userFeatures');
      if (userFeatures) {
        var feature = JSON.parse(userFeatures);
        if(feature.length>0){
          var featureExist = feature.find(m=>m.code=="PM_DSBL_Flag");
          if(featureExist!=null && featureExist != undefined)
            this.userFeature = true;
        }
      }
      this.showLoading = true;
      if(this.userFeature)
        this.isDisable=false;
      else
        this.isDisable = true;

      var today = (new Date()).getDay();
      if(today < 4)
        this.viewmode = false;
      else
        this.viewmode = true;

      this.GetCurrentWeek();
      this.GetAttributes();
      this.cols = [
        { field: 'projectName', header: 'Project Name', dynamicPlaceHolder: 'Search' },
        { field: 'dieCode', header: 'Die Code', dynamicPlaceHolder: 'Search' },
        { field: 'basePart', header: 'Base Part', dynamicPlaceHolder: 'Search' },
        { field: 'mfgPart', header: 'MFG Part', dynamicPlaceHolder: 'Search' },
        { field: 'packageName', header: 'Package Name', dynamicPlaceHolder: 'Search' },
        { field: 'subcon', header: 'Sub-Con', dynamicPlaceHolder: 'Search' },
        { field: 'projectOwner', header: 'Product Engineer', dynamicPlaceHolder: 'Search' },
        { field: 'te', header: 'Test Engineer', dynamicPlaceHolder: 'Search' },
        { field: 'de', header: 'Design Engineer', dynamicPlaceHolder: 'Search' },
        { field: 'ae', header: 'Application Engineer', dynamicPlaceHolder: 'Search' },
        { field: 'marketing', header: 'Marketing Lead', dynamicPlaceHolder: 'Search' },
        { field: 'currentPhase', header: 'Phase', dynamicPlaceHolder: 'Search' },
      ];
      this.cols2 = [
        { field: 'workWeek', header: 'Work Week', dynamicPlaceHolder: 'Search' },
        { field: 'reviewNotes', header: 'Remarks', dynamicPlaceHolder: 'Search' },
        { field: 'name', header: 'Added By', dynamicPlaceHolder: 'Search' },
        { field: 'reviewDate', header: 'Added Date', dynamicPlaceHolder: 'Search' }  
      ];
      this.cols3 = [
        { field: 'workWeek', header: 'Work Week', dynamicPlaceHolder: 'Search' },
        { field: 'meetingNotes', header: 'Meeting Notes', dynamicPlaceHolder: 'Search' },
        { field: 'name', header: 'Added By', dynamicPlaceHolder: 'Search' },
        { field: 'meetingDate', header: 'Added Date', dynamicPlaceHolder: 'Search' }  
      ];
      this.cols4 = [
        { field: 'TapeOut', header: 'Tape-out Date'},
        { field: 'Wafer_Start', header: 'Tape-out Pass Date'},
        { field: 'Wafer_Out', header: 'Fab/out'},
        { field: 'COB', header: 'COB'},
        { field: 'Assy_Out', header: 'Assembly Out'},
        { field: 'Untested_Sample', header: 'Untested Ship Out'},
        { field: 'FT_Pgm___Prod', header: 'FT Out'},
        { field: 'Tested_Sample', header: 'Tested Ship Out'},
        { field: 'ESD_LU', header: 'ESD/LU'},
        { field: 'ESD_Gun', header: 'Gun test'},
        { field: 'Complete_Burn_in', header: 'HTOL 500hrs'},
        { field: 'Pkg_Qual', header: 'Pkg Qual Test'},
        { field: 'Application_Check', header: 'Apps Road Test Qual'},
        { field: 'Release_to_Production', header: 'RTP'},
        { field: 'PCN', header: 'PCN'}
      ];
      this.status = [ 
        {name: 'New'},
        {name: 'In-Process'},
        {name: 'Completed'},
        {name: 'EOL'},
        {name: 'OBS'},
        {name: 'Waiting for Discontinuation'},
        {name: 'Seek Clarification for Discontinuation'},
        {name: 'Hold'},
        {name: 'Discontinued'},
      ];
      let ok = false;
      if(sessionStorage.getItem('reportp2status') != null && sessionStorage.getItem('reportp2status') != undefined)
      {
        var projectstats = [];
        projectstats = sessionStorage.getItem('reportp2status').split(',');
        projectstats.forEach((element) => {
          this.status.forEach(element1 => {
            if(element1.name == element)
              this.selectedStatus.push(element1);
          });
        });
        ok = true;
      }
      if(!ok)
      {
        this.status.forEach(element => {
          if(element.name == "New" || element.name == "In-Process" || element.name == "Waiting for Discontinuation" || element.name == "Seek Clarification for Discontinuation" || element.name == "Hold" )
            this.selectedStatus.push(element);
        });
        sessionStorage.reportp2status = this.selectedStatus;
      }
      this.editStartDate = new Date();
      this.editStartDate.setDate(this.editStartDate.getDate() -365);
      if(sessionStorage.getItem('p2StartDate') != null && sessionStorage.getItem('p2StartDate') != undefined)
      {
        if(sessionStorage.getItem('p2StartDate') != "")
        {
          this.editStartDate = this.datepipe.transform(sessionStorage.getItem('p2StartDate'), 'MM/dd/yyyy');
        }
      }
      this.editEndDate = new Date();
      if(sessionStorage.getItem('p2EndDate') != null && sessionStorage.getItem('p2EndDate') != undefined)
      {
        if(sessionStorage.getItem('p2EndDate') != "")
        {
          this.editEndDate = this.datepipe.transform(sessionStorage.getItem('p2EndDate'), 'MM/dd/yyyy');
        }
      }
      this.GetPhases();
    }
    GetCurrentWeek() {
        this._facadeService.GetCurrentWeek().subscribe((data) => {
        this.currentWeekNumber = data.returnObject;
      });
    }
    GetPhases() {
        this._facadeService.GetDistinctPhases().subscribe((data) => {
        this.Phases = data.returnObject;
        let ok = false;
        if(sessionStorage.getItem('p2phases') != null && sessionStorage.getItem('p2phases') != undefined && sessionStorage.getItem('p2phases').indexOf("object") <= -1)
        {
          var projectstats = [];
          projectstats = sessionStorage.getItem('p2phases').split(',');
          projectstats.forEach((element) => {
            this.Phases.forEach((ln) => {
              if(ln.id == element)
                this.selectedPhases.push(ln);
            });
          });
          ok = true;
        }
        if(!ok)
        {
          this.Phases.forEach(element => {
            if(element.name == "P2" || element.name == "P1")
              this.selectedPhases.push(element);
          });
          var lm=[];
          this.selectedPhases.forEach((element) => {
            lm.push(element.id);
          });
          sessionStorage.p2phases = lm;
        }
        this.getproducts();
      });
    }
    GetAttributes() {
        this._facadeService.GetAttributes().subscribe((data) => {
        this.Attributes = data.returnObject;
        if(sessionStorage.getItem('p2attributes') != null && sessionStorage.getItem('p2attributes') != undefined && sessionStorage.getItem('p2attributes').indexOf("object") <= -1)
        {
          var projectstats = [];
          projectstats = sessionStorage.getItem('p2attributes').split(',');
          projectstats.forEach((element) => {
            this.Attributes.forEach((ln) => {
              if(ln.name == element)
                this.selectedAttributes.push(ln);
            });
          });
        }
      });
    }

    getproducts() {
      this.showLoading=true;
      this.showdetails = false;
      this.error = "";
      if((this.editStartDate == null || this.editStartDate == undefined || this.editStartDate == "" ) &&
      (this.editEndDate != null && this.editEndDate != undefined && this.editEndDate != ""))
        this.error = "Please enter start date";
      else if((this.editStartDate != null && this.editStartDate != undefined && this.editStartDate != "" ) &&
      (this.editEndDate == null || this.editEndDate == undefined || this.editEndDate == ""))
        this.error = "Please enter end date";
      else if(new Date(this.editEndDate) <= new Date(this.editStartDate) )
        this.error = "Start Date should be less then End Date";
      else if(this.selectedAttributes != null && this.selectedAttributes !=undefined && this.selectedAttributes.length > 3)
        this.error = "Maximum 3 attributes selection is allowed";
      else
      {
        var att = [],atts=[];
        var count = 1;
        this.selectedAttributes.forEach((element) => {
          att.push({Seq:count,FieldName:element.name});
          atts.push(element.name);
          count ++;
        });
        if(this.selectedAttributes != null && this.selectedAttributes !=undefined && this.selectedAttributes.length > 0)
        {
          if(this.selectedAttributes.length  == 1)
          {
            this.cols = [
              { field: 'projectName', header: 'Project Name', dynamicPlaceHolder: 'Search' },
              { field: 'dieCode', header: 'Die Code', dynamicPlaceHolder: 'Search' },
              { field: 'basePart', header: 'Base Part', dynamicPlaceHolder: 'Search' },
              { field: 'mfgPart', header: 'MFG Part', dynamicPlaceHolder: 'Search' },
              { field: 'packageName', header: 'Package Name', dynamicPlaceHolder: 'Search' },
              { field: 'subcon', header: 'Sub-Con', dynamicPlaceHolder: 'Search' },
              { field: 'projectOwner', header: 'Product Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'te', header: 'Test Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'de', header: 'Design Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'ae', header: 'Application Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'marketing', header: 'Marketing Lead', dynamicPlaceHolder: 'Search' },
              { field: 'currentPhase', header: 'Phase', dynamicPlaceHolder: 'Search' },
              { field: 'attribute1', header: att[0].FieldName, dynamicPlaceHolder: 'Search' },
            ];
          }
          if(this.selectedAttributes.length  == 2)
          {
            this.cols = [
              { field: 'projectName', header: 'Project Name', dynamicPlaceHolder: 'Search' },
              { field: 'dieCode', header: 'Die Code', dynamicPlaceHolder: 'Search' },
              { field: 'basePart', header: 'Base Part', dynamicPlaceHolder: 'Search' },
              { field: 'mfgPart', header: 'MFG Part', dynamicPlaceHolder: 'Search' },
              { field: 'packageName', header: 'Package Name', dynamicPlaceHolder: 'Search' },
              { field: 'subcon', header: 'Sub-Con', dynamicPlaceHolder: 'Search' },
              { field: 'projectOwner', header: 'Product Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'te', header: 'Test Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'de', header: 'Design Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'ae', header: 'Application Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'marketing', header: 'Marketing Lead', dynamicPlaceHolder: 'Search' },
              { field: 'currentPhase', header: 'Phase', dynamicPlaceHolder: 'Search' },
              { field: 'attribute1', header: att[0].FieldName, dynamicPlaceHolder: 'Search' },
              { field: 'attribute2', header: att[1].FieldName, dynamicPlaceHolder: 'Search' },
            ];
          }
          if(this.selectedAttributes.length  == 3)
          {
            this.cols = [
              { field: 'projectName', header: 'Project Name', dynamicPlaceHolder: 'Search' },
              { field: 'dieCode', header: 'Die Code', dynamicPlaceHolder: 'Search' },
              { field: 'basePart', header: 'Base Part', dynamicPlaceHolder: 'Search' },
              { field: 'mfgPart', header: 'MFG Part', dynamicPlaceHolder: 'Search' },
              { field: 'packageName', header: 'Package Name', dynamicPlaceHolder: 'Search' },
              { field: 'subcon', header: 'Sub-Con', dynamicPlaceHolder: 'Search' },
              { field: 'projectOwner', header: 'Product Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'te', header: 'Test Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'de', header: 'Design Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'ae', header: 'Application Engineer', dynamicPlaceHolder: 'Search' },
              { field: 'marketing', header: 'Marketing Lead', dynamicPlaceHolder: 'Search' },
              { field: 'currentPhase', header: 'Phase', dynamicPlaceHolder: 'Search' },
              { field: 'attribute1', header: att[0].FieldName, dynamicPlaceHolder: 'Search' },
              { field: 'attribute2', header: att[1].FieldName, dynamicPlaceHolder: 'Search' },
              { field: 'attribute3', header: att[2].FieldName, dynamicPlaceHolder: 'Search' },
            ];
          }
        }
        else
        {
          this.cols = [
            { field: 'projectName', header: 'Project Name', dynamicPlaceHolder: 'Search' },
            { field: 'dieCode', header: 'Die Code', dynamicPlaceHolder: 'Search' },
            { field: 'basePart', header: 'Base Part', dynamicPlaceHolder: 'Search' },
            { field: 'mfgPart', header: 'MFG Part', dynamicPlaceHolder: 'Search' },
            { field: 'packageName', header: 'Package Name', dynamicPlaceHolder: 'Search' },
            { field: 'subcon', header: 'Sub-Con', dynamicPlaceHolder: 'Search' },
            { field: 'projectOwner', header: 'Product Engineer', dynamicPlaceHolder: 'Search' },
            { field: 'te', header: 'Test Engineer', dynamicPlaceHolder: 'Search' },
            { field: 'de', header: 'Design Engineer', dynamicPlaceHolder: 'Search' },
            { field: 'ae', header: 'Application Engineer', dynamicPlaceHolder: 'Search' },
            { field: 'marketing', header: 'Marketing Lead', dynamicPlaceHolder: 'Search' },
            { field: 'currentPhase', header: 'Phase', dynamicPlaceHolder: 'Search' },
          ];
        }

        var projectstats = [];
        this.selectedStatus.forEach((element) => {
          projectstats.push(element.name);
        });
        var phases1 = [];
        var lm=[];
        this.selectedPhases.forEach((element) => {
          phases1.push(element.name);
          lm.push(element.id);
        });
        var searchModel={
          'taskName':"",
          'periodType':"",
          'startDate':this.editStartDate == null || this.editStartDate == undefined || this.editStartDate == ""? new Date() : new Date(this.editStartDate),
          'endDate':this.editEndDate == null || this.editEndDate == undefined || this.editEndDate == ""? new Date() : new Date(this.editEndDate),
          'projectStatus':projectstats,
          'phases':phases1,
          'attributes':att
        }
        sessionStorage.p2phases = null;
        sessionStorage.reportp2status=null;
        sessionStorage.p2attributes=null;
        if(lm != undefined && lm != null && lm.length > 0)
          sessionStorage.p2phases = lm;
        sessionStorage.p2StartDate = this.editStartDate;
        sessionStorage.p2EndDate = this.editEndDate;
        if(projectstats != undefined && projectstats != null && projectstats.length > 0)
          sessionStorage.reportp2status = projectstats;
        if(atts != undefined && atts != null && atts.length > 0)
          sessionStorage.p2attributes = atts;
        this._facadeService.GetP2ReviewProjects(searchModel).subscribe(
          (data) => {
              this.activeproducts = data.returnObject;
              this.showLoading = false;
          },
          (error) => {
            console.log(error);
            this.showLoading = false;
          }
        );
      }
    }
    phasetree(PID)
    {
      sessionStorage.FromReport = true;
      sessionStorage.From = "p2summary";
      this.router.navigate(["/phasetree/"+PID]);
    }

    selectrow(productId,UserID) {
      this.showLoading = true;
      this.showdetails = true;
      this.RemarksError = "";
      this.MeetingsError = "";
      this.activeproducts.forEach(element => {
        if(element.projectId != productId)
          element.select = false;
        else
          element.select = true;
      });
      if(this.userFeature || UserID.toLocaleLowerCase() == this.userId.toLocaleLowerCase())
        this.isDisable=false;
      else
        this.isDisable = true;

        this._facadeService.GetP2ProjectByID(productId).subscribe(
        (data) => {
          this.projectDetailsData = data.returnObject;
          if(this.projectDetailsData != null &&  this.projectDetailsData != undefined) {
            this.milestonesData = JSON.parse(this.projectDetailsData.finalTasks);
            var allremarksdata = this.projectDetailsData.reviews;
            var allmeetingdata = this.projectDetailsData.meetings;

            if (this.projectDetailsData.project != undefined && this.projectDetailsData.project != null) {
              this.projectId = this.projectDetailsData.project.projectId;
            }
            console.log(this.milestonesData);
            if(this.milestonesData != null && this.milestonesData != undefined)
            {
              var task1, task2, task3, task4, task5, task6, task7, task8, task9, task10, task11, task12, task13, task14, task15;
              var task1S, task2S, task3S, task4S, task5S, task6S, task7S, task8S, task9S, task10S, task11S, task12S, task13S, task14S, task15S;
              for(let index = 0; index < this.milestonesData.length; index++)
              {
                this.milestonesData[index].bold1 = this.milestonesData[index].bold2 = this.milestonesData[index].bold3 = this.milestonesData[index].bold4 = this.milestonesData[index].bold5 = this.milestonesData[index].bold6 = this.milestonesData[index].bold7 = this.milestonesData[index].bold8 = this.milestonesData[index].bold9
                = this.milestonesData[index].bold10 = this.milestonesData[index].bold11 = this.milestonesData[index].bold12 = this.milestonesData[index].bold13 = this.milestonesData[index].bold14 = this.milestonesData[index].bold15 = false;
                this.milestonesData[index].color1 = this.milestonesData[index].color2 = this.milestonesData[index].color3 = this.milestonesData[index].color4 = this.milestonesData[index].color5 = this.milestonesData[index].color6 = this.milestonesData[index].color7 = this.milestonesData[index].color8 = this.milestonesData[index].color9
                = this.milestonesData[index].color10 = this.milestonesData[index].color11 = this.milestonesData[index].color12 = this.milestonesData[index].color13 = this.milestonesData[index].color14 = this.milestonesData[index].color15 = "";
                if(index == 0)
                {
                  task1S = this.milestonesData[index].TapeOut; task2S = this.milestonesData[index].Wafer_Start; task3S = this.milestonesData[index].Wafer_Out; task4S = this.milestonesData[index].COB; 
                  task5S = this.milestonesData[index].Assy_Out; task6S = this.milestonesData[index].Untested_Sample; task7S = this.milestonesData[index].FT_Pgm___Prod; task8S = this.milestonesData[index].Tested_Sample; 
                  task9S = this.milestonesData[index].ESD_LU; task10S = this.milestonesData[index].ESD_Gun; task11S = this.milestonesData[index].Complete_Burn_in; task12S = this.milestonesData[index].Pkg_Qual; 
                  task13S = this.milestonesData[index].Application_Check; task14S = this.milestonesData[index].Release_to_Production; task15S = this.milestonesData[index].PCN; 
                }               
                if(index == 1 || index == 2)
                {
                  if (this.milestonesData[index].TapeOut !== null && this.milestonesData[index].TapeOut !== undefined) {
                    this.milestonesData[index].TapeOut = new Date(this.milestonesData[index].TapeOutpe);
                    this.milestonesData[index].TapeOut = this.milestonesData[index].TapeOut.toLocaleDateString();
                  }
                  if (this.milestonesData[index].Wafer_Start !== null && this.milestonesData[index].Wafer_Start !== undefined) {
                    this.milestonesData[index].Wafer_Start = new Date(this.milestonesData[index].Wafer_Start);
                    this.milestonesData[index].Wafer_Start = this.milestonesData[index].Wafer_Start.toLocaleDateString();
                  }
                  if (this.milestonesData[index].Wafer_Out !== null && this.milestonesData[index].Wafer_Out !== undefined) {
                    this.milestonesData[index].Wafer_Out = new Date(this.milestonesData[index].Wafer_Out);
                    this.milestonesData[index].Wafer_Out = this.milestonesData[index].Wafer_Out.toLocaleDateString();
                  }
                  if (this.milestonesData[index].COB !== null && this.milestonesData[index].COB !== undefined) {
                    this.milestonesData[index].COB = new Date(this.milestonesData[index].COB);
                    this.milestonesData[index].COB = this.milestonesData[index].COB.toLocaleDateString();
                  }
                  if (this.milestonesData[index].Assy_Out !== null && this.milestonesData[index].Assy_Out !== undefined) {
                    this.milestonesData[index].Assy_Out = new Date(this.milestonesData[index].Assy_Out);
                    this.milestonesData[index].Assy_Out = this.milestonesData[index].Assy_Out.toLocaleDateString();
                  }
                  if (this.milestonesData[index].Untested_Sample !== null && this.milestonesData[index].Untested_Sample !== undefined) {
                    this.milestonesData[index].Untested_Sample = new Date(this.milestonesData[index].Untested_Sample);
                    this.milestonesData[index].Untested_Sample = this.milestonesData[index].Untested_Sample.toLocaleDateString();
                  }
                  if (this.milestonesData[index].FT_Pgm___Prod !== null && this.milestonesData[index].FT_Pgm___Prod !== undefined) {
                    this.milestonesData[index].FT_Pgm___Prod = new Date(this.milestonesData[index].FT_Pgm___Prod);
                    this.milestonesData[index].FT_Pgm___Prod = this.milestonesData[index].FT_Pgm___Prod.toLocaleDateString();
                  }
                  if (this.milestonesData[index].Tested_Sample !== null && this.milestonesData[index].Tested_Sample !== undefined) {
                    this.milestonesData[index].Tested_Sample = new Date(this.milestonesData[index].Tested_Sample);
                    this.milestonesData[index].Tested_Sample = this.milestonesData[index].Tested_Sample.toLocaleDateString();
                  }
                  if (this.milestonesData[index].ESD_LU !== null && this.milestonesData[index].ESD_LU !== undefined) {
                    this.milestonesData[index].ESD_LU = new Date(this.milestonesData[index].ESD_LU);
                    this.milestonesData[index].ESD_LU = this.milestonesData[index].ESD_LU.toLocaleDateString();
                  }
                  if (this.milestonesData[index].ESD_Gun !== null && this.milestonesData[index].ESD_Gun !== undefined) {
                    this.milestonesData[index].ESD_Gun = new Date(this.milestonesData[index].ESD_Gun);
                    this.milestonesData[index].ESD_Gun = this.milestonesData[index].ESD_Gun.toLocaleDateString();
                  }
                  if (this.milestonesData[index].Complete_Burn_in !== null && this.milestonesData[index].Complete_Burn_in !== undefined) {
                    this.milestonesData[index].Complete_Burn_in = new Date(this.milestonesData[index].Complete_Burn_in);
                    this.milestonesData[index].Complete_Burn_in = this.milestonesData[index].Complete_Burn_in.toLocaleDateString();
                  }
                  if (this.milestonesData[index].Pkg_Qual !== null && this.milestonesData[index].Pkg_Qual !== undefined) {
                    this.milestonesData[index].Pkg_Qual = new Date(this.milestonesData[index].Pkg_Qual);
                    this.milestonesData[index].Pkg_Qual = this.milestonesData[index].Pkg_Qual.toLocaleDateString();
                  }
                  if (this.milestonesData[index].Application_Check !== null && this.milestonesData[index].Application_Check !== undefined) {
                    this.milestonesData[index].Application_Check = new Date(this.milestonesData[index].Application_Check);
                    this.milestonesData[index].Application_Check = this.milestonesData[index].Application_Check.toLocaleDateString();
                  }
                  if (this.milestonesData[index].Release_to_Production !== null && this.milestonesData[index].Release_to_Production !== undefined) {
                    this.milestonesData[index].Release_to_Production = new Date(this.milestonesData[index].Release_to_Production);
                    this.milestonesData[index].Release_to_Production = this.milestonesData[index].Release_to_Production.toLocaleDateString();
                  }
                  if (this.milestonesData[index].PCN !== null && this.milestonesData[index].PCN !== undefined) {
                    this.milestonesData[index].PCN = new Date(this.milestonesData[index].PCN);
                    this.milestonesData[index].PCN = this.milestonesData[index].PCN.toLocaleDateString();
                  } 
                  if(index == 1)
                  {
                    task1 = this.milestonesData[index].TapeOut; task2 = this.milestonesData[index].Wafer_Start; task3 = this.milestonesData[index].Wafer_Out; task4 = this.milestonesData[index].COB; 
                    task5 = this.milestonesData[index].Assy_Out; task6 = this.milestonesData[index].Untested_Sample; task7 = this.milestonesData[index].FT_Pgm___Prod; task8 = this.milestonesData[index].Tested_Sample; 
                    task9 = this.milestonesData[index].ESD_LU; task10 = this.milestonesData[index].ESD_Gun; task11 = this.milestonesData[index].Complete_Burn_in; task12 = this.milestonesData[index].Pkg_Qual; 
                    task13 = this.milestonesData[index].Application_Check; task14 = this.milestonesData[index].Release_to_Production; task15 = this.milestonesData[index].PCN; 
                  }

                  if(index == 2)
                  {
                    if(task1 != null && task1 != '01/01/1970' && this.milestonesData[index].TapeOut != null && this.milestonesData[index].TapeOut != '01/01/1970' && (task1S == 'New' || task1S == 'InProgress'))
                    {
                      if(Date.parse(task1)){  if (new Date(task1) < new Date())  this.milestonesData[index].bold1 = true;  }
                      else{  if (task1 < new Date().toLocaleDateString())  this.milestonesData[index].bold1 = true;  }
                      if(Date.parse(this.milestonesData[index].TapeOut)) {  if (new Date(this.milestonesData[index].TapeOut) < new Date())  this.milestonesData[index].bold1 = true;  }
                      else {  if (this.milestonesData[index].TapeOut < new Date().toLocaleDateString())  this.milestonesData[index].bold1 = true;  }
                    }
                    if(task2 != null && task2 != '01/01/1970' && this.milestonesData[index].Wafer_Start != null && this.milestonesData[index].Wafer_Start != '01/01/1970' && (task2S == 'New' || task2S == 'InProgress'))
                    {
                      if(Date.parse(task2)){  if (new Date(task2) < new Date())  this.milestonesData[index].bold2 = true;  }
                      else{  if (task2 < new Date().toLocaleDateString())  this.milestonesData[index].bold2 = true;  }
                      if(Date.parse(this.milestonesData[index].Wafer_Start)) {  if (new Date(this.milestonesData[index].Wafer_Start) < new Date())  this.milestonesData[index].bold2 = true;  }
                      else {  if (this.milestonesData[index].Wafer_Start < new Date().toLocaleDateString())  this.milestonesData[index].bold2 = true;  }
                    }
                    if(task3 != null && task3 != '01/01/1970' && this.milestonesData[index].Wafer_Out != null && this.milestonesData[index].Wafer_Out != '01/01/1970' && (task3S == 'New' || task3S == 'InProgress'))
                    {
                      if(Date.parse(task3)){  if (new Date(task3) < new Date())  this.milestonesData[index].bold3 = true;  }
                      else{  if (task3 < new Date().toLocaleDateString())  this.milestonesData[index].bold3 = true;  }
                      if(Date.parse(this.milestonesData[index].Wafer_Out)) {  if (new Date(this.milestonesData[index].Wafer_Out) < new Date())  this.milestonesData[index].bold3 = true;  }
                      else {  if (this.milestonesData[index].Wafer_Out < new Date().toLocaleDateString())  this.milestonesData[index].bold3 = true;  }
                    }
                    if(task4 != null && task4 != '01/01/1970' && this.milestonesData[index].COB != null && this.milestonesData[index].COB != '01/01/1970' && (task4S == 'New' || task4S == 'InProgress'))
                    {
                      if(Date.parse(task4)){  if (new Date(task4) < new Date())  this.milestonesData[index].bold4 = true;  }
                      else{  if (task4 < new Date().toLocaleDateString())  this.milestonesData[index].bold4 = true;  }
                      if(Date.parse(this.milestonesData[index].COB)) {  if (new Date(this.milestonesData[index].COB) < new Date())  this.milestonesData[index].bold4 = true;  }
                      else {  if (this.milestonesData[index].COB < new Date().toLocaleDateString())  this.milestonesData[index].bold4 = true;  }
                    }
                    if(task5 != null && task5 != '01/01/1970' && this.milestonesData[index].Assy_Out != null && this.milestonesData[index].Assy_Out != '01/01/1970' && (task5S == 'New' || task5S == 'InProgress'))
                    {
                      if(Date.parse(task5)){  if (new Date(task5) < new Date())  this.milestonesData[index].bold5 = true;  }
                      else{  if (task5 < new Date().toLocaleDateString())  this.milestonesData[index].bold5 = true;  }
                      if(Date.parse(this.milestonesData[index].Assy_Out)) {  if (new Date(this.milestonesData[index].Assy_Out) < new Date())  this.milestonesData[index].bold5 = true;  }
                      else {  if (this.milestonesData[index].Assy_Out < new Date().toLocaleDateString())  this.milestonesData[index].bold5 = true;  }
                    }
                    if(task6 != null && task6 != '01/01/1970' && this.milestonesData[index].Untested_Sample != null && this.milestonesData[index].Untested_Sample != '01/01/1970' && (task6S == 'New' || task6S == 'InProgress'))
                    {
                      if(Date.parse(task6)){  if (new Date(task6) < new Date())  this.milestonesData[index].bold6 = true;  }
                      else{  if (task6 < new Date().toLocaleDateString())  this.milestonesData[index].bold6 = true;  }
                      if(Date.parse(this.milestonesData[index].Untested_Sample)) {  if (new Date(this.milestonesData[index].Untested_Sample) < new Date())  this.milestonesData[index].bold6 = true;  }
                      else {  if (this.milestonesData[index].Untested_Sample < new Date().toLocaleDateString())  this.milestonesData[index].bold6 = true;  }
                    }
                    if(task7 != null && task7 != '01/01/1970' && this.milestonesData[index].FT_Pgm___Prod != null && this.milestonesData[index].FT_Pgm___Prod != '01/01/1970' && (task7S == 'New' || task7S == 'InProgress'))
                    {
                      if(Date.parse(task7)){  if (new Date(task7) < new Date())  this.milestonesData[index].bold7 = true;  }
                      else{  if (task7 < new Date().toLocaleDateString())  this.milestonesData[index].bold7 = true;  }
                      if(Date.parse(this.milestonesData[index].FT_Pgm___Prod)) {  if (new Date(this.milestonesData[index].FT_Pgm___Prod) < new Date())  this.milestonesData[index].bold7 = true;  }
                      else {  if (this.milestonesData[index].FT_Pgm___Prod < new Date().toLocaleDateString())  this.milestonesData[index].bold7 = true;  }
                    }
                    if(task8 != null && task8 != '01/01/1970' && this.milestonesData[index].Tested_Sample != null && this.milestonesData[index].Tested_Sample != '01/01/1970' && (task8S == 'New' || task8S == 'InProgress'))
                    {
                      if(Date.parse(task8)){  if (new Date(task8) < new Date())  this.milestonesData[index].bold8 = true;  }
                      else{  if (task8 < new Date().toLocaleDateString())  this.milestonesData[index].bold8 = true;  }
                      if(Date.parse(this.milestonesData[index].Tested_Sample)) {  if (new Date(this.milestonesData[index].Tested_Sample) < new Date())  this.milestonesData[index].bold8 = true;  }
                      else {  if (this.milestonesData[index].Tested_Sample < new Date().toLocaleDateString())  this.milestonesData[index].bold8 = true;  }
                    }
                    if(task9 != null && task9 != '01/01/1970' && this.milestonesData[index].ESD_LU != null && this.milestonesData[index].ESD_LU != '01/01/1970' && (task9S == 'New' || task9S == 'InProgress'))
                    {
                      if(Date.parse(task9)){  if (new Date(task9) < new Date())  this.milestonesData[index].bold9 = true;  }
                      else{  if (task9 < new Date().toLocaleDateString())  this.milestonesData[index].bold9 = true;  }
                      if(Date.parse(this.milestonesData[index].ESD_LU)) {  if (new Date(this.milestonesData[index].ESD_LU) < new Date())  this.milestonesData[index].bold9 = true;  }
                      else {  if (this.milestonesData[index].ESD_LU < new Date().toLocaleDateString())  this.milestonesData[index].bold9 = true;  }
                    }
                    if(task10 != null && task10 != '01/01/1970' && this.milestonesData[index].ESD_Gun != null && this.milestonesData[index].ESD_Gun != '01/01/1970' && (task10S == 'New' || task10S == 'InProgress'))
                    {
                      if(Date.parse(task10)){  if (new Date(task10) < new Date())  this.milestonesData[index].bold10 = true;  }
                      else{  if (task10 < new Date().toLocaleDateString())  this.milestonesData[index].bold10 = true;  }
                      if(Date.parse(this.milestonesData[index].ESD_Gun)) {  if (new Date(this.milestonesData[index].ESD_Gun) < new Date())  this.milestonesData[index].bold10 = true;  }
                      else {  if (this.milestonesData[index].ESD_Gun < new Date().toLocaleDateString())  this.milestonesData[index].bold10 = true;  }
                    }
                    if(task11 != null && task11 != '01/01/1970' && this.milestonesData[index].Complete_Burn_in != null && this.milestonesData[index].Complete_Burn_in != '01/01/1970' && (task11S == 'New' || task11S == 'InProgress'))
                    {
                      if(Date.parse(task11)){  if (new Date(task11) < new Date())  this.milestonesData[index].bold11 = true;  }
                      else{  if (task11 < new Date().toLocaleDateString())  this.milestonesData[index].bold11 = true;  }
                      if(Date.parse(this.milestonesData[index].Complete_Burn_in)) {  if (new Date(this.milestonesData[index].Complete_Burn_in) < new Date())  this.milestonesData[index].bold11 = true;  }
                      else {  if (this.milestonesData[index].Complete_Burn_in < new Date().toLocaleDateString())  this.milestonesData[index].bold11 = true;  }
                    }
                    if(task12 != null && task12 != '01/01/1970' && this.milestonesData[index].Pkg_Qual != null && this.milestonesData[index].Pkg_Qual != '01/01/1970' && (task12S == 'New' || task12S == 'InProgress'))
                    {
                      if(Date.parse(task12)){  if (new Date(task12) < new Date())  this.milestonesData[index].bold12 = true;  }
                      else{  if (task12 < new Date().toLocaleDateString())  this.milestonesData[index].bold12 = true;  }
                      if(Date.parse(this.milestonesData[index].Pkg_Qual)) {  if (new Date(this.milestonesData[index].Pkg_Qual) < new Date())  this.milestonesData[index].bold12 = true;  }
                      else {  if (this.milestonesData[index].Pkg_Qual < new Date().toLocaleDateString())  this.milestonesData[index].bold12 = true;  }
                    }
                    if(task13 != null && task13 != '01/01/1970' && this.milestonesData[index].Application_Check != null && this.milestonesData[index].Application_Check != '01/01/1970' && (task13S == 'New' || task13S == 'InProgress'))
                    {
                      if(Date.parse(task13)){  if (new Date(task13) < new Date())  this.milestonesData[index].bold13 = true;  }
                      else{  if (task13 < new Date().toLocaleDateString())  this.milestonesData[index].bold13 = true;  }
                      if(Date.parse(this.milestonesData[index].Application_Check)) {  if (new Date(this.milestonesData[index].Application_Check) < new Date())  this.milestonesData[index].bold13 = true;  }
                      else {  if (this.milestonesData[index].Application_Check < new Date().toLocaleDateString())  this.milestonesData[index].bold13 = true;  }
                    }
                    if(task14 != null && task14 != '01/01/1970' && this.milestonesData[index].Release_to_Production != null && this.milestonesData[index].Release_to_Production != '01/01/1970' && (task14S == 'New' || task14S == 'InProgress'))
                    {
                      if(Date.parse(task14)){  if (new Date(task14) < new Date())  this.milestonesData[index].bold14 = true;  }
                      else{  if (task14 < new Date().toLocaleDateString())  this.milestonesData[index].bold14 = true;  }
                      if(Date.parse(this.milestonesData[index].Release_to_Production)) {  if (new Date(this.milestonesData[index].Release_to_Production) < new Date())  this.milestonesData[index].bold14 = true;  }
                      else {  if (this.milestonesData[index].Release_to_Production < new Date().toLocaleDateString())  this.milestonesData[index].bold14 = true;  }
                    }
                    if(task15 != null && task15 != '01/01/1970' && this.milestonesData[index].PCN != null && this.milestonesData[index].PCN != '01/01/1970' && (task15S == 'New' || task15S == 'InProgress'))
                    {
                      if(Date.parse(task15)){  if (new Date(task15) < new Date())  this.milestonesData[index].bold15 = true;  }
                      else{  if (task15 < new Date().toLocaleDateString())  this.milestonesData[index].bold15 = true;  }
                      if(Date.parse(this.milestonesData[index].PCN)) {  if (new Date(this.milestonesData[index].PCN) < new Date())  this.milestonesData[index].bold15 = true;  }
                      else {  if (this.milestonesData[index].PCN < new Date().toLocaleDateString())  this.milestonesData[index].bold15 = true;  }
                    }
                  }
                }
                if(index == 3)
                {
                  if (this.milestonesData[index].TapeOut !== null && this.milestonesData[index].TapeOut !== undefined) {
                    var val = Number(this.milestonesData[index].TapeOut);
                    if(val > 0)
                      this.milestonesData[index].color1 = "red";
                    if(val < 0)
                      this.milestonesData[index].color1 = "green";
                  }
                  if (this.milestonesData[index].Wafer_Start !== null && this.milestonesData[index].Wafer_Start !== undefined) {
                    var val = Number(this.milestonesData[index].Wafer_Start);
                    if(val > 0)
                      this.milestonesData[index].color2 = "red";
                    if(val < 0)
                      this.milestonesData[index].color2 = "green";
                  }
                  if (this.milestonesData[index].Wafer_Out !== null && this.milestonesData[index].Wafer_Out !== undefined) {
                    var val = Number(this.milestonesData[index].Wafer_Out);
                    if(val > 0)
                      this.milestonesData[index].color3 = "red";
                    if(val < 0)
                      this.milestonesData[index].color3 = "green";
                  }
                  if (this.milestonesData[index].COB !== null && this.milestonesData[index].COB !== undefined) {
                    var val = Number(this.milestonesData[index].COB);
                    if(val > 0)
                      this.milestonesData[index].color4 = "red";
                    if(val < 0)
                      this.milestonesData[index].color4 = "green";
                  }
                  if (this.milestonesData[index].Assy_Out !== null && this.milestonesData[index].Assy_Out !== undefined) {
                    var val = Number(this.milestonesData[index].Assy_Out);
                    if(val > 0)
                      this.milestonesData[index].color5 = "red";
                    if(val < 0)
                      this.milestonesData[index].color5 = "green";
                  }
                  if (this.milestonesData[index].Untested_Sample !== null && this.milestonesData[index].Untested_Sample !== undefined) {
                    var val = Number(this.milestonesData[index].Untested_Sample);
                    if(val > 0)
                      this.milestonesData[index].color6 = "red";
                    if(val < 0)
                      this.milestonesData[index].color6 = "green";
                  }
                  if (this.milestonesData[index].FT_Pgm___Prod !== null && this.milestonesData[index].FT_Pgm___Prod !== undefined) {
                    var val = Number(this.milestonesData[index].FT_Pgm___Prod);
                    if(val > 0)
                      this.milestonesData[index].color7 = "red";
                    if(val < 0)
                      this.milestonesData[index].color7 = "green";
                  }
                  if (this.milestonesData[index].Tested_Sample !== null && this.milestonesData[index].Tested_Sample !== undefined) {
                    var val = Number(this.milestonesData[index].Tested_Sample);
                    if(val > 0)
                      this.milestonesData[index].color8 = "red";
                    if(val < 0)
                      this.milestonesData[index].color8 = "green";
                  }
                  if (this.milestonesData[index].ESD_LU !== null && this.milestonesData[index].ESD_LU !== undefined) {
                    var val = Number(this.milestonesData[index].ESD_LU);
                    if(val > 0)
                      this.milestonesData[index].color9 = "red";
                    if(val < 0)
                      this.milestonesData[index].color9 = "green";
                  }
                  if (this.milestonesData[index].ESD_Gun !== null && this.milestonesData[index].ESD_Gun !== undefined) {
                    var val = Number(this.milestonesData[index].ESD_Gun);
                    if(val > 0)
                      this.milestonesData[index].color10 = "red";
                    if(val < 0)
                      this.milestonesData[index].color10 = "green";
                  }
                  if (this.milestonesData[index].Complete_Burn_in !== null && this.milestonesData[index].Complete_Burn_in !== undefined) {
                    var val = Number(this.milestonesData[index].Complete_Burn_in);
                    if(val > 0)
                      this.milestonesData[index].color11 = "red";
                    if(val < 0)
                      this.milestonesData[index].color11 = "green";
                  }
                  if (this.milestonesData[index].Pkg_Qual !== null && this.milestonesData[index].Pkg_Qual !== undefined) {
                    var val = Number(this.milestonesData[index].Pkg_Qual);
                    if(val > 0)
                      this.milestonesData[index].color12 = "red";
                    if(val < 0)
                      this.milestonesData[index].color12 = "green";
                  }
                  if (this.milestonesData[index].Application_Check !== null && this.milestonesData[index].Application_Check !== undefined) {
                    var val = Number(this.milestonesData[index].Application_Check);
                    if(val > 0)
                      this.milestonesData[index].color13 = "red";
                    if(val < 0)
                      this.milestonesData[index].color13 = "green";
                  }
                  if (this.milestonesData[index].Release_to_Production !== null && this.milestonesData[index].Release_to_Production !== undefined) {
                    var val = Number(this.milestonesData[index].Release_to_Production);
                    if(val > 0)
                      this.milestonesData[index].color14 = "red";
                    if(val < 0)
                      this.milestonesData[index].color14 = "green";
                  }
                  if (this.milestonesData[index].PCN !== null && this.milestonesData[index].PCN !== undefined) {
                    var val = Number(this.milestonesData[index].PCN);
                    if(val > 0)
                      this.milestonesData[index].color14 = "red";
                    if(val < 0)
                      this.milestonesData[index].color14 = "green";
                  }
                }
              };
            }
            this.rnote = "";
            this.reviewData = [];
            if(allremarksdata != undefined && allremarksdata != null)
            {           
              allremarksdata.forEach(element => {
                if(element.workWeek == this.currentWeekNumber) {
                  this.Orgrnote = element.reviewNotes;
                  this.rnote = element.reviewNotes;
                }
              });
              this.reviewData = allremarksdata.filter(x=>x.workWeek != this.currentWeekNumber);
              if(this.reviewData != undefined && this.reviewData != null)
              {
                this.reviewData.forEach(element => {
                  element.workWeek = "WW" +  element.workWeek;
                  if (element.reviewDate !== null && element.reviewDate !== undefined) {
                    element.reviewDate = new Date(element.reviewDate);
                    element.reviewDate = element.reviewDate.toLocaleDateString();
                  }
                });
              }
            }
            this.mnote = "";
            this.meetingData = [];
           if(allmeetingdata != undefined && allmeetingdata != null)
           {
              allmeetingdata.forEach(element => {
                if(element.workWeek == this.currentWeekNumber) {
                  this.Orgmnote = element.meetingNotes;
                  this.mnote = element.meetingNotes;
                }
              });
              this.meetingData = allmeetingdata.filter(x=>x.workWeek != this.currentWeekNumber);
              if(this.meetingData != undefined && this.meetingData != null)
              {
                this.meetingData.forEach(element => {
                  element.workWeek = "WW" +  element.workWeek;
                  if (element.meetingDate !== null && element.meetingDate !== undefined) {
                    element.meetingDate = new Date(element.meetingDate);
                    element.meetingDate = element.meetingDate.toLocaleDateString();
                  }
                });
              }
            }
          }
          this.showLoading = false;
        },
        (error) => {
          console.log(error);
          this.showLoading = false;
        }
        );
    }

    SaveReview(){
        this.showLoading = true;
        this.RemarksError = "";
        if(this.rnote != undefined && this.rnote != null && this.rnote != "")
        {
          if(this.Orgrnote ==  this.rnote)
            this.RemarksError = "Remarks are same, Please update remarks to save";
          else
          {
            if(this.projectId != undefined && this.projectId != null && this.projectId != ""
            && this.currentWeekNumber != undefined && this.currentWeekNumber != null && this.currentWeekNumber != 0)
            {
              var model = {
                "ProjectID":this.projectId,
                "WeekNo":this.currentWeekNumber,
                "Remarks":this.rnote,
                "AddedBy":this.userId
              }
              this._facadeService
              .SaveRemarks(model)
              .subscribe((data) => {
                if(data.returnCode == 0)
                {
                  //this.getProjectById(this.ProductID);
                  this.RemarksError = "Saved successfully";
                }
                else
                  this.RemarksError = data.returnObject;
              });
            }
            else
              this.RemarksError = "Some error ocuured, Please refresh the page again";
          }
        }
        else
          this.RemarksError = "Please enter remarks";
        this.showLoading = false;
    }

    SaveMeeting(){
      this.showLoading = true;
      this.MeetingsError = "";
      if(this.mnote != undefined && this.mnote != null && this.mnote != "")
      {
        if(this.Orgmnote ==  this.mnote)
          this.MeetingsError = "Meeting Notes are same, Please update note to save";
        else
        {
          if(this.projectId != undefined && this.projectId != null && this.projectId != ""
          && this.currentWeekNumber != undefined && this.currentWeekNumber != null && this.currentWeekNumber != 0)
          {
            var model = {
              "ProjectID":this.projectId,
              "WeekNo":this.currentWeekNumber,
              "Remarks":this.mnote,
              "AddedBy":this.userId
            }
            this._facadeService
            .SaveMeeting(model)
            .subscribe((data) => {
              if(data.returnCode == 0)
              {
                //this.getProjectById(this.ProductID);
                this.MeetingsError = "Saved successfully";
              }
              else
                this.MeetingsError = data.returnObject;
            });
          }
          else
            this.MeetingsError = "Some error ocuured, Please refresh the page again";
        }
      }
      else
        this.MeetingsError = "Please enter meeting notes";
      this.showLoading = false;
  }

    ShowRemarks() {
      this.remarksDisplay = true;
    }

    ShowMeetings() {
      this.meetingsDisplay = true;
    }
  
  }
