import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FacadeService } from 'src/app/facade/facade.service';
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-cycletime',
  templateUrl: './cycletime.component.html',
  styleUrls: ['./cycletime.component.scss']
})
export class CycletimeComponent implements OnInit {

  showLoading:boolean;
  userGuid = sessionStorage.getItem('userGuid');
  projects:any=[];
  selectedProject:any;
  projectInformation: any;
  responseError:any;
  tasksListsByProject: any[];
  Overlapinfo:boolean = false;
  selectedtasksListsByProject: any[];
  projectdescriptiondisplay: boolean = false;
  productselected:boolean = false;
  userFeature:boolean=false;
  constructor(private facadeService: FacadeService,private router: Router, private datePipe: DatePipe,) { }
  filteredProdType: any[];

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PM_DSBL_UB");
        if(featureExist!=null && featureExist != undefined)
          this.userFeature = true;
      }
    }
    this.GetProjects();
  }

  GetProjects(){
    this.showLoading = true;
    this.facadeService.GetActiveProjects().subscribe(
        (res) => {
            this.showLoading = false;
            this.projects = res.returnObject;  
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        });
}
SelectProject(){
  this.GetProjectByID();
  
}
GetProjectByID(){
  this.showLoading = true;
  this.facadeService.getProjectDetailsById(this.selectedProject.projectId).subscribe(
      (success) => {
          if (success.returnCode == 0) {
            this.productselected = true;
              if(success.returnObject.projectStartDate != '' || success.returnObject.projectStartDate != null){
                  success.returnObject.projectStartDate = new Date(success.returnObject.projectStartDate).toLocaleDateString()
              }
              if(success.returnObject.targetDate != '' || success.returnObject.targetDate != null){
                  success.returnObject.targetDate = new Date(success.returnObject.targetDate).toLocaleDateString()
              }
              this.projectInformation = success.returnObject;
              this.getTaskListByproject();
          }
          else{
              this.responseError = "Unable to retrieve Project Information. We are working to get it.";
              this.productselected = false;
          }
      });
}
filterproductListChange(event) {
  let filtered : any[] = [];
  let query = event.query;
  for(let i = 0; i < this.projects.length; i++) {
      let product= this.projects[i];
      if (product.projectName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(product);
      }
  }    
  this.filteredProdType = filtered;
}
getTaskListByproject() {
  this.showLoading = true;
  this.facadeService.getTaskListByProject(this.selectedProject.projectId, this.selectedProject.projectId, "Project").subscribe(
      (sucess) => {
          this.showLoading = false;
          if (sucess.returnObject) {
              this.tasksListsByProject = sucess.returnObject.taskList;
              this.tasksListsByProject = this.tasksListsByProject.filter(x => x.status == 'New');;
              this.tasksListsByProject.forEach(element => {
                  if(element.plannedEndDate != null && element.plannedEndDate != '01/01/1970')
                  {
                      if (new Date(element.plannedEndDate) < new Date() && (element.status == 'New' || element.status == 'InProgress'))
                          element.bold = true;
                      else 
                          element.bold = false;
                  }
                  else 
                      element.bold = false;
              });
          }
      }
  )
}

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

}


CycleTimeChange(id,event){
  var originalPhaseTeam=this.tasksListsByProject.find(m=>m.taskId==id);
  if(originalPhaseTeam!=null)
  {
    var cycle= event.target.value;
    if(cycle!=null && cycle!=""&&cycle!=undefined)
      originalPhaseTeam.duration=parseInt(cycle);
    else
      originalPhaseTeam.duration=0;
  }
  this.Getdates(false);
}

IsWeekedRequired(id,event){
  const checked = event.target.ariaChecked;
  var originalPhaseTeam=this.tasksListsByProject.find(m=>m.taskId==id);
  if(originalPhaseTeam!=null)
  {
    if (checked)
      originalPhaseTeam.isWeekend=true;
    else
      originalPhaseTeam.isWeekend=false;
  }
  this.Getdates(false);
}

Getdates(update)
{
  this.showLoading = true;
  let phaseTasks=[];
  if(this.tasksListsByProject!=null && this.tasksListsByProject.length>0){
    this.tasksListsByProject.forEach(element => {
      var phaseTaks={
        "TaskID":element.taskId,
        "CycleTime":element.duration,
        "IsWeekend":element.isWeekend
      }
      phaseTasks.push(phaseTaks);
    });
  }
  this.facadeService.GetDates(this.selectedProject.projectId,phaseTasks,update,this.userGuid).subscribe(
    (data) => {
      if(data.returnCode==0){
        this.showLoading = false;
        this.tasksListsByProject = data.returnObject.taskList;
        this.tasksListsByProject = this.tasksListsByProject.filter(x => x.status == 'New');;
        this.tasksListsByProject.forEach(element => {
            if(element.plannedEndDate != null && element.plannedEndDate != '01/01/1970')
            {
                if (new Date(element.plannedEndDate) < new Date() && (element.status == 'New' || element.status == 'InProgress'))
                    element.bold = true;
                else 
                    element.bold = false;

                    element.plannedStartDate = this.datePipe.transform( element.plannedStartDate, 'MM/dd/yyyy');
                    element.plannedEndDate = this.datePipe.transform( element.plannedEndDate, 'MM/dd/yyyy');
                    element.startDate = this.datePipe.transform( element.startDate, 'MM/dd/yyyy');
                    element.dueDate = this.datePipe.transform( element.dueDate, 'MM/dd/yyyy');
            }
            else 
                element.bold = false;

        });
        if(update)
          this.responseError = "Saved successfully";     
      }
      else
      {
        this.showLoading = false;
        this.responseError = data.returnObject.taskList[0].taskName;
      }
    },
    (error) => {
      this.showLoading = false;
      this.responseError = error;
      console.log(error);
    } 
  );
}
open(val,val1)
{
  this.router.navigate(['/customfields',{ projectId: val, pid: val1 }]);     
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'plannedStartDate'||event.field === 'plannedEndDate'
    ||event.field === 'startDate'||event.field === 'dueDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });
}
}
