<p-button class="p-mr-2 p-mb-2"  (click)="openFullscreen()" >
  View in full screen</p-button>
  <br/><br/>
<main>
  <article #fs fullscreen-able [class.is-active]="isActive">
      <!-- <button (click)="closeFullscreen()" *ngIf="isFullscreen">Exit</button> -->
      <div class="p-grid table-demo">
        <div class="p-col-12">
          <div class="card" style="min-height:600px;">
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
              <h5 style="color:#495057; padding:15px 0 0 15px;">Reviews</h5>
              <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
               
                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                  (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                  style="float:right; width:25px; height:25px;"></button>
                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                  (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                  style="float:right;height:25px; width:25px; "></button>
              </span>
            </div>
            <div style="min-width:970px; overflow-x: scroll;">
            <div class="invoice invoice-header" id="invoice-content">
              <p-table #dt [value]="reportsData" id="excel-table" dataKey="id" styleClass="p-datatable-striped" [rowHover]="true" [rows]="4"
                [paginator]="false" [filterDelay]="0" [responsive]="true" >
                <ng-template pTemplate="header">
                  <tr>
                    <th> Project Name </th>
                    <th> Die Code </th>
                    <th> Tape-Out Date </th>
                    <th> Tape-Out Pass Date </th>
                    <th> Fab/out </th>
                    <th> COB </th>
                    <th> Assembly Out </th>
                    <th> Untested Ship Out </th>
                    <th> FT Out </th>
                    <th> Tested Ship Out </th>
                    <th> ESD/LU </th>
                    <th> Gun test </th>
                    <th> HTOL 500hrs </th>
                    <th> Pkg Qual Test </th>
                    <th> Apps Road Test Qual </th>
                    <th> RTP </th>
                    <th> PCN </th>
                    <th> Remarks </th>
                    <th> Actions </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-reportData>
                  <tr class="p-selectable-row">
                     <td style="padding-left:15px;">
                      <span class="data-style">{{ reportData.projectName }}</span>
                    </td>
                     <td style="padding-left:15px;">
                      <span class="data-style">{{ reportData.dieCode!=null?reportData.dieCode.dieCode:"-" }}</span><br>
                      <span class="data-style">{{ reportData.dieCode!=null?reportData.dieCode.partNumber:"-" }}</span><br>
                      <span class="data-style">{{ reportData.dieCode!=null?reportData.dieCode.package:"-" }}</span>
                    </td>
                     <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.tapeOut?.currentDate }}</span><br>
                        <span class="data-style">{{ reportData.tapeOut?.actualDate }}</span><br>
                        <span class="data-style">{{ reportData.tapeOut?.delta }}</span>
                      </td>
                       <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.tapeoutPassDate!=null?reportData.tapeoutPassDate.currentDate:"-" }}</span><br>
                        <span class="data-style">{{ reportData.tapeoutPassDate!=null?reportData.tapeoutPassDate.actualDate:"-" }}</span><br>
                        <span class="data-style">{{ reportData.tapeoutPassDate!=null?reportData.tapeoutPassDate.delta:"-" }}</span>
                      </td>
                       <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.fabOut!=null?reportData.fabOut.currentDate:"-" }}</span><br>
                        <span class="data-style">{{ reportData.fabOut!=null?reportData.fabOut.actualDate:"-" }}</span><br>
                        <span class="data-style">{{ reportData.fabOut!=null?reportData.fabOut.delta:"-" }}</span>
                      </td>
                     <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.cob?.currentDate }}</span><br>
                        <span class="data-style">{{ reportData.cob?.actualDate }}</span><br>
                        <span class="data-style">{{ reportData.cob?.delta }}</span>
                      </td>
                       <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.assyOut?.currentDate }}</span><br>
                        <span class="data-style">{{ reportData.assyOut?.actualDate }}</span><br>
                        <span class="data-style">{{ reportData.assyOut?.delta }}</span>
                      </td>
                       <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.untestedSample?.currentDate }}</span><br>
                        <span class="data-style">{{ reportData.untestedSample?.actualDate }}</span><br>
                        <span class="data-style">{{ reportData.untestedSample?.delta }}</span>
                      </td>
                       <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.ftOut!=null?reportData.ftOut.currentDate:"-" }}</span><br>
                        <span class="data-style">{{ reportData.ftOut!=null?reportData.ftOut.actualDate:"-" }}</span><br>
                        <span class="data-style">{{ reportData.ftOut!=null?reportData.ftOut.delta:"-" }}</span>
                      </td>
                     <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.testedSample?.currentDate }}</span><br>
                        <span class="data-style">{{ reportData.testedSample?.actualDate }}</span><br>
                        <span class="data-style">{{ reportData.testedSample?.delta }}</span>
                      </td>
                     <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.esdlu?.currentDate }}</span><br>
                        <span class="data-style">{{ reportData.esdlu?.actualDate }}</span><br>
                        <span class="data-style">{{ reportData.esdlu?.delta }}</span>
                      </td>
                       <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{reportData.gunTest!=null? reportData.gunTest.currentDate:"-"  }}</span><br>
                        <span class="data-style">{{reportData.gunTest!=null?  reportData.gunTest?.actualDate:"-" }}</span><br>
                        <span class="data-style">{{reportData.gunTest!=null?  reportData.gunTest?.delta:"-" }}</span>
                      </td>
                       <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.htol!=null?reportData.htol.currentDate:"-"  }}</span><br>
                        <span class="data-style">{{ reportData.htol!=null?reportData.htol.actualDate:"-"  }}</span><br>
                        <span class="data-style">{{ reportData.htol!=null?reportData.htol.delta:"-"  }}</span>
                      </td>
                     <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.pkgQual?.currentDate }}</span><br>
                        <span class="data-style">{{ reportData.pkgQual?.actualDate }}</span><br>
                        <span class="data-style">{{ reportData.pkgQual?.delta }}</span>
                      </td>
                       <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.appRoad!=null?reportData.appRoad.currentDate:"-" }}</span><br>
                        <span class="data-style">{{ reportData.appRoad!=null?reportData.appRoad?.actualDate:"-"  }}</span><br>
                        <span class="data-style">{{ reportData.appRoad!=null?reportData.appRoad?.delta:"-"  }}</span>
                      </td>
                       <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.rtp!=null?reportData.rtp.currentDate:"-"  }}</span><br>
                        <span class="data-style">{{ reportData.rtp!=null?reportData.rtp.actualDate:"-"  }}</span><br>
                        <span class="data-style">{{ reportData.rtp!=null?reportData.rtp.delta:"-"  }}</span>
                      </td>
                      <td style="padding-left:15px; text-align: center;">
                        <span class="data-style">{{ reportData.pcn!=null?reportData.pcn.currentDate:"-"  }}</span><br>
                        <span class="data-style">{{ reportData.pcn!=null?reportData.pcn.actualDate:"-"  }}</span><br>
                        <span class="data-style">{{ reportData.pcn!=null?reportData.pcn.delta:"-"  }}</span>
                      </td>
                      <td style="padding-left:15px; text-align: left;">
                        <span class="data-style">{{ reportData.remarks }}</span>
                      </td>
                      <td style="padding-left:15px;">
                        <span>
                          <button
                            pButton
                            type="button"
                            pTooltip="Add Notes"
                            tooltipPosition="right"
                            class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                            icon="pi pi-plus"
                            style="margin-top: 5px; height: 25px; width: 25px"
                            (click)="AddNotes(reportData.projectGuid)"
                          ></button>
                        </span>
                      </td>
                  </tr>
                </ng-template>
    
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="18">No Reports found.</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div
      style="
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.2;
        z-index: 9999;
      "
      *ngIf="showLoading"
    >
      <i
        class="pi pi-spin pi-spinner"
        style="position: fixed; top: 50%; left: 50%; font-size: 5rem"
      ></i>
    </div>
    <p-dialog
  header="Add Notes"
  [(visible)]="reviewNotesDisplay"
  modal="modal"
  showEffect="fade"
  [style]="{ width: '70%' }"
>
  <hr />
  <div class="p-grid" style="height: 500px">
    <div class="p-col-12 ">
      <div class="p-grid p-fluid">
        <div class="p-col-12" style="text-align: center">
          <div *ngIf="allReviews.length > 0"
            class="card no-gutter widget-overview-box widget-overview-box-1"
            style="min-height: 230px; overflow-y: scroll"
          >
            <ul
              class="widget-image-list"
              *ngFor="let review of allReviews"
            >
              <li>
                <span class="listitem-value">
                  <ul>
                    <li style="text-align: left">
                      <div style="float: left">
                        <!-- <span
                          style="
                            color: red;
                            font-size: 16px;
                            padding-right: 10px;
                          "
                          >{{ review.approvedBy }}:</span
                        > -->
                        <span
                          style="
                            color: #2196f3;
                            font-size: 14px;
                            padding-right: 10px;
                          "
                          >{{
                            review.reviewDate
                              | date: "MM/dd/yyyy hh:mm a"
                          }}:</span
                        >{{ review.reviewNotes }}
                      </div>
                    </li>
                  </ul>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="p-col-12">
          <label for="description">Today's Notes</label>
          <textarea
            id="description"
            type="text"
            rows="5"
            [(ngModel)]="reviewNotes"
            pInputTextarea
          ></textarea>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-12" style="vertical-align: center">
      <small class="p-invalid" style="float:right;">{{responseError}}</small>
    </div>
    <div class="p-col-12 p-md-8"></div>
    <div class="p-col-12 p-md-4">
      <span
        class="p-input-icon-left"
        style="float: right; margin-right: 10px; margin-top: 8px"
      >
        <button
          pButton
          type="button"
          label="Save"
          icon="pi pi-check"
          iconPos="left"
          class="p-button-raised p-button-success"
          style="margin-top: -5px; margin-bottom: -5px"
          (click)="saveReview()"
        ></button>
        <button
          pButton
          type="button"
          label="Cancel"
          icon="pi pi-refresh"
          iconPos="left"
          (click)="reviewNotesDisplay = false"
          class="p-button-raised p-button-secondary"
          style="margin-top: -5px; margin-bottom: -5px; margin-left: 10px"
        ></button>
      </span>
    </div>
   
  </div>
</p-dialog>
  </article>
</main>
