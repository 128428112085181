import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectDetailsService {

  constructor(
    private httpService: HttputilityService
  ) { }

  CreateSiliconLayer(data) {
    return this.httpService.Add('PMAPI', `Projects/CreateSiliconLayer`, 'httpOptions', data)
  }

  createProject(data) {
    return this.httpService.Add('PMAPI', `Projects/CreateProject`, 'httpOptions', data)
  }
  getProjectDetailsById(id) {
    return this.httpService.Get('PMAPI', `Projects/GetProjectByID?ProjectID=${id}`);
  }
  getListsByProjectId(projectId) {
    return this.httpService.Get('UMSAPI', `Lists/GetLists?ProjectID=${projectId}`)
  }
  getTasksByListId(listId) {
    return this.httpService.Get('UMSAPI', `Tasks/GetTasksByList?ListID=${listId}`)
  }
  getSpaceList() {
    return this.httpService.Get('PMAPI', `Spaces/GetSpaces`)
  }
  getCustomFieldList(projectId) {
    return this.httpService.Get('UMSAPI', `customfields/GetCustomFieldbyEntityID?EntityID=` + projectId)
  }
  getUserList() {
    return this.httpService.Get('UMSAPI', `user/GetUsers`)
  }
  getProjectManagerUsers() {
    return this.httpService.Get('UMSAPI', `user/GetProjectManagers`)
  }
  saveSpace(space) {
    return this.httpService.Add('PMAPI', `Spaces/CreateSpace`, 'httpOptions', space)
  }
  deleteSpace(space) {
    return this.httpService.Add('PMAPI', `Spaces/DeleteSpace`, 'httpOptions', space)
  }
  getProjectDescription(){
    return this.httpService.Get('PMAPI', `Projects/GetAllProjectDescriptions`)
  }
  saveProjectDesc(data) {
    return this.httpService.Add('PMAPI', `Projects/SaveProjectDescription`, 'httpOptions', data)
  }
  deleteProjectDesc(data) {
    return this.httpService.Add('PMAPI', `Projects/DeleteProjectDescription`, 'httpOptions', data)
  }
  GetReportData(){
    return this.httpService.Get('PMAPI', `Report/GetReport/`);
  }

  GetManagerProjects(userid){
    return this.httpService.Get('PMAPI', 'Project/GetManagerProjects?UserID='+userid);
  }

  GetActiveProjects(){
    return this.httpService.Get('PMAPI', 'Project/GetActiveProjects');
  }

  GetProjectByID(projectId){
    return this.httpService.Get('PMAPI', 'Projects/GetProjectByID?ProjectID='+projectId);
  }

  UpdateProjectActualStartDate(ProjectID,ChangeDate){
    return this.httpService.Get('PMAPI', `Project/UpdateProjectActualStartDate?ProjectID=`+ProjectID+'&ChangeDate='+ChangeDate);
  }

  GetDates(ProjectID,tasks,Update,By){
    return this.httpService.Add('PMAPI', `Tasks/GetDates?ProjectID=`+ProjectID+'&Update='+Update+'&By='+By,'httpOptions', tasks);
  }
  UpdateTaskAssignee(tasks){
    return this.httpService.Add('PMAPI', 'Tasks/UpdateTaskAssignee','httpOptions', tasks);
  }
  GetManageMilestoneDates(tasks){
    return this.httpService.Add('PMAPI', 'Tasks/GetManageMilestoneDates','httpOptions', tasks);
  }

  /*BEGIN- Added by Suresh*/
  GetP2ProjectByID(productId){
    return this.httpService.Get('PMAPI', 'Projects/GetReviewDetails?ProjectID='+productId);
  }

  // InserReview(data) {
  //   return this.httpService.Add('PMAPI', `Projects/InsertReview`, 'httpOptions', data)
  // }

  // UpdateReview(data) {
  //   return this.httpService.Add('PMAPI', `Projects/UpdateReview`, 'httpOptions', data)
  // }

  // GetReviews(projectId){
  //   return this.httpService.Get('PMAPI', 'Projects/GetReviews?ProjectID='+projectId);
  // }
  
  // InserMeeting(data) {
  //   return this.httpService.Add('PMAPI', `Projects/InsertMeeting`, 'httpOptions', data)
  // }

  SaveMeeting(data) {
    return this.httpService.Add('PMAPI', `ReviewAction/SaveMeetings`, 'httpOptions', data)
  }
  // GetMeetings(projectId){
  //   return this.httpService.Get('PMAPI', 'Projects/GetMeetings?ProjectID='+projectId);
  // }
  /*END- Added by Suresh*/

  UpdateSecurityToken(token){
    return this.httpService.Add('UMSAPI', "user/UpdateSecurityToken?Token=" + token, 'httpOptions', null);
  }
  CreateSecurityToken(){
    return this.httpService.Add('UMSAPI', "user/CreateSecurityToken", 'httpOptions', null);
  }
  GetMenusByUser(userId,applicationid)
  {
    return this.httpService.Get('UMSAPI','GetMenusByUser?userid='+userId+'&applicationid='+applicationid);
  }
  downloadDMSFile(filename,newname){
    return this.httpService.downloadDMSFile('Download?filename='+filename+"&newname="+newname);
  }
  AddUserTracking(model){
    return this.httpService.Add('UMSAPI', "user/AddUserTracking", 'httpOptions', model);
  }
}
