import { Component, OnInit, ViewChild} from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from 'primeng/table';
import { Router} from '@angular/router';
import { SortEvent} from 'primeng/api';
import { DatePipe } from '@angular/common';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-overduemilestone',
  templateUrl: './overduemilestone.component.html',
  styleUrls: ['./overduemilestone.component.scss']
})
export class OverduemilestoneComponent implements OnInit {

  loading: "loading";
  showLoading:boolean;
  cols: any[];
  overduetasks: any[];
  selectedoverduetasks: any[]=[];
  @ViewChild('dt') table: Table;

  milestones:any[];
  selectedmilestones:any[]=[];

  productlines: any[];
  selectedproductlines:any[]=[];

  projects: any[];
  selectedprojects:any[]=[];

  owners: any[];
  selectedowners:any[]=[];

  error:string = "";
  Count:number = 0;
constructor(
  private _facadeService: FacadeService,
  private datePipe: DatePipe,
  private router: Router,public datepipe: DatePipe,private printService:PrintcommonService
) {}

ngOnInit() {
  this.showLoading = true;
  
  this.cols = [
    { field: 'projectName', header: 'Project' },
    { field: 'dieCode', header: 'Die Code' },
    { field: 'mfgPart', header: 'Mfg Part' },
    { field: 'productLine', header: 'Product Line' },
    { field: 'projectOwner', header: 'Product Engineer' },
    // { field: 'currentPhase', header: 'Current Phase' },
    { field: 'taskName', header: 'Milestone' },
    { field: 'taskAssignee', header: 'Assignee' },
    { field: 'targetEndDate', header:'Target End Date' },
    { field: 'currentEndDate', header: 'Current End Date' },
  ];
  sessionStorage.projectName = "";
  sessionStorage.dieCode = "";
  sessionStorage.mfgPart = "";
  sessionStorage.productLine = "";
  sessionStorage.targetEndDate = "";
  sessionStorage.currentEndDate = "";
  sessionStorage.projectOwner = "";
  sessionStorage.taskName = "";
  this.GetMilestones();
  
}
GetMilestones() {
  this.showLoading = true;
  this.selectedmilestones = [];
  this._facadeService
  .GetAllMilestones()
  .subscribe((data) => {
    this.milestones = data.returnObject;
    let ok = false;
    if(sessionStorage.getItem('overduemilestone') != null && sessionStorage.getItem('overduemilestone') != undefined && sessionStorage.getItem('overduemilestone').indexOf("object") <= -1)
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('overduemilestone').split(',');
      projectstats.forEach((element) => {
        this.milestones.forEach((ln) => {
          if(ln.taskName == element)
            this.selectedmilestones.push(ln);
        });
      });
      ok = true;
    }
    if(!ok)
    {
      if(this.milestones != null && this.milestones != undefined)
      {
        if(this.milestones.length > 0)
        {
          this.milestones.forEach((ln) => {
            this.selectedmilestones.push(ln);
          });
          var lm=[];
          this.selectedmilestones.forEach((element) => {
            lm.push(element.taskName);
          });
          sessionStorage.overduemilestone = lm;
        }
      }
    }
    this.GetOwners();
  });
}

GetProductLine() {
  this._facadeService
  .GetProductLine()
  .subscribe((data) => {
    this.productlines = data.returnObject;
    let ok = false;
    if(sessionStorage.getItem('overdueline') != null && sessionStorage.getItem('overdueline') != undefined && sessionStorage.getItem('overdueline').indexOf("object") <= -1)
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('overdueline').split(',');
      projectstats.forEach((element) => {
        this.productlines.forEach((ln) => {
          if(ln.id == element)
            this.selectedproductlines.push(ln);
        });
      });
      ok = true;
    }
    if(!ok)
    {
      if(this.productlines != null && this.productlines != undefined)
      {
        if(this.productlines.length > 0)
        {
          this.productlines.forEach((ln) => {
            this.selectedproductlines.push(ln);
          });
        }
      }
      var lm=[];
      this.selectedproductlines.forEach((element) => {
        lm.push(element.id);
      });
      sessionStorage.overdueline = lm;
    }
    this.GetProjects();
  });
}

GetOwners() {
  this._facadeService
  .GetProductOwners()
  .subscribe((data) => {
    this.owners = data.returnObject;
    let ok = false;
    if(sessionStorage.getItem('overdueowner') != null && sessionStorage.getItem('overdueowner') != undefined && sessionStorage.getItem('overdueowner').indexOf("object") <= -1)
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('overdueowner').split(',');
      projectstats.forEach((element) => {
        this.owners.forEach((ln) => {
          ln.name = ln.firstName + " " + ln.lastName;
          if(ln.userGuid == element)
            this.selectedowners.push(ln);
        });
      });
      ok = true;
    }
    if(!ok)
    {
      if(this.owners != null && this.owners != undefined)
      {
        if(this.owners.length > 0)
        {
          this.owners.forEach((ln) => {
            ln.name = ln.firstName + " " + ln.lastName;
            this.selectedowners.push(ln);
          });
        }
      }
      var lm=[];
      this.selectedowners.forEach((element) => {
        lm.push(element.userGuid);
      });
      sessionStorage.overdueowner = lm;
    }
    this.GetProductLine();
  });
}

GetProjects() {
  this.projects = [];
  if(this.selectedproductlines != null && this.selectedproductlines != undefined)
  {
    if(this.selectedproductlines.length > 0)
    {
      var projectstats = [];
      this.selectedproductlines.forEach((element) => {
        projectstats.push(element.id);
      });
      this.selectedprojects = [];
      this._facadeService
      .GetProjectsByProductlines(projectstats)
      .subscribe((data) => {
        this.projects = data.returnObject;
        let ok = false;
        if(sessionStorage.getItem('overdueprojects') != null && sessionStorage.getItem('overdueprojects') != undefined && sessionStorage.getItem('overdueprojects').indexOf("object") <= -1)
        {
          var projectstats = [];
          projectstats = sessionStorage.getItem('overdueprojects').split(',');
          projectstats.forEach((element) => {
            this.projects.forEach((ln) => {
             
              if(ln.projectId == element)
                this.selectedprojects.push(ln);
            });
          });
          ok = true;
        }
        if(!ok)
        {
          if(this.projects != null && this.projects != undefined)
          {
            if(this.projects.length > 0)
            {
              this.projects.forEach((ln) => {
                this.selectedprojects.push(ln);
              });
            }
          }
          var lm=[];
          this.selectedprojects.forEach((element) => {
            lm.push(element.projectId);
          });
          sessionStorage.overdueprojects = lm;
        }
        this.getprojects();
      });
    }
    else
    {
      this.error = "Please select atleast one product line";
      this.showLoading = false;
    }
  }
  else
  {
    this.error = "Please select atleast one product line";
    this.showLoading = false;
  }
}

phasetree(PID)
{
  sessionStorage.FromReport = true;
  sessionStorage.From = "overdue";
  this.router.navigate(["/phasetree/"+PID]);
}

getprojects() {
  this.error = "";
  if(this.selectedmilestones == null || this.selectedmilestones == undefined || this.selectedmilestones.length == 0)
    this.error = "Please select atleast one milestone";
  else if(this.selectedowners == null || this.selectedowners == undefined || this.selectedowners.length == 0)
    this.error = "Please select atleast one product engineer";
  else if(this.selectedproductlines == null || this.selectedproductlines == undefined || this.selectedproductlines.length == 0)
    this.error = "Please select atleast one product line";
  else if(this.selectedprojects == null || this.selectedprojects == undefined || this.selectedprojects.length == 0)
    this.error = "Please select atleast one project";
  else
  {
    this.showLoading = true;
    var projects1 = [];
    this.selectedprojects.forEach((element) => {
      projects1.push(element.projectId);
    });
    sessionStorage.overdueprojects = projects1;
    var milestones1 = [];
    this.selectedmilestones.forEach((element) => {
      milestones1.push(element.taskName);
    });
    sessionStorage.overduemilestone = milestones1;
    var owners1 = [];
    this.selectedowners.forEach((element) => {
      owners1.push(element.userGuid);
    });
    sessionStorage.overdueowner = owners1;
    var lines = [];
    this.selectedproductlines.forEach((element) => {
      lines.push(element.id);
    });
    sessionStorage.overdueline = lines;
    var searchModel={
      'projects':projects1,
      'productLines':lines,
      'milestones':milestones1,
      'owners':owners1
    }
    this._facadeService.GetOverdueMilestone(searchModel).subscribe(
      (data) => {
          this.overduetasks = data.returnObject;
        this.overduetasks.forEach(element => {
            element.targetEndDate = this.datePipe.transform( element.targetEndDate, 'MM/dd/yyyy');
            element.currentEndDate = this.datePipe.transform( element.currentEndDate, 'MM/dd/yyyy');
          });
          
        this.CountM();
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
}
CountM()
{
  if(this.overduetasks != undefined && this.overduetasks != null)
  {
    var temp = this.overduetasks;
    if(sessionStorage.projectName != "")
      temp = temp.filter(x=> x.projectName.toLowerCase().includes(sessionStorage.projectName.toLowerCase()));
    if(sessionStorage.dieCode != "")
      temp = temp.filter(x=> x.dieCode.toLowerCase().includes(sessionStorage.dieCode.toLowerCase()));
    if(sessionStorage.mfgPart != "")
      temp = temp.filter(x=> x.mfgPart.toLowerCase().includes(sessionStorage.mfgPart.toLowerCase()));
    if(sessionStorage.productLine != "")
      temp = temp.filter(x=> x.productLine.toLowerCase().includes(sessionStorage.productLine.toLowerCase()));
    if(sessionStorage.targetEndDate != "")
      temp = temp.filter(x=> x.targetEndDate.toLowerCase().includes(sessionStorage.targetEndDate.toLowerCase()));
    if(sessionStorage.currentEndDate != "")
      temp = temp.filter(x=> x.currentEndDate.toLowerCase().includes(sessionStorage.currentEndDate.toLowerCase()));
    if(sessionStorage.projectOwner != "")
      temp = temp.filter(x=> x.projectOwner.toLowerCase().includes(sessionStorage.projectOwner.toLowerCase()));
    if(sessionStorage.taskName != "")
      temp = temp.filter(x=> x.taskName.toLowerCase().includes(sessionStorage.taskName.toLowerCase()));
    this.Count = temp.length;
  }
  else
    this.Count = 0;
}

test(val,field,dt)
{
  if(field == "projectName")
    sessionStorage.projectName = val;
  else if(field == "dieCode")
    sessionStorage.dieCode = val;
  else if(field == "mfgPart")
    sessionStorage.mfgPart = val;
  else if(field == "productLine")
    sessionStorage.productLine = val;
  else if(field == "projectOwner")
    sessionStorage.projectOwner = val;
  else if(field == "taskName")
    sessionStorage.taskName = val;
  else if(field == "targetEndDate")
    sessionStorage.targetEndDate = val;
  else if(field == "currentEndDate")
    sessionStorage.currentEndDate = val;
  dt = dt.filter(val,field, 'contains');
  this.CountM();
}

exportExcel() {
  /* table id is passed over here */   
  let element = document.getElementById('excel-table');
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  this.delete_row(wb.Sheets.Sheet1, 1);
  ws['!cols'] = [{ wpx : 220 },{ wpx : 80 },{ wpx : 150 },{ wpx : 70 },{ wpx : 100 },{ wpx : 170 },{ wpx : 150 },{ wpx : 90 },{ wpx : 90 }];
  /* save to file */
  var date=new Date();
  let latest_date =this. datepipe. transform(date, "MM-dd-yyyy");
  XLSX.writeFile(wb, "Overdue Milestones_" + latest_date + ".xlsx");
}

delete_row (ws, row_index){
let range = XLSX.utils.decode_range(ws["!ref"])
for(var R = row_index; R < range.e.r; ++R){
    for(var C = range.s.c; C <= range.e.c; ++C){
      ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
      if(ws[this.ec(R+1, C)].v == "&nbsp;&nbsp;" || ws[this.ec(R+1, C)].v == "&nbsp;"|| ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
        ws[this.ec(R+1, C)].v  = "";
    }
}
range.e.r--
delete (ws['J1'])
ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
return XLSX.utils.encode_cell({r:r,c:c})
}

print() {
var Content = document.getElementById('invoice-content').innerHTML;
this.printService.Print(Content,'Overdue Milestones','trSearch',true,0);
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'targetEndDate'||event.field === 'currentEndDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });
}
}
